@mixin responsive-table-active() {
    display: block;
    font-size: 14px;

    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead tr {
        left: -9999px;
        position: absolute;
        top: -9999px;
    }

    tbody tr {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    td {
      border: none;
      @if $theme-name == "leukoplast" {
        border-bottom: 1px solid $color-white;
        min-height: 38px;
      } @else {
        border-bottom: 1px solid $border-color;
      }
      padding-left: 200px;
      position: relative;
      white-space: normal;

      &.hidden-empty {
          display: none;
      }

      &:before {
        @include overflow-ellipsis();
        @include font-size($font-size-table-header);
        @if $theme-name == "leukoplast" {
          background-color: $logo-color;
        } @else {
          background-color: $accent-color;
        }
        bottom: 0;
        color: #fff;
        content: attr(data-label);
        font-weight: normal;
        left: 0;
        padding: 10px;
        position: absolute;
        top: 0;
        white-space: nowrap;
        width: 160px;
      }

      @if ($theme-name == "cutimed") {
        @media (max-width: $grid-bp-xs-max) {
          border-bottom: 1px solid $color-white;

          &::before {
            padding-left: 20px;
          }
        }
      }
    }

    &.light-table {
        td {
            &:before {
                background: transparent;
                border-right: 1px solid $border-color;
                color: $accent-color;
                font-family: $fontfamily-regular-bold;
            }
        }
    }

    .toggle-row-description {
        display: none;

        td {
            padding: 0;

            .description-container {
                .description-image-container {
                    text-align: center;
                }
            }

            &:before {
                border: none;
                width: 0;
            }
        }
    }

    .first-column {
        display: none !important;
    }
}

@if ($theme-name == "cutimed") {
  td {
    color: $color-gray;

    @media (min-width: $grid-bp-sm) {
      line-height: 20px;
    }
  }
}

.component-table {
    @include mediaquery(xs) {
        table.responsive {
          @include responsive-table-active();

            td {
                padding-left: 180px;

                @if ($theme-name == "cutimed") {
                  min-height: 46px;
                }
            }

            td:before {
                width: 140px;

                @if ($theme-name == "cutimed") {
                  font-family: $fontfamily-regular-bold;
                }
            }
        }
    }

    @include mediaquery(xxs) {
        table.responsive {
            td {
                padding-left: 140px;
            }

            td:before {
                width: 100px;
            }
        }
    }

    @include mediaquery(340px) {
        table.responsive {
            td {
                padding-left: 140px;
            }

            td:before {
                width: 100px;
            }
        }
    }
    table {
        @include mediaquery(sm) {
            font-size: 14px;
        }
        border-collapse: collapse;
        width: 100%;

        th {
            @if $theme-name == "leukoplast" {
              background-color: $logo-color;
            } @else {
              background-color: $accent-color;
            }
            color: #fff;
            font-weight: normal;
            height: 50px;

            @if ($theme-name == "cutimed") {
              font-family: $fontfamily-regular-bold;
              line-height: 25px
            }
        }

        td,
        th {
          @include mediaquery(sm) {
            padding: 10px 5px;
          }
          padding: 10px;

          @if ($theme-name != "cutimed") {
            text-align: left;
          } @else {
            @media (min-width: $grid-bp-sm) {
              text-align: center;
            }
          }
        }

        tr {
          border-bottom: 1px solid $table-light-border-color;

          &:nth-child(odd) {
            background-color: $table-row-odd-color;
          }

          &:nth-child(even) {
            background-color: $table-row-even-color;
          }

          @if ($theme-name == "cutimed") {
            @media (max-width: $grid-bp-xs-max) {
              border-bottom: none;
              line-height: 25px;

              &:nth-child(odd) {
                background-color: $table-row-odd-color-sm;
              }

              &:nth-child(even) {
                background-color: $table-row-even-color-sm;
              }
            }
          }
        }

        .form-field {
            margin-bottom: 0;

            .field-checkbox-container {
                margin-bottom: 0;

                input {
                    + label {
                        display: block;
                        min-height: inherit;
                        padding: 0;

                        i {
                            font-size: 20px;
                            position: relative;

                            &:before {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

            .field-input {
                width: 50px;
            }
        }
    }
}

@media (min-width: $grid-bp-sm) {
  .table-container {
    overflow-x: auto;
  }

  .component-table th {
    min-width: 100px;

    @if ($theme-name == "cutimed") {
      border-right: 3px solid $color-white;
    }
  }
}

.table-container--excel {
    max-height: 400px;
  }

@media (min-width: $grid-bp-md) {
  .table-container--excel {
    max-height: 600px;
  }
}

.table-container--excel tbody {
  white-space: nowrap;
}
