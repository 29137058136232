.buy-in-store-link:hover {
  text-decoration: underline;
}

.buy-in-store-map {
  height: 500px;
  width: 100%;
}

.buy-in-store-no-results {
  @include font-size($font-size-form-error-message);
  font-family: $fontfamily-regular;
  padding-top: 20px;
  text-align: center;
}

.buy-in-store-tabs .tab-list,
.buy-in-store-tabs .tab-list .tab {
  border-bottom: 3px solid $border-color ;
}

.buy-in-store-tabs .tab {
  bottom: -3px;
  padding: 2px 12px;
}

.buy-in-store-tabs .tab.active {
  border: 3px solid $border-color;
  border-bottom: transparent;
}

.buy-in-store-tabs .tab-content {
  margin-top: 10px;
}

.buy-in-store-map .marker-heading {
  @include font-size($font-size-header-item-title);
  color: $success-color;
}

.buy-in-store-map .buy-in-store-marker .marker-text-content p {
  color: $color-black;
  font-size: 12px;
}

@media (max-width: $grid-bp-sm) {
  .buy-in-store-popUp .mfp-content {
    position: absolute;
    top: 0;
    left: 0;
  }
}

//Iri Form
.where-to-buy .icon-validation-cross {
  display: none !important;
}

.retailer-cointainer {
  position: relative;
}

.retailer-cointainer h2,
.specialty-stores h2,
.buy-direct h2 {
  @include font-size($font-size-paragraph);
  font-family: $fontfamily-regular-bold !important;
  color: $buy-now-button-color
}

.buy-direct .simple-link-list-image {
  max-width: 200px;
}

.article-group-item {
  border-bottom: 1px $border-color solid;
  padding: 15px 0;
}

.article-group-item p {
  margin-bottom: 0;
}

.article-item-title {
  font-family: $fontfamily-regular !important;
  font-weight: 400;
}

.article-item {
  font-family: $fontfamily-regular-bold !important;
  color: $segment-color;
}
