.site-search-box {
  position: relative;
  padding-top: 15px;

  @if ($theme-name == "women") {
    padding-top: 0;
  }
}

.site-search-box-header.site-search-box {
  @media (max-width: $grid-bp-sm-max) {
    padding-top: 0;
  }
}

.overlay-search-bg {
  @include transition(opacity $mobile-header-animation-speed);
  @include backgroundBackdrop();

  z-index: 4;
  top: $header-height;
  opacity: 1;

  @if ($theme-name == "actimove") {
    top: 130px;
  } @else if ($theme-name == "cutimed") {
    top: 185px;
  } @else if ($theme-name == "men") {
    top: 125px;
  }
}

.site-search-box--is-expanded {
  position: absolute;
  z-index: 10;
  width: auto;
  right: 20px;
  left: 60px;
  height: 38px;
  background-color: transparent;

  @media (min-width: $grid-bp-xs-max) {
    right: unset;
    left: unset;
    width: 50%;
  }

  @media (min-width: $grid-bp-md) {
    top: 35px;
    left: 325px; // value position left from header-details-items-container(this is parent container) according to design
    width: 70%;
    height: 50px;

    @if ($theme-name == "men") {
      top: 20px;
    }

    @if ($theme-name == "cgr" or $theme-name == "com") {
      top: -5px;
    }

    @if ($theme-name == "women") {
      height: initial;

      @media (min-width: $grid-bp-md) {
        top: 15px;
      }
    }
  }
}

@media (min-width: $grid-bp-md) {
  .header-search-expanded .header-top {
    padding-top: 14px;
  }
}

.input-site-search {
  @include font-size-and-line-height($font-size-input-search, $line-height-input-search);

  box-sizing: border-box;
  border: transparent;
  color: $home-color;
  background-color: transparent;
  height: 38px;
  padding-left: 40px;
}

.site-search-page {
  padding-bottom: 40px;
}

.site-search-page .input-site-search,
.site-search-box--is-expanded .input-site-search {
  position: relative;
  width: 570px;
  border: 1px solid $input-search-border-color;
  border-radius: $btn-border-radius;
  padding-right: 40px;
}

.site-search-page .site-search-box .btn-search--reset {
  transform: unset;
  top: $btn-search-reset-position-top;
}

.site-search-box--is-expanded .input-site-search {
  border: 1px solid $input-search-border-color-active;
  background: $color-white;
}

.header-details-items-container .site-search-page .input-site-search:focus {
  outline: 1px solid $input-search-border-color-active;
}

.header-details-items-container .site-search-box--is-expanded .input-site-search:focus {
  outline: none;

  @media (min-width: $grid-bp-md) {
    @if ($theme-name != "jobst") {
      outline: 1px solid $input-search-border-color-active;
    }
  }
}

.btn.btn-search {
  width: 28px;
  height: 28px;
}

.site-search-box-header .input-site-search {
  cursor: pointer;

  @media (max-width: $grid-bp-sm-max) {
    width: 0;
  }
}

.site-search-box-header .input-site-search::placeholder {
  @include font-size($font-size-paragraph);

  font-family: $header-search-placeholder-font-family;
  color: $input-search-placeholder-color;
  opacity: 1;
}

@if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
  @media (min-width: $grid-bp-md) {
    .site-search-box-header .input-site-search:hover::placeholder {
      color: $header-item-link-hover;
    }

    .site-search-box--is-expanded .input-site-search:hover::placeholder {
      color: $input-search-placeholder-color-active;
    }
  }
}

.site-search-page .input-site-search::placeholder,
.site-search-box--is-expanded .input-site-search::placeholder {
  @include font-size($font-size-placeholder-site-search);

  border-radius: $btn-border-radius;
  font-family: $fontfamily-regular;
  color: $input-search-placeholder-color-active;
}

.input-site-search::-webkit-search-cancel-button {
  display: none;
}

.site-search-box--is-expanded .input-site-search {
  cursor: auto;
}

.header-search-expanded .btn-search--collapsed.btn-search--mobile {
  display: none;
}

.site-search-box-header .btn-search--collapsed .icon {
  &:hover {
    color: $home-color;

    @if ($theme-name == "women") {
      color: $color-white;
    }
  }
}

.btn.btn-search--reset {
  position: absolute;
  right: 40px;
  top: 20px;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 26px;
  background-color: $input-search-color;
  border: 1px solid $color-white;

  &:hover {
    background-color: $input-search-color;
  }

  @if ($theme-name == "women") {
    min-height: 26px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-blue-light;
  }

  @if (
    $theme-name ==
      "men" or
      $theme-name ==
      "cgr" or
      $theme-name ==
      "leukoplast" or
      $theme-name ==
      "jobst" or
      $theme-name ==
      "cutimed"
  ) {
    top: 33px;
    transform: translateY(-50%);
  }

  @if ($theme-name == "actimove") {
    top: 23px;
  }

  @media (min-width: $grid-bp-md) {
    left: 535px;
  }
}

.site-search-box .btn::after {
  @if ($theme-name == "jobst") {
    display: none;
  }
}

.site-search-page .btn.btn-search--reset {
  right: 145px;
}

.btn.btn-search--reset .icon {
  transform: translateY(0);
  left: 2px;
  top: 2px;
  color: $color-white;
}

.btn .icon-validation-cross {
  @include font-size($btn-search-page-close-icon);

  position: absolute;
  color: $home-color;

  @if ($theme-name == "women") {
    color: $color-white;
  }
}

.search-icon-path {
  stroke: $logo-color;

  @if ($theme-name == "women" or $theme-name == "cgr") {
    stroke: $color-white;
  }
}

.site-search-page .btn-search:disabled .search-icon-path,
.site-search-box--is-expanded .btn-search:disabled .search-icon-path {
  stroke: $input-search-color;
}

.site-search-box .btn-search {
  left: 6px;
  top: $btn-search-icons-position-top;

  @if ($theme-name == "women") {
    transform: translateY(-50%);
    line-height: initial;
    min-height: initial;
  }
}

.site-search-box--is-expanded .btn-search {
  pointer-events: auto;
}

@if ($theme-name != "cutimed") {
  .search-result-filter-item {
    @include roundedButton();

    &--is-active {
      background-color: $segment-color;
      color: $color-white;
      pointer-events: none;
    }
  }
}

.search-page-content {
  padding-top: 10px;
}

.search-page-content .search-article-link {
  display: flex;
  width: 100%;
}

.search-article-list {
  display: flex;
  padding-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  border-bottom: 1px solid $input-search-color;
}

.search-article-list .search-article-title {
  @include font-size-and-line-height($font-size-items-list, $line-height-accordion-title);

  padding-bottom: 10px;
  font-weight: normal;
  font-family: $fontfamily-regular;
}

.search-article-list-content {
  width: 90%;
}

.search-article-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 10%;
  font-size: 18px;
}

.search-pagination-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.search-result-filter--is-mobile {
  display: none;
}

.none-results-search-page .title.has-border {
  @include font-size($font-size-section-title);

  border-bottom: 1px solid $title-border-color;
  font-family: $fontfamily-regular;
  padding-bottom: 10px;
}

.search-pagination {
  margin-left: auto;
  margin-top: 15px;
  width: auto;
}

@if ($theme-name == "women") {
  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-blue-light;
  }

  .site-search-box--is-expanded .btn-search .search-icon-path {
    stroke: $color-blue;
  }

  .site-search-box--is-expanded .input-site-search {
    color: $primary-light-button-color;
  }

  @media (max-width: $grid-bp-sm-max) {
    .site-search-box-header .input-site-search::placeholder {
      color: $color-blue-light;
    }
  }

  @media (min-width: $grid-bp-md) {
    .header-container .menu {
      margin-top: 10px;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .site-search-box-header .input-site-search::placeholder {
    color: $logo-color;
    font-size: 14px;
    line-height: 18px;
  }

  .site-search-box--is-expanded .input-site-search,
  .site-search-page .input-site-search {
    border: 1px solid $segment-color;
    color: $segment-color-alt;
    font-size: $font-size-placeholder-site-search;
    line-height: 18px;
  }

  .site-search-box--is-expanded .btn-search:disabled .search-icon-path,
  .site-search-page .btn-search:disabled .search-icon-path {
    stroke: $segment-color;
  }

  .header-details-items-container .search-icon-path {
    stroke: $segment-color-alt;

    @media (min-width: $grid-bp-md) {
      stroke: $logo-color;
    }
  }

  .header-details-items-container .site-search-box--is-expanded .search-icon-path {
    @media (max-width: $grid-bp-sm-max) {
      stroke: $logo-color;
    }
  }

  .site-search-page .site-search-box .input-site-search::placeholder,
  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $segment-color;
  }

  .btn.btn-search--reset {
    background-color: $segment-color;
  }

  .site-search-page .input-site-search:focus,
  .site-search-box--is-expanded .input-site-search:focus {
    outline: 1px solid $segment-color-alt;
  }

  .search-article-list .search-article-title,
  .search-article-icon,
  .btn.btn-search--collapsed .icon,
  .btn.btn-search--collapsed .icon:hover {
    color: $logo-color;
  }

  .search-result-filter-item {
    border: 1px solid $logo-color;
    color: $logo-color;

    &--is-active {
      background-color: $logo-color;
      border: 1px solid $logo-color;
      color: $color-white;
    }

    &:hover {
      color: $logo-color;
    }
  }

  .search-article-text {
    font-size: 16px;
    line-height: 22px;
  }

  .none-results-search-page .title.has-border {
    border: none;
    margin: 0;
    padding-bottom: 20px;
  }
}

@media (max-width: $grid-bp-sm-max) {
  .site-search-box-header.site-search-box--is-expanded .input-site-search {
    width: 100%;
  }

  .site-search-box--is-expanded .btn-search--collapsed {
    display: none;
  }

  .header-search-expanded .btn-search--collapsed.btn-search--mobile {
    @include arrowIcon($mobile-header-search-close-icon-color);

    display: flex;
    background-image: none;
    width: 100%;
    height: 100%;
    pointer-events: none;
    min-height: unset;

    &:before {
      display: none;
    }

    &:after {
      top: 50%;
      left: 26px;
      right: unset;
      transform: translateY(-50%) rotate(180deg);
      pointer-events: fill;
      font-weight: 400;

      @if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
        font-size: 26px;
      }
    }
  }

  .site-search-box-header.site-search-box .btn-search {
    @if ($theme-name == "women") {
      top: 18px;
    } @else {
      top: 5px;
    }
  }

  .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset {
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    min-height: unset;

    @if ($theme-name == "actimove") {
      top: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset .icon {
    font-size: 16px;
    top: unset;
    left: unset;
    transform: translate(15px, -8px);

    @if ($theme-name == "actimove") {
      transform: translate(13px, -8px);
    }
  }

  .header-details-items-container .site-search-box-header .btn-search .svg-icon {
    @if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
      width: 26px;
      height: 26px;
    } @else if ($theme-name == "jobst") {
      width: 22px;
      height: 22px;
    } @else {
      width: 20px;
      height: 20px;
    }
  }

  .site-search-box-header.site-search-box--is-expanded .btn-search .svg-icon {
    width: 16px;
    height: 16px;
  }

  .site-search-page .btn.btn-search--collapsed {
    right: calc(50% + 20px);
  }

  .overlay-search-bg,
  .search-result-filter--is-desktop {
    display: none;
  }
}

@if ($theme-name == "men") {
  .btn-search--collapsed,
  .btn-search--reset {
    clip-path: none;
  }

  .btn-search--collapsed::after {
    color: transparent;
  }

  .site-search-box .btn.btn-search--collapsed::before {
    background-color: transparent;
  }

  .btn-search--collapsed .icon.icon-validation-cross,
  .btn-search--collapsed .icon.icon-validation-cross:hover {
    color: $color-white;
  }

  .site-search-box--is-expanded .input-site-search {
    color: $color-primary-black;
  }

  .site-search-box-header .input-site-search::placeholder {
    @include line-height($line-height-input-search);

    color: $color-gray-light;
    font-family: $fontfamily-regular;
    font-weight: normal;

    @media (min-width: $grid-bp-md) {
      color: $input-search-placeholder-color;
    }
  }

  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $input-search-placeholder-color-active;
  }

  .site-search-box .btn.btn-search--reset::before,
  .site-search-box .btn.btn-search::before {
    background-color: transparent;
  }

  .site-search-box .btn.btn-search--reset::after,
  .site-search-box .btn.btn-search::after {
    color: transparent;
  }

  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-gray-light;
  }

  .site-search-box--is-expanded .btn-search .search-icon-path {
    stroke: $color-heading;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-white;
  }

  .btn.btn-search--reset,
  .btn.btn-search--reset:hover {
    background-color: $input-search-placeholder-color-active;
  }

  .btn.btn-search--reset {
    border: 1px solid $input-search-placeholder-color-active;
  }

  .header-details-items-container .btn.btn-search--reset .icon {
    color: unset;

    @media (max-width: $grid-bp-sm-max) {
      color: $color-white;
    }
  }
}

@if ($theme-name == "cgr") {
  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-blue-light;
  }

  .site-search-box--is-expanded .btn-search .search-icon-path {
    stroke: $color-link;
  }
}

@if ($theme-name == "cutimed") {
  .site-search-page .site-search-box {
    padding-top: 0;
  }

  .site-search-page .input-site-search {
    border: 1px solid $search-border-color;
    line-height: 19px;
    height: 50px;
    padding-left: 65px;

    @media (min-width: $grid-bp-md) {
      width: 570px;
    }
  }

  .site-search-page .site-search-box .btn-search {
    left: 0;
    top: 0;
    background-color: $color-blue;
    width: 50px;
    height: 50px;
    font-size: 30px;
    padding: 6px;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-gray;

    @media (min-width: $grid-bp-md) {
      stroke: $color-blue;
    }
  }

  .site-search-box .btn .icon-validation-cross {
    font-size: 26px;
  }

  .site-search-page .btn.btn-search--reset,
  .site-search-page .btn.btn-search--reset:hover {
    border: 1px solid $color-cuticell-classic;
    background-color: $color-cuticell-classic;
  }

  .site-search-box .btn.btn-search--reset .icon {
    left: 0;
    top: 0;
  }

  .site-search-box-header {
    padding-top: 0;
  }

  .site-search-box-header .input-site-search::placeholder {
    @include font-size-and-line-height(15px, 20px);

    color: $color-blue;
  }

  .site-search-box-header .btn-search {
    top: 6px;
  }

  .site-search-box--is-expanded {
    padding-top: 15px;
  }

  .site-search-box--is-expanded .input-site-search {
    border: 1px solid $header-search-border-color;
    color: $color-gray;
  }

  .site-search-box--is-expanded .input-site-search::placeholder {
    color: $input-search-placeholder-color-active;
  }

  .site-search-box--is-expanded .btn .icon-validation-cross {
    font-size: 22px;
  }

  .site-search-box--is-expanded .input-site-search {
    @include font-size-and-line-height(14px, 18px);

    padding-left: 40px;

    @media (min-width: $grid-bp-md) {
      padding-left: 55px;
    }
  }

  .site-search-box.site-search-box--is-expanded .btn-search {
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    padding: 5px;

    @media (min-width: $grid-bp-md) {
      top: 15px;
      background-color: $color-blue;
    }
  }

  .site-search-page .search-icon-path,
  .site-search-page .btn-search:disabled .search-icon-path {
    stroke: $color-white;
  }

  .site-search-box--is-expanded .search-icon-path {
    stroke: $color-blue;

    @media (min-width: $grid-bp-md) {
      stroke: $color-white;
    }
  }

  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-gray-medium;

    @media (min-width: $grid-bp-md) {
      stroke: $color-white;
    }
  }

  .none-results-search-page .title.has-border {
    @include font-size-and-line-height(23px, 26px);

    font-family: $fontfamily-regular-bold-condensed;
    border: 0;
    margin: 0;
  }

  .none-results-search-page ul {
    padding-left: 10px;
  }

  .none-results-search-page ul li {
    line-height: 25px;
    list-style-type: "- ";
  }

  .search-result-filter-item {
    @include btn("tertiary-blue");

    &--is-active {
      @include btn("secondary");
    }
  }

  .search-article-list .search-article-title {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold-condensed;
  }

  .search-article-list .search-article-text {
    line-height: 25px;
  }
}

@if ($theme-name == "actimove") {
  .site-search-box .input-site-search {
    padding-left: 40px;
  }

  .site-search-box--is-expanded .input-site-search {
    border-radius: 3px;
  }

  .site-search-page .input-site-search {
    border: 1px solid $color-aluminium;
    border-radius: 3px;

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: 470px;
    }
  }

  .site-search-page .btn.btn-search--reset {
    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      left: 440px;
    }
  }

  .site-search-page .input-site-search:focus {
    outline: 1px solid $color-primary;
  }

  .site-search-box-header .input-site-search::placeholder {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;

    @media (max-width: $grid-bp-sm-max) {
      color: $color-aluminium;
    }
  }

  .site-search-box .btn-search {
    top: 20px;
  }

  .site-search-box .btn-search .svg-icon {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-light-black;
  }

  .site-search-box--is-expanded .search-icon-path {
    stroke: $color-primary;
  }

  .site-search-box .btn {
    background-image: none;

    &:after {
      display: none;
    }
  }

  .btn.btn-search--reset {
    width: 20px;
    height: 20px;
    right: 36px;

    &:hover {
      background-color: $color-light-black;
    }
  }

  .btn.btn-search--reset .icon {
    top: 1px;
    left: 0;
  }

  .btn .icon-validation-cross {
    color: $color-black;
  }

  .search-result-filter--is-desktop {
    margin-left: 5px;
    display: none;

    @media (min-width: $grid-bp-sm) {
      display: block;
    }
  }

  .search-result-filter-item {
    @include btn("secondary");

    line-height: 22px;
    border-radius: 0;
    transform: skewX(350deg);

    &:after {
      display: none;
    }
  }

  .search-result-filter-item--is-active {
    @include btn("primary");

    border: 1px solid $logo-color;
  }

  .search-result-filter-item-text {
    display: inline-block;
    transform: skewX(10deg);
  }

  .search-page-content .product-list .component-product-box {
    width: calc(50% - 20px);

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: calc(33.33% - 20px);
    }

    @media (min-width: $grid-bp-md) {
      width: calc(25% - 20px);
    }
  }

  .search-page-content .search-pagination .pagination {
    justify-content: flex-start;
  }

  .search-pagination {
    margin-top: 4px;

    @media (min-width: $grid-bp-md) {
      margin-top: 25px;
    }
  }

  .search-article-list ~ .search-pagination {
    margin-top: 24px;

    @media (min-width: $grid-bp-md) {
      margin-top: 45px;
    }
  }

  .search-article-icon {
    color: $color-primary;
  }

  .search-article-list .search-article-title {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-titles-normal;
    font-style: italic;
  }

  .search-article-text {
    color: $color-light-black;
    line-height: 22px;
  }

  .none-results-search-page {
    color: $color-light-black;
  }

  .none-results-search-page .title.has-border {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-titles-normal;
    font-style: italic;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-color: $color-aluminium;
    color: $home-color;

    @media (min-width: $grid-bp-sm) {
      width: 66.66%;
    }
  }

  .none-results-search-page ul {
    padding-top: 24px;
    color: $color-light-black;
    padding-left: 28px;

    li:not(:first-of-type) {
      padding-top: 16px;
    }
  }
}

@if ($theme-name == "jobst") {
  .site-search-box-header .input-site-search,
  .site-search-box-header .input-site-search::placeholder,
  .site-search-page .input-site-search::placeholder {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray-50;
  }

  .header-search-expanded .site-search-box-header .input-site-search {
    color: $color-gray;
  }

  .site-search-box--is-expanded .input-site-search:focus {
    border: 1px solid $color-primary-light;
  }

  .header-details-items-container .search-icon-path {
    stroke: $color-primary;
  }

  .site-search-box--is-expanded .btn-search:disabled .search-icon-path {
    stroke: $color-gray-50;
  }

  .site-search-box--is-expanded .btn-search .search-icon-path,
  .site-search-page .btn-search .search-icon-path {
    stroke: $color-primary-light;
  }

  .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset {
    background-color: $color-gray-50;
  }

  .page-search-results .product-box-container {
    margin-bottom: -20px;
  }

  .site-search-page .input-site-search {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
    background-color: $color-white;
  }

  .site-search-page .btn-search:disabled .search-icon-path {
    color: $color-aluminium;
  }

  .site-search-page .btn-search .svg-icon {
    width: 20px;
    height: 20px;
  }

  .site-search-page .btn.btn-search--reset {
    width: 20px;
    height: 20px;
    background-color: $color-aluminium;
  }

  .site-search-page .btn.btn-search--reset .icon {
    font-size: 16px;
    top: unset;
    left: unset;
    transform: translate(15px, -8px);
  }

  .search-result-filter--is-desktop {
    display: none;

    @media (min-width: $grid-bp-sm) {
      display: block;
    }
  }

  .search-result-filter-item {
    @include font-size-and-line-height(14px, 18px);

    border: 1px solid $color-light;
    border-radius: unset;
    border-bottom-right-radius: 12px;
    color: $color-gray;
    padding: 10px;
    margin-right: 2px;

    &--is-active {
      background-color: $color-primary-light;
      color: $color-white;
      pointer-events: none;
      border-color: $color-primary-light;
    }

    &:hover {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
      color: $color-white;
    }
  }

  .search-article-list {
    border-bottom: 1px solid $color-aluminium;
  }

  .search-article-list .search-article-title {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-regular-bold;
  }

  .search-article-text {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .search-article-icon {
    color: $search-article-icon-color;
  }

  .search-page-content .search-pagination {
    margin-top: 24px;

    @media (min-width: $grid-bp-md) {
      margin-top: 40px;
    }
  }

  .search-page-content .search-pagination .pagination {
    justify-content: flex-start;
    align-items: center;
  }

  .none-results-search-page h2.title.has-border {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold;
    border-color: $border-color;
    margin-top: 8px;
    margin-bottom: 16px;
    padding-bottom: 8px;

    @media (min-width: $grid-bp-md) {
      width: 66.66%;
    }
  }

  .section-description.none-results-search-page p,
  .none-results-search-page ul li {
    @include font-size-and-line-height(18px, 26px);

    color: $color-gray;
    font-family: $fontfamily-regular-light;
  }

  .none-results-search-page ul {
    padding-top: 16px;
    padding-left: 28px;
  }

  @media (min-width: $grid-bp-md) {
    .input-site-search {
      padding-left: 35px;
    }

    .header-details-items-container .site-search-box-header .btn-search .svg-icon,
    .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset {
      width: 20px;
      height: 20px;
    }

    .site-search-box-header .input-site-search::placeholder {
      font-family: $fontfamily-regular;
      color: $color-primary;
    }

    .header-search-expanded .site-search-box-header .input-site-search::placeholder {
      color: $color-gray-50;
      font-family: $fontfamily-regular-light;
    }

    .header-details-items-container .site-search-box--is-expanded .btn.btn-search--reset .icon {
      font-size: 16px;
      top: unset;
      left: unset;
      transform: translate(15px, -8px);
    }

    .site-search-box--is-expanded .btn.btn-search--collapsed .icon {
      font-size: 22px;

      &::before {
        content: "\2715";
      }
    }
  }
}

@media (max-width: $grid-bp-sm) {
  .site-search-page {
    padding-bottom: 10px;
  }

  .site-search-page .btn.btn-search--reset {
    position: absolute;
    right: 10px;
  }

  .site-search-page .input-site-search {
    width: 100%;
  }
}

@media (min-width: $grid-bp-md) and (max-width: $grid-bp-lg) {
  .site-search-box--is-expanded {
    @if ($theme-name != "men") {
      left: 230px;
      width: 75%;
    } @else {
      width: 66%;
    }
  }

  .input-site-search.expand-search {
    width: 470px;
  }
}

@media (min-width: $grid-bp-md) {
  .site-search-page .btn.btn-search--collapsed {
    left: 540px;
  }

  .site-search-box--is-expanded .btn-search--collapsed {
    right: 0;
  }
}
