.links {
  display: none;
}

.links li {
  @if ($theme-name == "cgr" or $theme-name == "women") {
    color: $text-color;
  }

  display: block;
  float: left;
  padding: 0 0 0 20px;
  position: relative;

  @if ($theme-name == "jobst") {
    line-height: 18px;
  } @else {
    line-height: 24px;
  }
}

.links a {
  color: $header-links-color;
  font-family: $fontfamily-regular;
  font-size: 14px;

  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt;

    &:hover {
      color: $logo-color;
    }
  }

  @if ($theme-name == "women") {
    color: $header-link-active-color;
  }

  @if ($theme-name == "cutimed") {
    font-size: 12px;
    line-height: 14px;
  }

  &:hover {
    @if ($theme-name == "leukoplast") {
      color: $logo-color;
    } @else if ($theme-name == "cutimed") {
      color: $color-gray;
    }
  }
}

.links li a {
  @if ($theme-name == "men") {
    color: $color-white;

    &:hover {
      color: $color-yellow;
    }
  }
}

.country-selector,
.language-selector {
  @include mediaquery(md) {
    display: block;
  }

  float: left;
}

.language-selector .title {
  @include font-size($font-size-header-item-title);
  font-family: $fontfamily-regular;
  color: $logo-color;
  float: left;
  margin-right: 7px;

  @if ($theme-name == "leukoplast") {
    font-family: $fontfamily-regular-bold;
    color: $segment-color-alt;
  }

  @if ($theme-name == "men") {
    color: $color-white;
  }

  // TODO: Refactor with mobile header
  margin-top: 1px;

  @if ($theme-name == "cutimed") {
    color: $color-gray;
    font-size: 12px;
  }
}

.languages {
  @if ($theme-name == "leukoplast") {
    color: $segment-color-alt;
  } @else {
    color: $logo-color;
  }
  float: left;
}

.languages a.active {
  font-family: $fontfamily-regular-bold;
}

.country-selector-columns-container {
  column-count: 3;
  column-rule: 1px solid $country-selector-border-color;

  @if ($theme-name == "cutimed") {
    column-gap: 45px;
  } @else if ($theme-name == "actimove") {
    column-gap: 120px;
  } @else {
    column-gap: 50px;
  }
}

.links-container-wrapper {
  width: 100%;
  background-color: $color-heading;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed" or $theme-name == "actimove" or $theme-name == "jobst") {
    background-color: $color-white;
  }

  @if ($theme-name == "cgr") {
    background-color: $links-container-wrapper-color;
  }
}

.links-container {
  margin-left: 0;
}

@media (min-width: $grid-bp-md) {
  .links {
    display: block;
    float: right;
    margin: 0;
    min-height: 25px;
  }

  .links-container {
    left: 30px;
    position: absolute;
    right: 30px;
    z-index: 2;
    min-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 40px;
    max-width: 935px;

    @if (
      $theme-name == "com"
      or $theme-name == "actimove"
      or $theme-name == "cutimed"
      or $theme-name == "leukoplast"
      or $theme-name == "jobst") {
      justify-content: flex-end;
    }
  }
}

@media (min-width: $container-max-width) {
  .links-container {
    max-width: 1170px;
    margin: 0 auto;
  }
}

@if ($theme-name == "leukoplast") {
  .country-selector-columns-container .submenu-title {
    color: $segment-color-alt;
  }
}

.simple-link-list-image {
  margin-bottom: 20px;
}

.simple-link-list-narrow {
  margin: 0 auto;
  text-align: center;
}

.simple-link-list-narrow .reset-list {
  justify-content: space-between;
}

@media (min-width: $grid-bp-md) {
  .simple-link-list-narrow {
    margin: auto;
    text-align: left;
  }
}
