@if ($is-tena-theme) {
  .product-carousel {
    padding-bottom: 50px;
    user-select: none;
  }

  .product-carousel .swiper-slide {
    display: flex;
    height: auto;
  }

  .product-carousel-title {
    @include font-size-and-line-height($product-carousel-title-font-size, $product-carousel-title-line-height);

    margin-bottom: 35px;
    font-weight: 400;

    @if ($theme-name == "actimove") {
      font-family: $fontfamily-titles-normal;
      font-style: italic;
    } @else if ($theme-name == "women") {
      margin-bottom: 10px;
    } @else {
      font-family: $fontfamily-regular;
    }
  }

  .product-carousel-slide {
    background-color: $color-white;
    max-width: 280px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 $product-box-shadow;
    margin: 10px 10px 25px 15px;

    @if ($theme-name == "women") {
      border-radius: 10px;
      display: flex;
    }
  }

  @if ($theme-name == "women") {
    .product-carousel-slide a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .product-carousel-text:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  @if ($theme-name == "cgr") {
    .product-carousel-slide a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .product-carousel-image {
    max-width: 100%;
    object-fit: cover;
    height: 90px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @if ($theme-name == "women" or $theme-name == "cgr") {
      border-bottom: 5px solid $product-carousel-border-color;
      margin-bottom: -5px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 1;
    }
  }

  .product-carousel-content {
    width: 100%;
    text-align: center;
    padding: 10px 5px;
    word-break: break-all;

    @if ($theme-name == "women" or $theme-name == "cgr") {
      background-image: url($product-carousel-bg-image);
      background-size: cover;
      color: $color-white;
      padding: 15px 5px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: break-word;
    }
  }

  .product-carousel-text {
    @include font-size-and-line-height($product-carousel-text-font-size, $product-carousel-text-line-height);

    font-family: $fontfamily-asap;
    margin: 0;
    padding: 0;

    @if ($theme-name == 'cgr') {
      color: $color-white;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  .product-carousel .swiper-scrollbar-drag {
    background-color: $product-carousel-scrollbar-color;
    height: 5px;
    bottom: 0;
  }

  .product-intro-container-frost .component-image-carousel .swiper-scrollbar-drag {
    background-color: $product-carousel-scrollbar-color;
  }

  @media (min-width: $grid-bp-sm) {
    .product-carousel-image {
      height: 170px;

      @if ($theme-name == "women") {
        border-bottom: 5px solid $product-carousel-border-color;
      }
    }

    .product-carousel-slide {
      border-radius: 8px;
      box-shadow: 1px 4px 16px 0 $product-box-shadow;
      margin: 10px 10px 40px 15px;

      @if ($theme-name == "women") {
        margin: 10px 10px 40px 0;
      }
    }

    .product-carousel-content {
      padding: 15px 5px 25px;

      @if ($theme-name == "women") {
        padding: 25px 5px;
      }

      @if ($theme-name == "cgr") {
        padding: 16px;
      }
    }

    .product-intro-container-frost .component-image-carousel .swiper-scrollbar {
      display: none;
    }
  }
}

@if ($theme-name == 'actimove') {
  .product-list-carousel .swiper-scrollbar {
    background-color: $color-white;
    height: 6px;
    border: 1px solid $products-card-carousel-scrollbar-color;
  }

  .product-list-carousel .swiper-scrollbar-drag {
    background-color: $products-card-carousel-scrollbar-color;
  }

  .product-carousel-text {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
    font-weight: 600;
  }
}