.landing-promo-links {
  position: relative;
  padding: 270px 0;

  @media (min-width: $grid-bp-sm) {
    padding: 320px 0;
  }
}

.landing-promo-links-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, 0.44);
  z-index: 1;
}

.landing-promo-links-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-promo-links-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.landing-promo-link {
  @include font-size-and-line-height($landing-link-font-size, $landing-link-line-height);

  font-family: $fontfamily-regular-bold;
  color: $landing-link-color;
  display: block;
  background-color: $color-white;
  text-align: center;
  padding: 11px 0;
  margin-bottom: 20px;
  border-radius: 4px;
  min-width: 270px;

  @media (min-width: $grid-bp-sm) {
    padding: 24px 0;
    min-width: 418px;
  }
}