.source {
  font-size: 14px;

  @if ($theme-name == "leukoplast") {
    padding-bottom: 10px;
  } @else {
    border-top: 1px solid $border-color;
    padding: 10px 0;
  }

  .source-list {
    @extend .reset-list;

    .source-item {
      margin-bottom: 8px;
    }
  }

  a {
    @include font-setup();
		color: $cta-bgcolor;

    &:hover {
			color: $cta-bg-hover;
			text-decoration: underline;
    }
  }
}
