.compare-products {
  overflow-x: hidden;
}

.compare-products-columns {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  width: 100%;
}

.compare-products-column {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;

  &--no-data {
    height: fit-content;
  }
}

.compare-products-column:nth-child(3),
.compare-products-column:nth-child(4) {
  display: none;
}

.compare-products-item {
  background-color: $compare-product-bg-color;
  border-radius: 8px;
  padding: 24px 8px 32px;
  text-align: center;
  word-break: break-word;
}

.compare-products-item .detail {
  text-align: center;
}

.compare-products-item .rating {
  display: inline-block;
  margin-bottom: 48px;
}

.compare-products-item .compare-product-reviews {
  margin-bottom: 48px;
}

.compare-products-image {
  height: 95px;
  margin-bottom: 16px;
  max-width: 100%;
  object-fit: contain;
  width: auto;
}

.compare-products-item .box-heading {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  word-break: break-word;
}

.compare-products-item .box-text {
  margin: 0;
  padding: 8px 0 24px;
  word-break: break-word;
}

.compare-products-footer {
  margin-top: auto;
}

.compare-products-footer .btn {
  font-size: 16px;
  margin: 0;
  width: 100%;

  @media (min-width: $grid-bp-md) {
    font-size: 19px;
  }
}

.compare-products-footer .form {
  display: flex;
  flex-direction: column-reverse;
  float: none;
  margin-bottom: 0;
  width: 100%;
}

.compare-products-footer .form-field {
  margin-bottom: 8px;
}

.compare-products-footer .form .btn {
  @include btn("secondary");

  margin: 0;
  width: 100%;
  padding: 13px 0;
  margin: 30px 0 8px;
}

.compare-products-footer .buy-now-highlighted {
  margin: 30px 0 8px;
}

.compare-products-filters .select2-container,
.compare-products-filters .select2-container .select2-selection--single {
  height: 100%;
}

.compare-products-filters .select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: unset;
  white-space: normal;
  width: 100%;
}

.compare-products-heading {
  color: $color-purple;
  font-family: $fontfamily-titles-thin;
  font-size: 22px;
  line-height: 28px;
  margin: 20px 0 16px;
  text-transform: uppercase;
}

.product-color-circle {
  margin: 0 auto 8px;
}

.product-color-outer {
  border: 1px solid $input-search-color;
  border-radius: 50%;
  height: 48px;
  position: absolute;
  width: 48px;
}

.product-color-inner {
  border-radius: 50%;
  height: 40px;
  margin: 4px;
  width: 40px;
}

.compare-product-sizes {
  display: flex;
  flex-direction: column;
}

.compare-product-sizes-label,
.compare-product-length-value {
  font-family: $fontfamily-regular;
  font-size: 28px;
  line-height: 32px;
  padding-top: 28px;
}

.compare-product-length-label {
  margin-bottom: 24px;
}

.compare-product-sizes-value {
  margin-bottom: 24px;
}

.compare-product-type-image {
  max-width: 46px;
}

.compare-product-type-text {
  @include font-size-and-line-height(16px, 18px);

  margin: 8px 0;
  text-align: center;
}

.compare-product-type-link {
  text-align: center;
  color: $compare-product-type-link-color;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 48px;
}

.product-item-icon {
  align-self: center;
  width: auto;
}

.compare-product-benefit-icon {
  cursor: pointer;
  width: 64px;
}

.compare-product-benefit-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.compare-products-quantity {
  line-height: 22px;
  margin-bottom: 28px;
}

.compare-product-benefit {
  display: flex;
  flex-direction: column;
  margin: 16px 0 8px;
}

.compare-product-benefit-text {
  cursor: pointer;
  display: block;
  margin-top: 5px;
}

.compare-product-sample {
  display: flex;
  flex-direction: column;
}

.compare-products-filter .select2-selection--single .select2-selection__rendered {
  color: $color-purple;
  line-height: 18px;
  padding-left: 0;

  @if($theme-name == 'cgr' or $theme-name == 'women') {
    color: $color-primary-black;
  }
}

.compare-products-filters {
  position: relative;
  padding-top: 2px;
}

.compare-products-filters-container {
  padding: 8px 0;

  &--is-sticky {
    background-color: $color-white;
    box-shadow: 1px 4px 16px 0 $box-shadow-color;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}

.compare-product-reset {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  pointer-events: none;
}

.compare-product-reset-text {
  @include font-size-and-line-height(16px, 22px);

  padding-left: 4px;
  margin-bottom: 0;
  color: $color-purple;
  font-family: $fontfamily-regular-bold;

  @if($theme-name == 'cgr') {
    color: $segment-color;
  }
}

.compare-product-reset-icon,
.compare-product-reset-text {
  pointer-events: auto;
}

@if($theme-name == 'men') {
  .compare-products-footer .btn,
  .compare-products-footer .form .btn {
    @include btn("secondary");

    padding-right: 20px;
    background-color: $color-blue;
  }

  .compare-products-footer .btn.buy-now-highlighted {
    @include btn("primary");

    padding-right: 20px;
  }

  .compare-products-footer .btn.btn-product-details::after,
  .compare-products-footer .form .btn::after,
  .compare-products-footer .btn.buy-now-highlighted::after {
    display: none;
  }

  .compare-products-footer .btn.buy-now-highlighted {
    &::before {
      background-color: $compare-product-bg-color;
    }

    &:hover::before {
      background-color: $compare-product-bg-color;
    }
  }
}

@if($theme-name == 'cgr') {
  .compare-products-footer .form .btn {
    @include btn("primary"); 
  }
}

.compare-products .article-list-container .article-list .article-list-item .article-list-image {
  float: none;
  margin-bottom: 16px;
}

.compare-products .article-list-item-title {
  @include font-size-and-line-height(22px, 28px);

  font-family: $fontfamily-regular;
  margin-bottom: 16px;
}

.compare-products .component-article-list .article-list-container .text-content {
  @include font-size-and-line-height(16px, 22px);
}

.compare-products .panel {
  box-shadow: 1px 4px 16px 0px rgba($compare-panel-box-shadow-color, 0.2);
}

@if ($theme-name == 'women' or $theme-name == 'men' or $theme-name == 'cgr') {
  .compare-product-reset-icon {
    order: 1;
    padding-left: 4px;
  }

  .compare-products .select2-dropdown.select2-dropdown--below {
    top: 0;
  }

  .compare-product-reset-icon path,
  .compare-product-reset-icon polygon {
    stroke: $compare-reset-icon-color;
    fill: $compare-reset-icon-color;
  }

  .compare-products-item .box-heading {
    font-weight: 600;

    @media (min-width: $grid-bp-md) {
      font-weight: 400;
    }
  }

  .compare-products-heading {
    @include font-size-and-line-height(28px, 32px);
    font-weight: 400;
    letter-spacing: unset;

    @media (min-width: $grid-bp-md) {
      font-weight: 600;
      letter-spacing: 3px;
    }
  }

  .product-color-circle {
    border-color: $compare-product-circle-color;
  }

  .compare-products .product-color-text {
    @include font-size-and-line-height(16px, 18px);

    color: $color-primary-black;
  }

  .compare-products .compare-product-sizes-label {
    font-weight: 400;
  }

  .compare-products .product-item-icon path {
    stroke: $segment-color;
  }

  .compare-products .product-item-icon polygon {
    stroke: $segment-color;
    fill: $segment-color;
  }

  .compare-products .compare-products-quantity,
  .compare-products .compare-product-sample,
  .compare-products .compare-product-benefit {
    @include font-size-and-line-height(16px, 18px);
  }

  .compare-products .btn {
    padding-right: 20px;
  }

  .compare-products .btn .icon-button-right {
    display: none;
  }

  .compare-products-filter {
    border: 1px solid $compare-products-filter-border-color;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  .compare-products-filters .select2-container .select2-selection--single {
    display: flex;
    align-items: center;
  }

  .compare-products-filters .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .compare-products-footer .btn.btn-product-details {
    @if ($theme-name == 'men') {
      @include btn("primary");
    }

    @if ($theme-name == 'women') {
      @include btn("primary-light");
      @include font-size-and-line-height(16px, 22px);
    }

    @if ($theme-name == 'cgr') {
      @include btn("primary-light");

      background-color: transparent;
    }
  }

  .compare-products-footer .btn.buy-now-highlighted {
    @if ($theme-name == 'men') {
      @include btn("secondary");

      background-color: $color-blue;
    }

    @if ($theme-name == 'women') {
      @include btn("secondary");
    }

    @if ($theme-name == 'cgr') {
      @include btn("primary");
    }
  }

  .compare-products-footer .btn.btn-product-details,
  .compare-products-footer .btn.buy-now-highlighted {
    padding: 13px 0;
  }

  .compare-products .product-button-variations {
    display: flex;
    flex-direction: column-reverse;
  }

  .compare-products-footer .rating {
    margin-bottom: 0;
  }

  .compare-products > div .section:nth-child(2) .compare-products-item,
  .compare-products > div .section:nth-child(3) .compare-products-item,
  .compare-products > div .section:nth-child(4) .compare-products-item  {
    background: transparent;
    border: 1px solid $compare-product-border-color;
  }
}

@media (max-width: $grid-bp-xs-max) {
  .compare-products-filters .select2-dropdown {
    width: 100% !important;
  }

  .compare-products-filters .select2-container {
    left: 0 !important;
    right: 0;
    width: 100%;
  }
}

@media (min-width: $grid-bp-sm) {
  .compare-products-columns {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .compare-products-column:nth-child(3) {
    display: flex;
  }

  .compare-products-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  .compare-products-image {
    height: 120px;
    margin-bottom: 24px;
  }

  .compare-products .btn-buy-now {
    margin: 24px 0 16px;
  }
}

@media (min-width: $grid-bp-md) {
  .compare-products-image {
    height: 160px;
  }

  .compare-products-item .box-heading {
    font-family: $fontfamily-regular;
    font-size: 22px;
    line-height: 28px;
  }

  .compare-products-heading {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (min-width: $grid-bp-lg) {
  .compare-products-columns {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .compare-products-column:nth-child(4) {
    display: flex;
  }
}
