@if ($is-tena-theme) {
  .product-supporting-carousel-frost-wrapper {
    background-color: $product-box-background;
  }

  .product-supporting-carousel-frost {
    margin-bottom: 25px;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  @media (max-width: $grid-bp-xs) {
    .product-supporting-carousel-frost-wrapper .product-box-img img {
      max-width: 90px;
      max-height: 100%;
    }
  }

  .product-supporting-carousel-frost .product-carousel-title {
    @include font-size-and-line-height(28px, 32px);

    padding-bottom: 10px;
    color: $home-color;
    font-family: $fontfamily-regular;
    border-bottom: 1px solid $title-border-color;
    max-width: 100%;
  }

  .product-supporting-carousel-frost .component-product-box--full-width {
    width: 100%;
    cursor: default;
  }

  .product-supporting-carousel-frost .product-box-container {
    height: 100%;
  }

  .product-supporting-carousel-frost .swiper-wrapper {
    padding-left: 10px;
  }

  .product-supporting-carousel-frost .swiper-slide {
    height: auto;
  }

  .product-supporting-carousel-frost .swiper-scrollbar {
    background-color: $scrollbar-background-color;
  }

  .product-supporting-carousel-frost .swiper-scrollbar-drag {
    background-color: $scrollbar-drag-color;
  }

  @media (min-width: $grid-bp-sm) {
    .product-supporting-carousel-frost-wrapper {
      background-color: $color-white;
    }

    .product-supporting-carousel-frost .product-carousel-title {
      max-width: 65%;
    }

    .product-supporting-carousel-frost .swiper-wrapper {
      padding-left: 0;
    }
  }
}

@if ($theme-name == 'actimove') {
  .product-supporting-carousel-frost-wrapper {
    background-color: $color-white;
  }

  .product-supporting-carousel-frost .product-carousel-title {
    @include font-size-and-line-height($font-size-section-title, $line-height-section-title);

    color: $section-title-color;
    border-bottom: 1px solid $color-aluminium;
    font-family: $fontfamily-header;
    max-width: 100%;
    font-style: italic;
  }

  .product-supporting-carousel-frost .swiper-scrollbar {
    background-color: $color-white;
    height: 8px;
    border: 1px solid $products-card-carousel-scrollbar-color;
  }

  .product-supporting-carousel-frost .swiper-scrollbar-drag {
    background-color: $products-card-carousel-scrollbar-color;
  }
}

@if ($theme-name == 'jobst') {
  .product-supporting-carousel-frost-wrapper {
    background-color: $color-light-25;
  }

  .product-supporting-carousel-frost .product-carousel-title {
    @include font-size-and-line-height(28px, 32px);

    padding-bottom: 0;
    color: $color-heading;
    font-family: $fontfamily-regular-bold;
    font-weight: 400;
    border-bottom: none;
    margin-bottom: 24px;
    margin-left: 10px;
  }

  .product-supporting-carousel-frost .swiper-wrapper {
    padding-left: 12px;
  }

  .product-supporting-carousel-frost .swiper-slide {
    max-width: 160px;
  }

  @media (min-width: $grid-bp-xxs) {
    .product-supporting-carousel-frost .swiper-slide {
      max-width: 182px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .product-supporting-carousel-frost .swiper-slide {
      max-width: 220px;
    }
  }

  @media (min-width: $grid-bp-lg) {
    .product-supporting-carousel-frost .swiper-slide {
      max-width: 280px;
    }
  }
}