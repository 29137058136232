.cta--has-background {
  background-color: $cta-background-color;
}

.cta--has-background-light {
  background-color: $cta-background-light-color;
}

@if ($theme-name == "cutimed") {
  .cta--is-double.cta--has-background .cta-item {
    margin-bottom: 25px;
  }

  .cta--is-double .cta-content,
  .cta--is-double .btn.cta-btn {
    text-align: center;
  }

  .cta--is-double .cta-title {
    @include font-size-and-line-height(35px, 42px);
  }

  .cta--is-double .cta-text {
    margin-top: 10px;
  }

  .cta--has-background .cta-content {
    color: $color-white;
  }
}

@media (min-width: $grid-bp-sm) {
  .cta--is-double {
    @include clearfix();
  }

  .cta--is-double .cta-item {
    float: left;
    width: 50%;
  }

  @if ($theme-name == "cutimed") {
    .cta--is-double {
      display: flex;
      justify-content: space-between;
      gap: 25px;

      &::before,
      &::after {
        display: none;
      }
    }

    .cta--is-double .cta-item {
      flex-basis: 50%;
    }

    .cta--is-double .cta-content {
      text-align: left;
    }

    .cta--is-double .cta-title {
      @include font-size-and-line-height(28px, 32px);
    }

    .cta--is-double .btn.cta-btn {
      margin-top: 30px;
      text-align: center;
      min-width: 280px;
    }
  }
}

.cta--is-double.cta--is-half-width .cta-item {
  float: none;
  width: 100%;
}

.cta-info {
  width: 100%;

  @media (min-width: $grid-bp-md) {
    .btn.cta-btn:nth-of-type(2) {
      margin-left: 10px;
    }
  }
}

.cta-item-link {
  display: flex;
  flex-direction: column;

  padding: $cta-spacing;
  position: relative;

  @media (min-width: $grid-bp-xs) {
    padding: 30px;

    @if ($theme-name == "women") {
      padding: $cta-spacing;
    }
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name != "actimove") {
      flex-direction: row;
    }

    align-items: center;
    padding: 25px;

    @if ($theme-name == "women") {
      padding: $cta-spacing;
    }
  }

  @media (min-width: $grid-bp-md) {
    @if ($theme-name == "actimove") {
      flex-direction: row;
    }

    @if ($theme-name == "women") {
      padding: 24px 40px;
    }
  }

  @media (min-width: $grid-bp-lg) {
    padding: 45px;

    @if ($theme-name == "women") {
      padding: 24px 40px;
    }
  }
}

.cta-btn {
  @if ($theme-name != "men" and $theme-name != "jobst" and $theme-name != "leukoplast") {
    background-color: $cta-button-background-color;
    border: 2px solid $cta-button-background-color;
    color: $cta-button-text-color;

    &:hover {
      background-color: $cta-button-hover-background-color;
      border: 2px solid $cta-button-hover-background-color;
      color: $cta-button-hover-text-color;
    }
  }

  @if ($theme-name == "women") {
    @include font-size-and-line-height(19px, 19px);

    border: 1px solid $cta-button-border-color;

    &:hover {
      border: 1px solid $cta-button-border-color;
    }
  }

  display: inline-block;
  height: 100%;
  margin-top: 25px;
  max-width: 100%;
  min-width: 170px;
  text-align: center;
  width: 100%;
  word-break: break-word;
  margin-top: 15px;

  @media (min-width: $grid-bp-sm) {
    margin-top: 15px;
  }

  @media (min-width: $grid-bp-md) {
    margin-top: 0;
    width: auto;

    @if ($theme-name != "men") {
      max-width: 300px;
    }
  }

  @if ($theme-name == "cutimed" or $theme-name == "jobst") {
    @include btn("primary");
  }

  @if ($theme-name == "actimove") {
    @include btn("primary-light");

    border: none;
    min-height: 40px;
    min-width: 130px;

    @media (max-width: $grid-bp-sm-max) {
      padding-right: 20px;
    }

    &:hover {
      border: none;
    }
  }

  @if ($theme-name == "cgr") {
    @include btn("primary-light");
  }

  .cta-btn-text {
    @include font-size($cta-btn-font-size);

    @if ($theme-name == "men" or $theme-name == 'women') {
      @include font-size-and-line-height($cta-btn-font-size, $cta-btn-line-height);
    }

    @if ($theme-name == "actimove" or $theme-name == "cutimed") {
      @include font-size-and-line-height(16px, 22px);
    }

    @if ($theme-name == "men") {
      font-family: $fontfamily-regular;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .cta-btn {
    min-width: 200px;
  }

  .cta-info .cta-btn {
    max-width: unset;
  }

  .cta-info .btn .cta-btn-text {
    margin-right: 22px;
  }

  .cta-info .btn .icon-button-right {
    @media (max-width: $grid-bp-sm-max) {
      right: unset;
    }

    &::before {
      content: "\e605";
    }
  }
}

// Selected first CTA of component with default background color
.cta.cta--has-background .cta-info .btn.cta-btn:nth-of-type(1) {
  @if ($theme-name != "men") {
    @include btn("secondary-light");
  }

  @if ($theme-name == "men") {
    @include btn("transparent-light");

    padding-left: 10px;
    padding-right: 25px;
  }
}

// Selected second CTA of component with default background color
.cta.cta--has-background .cta-info .btn.cta-btn:nth-of-type(2) {
  @if ($theme-name == "leukoplast") {
    @include btn("light");
  }

  @if ($theme-name == "actimove" or $theme-name == "women" or $theme-name == "cutimed" or $theme-name == "cgr" or $theme-name == "jobst") {
    @include btn("primary-light");
  }

  @if ($theme-name == "com") {
    @include btn("secondary");
  }

  @if ($theme-name == "men") {
    @include btn("accent");

    padding-left: 10px;
    padding-right: 35px;
    min-width: 170px;

    @media (max-width: $grid-bp-sm) {
      width: 100%;
    }
  }
}

// Selected first CTA of component with custom background color
.cta:not(.cta--has-background) .cta-info .btn.cta-btn:nth-of-type(1) {
  @if ($theme-name == "leukoplast") {
    @include btn("primary");
    @include arrowIcon($logo-color);
  }

  @if ($theme-name == "actimove" or $theme-name == "cutimed" or $theme-name == "com") {
    @include btn("secondary");
  }

  @if ($theme-name == "women" or $theme-name == "cgr") {
    @include btn("primary-light");
  }

  @if ($theme-name == "men") {
    @include btn("transparent-dark");

    padding-left: 10px;
    padding-right: 25px;
  }

  @if ($theme-name == "jobst") {
    @include btn("secondary-light");

    color: $color-primary;
    border-color: $color-primary;

    &::after {
      color: $color-primary;
    }
  }
}

// Selected second CTA of component with custom background color
.cta:not(.cta--has-background) .cta-info .btn.cta-btn:nth-of-type(2) {
  @if ($theme-name == "leukoplast") {
    @include btn("primary-light");
    @include arrowIcon($color-white);
  }

  @if ($theme-name == "actimove" or $theme-name == "women" or $theme-name == "cutimed" or $theme-name == "cgr" or $theme-name == "com" or $theme-name == "jobst") {
    @include btn("primary");

    @if ($theme-name == "com") {
      color: $color-white;
    }
  }

  @if ($theme-name == "men") {
    @include btn("secondary");

    padding-left: 10px;
    padding-right: 25px;
  }
}

.cta--is-double .cta-btn {
  text-align: left;

  @media (min-width: $grid-bp-sm) {
    max-width: 200px;
  }

  @media (min-width: $grid-bp-md) {
    white-space: initial;
  }
}

.cta--is-double .cta-text,
.cta--is-half-width .cta-text,
.cta--is-full-width .cta-text {
  @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
}

.cta--is-double .cta-btn .cta-btn-text,
.cta--is-half-width .cta-btn .cta-btn-text,
.cta--is-full-width .cta-btn .cta-btn-text {
  @if ($theme-name == "cutimed" or $theme-name == "actimove") {
    @include font-size-and-line-height(16px, 22px);
  } @else if ($theme-name == "jobst") {
    font-size: 18px;
  } @else {
    font-size: 19px;
  }
}

.cta--is-half-width .cta-btn {
  white-space: initial;
  @if ($theme-name == "cutimed") {
    text-align: center;
    margin-top: 30px;
  } @else {
    text-align: left;
  }
}

.cta--is-half-width .cta-inline-img-container {
  margin: 0 auto 25px;
}

@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
  .cta-btn {
    width: auto;
  }

  .cta--is-half-width {
    .cta-btn {
      width: 100%;
    }

    .cta-item-link {
      flex-wrap: wrap;
    }
  }

  @if ($theme-name == "actimove") {
    .cta--is-full-width .cta-btn {
      width: 100%;
      margin-top: 25px;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .cta-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: $cta-spacing;
  }

  .cta-btn {
    height: fit-content;
    align-self: center;
  }

  .cta-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .cta--is-full-width .cta-content {
    max-width: calc(100% - 250px);
  }
}

.cta-content,
.cta-text {
  @if ($theme-name != "cutimed") {
    color: $color-white;
  }
}

.cta-content {
  text-align: left;
}

.cta--is-half-width .cta-content {
  margin-right: 0;
}

.cta-content--is-dark,
.cta-content--is-dark .cta-title,
.cta-content--is-dark .cta-text {
  color: $home-color;

  @if ($theme-name == "men") {
    color: $color-heading;
  }
}

.cta-title {
  font-weight: normal;

  @if ($theme-name != "cutimed") {
    @include font-size-and-line-height(28px, 32px);
  }

  @if ($theme-name == "women") {
    text-align: center;
  }

  @if ($theme-name == "com") {
    font-family: $fontfamily-regular;
  } @else {
    font-family: $cta-title-font;
    text-transform: $cta-title-transform;
  }

  @if ($theme-name == "men") {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    margin-bottom: 10px;
  }

  @if ($theme-name == "actimove") {
    font-style: italic;
  }

  @if ($theme-name == "cgr") {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    font-family: $fontfamily-regular-bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: $grid-bp-sm) {
      letter-spacing: 4px;
    }

    @media (min-width: $grid-bp-sm) {
      padding-right: 24px;
    }
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name == "cgr") {
      text-align: left;
    }

    margin-right: 10px;
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name == "women") {
      text-align: left;
    }
  }
}

.cta-text {
  @include font-size($font-size-cta-text);

  @if ($theme-name == "men") {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
  }

  @if ($theme-name == "cgr") {
    text-align: center;
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name == "cgr") {
      padding-right: 24px;
      text-align: left;
    }
  }

  @if ($theme-name == "women") {
    text-align: center;
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name == "women") {
      text-align: left;
    }
  }
}

.cta--is-double .cta-title,
.cta-text {
  margin-bottom: 0;
}

.cta-cover-img {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.cta-inline-img-container {
  margin-bottom: 25px;
}

.cta-inline-img {
  height: auto;
  width: 120px;
}

@media (min-width: $grid-bp-sm) {
  .cta-item-link--has-cover-img .cta-content {
    margin-right: $cta-spacing;
  }

  .cta-inline-img-container {
    padding-right: $cta-spacing;
    margin-bottom: 0;
  }
}

.cta-item-link--has-inline-img {
  .cta-inline-img-container {
    flex-basis: 20%;
    text-align: center;
  }

  .cta-content {
    flex-basis: 60%;
    margin-right: 0;
  }

  .cta-text {
    margin-top: 0;
  }

  .cta-btn {
    margin-left: initial;
  }
}

.equal-height-container {
  display: flex;
  flex-wrap: wrap;

  @if ($theme-name != "leukoplast") {
    .cta-container {
      display: flex;
      flex-basis: 100%;
    }

    &.contains-different-components .cta-container {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

    &.contains-different-components .cta--is-third-width {
      margin-bottom: 0;
      height: 100%;
    }

    &.contains-different-components .cta-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.cta--is-third-width {
  width: 100%;
  margin-bottom: $cta-spacing;

  .cta-btn {
    width: 100%;
  }

  .cta-btn-text {
    @include font-size(19px);
  }

  .cta-title {
    @include font-size-and-line-height(16px, 18px);
  }

  .cta-text {
    @include font-size-and-line-height(22px, 28px);

    @if ($theme-name == "men") {
      @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
    }
  }

  .cta-item-link--has-inline-img {
    .cta-inline-img-container {
      margin-bottom: 32px;
      margin-top: 65px;
      padding-right: 0;
      text-align: center;
    }

    .cta-btn {
      margin-top: 0;
    }
  }
}

@if ($theme-name != "leukoplast") {
  .cta-container .pushbox-shadow {
    position: absolute;
    bottom: -7px;
    left: 10px;
    right: 10px;
    background-image: url("../img/box-shadow.png");
    height: $pushbox-shadow-height;
    background-position: center;
    background-color: $color-white;
  }

  .cta--is-full-width .cta-btn {
    @if ($theme-name == "cutimed") {
      text-align: center;
      margin-top: 30px;

      @media (min-width: $grid-bp-md) {
        margin-top: auto;
        margin-bottom: auto;
      }
    } @else {
      text-align: left;
    }
  }

  @media (min-width: $grid-bp-md) {
    .cta--is-full-width .cta-title {
      @include font-size-and-line-height(40px, 44px);
    }
  }
}

@if ($theme-name == "men") {
  .cta-has-gradient {
    @include ctaBackgroundGradient();
  }

  .cta--is-third-width .cta-title,
  .cta--is-full-width .cta-title,
  .cta--is-half-width .cta-title {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);
  }
}

@media (min-width: $grid-bp-sm) {
  .cta-item-link--has-cover-img {
    flex-direction: row;

    @if ($theme-name == "actimove") {
      min-height: 160px;
    } @else {
      min-height: 260px;
    }
  }

  .cta-item-link--has-inline-img .cta-inline-img-container {
    text-align: left;
  }

  .cta-inline-img {
    width: 140px;
  }

  .equal-height-container .cta-container {
    flex-basis: 50%;
  }

  .cta--is-third-width {
    .cta-item {
      height: 100%;
    }

    .cta-item-link {
      height: 100%;
    }

    .cta-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .cta-btn {
      align-self: flex-start;
      width: fit-content;
      height: fit-content;
    }
  }
}

@if ($theme-name == "cutimed") {
  .cta--is-full-width .cta-title,
  .cta--is-half-width .cta-title {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    margin-bottom: 38px;

    @media (min-width: $grid-bp-md) {
      margin-bottom: 22px;
    }
  }

  .cta--is-full-width a:hover,
  .cta--is-half-width a:hover {
    color: unset;
  }

  .cta-item-link {
    padding: 40px 18px 50px;

    @media (min-width: $grid-bp-md) {
      padding: 28px 100px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .cta--is-full-width .cta-item-link--has-inline-img .cta-content {
      margin-right: 30px;
    }
  }
}

@if ($theme-name == "actimove") {
  .cta-has-gradient {
    @include ctaBackgroundGradient();
  }

  .cta--is-full-width .cta-item-link {
    padding: 40px 24px;

    @media (min-width: $grid-bp-sm) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (min-width: $container-max-width) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .cta--is-full-width .cta-item-link.cta-item-link--has-inline-img {
    @media (max-width: $grid-bp-xs-max) {
      padding-top: 164px;
      padding-bottom: 24px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      padding-top: 240px;
    }

    @media (min-width: $grid-bp-md) {
      padding-left: 300px;
      padding-top: 64px;
      padding-bottom: 40px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 24px solid $color-white;
      }
    }
  }

  .cta--is-full-width .cta-content {
    @media (min-width: $container-max-width) {
      max-width: 770px;
    }
  }

  .cta--is-full-width .cta-title {
    @include font-size-and-line-height($cta-title-font-size, $cta-title-line-height);

    margin-bottom: 10px;
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-title {
    @include font-size-and-line-height(28px, 32px);
  }

  .cta--has-background-light .cta-btn {
    @include btn("primary");
  }

  .cta--is-full-width .cta-btn {
    @media (max-width: $grid-bp-sm-max) {
      text-align: center;

      .cta-btn-text {
        margin-right: 10px;
      }

      &:after {
        right: auto;
      }
    }

    @media (min-width: $grid-bp-md) {
      max-width: 225px;
    }
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-inline-img-container {
    position: absolute;
    width: 100%;
    padding-right: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;

    @media (max-width: $grid-bp-xs-max) {
      margin-bottom: 24px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      height: 200px;
      margin-bottom: 40px;
    }

    @media (min-width: $grid-bp-md) {
      width: 275px;
      height: 100%;
      right: unset;
      // Calculates an angle of 10 degrees on the right side
      clip-path: polygon(0 0, 100% 0, calc(100% + -0.18 * 100vmax) 100vmax, 0 100vmax);
    }
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-inline-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .cta--is-full-width .cta-item-link--has-inline-img .cta-content {
    @media (min-width: $grid-bp-md) {
      margin-right: 24px;
    }

    @media (min-width: $container-max-width) {
      margin-right: 40px;
    }
  }
}

@if ($theme-name == "jobst") {
  .cta--is-full-width .cta-item-link {
    padding: 40px 20px;

    @media (min-width: $grid-bp-md) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .cta-has-gradient {
    @include ctaBackgroundGradient();
  }

  .cta--is-full-width .cta-inline-img-container {
    margin-bottom: 24px;

    @media (min-width: $grid-bp-sm) {
      margin-bottom: 0;
      padding-right: 24px;
    }
  }

  .cta--is-full-width .cta-inline-img {
    width: 100%;

    @media (min-width: $grid-bp-sm) {
      width: 140px;
    }
  }

  .cta--is-full-width .cta-info {
    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;
      column-gap: 24px;
    }
  }

  .cta--is-full-width .cta-title {
    @include font-size-and-line-height($font-size-cta-title, $line-height-cta-title);

    margin-bottom: 24px;
  }

  .cta--is-full-width .cta-text {
    font-family: $fontfamily-regular;
  }

  .cta--is-full-width .cta-btn {
    @media (max-width: $grid-bp-xs-max) {
      text-align: center;
      padding-right: 20px;

      &:after {
        right: auto;
        margin-left: 10px;
      }
    }

    @media (min-width: $grid-bp-sm) {
      margin-top: 0;
      max-width: 225px;
    }
  }

  .cta--is-full-width .cta-item-link--has-cover-img .cta-btn {
    @include btn("primary-light");

    @media (max-width: $grid-bp-xs-max) {
      &:after {
        right: auto;
        margin-left: 10px;
      }
    }
  }
}

@if ($theme-name == "cgr" or $theme-name == "women") {
  .cta-item-link .cta-btn {
    text-align: center;
    padding-right: 20px;
    border-width: 1px;

    &:hover {
      border-width: 1px;
    }

    @media (min-width: $grid-bp-sm) {
      text-align: left;
      padding-right: 55px;
    }
  }

  .cta-item-link .cta-btn .icon-button-right {
    position: static;
    padding-left: 16px;

    @media (min-width: $grid-bp-sm) {
      position: absolute;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .equal-height-container .cta-container {
    flex-basis: 33%;
  }

  .cta--is-double .cta-info,
  .cta--is-half-width .cta-info {
    flex-direction: column;
    justify-content: flex-start;

    .cta-btn {
      align-self: start;

      @if ($theme-name != "cutimed") {
        margin-top: $vspace-sm;
      }
    }
  }
}