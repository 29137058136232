.info-panel-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 51;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  @media (min-width: $grid-bp-sm) {
    justify-content: flex-end;
  }
}

.info-panel-wrapper--opened {
  visibility: visible;
  opacity: 100%;
  pointer-events: unset;

  .panel {
    overflow-y: auto;
  }
}

.info-panel {
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 51;
  opacity: 0;
  pointer-events: none;
  width: 100%;

  @media (min-width: $grid-bp-md) {
    justify-content: flex-end;
    width: 400px;
  }
}

.info-panel--opened {
  visibility: visible;
  opacity: 100%;
  pointer-events: unset;

  .panel {
    overflow-y: auto;
  }
}

.info-panel-content {
  padding: 80px 14px 70px;
  height: 100vh;
  background-color: $color-white;
  box-shadow: 0 0 5px rgba($color-black, 0.2);
  border-radius: 8px 8px 0 0;
  width: 100%;
  overflow: auto;
  width: 100%;

  @media (min-width: $grid-bp-md) {
    top: 0;
    max-height: unset;
    height: 100%;
    padding: 68px 20px;
    transition: 0.5s;
    border-radius: 0;
    width: 400px;
  }
}

.info-panel-close-button {
  @include font-size-and-line-height(28px, 34px);

  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $product-intro-panel-close-button;
  color: $color-white;
  text-align: center;
  cursor: pointer;
  top: 12px;
  right: 18px;
  left: unset;
  display: flex;
  justify-content: center;

  @if ($is-tena-theme) {
    align-items: unset;
  }

  @if ($theme-name == 'actimove' or $theme-name == 'cutimed') {
    align-items: center;
  }

  @if ($theme-name == 'jobst') {
    color: $color-black;
  }
}

.info-panel-content-group--hide {
  display: none;
}

.svg-incontinence .full-drop {
  fill: $absorbency-color-drop-style-filled;
}

.info-panel-mainTitle {
  @include font-size-and-line-height(22px, 28px);

  margin-bottom: 15px;

  @if ($theme-name == "men") {
    text-transform: uppercase;
    color: $info-panel-main-title-color;
    font-family: $info-panel-main-title-font;
    font-weight: 500;
  } @else if ($theme-name == "women") {
    font-family: $fontfamily-regular;
  } @else if ($theme-name == "cutimed") {
    @include font-size-and-line-height(21px, 25px);

    font-family: $info-panel-main-title-font;
  } @else if ($theme-name == "jobst") {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-regular-bold;
  } @else if ($theme-name == "actimove") {
    @include font-size-and-line-height(22px, 26px);

    font-weight: 600;
  }
}

.info-panel-title {
  @include font-size-and-line-height(16px, 22px);

  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 10px;

  @if ($theme-name == "jobst") {
    @include font-size-and-line-height(18px, 26px);

    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.info-panel-description {
  margin-bottom: 20px;

  @if ($theme-name == "jobst") {
    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }
}

.info-panel-img {
  width: 100%;
  height: auto;

  @if ($theme-name == "jobst") {
    width: auto;
    height: 80px;
  }
}

.info-panel-item-circle {
  width: 44px;
  height: 44px;
  border-radius: 100%;

  @if ($theme-name == "jobst") {
    border: 1px solid $color-gray-50;
  }
}

.info-panel-item-square {
  width: 48px;
  height: 40px;
  border-bottom-right-radius: 20px;
}

.info-panel-item-image {
  height: 80px;
}