.header-logo {
  display: inline-block;
  margin-top: 0;
  width: $mobile-header-logo-width;

  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @if ($theme-name == "leukoplast") {
    left: 70px;
    position: relative;
  }

  @if ($theme-name == "cutimed") {
    align-items: center;
    display: inline-flex;
    margin: 0 auto;
    min-height: 60px;
  }

  @media (min-width: $grid-bp-md) {
    position: absolute;
    width: $header-logo-width;

    @if ($theme-name == "leukoplast") {
      left: 30px;
      top: 20px;
    } @else if ($theme-name == "cutimed") {
      display: inline-block;
      left: 30px;
      margin: 0;
      top: 45px;
    } @else if ($theme-name == "men") {
      justify-content: start;
      margin-top: -15px;
    } @else if ($theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
      left: 0;
      position: relative;
      min-height: 0;
    } @else {
      min-height: 96px;
    }
  }
}

.header-logo img {
  height: inherit;
  width: inherit;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    vertical-align: bottom;
  }

  @if ($theme-name == "men") {
    width: 165px;
    padding-top: 20px;
    padding-bottom: 13px;

    @media (min-width: $grid-bp-xxs) {
      margin-top: 0;
      margin-left: 0;
      width: 165px;
      padding-top: 20px;
      padding-bottom: 13px;
    }

    @media (min-width: $grid-bp-md) {
      margin-left: 0;
      width: 240px;
    }
  }

  @if ($theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
    width: 86px;

    @media (min-width: $grid-bp-md) {
      width: 110px;
      margin-left: 0;
    }
  }

  @if ($theme-name == "com") {
    @media (min-width: $grid-bp-md) {
      height: 60px;
      margin-top: 10px;
    }
  }
}

@if ($theme-name == "actimove") {
  .header-logo {
    left: 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    width: 125px;

    @media (min-width: $grid-bp-md) {
      left: 30px;
      width: 180px;
      margin-top: 5px;
    }
  }
}

@if ($theme-name == "jobst") {
  .header-logo {
    display: flex;
    align-items: center;
    width: 88px;
    margin-top: 8px;
    margin-left: 20px;

    @media (min-width: $grid-bp-md) {
      width: 148px;
      margin-top: 5px;
      margin-left: unset;
    }
  }
}
