* {
  box-sizing: border-box;
}

body {
  background: $background-color;
  margin: 0;
  position: relative;

  a,
  input,
  button,
  label {
    -webkit-tap-highlight-color: $btn-box-shadow;
  }

  @if ($theme-name == "leukoplast") {
    font-size: 17px;
  }
}

.body {
  position: relative;

  @if ($theme-name == "jobst") {
    background-color: $color-light-25;
  }

  @media (min-width: $grid-bp-md) {
    top: 0;
  }
}

.wrapper {
  margin-bottom: 100px;
  position: relative;

  @media (min-width: $grid-bp-md) {
    margin-bottom: 0;
  }
}

@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-md) {
  .wrapper {
    margin-bottom: 170px;
  }
}

.viewport {
  width: 100%;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

@for $i from 1 through length($screen-sizes) {
  $screen: nth($screen-sizes, $i);
  $type: nth($screen, 1);

  @include mediaquery($type) {
    .no-float-#{$type} {
      float: none;
    }
  }
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.align-text-left {
  text-align: left;
}

.align-text-center {
  text-align: center !important;
}

.align-text-center ul {
  display: inline-block;
}

.align-text-right {
  text-align: right;
}

.vspace-lg {
  margin-bottom: $vspace-lg;
}

.vspace-md {
  margin-bottom: $vspace-md;
}

.vspace-sm {
    margin-bottom: $vspace-sm;
  }

.vspace-xs {
  margin-bottom: $vspace-xs;
}

.vspace-xxs {
  margin-bottom: $vspace-xxs;
}

.vspace-none {
  margin-bottom: 0;
}

@for $i from 1 through length($screen-sizes) {
  $screen: nth($screen-sizes, $i);
  $type: nth($screen, 1);

  @include mediaquery($type) {
    .vspace-#{$type}-lg {
      margin-bottom: $vspace-lg;
    }

    .vspace-#{$type}-md {
      margin-bottom: $vspace-md;
    }

    .vspace-#{$type}-sm {
      margin-bottom: $vspace-sm;
    }

    .vspace-#{$type}-xs {
      margin-bottom: $vspace-xs;
    }

    .vspace-#{$type}-xxs {
      margin-bottom: $vspace-xxs;
    }

    .vspace-#{$type}-none {
      margin-bottom: 0;
    }
  }
}

@for $i from 1 through length($screen-sizes) {
  $screen: nth($screen-sizes, $i);
  $type: nth($screen, 1);

  @include mediaquery($type) {
    .align-title-offset-#{$type}-none {
      margin-top: 0 !important;
    }
  }
}

.date-title {
  font-size: 12px;
  margin-bottom: 5px;

  i {
    font-size: 15px;
    margin-right: 5px;
    vertical-align: -10%;
  }
}

.section {
  position: relative;
  background: {
    position: top center;
    repeat: no-repeat;
  }

  .section-description {
    @include clearfix();
    margin-bottom: 0;
    padding-bottom: 10px;

    &.no-border {
      margin-bottom: 25px;
      padding: 0;
    }

    .title,
    .subtitle {
      margin: 0;
      padding: 0;
    }

    .title {
      @if ($theme-name != "cutimed") {
        @include font-size($font-size-section-description-title);
      } @else {
        @include font-size-and-line-height(28px, 32px);
      }

      font-family: $fontfamily-titles-normal;
      padding-bottom: 5px;

      @if $theme-name != "leukoplast" {
        border-bottom: 1px solid $border-color;
        margin: 0 0 20px;
      } @else {
        margin: 20px 0;
      }

      // Font style was added due to incorrect display of LucidaGrande font in Safari browser.
      @if ($theme-name == "actimove") {
        font-style: italic;
      }

      @if ($theme-name == "jobst") {
        @include font-size-and-line-height($font-size-h1-category-page-title, $line-height-h1-category-page-title);

        font-family: $fontfamily-regular-bold;
        padding-bottom: unset;
        border-bottom: unset;
        margin-bottom: 18px;
        margin-top: 20px;

        @media (min-width: $grid-bp-md) {
          margin-top: 60px;
        }
      }
    }

    @if ($theme-name == "cutimed") {
      .mega-title {
        @include font-size-and-line-height(35px, 42px);
      }

      .mega-title,
      .title {
        font-family: $fontfamily-header;
        border: none;
        margin: 25px 0 10px;
      }

      .mega-header-description,
      .header-description {
        @include font-size-and-line-height(16px, 25px);

        color: $color-gray;
        margin: 0;
        padding: 0;
      }

      .header-description {
        max-width: 100%;

        @media (min-width: $grid-bp-sm) {
          max-width: 70%;
        }
      }
    }

    @if ($theme-name == "leukoplast") {
      .mega-title {
        margin: 20px 0;
        @include font-size($font-size-gallery-carousel-heading);
      }

      h2.title a {
        color: $logo-color;
        font-weight: bold;
      }

      ul li {
        padding-top: 20px;
      }
    }

    @if ($theme-name == "jobst") {
      h2.title {
        @include font-size-and-line-height($font-size-h2-landing-page-title, $line-height-h2-landing-page-title);

        margin-top: 30px;
      }
    }

    .description {
      font-size: 16px;

      @include mediaquery(sm) {
        width: 100%;
      }
      line-height: 23px;
      width: 60%;
    }

    p {
      @include font-size($font-size-paragraph);

      @include mediaquery(sm) {
        width: 100%;
      }
      font-family: $fontfamily-regular;
      margin-bottom: 0;
    }

    ul {
      margin: 0;

      li {
        line-height: 1.4em;
      }
    }
  }
}

.component-text-box {
  @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);
}

@if ($theme-name == "actimove" or $theme-name == "women" or $theme-name == "jobst") {
  .section-title-wrapper {
    float: none !important;
    margin-inline: auto;
  }
}

.section-title,
.component-title {
  @include font-size-and-line-height($font-size-section-title, $line-height-section-title);

  &.section-title--is-large {
    @include font-size-and-line-height($font-size-section-title-large, $line-height-section-title-large);

    letter-spacing: $letter-spacing-section-title-large;
  }

  @if ($theme-name == "com") {
    border-bottom: 1px $border-color-alt solid;
    margin-bottom: 14px;
    padding: 0 0 13px;
  } @else if ($theme-name == "women") {
    margin-bottom: 16px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: $color-blue;
  } @else {
    border-bottom: 1px $border-color solid;
    margin-bottom: 25px;
    padding: 0 0 10px;
  }

  @if ($theme-name == "men") {
    color: $color-heading;
    text-transform: uppercase;
  }

  @include mediaquery(md) {
    display: inline-block;
    width: 100%;

    @if ($theme-name == "women") {
      margin-top: 16px;
    } @else if ($theme-name == "jobst") {
      padding-bottom: 16px;
    } @else {
      margin: 24px 0 18px;
      padding: 0 0 5px;
    }
  }

  @if ($theme-name == "leukoplast") {
    letter-spacing: -0.5px;
  }

  @if ($theme-name == "cutimed") {
    color: $color-gray;
    font-family: $fontfamily-regular-bold-condensed;
  } @else if ($theme-name == "jobst") {
    font-family: $fontfamily-regular-bold;
  } @else {
    font-family: $fontfamily-titles-thin;
  }

  @if ($theme-name == "actimove") {
    color: $section-title-color;
    border-bottom: 1px solid $color-aluminium;
    font-family: $fontfamily-titles-normal;
    font-style: italic;
  }

  @if ($theme-name == "jobst") {
    font-family: $fontfamily-regular-bold;
    padding-bottom: 16px;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-light;
  }

  margin-top: 0;

  a {
    @if ($theme-name == "women" or $theme-name == "men") {
      color: $anchor-normal-state;
    } @else {
      color: $header-section-title-color;
    }

    &:hover {
      @if ($theme-name == "women" or $theme-name == "men") {
        color: $link-hover-color;
      } @else {
        color: $component-title-color;
      }
    }
  }

  &.no-border {
    border: 0;
    padding-bottom: 0;

    @if $theme-name == "leukoplast" {
      margin-bottom: 20px;
    } @else {
      margin-bottom: 10px;
    }
  }

  &.margin-small {
    margin-bottom: 5px;
  }

  &.margin-none {
    margin-bottom: 0;
  }

  &.description {
    @include font-size($font-size-section-description-title);
  }
}

@if ($theme-name == "cutimed") {
  .component-table .component-title {
    @media (max-width: $grid-bp-xs-max) {
      padding-left: 20px;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .section-single-rich-text-content .section-title {
    @include font-size-and-line-height(40px, 44px);

    letter-spacing: -0.5px;
  }

  .leukoplast-align-center {
    text-align: center !important;
  }
}

@if ($theme-name == "women") {
  .section-single-rich-text-content .section-title {
    @include font-size-and-line-height(22px, 28px);

    letter-spacing: normal;
  }

  .section-title {
    border-bottom: 1px solid rgba($color-black, 0.2);
    padding-bottom: 7px;
  }
}

@if ($theme-name == "cgr") {
  .section-title {
    color: $color-heading;
    position: relative;
    border-bottom: unset;
    padding-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 600;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      height: 7px;
      background-image: url("../img/cgr/derline/dividerline_S.svg");
      background-repeat: no-repeat;
      background-position: left;
    }

    @media (min-width: $grid-bp-sm) {
      &::after {
        background-image: url("../img/cgr/derline/dividerline_M.svg");
      }
    }

    @media (min-width: $grid-bp-md) {
      &::after {
        background-image: url("../img/cgr/derline/dividerline_L.svg");
      }
    }

    @media (min-width: $grid-bp-lg) {
      &::after {
        background-image: url("../img/cgr/derline/dividerline_XL.svg");
      }
    }
  }

  .no-border.section-title {
    &::after {
      display: none;
    }
  }

  .section-single-rich-text-content .section-title {
    @include font-size-and-line-height(22px, 28px);

    color: $color-primary-black;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .section-single-rich-text-content a {
    color: $single-reach-link-color;
    text-decoration: underline;
    font-weight: 600;
  }

  .section-single-rich-text-content a:hover {
    color: $single-reach-link-hover-color;
  }

  .section-single-rich-text-container .btn {
    display: inline-block;
  }

  .section-single-rich-text-container strong,
  .section-single-rich-text-container b {
    font-family: $fontfamily-regular-bold;
  }
}

.section-single-rich-text-container {
  display: flow-root;
}

.section-single-rich-text-container {
  display: flow-root;
}

.section-single-rich-text-content {
  display: inline-block;
}

@if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
  .action-button-container {
    text-align: center;
  }

  @media (max-width: $grid-bp-xs-max) {
    .action-button-container .btn {
      text-align: center;
      width: 100%;
    }
  }

  .simple-list-heading {
    @include font-size-and-line-height($simple-list-heading-font-size, $simple-list-heading-line-height);
  }

  .simple-list-heading ~ div {
    @include font-size-and-line-height(16px, 22px);
  }
}

.section-single-rich-text-content {
  @if ($theme-name != "leukoplast") {
    min-height: 50px;
  }

  @if ($theme-name != "men") {
    text-align: center;
  }

  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
}

.section-single-rich-text-content:last-of-type {
  margin-bottom: 0 !important;
}

.sectionTitleSpacingTop {
  margin-top: 25px;
}

.img-fluid {
  @include img-fluid();
}

.caption-image {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .caption {
    @include font-size($font-size-caption-image);

    text-align: left;
    color: $image-carousel-caption-color;
    background: $caption-background-transparent;
    bottom: 0;
    padding-bottom: 30px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 22px;
    position: absolute;
    width: 100%;

    @include mediaquery(sm) {
      @if $theme-name == "leukoplast" {
        background: $caption-background;
      }
      position: relative;
    }

    .caption-header {
      @include font-size($font-size-caption-image);

      @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
        line-height: $line-height-caption-image;
      }
    }

    .caption-subheader {
      @include font-size($font-size-caption-image-subheader);

      margin-bottom: 0;

      @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
        line-height: 22px;
      }
    }
  }
}

.hide {
  display: none !important;
}

@include mediaquery(sm) {
  .hide-sm {
    display: none !important;
  }
}
@include mediaquery(xs) {
  .hide-xs {
    display: none !important;
  }
}
@include mediaquery(xxs) {
  .hide-xxs {
    display: none !important;
  }
}

@media (min-width: $grid-bp-sm) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: $grid-bp-md) {
  .hide-lg {
    display: none !important;
  }
}

@include mediaquery(sm) {
  .show-sm {
    display: inherit !important;
  }
}
@include mediaquery(xs) {
  .show-xs {
    display: inherit !important;
  }
}
@include mediaquery(xxs) {
  .show-xxs {
    display: inherit !important;
  }
}

.Flex {
  @include flex();
}

.Flex--aCenter {
  @include flex-align-items(center);
}

.Flex--jCenter {
  @include flex-align-items(center);
}

.Flex--desktop-reverse {
  @media (min-width: $grid-bp-md) {
    flex-direction: row-reverse;
  }
}

.Flex--self-center {
  align-self: center;
}

.LightenText {
  color: $color-white;
}

.skip-to-content {
  position: absolute;
  left: -9999px;
  top: auto;
  overflow: hidden;
  color: $link-color;
}

.skip-to-content:focus {
  left: 0;
  top: -25px;
  padding: 10px;
  z-index: 1000;
  position: static;
}

.side-anchor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 50%;

  @media (min-width: $grid-bp-sm) {
    top: 30%;
  }

  @media (min-height: 360px) and (max-width: $grid-bp-sm) {
    top: 75%;
  }

  @media (max-height: 650px) and (min-width: $grid-bp-sm) {
    top: 35%;
  }

  @media (min-height: 650px) and (min-width: $grid-bp-sm) {
    top: 75%;
  }

  right: 0;
  height: 80px;
  width: 80px;
  border-width: 0;
  padding-left: 15px;
  transition: width 0.5s ease-out;
  color: $color-white;
  z-index: 3;

  @if ($theme-name == "cutimed") {
    background-color: $color-pink;
    border-radius: 12px 0 0 12px;
  } @else {
    background-color: $logo-color;
    border-radius: 25px 0 0 25px;
  }

  &:hover {
    width: 95px;
    color: $color-white;
  }

  .side-anchor-message {
    display: none;
    transition: margin-left 0.5s ease-out;

    @if ($theme-name == "cutimed") {
      @include font-size-and-line-height(15px, 20px);

      font-family: $fontfamily-regular-bold;
    } @else {
      font-size: 20px;
    }
  }

  .side-anchor-letter-icon {
    width: 50px;
  }
}

.side-anchor.has-no-icon {
  justify-content: center;
  padding: 0;

  @if ($theme-name == "cutimed") {
    height: 140px;
    width: 40px;
  } @else {
    height: 155px;
    width: 50px;
  }

  .side-anchor-message {
    display: block;
    transform: rotate(-90deg);
    white-space: nowrap;
    margin: 0;
  }

  .side-anchor-letter-icon {
    display: none;
  }

  &:hover {
    width: 70px;

    .side-anchor-message {
      margin-left: -20px;
    }
  }
}

@media (min-width: $grid-bp-sm) {
  .side-anchor.has-icon-and-label {
    justify-content: center;
    height: 155px;
    width: 50px;
    padding: 0;

    .side-anchor-message {
      display: block;
      transform: rotate(-90deg);
      white-space: nowrap;
      margin: 0;
    }

    .side-anchor-letter-icon {
      display: none;
    }

    &:hover {
      width: 70px;

      .side-anchor-message {
        margin-left: -20px;
      }
    }
  }
}

@if ($is-tena-theme) {
  .action-button-container {
    text-align: center;
  }
}

@if ($theme-name == "actimove") {
  .section-title {
    @include font-size-and-line-height (28px, 32px);
  }

  .section-single-rich-text-content {
    color: $color-light-black;
  }

  .section-single-rich-text-content .section-title {
    @include font-size-and-line-height(22px, 26px);
  }

  .action-button-container {
    text-align: center;
    margin-top: 24px !important;
  }

  .action-button-container .btn {
    min-height: 40px;
    min-width: 130px;

    @media (max-width: $grid-bp-xs-max) {
      text-align: center;
      width: 100%;

      &:after {
        right: auto;
        margin-left: 8px;
      }
    }
  }
}

@if ($theme-name == "jobst") {
  .banner .caption-image .caption {
    border-bottom-right-radius: 40px;

    @media (min-width: $grid-bp-md) {
      width: 67%;
      margin-bottom: 24px;
    }
  }

  .btn.action-button {
    @include btn("secondary");

    text-align: center;

    &::after {
      margin-left: 10px;
      right: unset;
    }
  }

  .section-single-rich-text-content {
    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .section-single-rich-text-content a {
    color: $color-primary;
    text-decoration: underline;

    &:hover {
      color: $color-primary-light;
      text-decoration: underline;
    }
  }

  .section-single-rich-text-content .section-title {
    color: $color-primary;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
  }

  .section-single-rich-text-content ~ .action-button-container {
    margin-top: 24px;
  }
}
