.component-form {
    @if ($theme-name == "com") {
        .vspace-sm {
            margin-bottom: 20px !important;
        }
    }

    .text-content {
        margin-bottom: 25px;
    }
}

#productForm .crate {
    background-color: transparent;
    border-width: 0;
}

.formMessages-wrap {
    margin-top: 30px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;

    &.pass {
        border: 1px dashed green;
    }

    &.fail {
        border: 1px dashed red;
    }

    .messageTitle {
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .formMessages {
        margin: 0;
    }
}
