form {
    margin-bottom: 0;
}

input {
    @if ($theme-name == "com") {
        border-radius: 3px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

input[type=number] {
  appearance: textfield;
}

.checkbox-button-align {
  float: left;
}

input[type=checkbox] {
	margin: 2px 0 0 5px;

  @if $theme-name == "leukoplast" {
    height: 20px;
    width: 20px;
  }
}

input[type="range"] {
    width: 100%;
}

input[type="submit"] {
    appearance: none;
}

.field-hidden-input {
    input {
        display: none;

        + label {
            @if ($theme-name == "com") {
              font-family: $fontfamily-regular-bold;
            }
            cursor: pointer;
            position: relative;

            i {
                left: 0;
                position: absolute;
                top: 0;
            }

            i:before {
                font-size: 20px;
                padding-right: 10px;
                position: relative;
                top: 0;
            }
        }

        &:not(:checked) + label.btn {
            @extend .btn-inactive;
            cursor: pointer !important;
        }

        &:checked + label i:before {
            content: "\e609" !important;
        }
    }
}

// INPUT
%field-input {
    @if ($theme-name == "com") {
      border: 1px solid $border-color-alt;
    } @else if $theme-name == "leukoplast" {
      border: transparent;
      height: 40px;
      background-color: $background-color-alt;
    } @else if ($theme-name == "actimove") {
      border: 1px solid $color-aluminium;
      height: 40px;
      line-height: 22px;
      border-radius: 3px;
    } @else {
      border: 1px solid $border-color;
    }

    @include font-size($font-size-form-fields);

    @if ($theme-name == "cutimed") {
      border-radius: 3px;
      height: 36px;
    }

    @include mediaquery(xs) {
        &:before {
            top: 5px;
        }

        &:after {
            bottom: 5px;
        }
    }

    color: $home-color;
    font-weight: normal;
    margin: 0;
    padding: 6px 10px;
    text-transform: none;
    width: 100%;

    &.disabled,
    &[disabled] {
        color: $form-field-disabled-color;
    }

    .invalid &,
    .valid & {
        box-shadow: none;
        padding-right: 30px;

        &[type="number"] {
            padding-right: 18px;
        }
    }
}

// SELECT
%field-select {
    @extend %field-input;

    &:before {
        top: 6px;
    }

    &:after {
        bottom: 6px;
    }
}

// Validation
%validate-icon {
  @if ($theme-name == "leukoplast" or $theme-name == "actimove" or $theme-name == "jobst") {
    display: none;
  }

  position: absolute;
  right: 8px;
  @if ($theme-name != "cutimed") {
    top: 8px;
  } @else {
    top: 12px;
  }
}

.validate {
    position: relative;

    &.field-checkbox-container {
      padding: 10px 0;
        @if $theme-name == "leukoplast" {
          label {
            display: block;
          }

          input[type="checkbox"] {
            height: 40px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 40px;
          }

          input[type="checkbox"] + .para_align_text > label::before {
            border: 1px solid $logo-color;
            border-radius: 2px;
            content: "";
            height: 18px;
            left: 5px;
            position: absolute;
            top: 12px;
            width: 18px;
          }

          input[type="checkbox"]:checked + .para_align_text > label::before {
            background: $logo-color;
          }

          label::after {
            content: "";
            border: 4px solid;
            border-left: 0;
            border-top: 0;
            height: 11px;
            left: 11px;
            opacity: 0;
            position: absolute;
            top: 13px;
            transform: rotate(45deg);
            width: 5px;
          }

          input[type="checkbox"]:checked + .para_align_text label::after {
            opacity: 1;
            color: $color-white;
          }
        }
    }

    &.invalid {
        &.validate-input input,
        &.validate-select .field-select,
        &.validate-textarea textarea,
        &.validate-password input,
        &.field-checkbox-container,
        &.validate-select .select2-selection  {
            border: 1px solid $error-color;
        }

        .icon-validation-cross {
            @extend %validate-icon;
            color: $error-color;
        }
    }

    &.validate-select {
        .icon-validation-cross {
            right: 26px;
        }
    }

  @if $theme-name == "leukoplast" {
    &:focus {
      border: 1px solid $accent-color;
    }
  }
}

.form .form-field .icon-validation-tick:before {
  display: none;
}

.field-checkbox-container {
  clear: both;
}

.para_align_text {
  margin-bottom: 0;
  padding-left: 30px;
}

.field-checkbox-container.validate .para_align_text {
  padding-right: 30px;
}

.form-inner-button {
  margin-right: 10px;
  margin-top: 15px;
}

.mandatory-fields-info {
  margin-left: 10px;
}

.form {
  position: relative;
    .field {
        position: relative;

        &.field-radio {
            i {
                display: none;
            }
        }
    }

    a {
        @if ($theme-name == "cgr") {
            color: $accent-color;
        }
    }

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }

    .form-field {
        @include font-size($font-size-form-fields);
        margin-bottom: 10px;

        @if ($theme-name == "jobst") {
          margin-bottom: 24px;
        }

        .para_align_text a {
          @include font-setup();
          color: $cta-bgcolor;
          cursor: pointer;

          @if ($theme-name == "leukoplast") {
            color: $logo-color;
          } @else if ($theme-name == "cutimed") {
            color: $color-gray;
          } @else if ($theme-name == "actimove") {
            color: $color-primary;
          } @else if ($theme-name == "women") {
            @include font-size-and-line-height(16px, 22px);
          } @else if ($theme-name == "jobst") {
            color: $color-gray;
          }

          &:hover {
              color: $cta-bg-hover;
              text-decoration: underline;
          }
        }

        &.disabled {
            color: $form-field-disabled-color;
        }

        .field-label {
            @include font-setup();
            @if ($theme-name == "com") {
                font-family: $fontfamily-regular-bold;
              } @else if  $theme-name == "leukoplast" {
                font-family: $fontfamily-regular-bold;
                font-size: 17px;
                line-height: 22px;
            } @else if $theme-name == "cutimed" {
              font-family: $fontfamily-regular-bold;
              font-size: 16px;
              line-height: 25px;
              color: $color-gray;
            } @else if $theme-name == "actimove" {
              @include font-size-and-line-height(16px, 22px);

              font-family: $fontfamily-titles-thin;
              color: $color-primary;
            } @else if $theme-name == "jobst" {
              @include font-size-and-line-height(18px, 26px);

              color: $color-gray;
              font-family: $fontfamily-regular-light;
              margin-bottom: 4px;
              font-weight: normal;
            } @else {
              font-family: $fontfamily-titles-normal;
            }

            display: block;
          }

        .field-textarea,
        .field-input {
            @extend %field-input;

            @if ($theme-name == "com" or $theme-name == "jobst") {
              border-radius: 3px;
            }
        }

        .field-select {
            @extend %field-select;
        }

        .field-textarea {
            resize: vertical;
            @if $theme-name == "leukoplast" {
              min-height: 80px;
            }
        }
    }

    .form-field-submit {
        margin-top: $vspace-sm;
    }
}

@if ($theme-name == "com") {
  .form .form-field .field-checkbox-container input + label i {
    left: 1px;
    position: absolute;
    top: 1px;
  }

  .form .form-field .field-checkbox-container input + label i:after,
  .form .form-field .field-checkbox-container input + label i:before {
    font-size: 20px;
    padding-right: 10px;
    position: relative;
    top: 0;
  }

  .form .form-field .field-checkbox-container input + label i:after {
    color: #fff;
    content: "\e622" !important;
    left: 0;
    position: absolute;
    top: 0;
  }

  .form .form-field .field-checkbox-container {
    input:checked + label i:before {
      content: "\e623" !important;
    }
  }

  .form .form-field .field-checkbox-container input[disabled] + label {
    color: #cfcfcf;
  }
}

@if ($theme-name == "com" or $theme-name == "women") {
  .form-field-submit .field-submit:hover {
    color: $color-bright-blue;
  }
}

.form-field-submit {
  @if $theme-name == "leukoplast" {
    text-align: right;
  } @else if $theme-name == "cutimed"{
    text-align: left;
  }
}

label {
    i {
        font-family: $fontfamily-regular-light;
        font-style: normal;
    }
}

.pre-loader {
    display: none;
}

.pre-loader.show {
    background-color: $color-white;
    background-position: center 20px;
    background-repeat: no-repeat;
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0.75;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    @if ($theme-name == "cgr") {
        background-image: url("../img/carer-pre-loader.gif");
    } @else {
        background-image: url("../img/pre-loader.gif");
    }
}

.g-recaptcha {
    padding: 15px 10px;
}

.captcha-error {
    padding-left: 15px;
}

.radio-button-align {
  float: left;
}

.field-radio-container-column {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $grid-bp-md) {
    &:nth-of-type(odd) {
      padding-left: 0;
      padding-right: 10px;
    }

    &:nth-of-type(even) {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}

.validate-message {
  clear: both;
  color: $error-color;
  display: none;
  margin-top: 10px;
}

.field-label {
  margin-bottom: 8px;
}

.field-label.vspace-sm {
  margin-bottom: $vspace-sm;
}

.newsletter-signup-form .field-checkbox-container .form-field,
.newsletter-signup-form .form-field .field-checkbox-container {
  font-size: 16px;
  line-height: 22px;
}

.newsletter-signup-form .field-checkbox {
  height: 20px;
  width: 20px;
}

.newsletter-signup-form .para_align_text {
  padding-left: 35px;
}

.newsletter-signup-form .g-recaptcha {
  padding: 0 10px 20px;
}

.newsletter-signup-form .form-field {
  margin-bottom: 15px;

  @if $theme-name == "jobst" {
    margin-bottom: 24px;
  }
}

.newsletter-signup-popup .form-field {
  font-size: 16px;
  line-height: 22px;

  @media (max-width: $grid-bp-xs-max) {
    margin-bottom: 10px;
  }
}

.newsletter-signup-popup .para_align_text {
  padding-left: 35px;
}

.newsletter-signup-popup .captcha-error {
  margin-top: -20px;
  padding-bottom: 30px;
  padding-left: 10px;
}

.select-level-2 {
  color: $color-black;
  font-family: $fontfamily-regular-bold;
}

.select-level-3 {
  display: block;
  margin-left: 1em;
}

@if $theme-name == "leukoplast" {
  .form .btn {
    @include btn("secondary");
    min-width: $btn-min-width;
  }

	.para_align_text {
		padding-left: 35px !important;
	}

  .form .select2-container--default .select2-selection--single {
    background-color: $background-color-alt;
  }

  .form .select2-container--default .select2-selection--single,
  .form .select2-container--default .select2-selection--single .select2-selection__rendered,
  .form .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    line-height: 40px;
  }
}

@if $theme-name == "cutimed" {
  .form .field-submit  {
    @include btn("secondary");

    background-color: $color-pink;
    border: none;
    height: 40px;

    @media (min-width: $grid-bp-sm) {
      width: 280px;
    }
  }

  .para_align_text {
    line-height: 18px;
  }
}

@if ($theme-name == "actimove") {
  .form .select2-container--default .select2-selection--single {
    background-color: $color-white;
  }

  .form .select2-container--default .select2-selection--single,
  .form .select2-container--default .select2-selection--single .select2-selection__rendered,
  .form .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }

  .form .select2-container--default .select2-selection--single .select2-selection__rendered {
    @include font-size-and-line-height(16px, 22px);

    padding-top: 10px;
    color: $home-color;
  }

  .form .form-field-submit {
    margin-top: 0;
  }

  .form .field-submit {
    min-width: 120px;
    padding-right: 50px;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .form .form-field .field-checkbox {
    margin-left: 0;
  }

  .form .field-checkbox-container .para_align_text,
  .form .field-radio-container .para_align_text {
    padding-left: 30px;
  }

  input[type="checkbox"] + .para_align_text > label::before {
    content: "";
    border: 1px solid $color-aluminium;
    background-color: $color-white;
    border-radius: 2px;
    height: 18px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 18px;
  }

  input[type="checkbox"]:checked + .para_align_text > label::before {
    background-color: $color-primary;
    border: 1px solid $color-primary;
  }

  .field-checkbox-container label::after {
    content: "";
    border: 2px solid;
    border-left: 0;
    border-top: 0;
    height: 10px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 14px;
    transform: rotate(45deg);
    width: 5px;
  }

  input[type="checkbox"]:checked + .para_align_text label::after {
    opacity: 1;
    color: $color-white;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-left: 0;
  }
}

@if $theme-name == "jobst" {
  .form .field.field-input {
    height: 40px;
  }

  .para_align_text label,
  .para_align_text label a {
		color: $color-gray;
	}

  .form .select2-container--default .select2-selection--single {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    background-color: $background-color-alt;
    border: none;
    color: $color-gray;
  }

  .form .select2-container--default .select2-selection--single,
  .form .select2-container--default .select2-selection--single .select2-selection__rendered,
  .form .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    line-height: 40px;
  }

  @media (max-width: $grid-bp-xxs-max) {
    .form .form-field .btn {
      text-align: center;

      &::after {
        right: unset;
        margin-left: 10px;
      }
    }
  }
}