.campaign {
  @include svg-play-icon();

  flex-wrap: wrap;

	@media (min-width: $grid-bp-sm) {
		margin: 0 auto;
		max-width: $container-max-width;
		min-height: 220px;
	}

	@media (min-width: $grid-bp-md) {
		min-height: 290px;
	}

	@media (min-width: $container-max-width) {
		min-height: 500px;
	}
}

.campaign.campaign--is-light {
  background-color: $campaign-light-background-color;
}

.campaign .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.campaign-column-text {
  word-break: break-word;

  @media (max-width: $grid-bp-sm-max) {
    padding: 24px;
  }
}

.campaign--center .campaign-column.campaign-column-text {
  text-align: center;
}

.campaign-ctas {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media (min-width: $grid-bp-md) {
    flex-direction: row;
    margin-top: $campaign-buttons-margin;
  }
}

.campaign--center .campaign-ctas {
  justify-content: center;
}

.campaign-column .campaign-ctas .btn {
  margin-bottom: 0;
  margin-top: 16px;
  text-align: center;
  width: 100%;

  @media (min-width: $grid-bp-md) {
    min-width: 130px;
    width: unset;
  }

  &:after {
    @media (max-width: $grid-bp-sm-max) {
      margin-left: 15px;
      right: auto;
    }
  }
}

// First button for campaign dark theme
.campaign-ctas .horizontal-btns-first {
  @if ($theme-name == "men") {
    @include btn("secondary");
  } @else if ($theme-name == "leukoplast" or $theme-name == "jobst") {
    @include btn("primary-light");
  } @else {
    @include btn("primary");
  }
}

.campaign-ctas .horizontal-btns span {
  @if ($theme-name == "men") {
    font-family: $fontfamily-regular;
  }
}

// Second button for campaign dark theme
.campaign-column .campaign-ctas .btn-dark.btn-hollow {
  @if ($theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
    @include btn("primary-light");
  } @else if ($theme-name == "leukoplast") {
    @include btn("primary");
  } @else if ($theme-name == "men") {
    @include btn("transparent-dark");
  } @else {
    @include btn("secondary");
  }
}

// First button for campaign light theme
.campaign--is-light .campaign-ctas .horizontal-btns-first {
  @if ($theme-name == "leukoplast") {
    @include btn("light");
  } @else if ($theme-name == "men") {
    @include btn("primary");

    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }
  } @else if ($theme-name == "com") {
    @include btn("secondary");
  } @else {
    @include btn("primary-light");
  }
}

// Second button for campaign light theme
.campaign--is-light .campaign-column .campaign-ctas .btn-dark.btn-hollow {
  @if ($theme-name == "men") {
    @include btn("transparent-light");
  } @else {
    @include btn("secondary-light");
  }
}

.campaign-ctas .btn .icon-button-right {
  @media (max-width: $grid-bp-sm-max) {
    margin-left: 15px;
    right: auto;
  }
}

.container .campaign {
  @media (min-width: $container-max-width) {
    min-height: 365px;
  }
}

.campaign-container {
	display: flex;
	flex-direction: column-reverse;

	@media (min-width: $grid-bp-sm) {
		display: block;
		margin: 0 auto;
		max-width: 1600px;
	}
}

.campaign-column {
	flex-basis: 50%;
	max-width: 50%;
	position: relative;

	@media (max-width: $grid-bp-md) {
		flex-basis: 100%;
		max-width: 100%;
	}

	&:last-of-type:not(:first-of-type) {
		margin-top: 20px;

    @if ($theme-name == "actimove") {
      margin-top: 0;
    }

		@media (min-width: $grid-bp-md) {
			margin-top: 0;
		}
	}

	&--with-padding {
		padding: 25px;
	}
}

.campaign-column,
.campaign-column p {
  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  color: $home-color;
  margin-bottom: 0;
}

.campaign-column.LightenText,
.campaign-column.LightenText p {
  @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  color: $color-white;
}

@media (min-width: $grid-bp-md) {
	.campaign-column-text {
    padding: 25px;
  }

  .campaign-column--with-padding {
		padding: 40px;
  }
}

.campaign-column .campaign-title {
  &.campaign-title--large {
    @include font-size-and-line-height(
      $font-size-campaign-image-title-large,
      $line-height-campaign-image-title-large
    );

    letter-spacing: $letter-spacing-campaign-image-title-large;
  }
}

.campaign-column .campaign-title {
  @include font-size-and-line-height($font-size-campaign-title, $line-height-campaign-image-title);
}

@if ($theme-name == "men") {
  .campaign-column .campaign-title {
    color: $color-purple;
    font-family: $fontfamily-titles-thin;
    text-transform: uppercase;
  }

  .campaign-column.LightenText .campaign-title {
    color: $color-white;
  }

  @media (max-width: $grid-bp-sm-max) {
    .campaign-ctas .btn:after,
    .campaign-ctas .btn.btn-hollow.btn-dark:after,
    .campaign--is-light .campaign-ctas .horizontal-btns-first::after,
    .campaign--is-light .btn.btn-hollow.btn-dark:after {
      right: auto;
      margin-left: 15px;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .campaign-column .campaign-title {
    padding-bottom: 30px;
  }
}

@if ($theme-name == "leukoplast") {
  .campaign-column-text {
    padding: 24px;
    z-index: 2;

    @media (min-width: $grid-bp-sm) {
      &:first-of-type:last-of-type {
        text-align: right;

        .horizontal-btns {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }

    @media (min-width: $grid-bp-md) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .campaign-column-text:first-of-type:last-of-type .campaign-ctas {
    @media (min-width: $grid-bp-sm) {
      justify-content: flex-end;
    }
  }

  .campaign-column .campaign-title {
    padding-bottom: 30px;
  }

  .campaign-container .campaign:not(.campaign--is-light) .campaign-column.LightenText .campaign-title,
  .campaign-container .campaign:not(.campaign--is-light) .campaign-column.LightenText .campaign-subtitle {
    color: $color-white;

    @media (max-width: $grid-bp-sm) {
      color: $segment-color-alt;
    }
  }

  .campaign-container .campaign-bg-image {
    z-index: 1;
  }

  @media (max-width: $grid-bp-xs-max) {
    .campaign--center .campaign-ctas {
      flex-direction: column;
    }
  }
}

@if ($theme-name == "women" or $theme-name == "cgr") {
  .campaign-container .btn {
    width: fit-content;
  }

  @media (min-width: $grid-bp-md) {
    .campaign-container .campaign-column .campaign-title {
      padding-bottom: 30px;
    }
  }
}

@if ($theme-name == "women") {
  .campaign-ctas .horizontal-btns-first,
  .campaign-column .campaign-ctas .btn-dark.btn-hollow {
    @include font-size-and-line-height(16px, 22px);
  }

  .campaign-column-text {
    padding: 25px;
  }

  .campaign-column .campaign-title.campaign-title--large {
    @include font-size-and-line-height(28px, 32px);
  }

  .campaign-container .btn {
    width: fit-content;
  }

  .campaign-container .campaign-column .campaign-title {
    padding-bottom: 20px;
  }
}

@if ($theme-name == "cgr") {
  .campaign-column .campaign-title.campaign-title--large,
  .campaign-column .campaign-title {
    letter-spacing: 3px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .campaign-container .campaign-column .campaign-title {
    padding-bottom: 20px;
  }
}

@if ($theme-name == "cutimed") {
  .campaign--center .campaign-column-text {
    text-align: left;
  }

  .campaign-column .campaign-title {
    font-family: $fontfamily-regular-bold-condensed;
    padding-bottom: 15px;
  }

  .campaign-column .campaign-subtitle {
    font-size: 16px;
  }

  @media (max-width: $grid-bp-xs-max) {
    .campaign--center .campaign-ctas {
      flex-direction: column;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .campaign-column-text {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .campaign--nonCentered .campaign-column.campaign-column-text {
      padding: 0 0 0 36px;
    }

    .campaign--nonCentered.Flex--desktop-reverse .campaign-column-text {
      padding: 0 36px 0 0;
    }

    .campaign-container .campaign-left-padding .campaign-column-text {
      padding: 0 36px;
    }

    .campaign--nonCentered .campaign-column--with-padding.campaign-column-text {
      padding: 40px;
    }
  }
}

.campaign-column .campaign-subtitle {
	padding-bottom: $campaing-image-h4-padding-bottom;
	font-family: $campaign-subtitle-font;

  @if ($theme-name == "men") {
    font-family: $fontfamily-titles-thin;
  }

	@if ($theme-name == "leukoplast") {
		@include font-size-and-line-height(16px, 22px);
	} @else {
    font-weight: normal;
    text-transform: uppercase;
	}

  @if ($theme-name == "actimove") {
    @include font-size-and-line-height(14px, 18px);

    letter-spacing: 0.4px;
  }

  @if ($theme-name == "women") {
    @include font-size-and-line-height(16px, 22px);

    letter-spacing: 4px;
    text-transform: uppercase;

    @media (min-width: $grid-bp-sm) {
      letter-spacing: 5px;
    }
  }

  @if ($theme-name == "cgr") {
    letter-spacing: 3px;

    @media (min-width: $grid-bp-sm) {
      letter-spacing: 4px;
    }
  }
}

@if ($theme-name == "jobst") {
  .campaign--center .campaign-ctas {
    display: block;
  }

  .campaign-container .campaign-ctas .btn span {
    font-weight: 400;
  }

  .campaign--center .campaign-column-text,
  .campaign-column .campaign-title,
  .campaign-column .campaign-subtitle {
    font-family: $fontfamily-regular;
  }

  .campaign-column .campaign-title,
  .campaign-column .campaign-subtitle {
    color: $color-heading;
  }

  .campaign-column.LightenText .campaign-title,
  .campaign-column.LightenText .campaign-subtitle {
    color: $color-white;
  }

  .campaign--center .campaign-column-text,
  .campaign-column p {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

    color: $color-gray;
    font-weight: 300;
  }

  .campaign-column .campaign-title {
    @include font-size-and-line-height($campaing-title-font-size, $campaing-title-line-height);

    font-weight: 700;
    padding-bottom: 24px;
  }

  .campaign-column .campaign-subtitle {
    @include font-size-and-line-height($campaing-subtitle-font-size, $campaing-subtitle-line-height);
  }

  .campaign .play-icon-arrow {
    fill: $color-white;
  }

  .campaign-container .campaign .svg-icon.play-icon {
    fill: $color-primary;
    stroke: $color-primary;
  }

  .campaign-container .play-icon-circle {
    opacity: 0.67;
  }

  .campaign-container .play-icon-pause {
    opacity: 0;
  }

  .campaign .play-icon-circle, .campaign .play-icon-arrow {
    stroke: none;
  }

  .campaign-container .campaign-left-padding .campaign-column-text {
    padding: 0 20px;
  }

  .campaign-container .campaign-text-content {
    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .campaign-container .campaign-text-content a {
    font-family: $fontfamily-regular;
    text-decoration: underline;
  }

  @media (max-width: $grid-bp-md) {
    .campaign-container .campaign-ctas .btn,
    .campaign-container .campaign-ctas .btn:hover,
    .campaign-column .campaign-ctas .btn-dark.btn-hollow {
      &::after {
        right: auto;
        margin-left: 15px;
      }
    }
  }

  @media (min-width: $grid-bp-md) {
    .campaign--nonCentered .campaign-column.campaign-column-text,
    .campaign--nonCentered.Flex--desktop-reverse .campaign-column-text,
    .campaign--nonCentered .campaign-column--with-padding.campaign-column-text {
      padding: 40px;
    }

    .campaign-column .campaign-title {
      padding-bottom: 40px;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .campaign-text-content a {
    color: $logo-color;

    &:hover {
      color: $segment-color-alt;
      text-decoration: underline;
    }
  }
}

@if ($theme-name != "leukoplast") {
  .campaign-icon {
    height: 80px;
    width: auto;

    @media (min-width: $grid-bp-sm) {
      height: 100px;
    }
  }
}

.campaign-column img {
	@media (min-width: $grid-bp-md) {
		margin: 0 auto;
		max-height: 600px;
		max-width: 100%;
		object-fit: contain;
	}
}

.campaign-column .campaign-img {
  @if ($theme-name == "men") {
    @include imageClipPath();
  }
}

@media (max-width: $grid-bp-xs-max) {
	.campaign .campaign-column--with-padding {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.campaign-bg-image {
	width: 100%;

	@media (min-width: $grid-bp-sm) {
		bottom: 0;
		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}
}

.campaign-column .jw-wrapper,
.carousel-alt .jw-wrapper {
	background-color: transparent;
}

@if ($theme-name == "actimove") {
  .campaign-column-text {
    padding: 24px;

    @media (min-width: $grid-bp-md) {
      padding: 40px;
    }
  }

  .campaign-column .campaign-title {
    padding-bottom: 24px;
    font-style: italic;

    @media (min-width: $grid-bp-md) {
      padding-bottom: 38px;
    }
  }

  .campaign-ctas .btn {
    text-align: center;
    min-height: 40px;

    @media (min-width: $grid-bp-md) {
      text-align: left;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .campaign-ctas .btn:after,
    .campaign-ctas .btn.btn-hollow.btn-dark:after {
      right: auto;
      margin-left: 15px;
    }
  }
}