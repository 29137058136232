.dealer-locator {
  display: flex;
  flex-wrap: wrap;
}

.dealer-locator-options,
.dealer-locator-search-results {
  flex-basis: 100%;
  max-width: 100%;
}

.dealer-locator-map {
  flex-basis: 100%;
  max-width: 100%;
  height: 450px;
}

.dealer-locator-back-btn,
.dealer-locator-num-of-dealers-desktop {
  display: none;
}

.dealer-locator-mobile-result-selector {
  display: none;
  width: 100%;
  margin: 15px 0;
}

.dealer-locator-show-map,
.dealer-locator-show-results {
  width: 100%;
  outline: none;
  border: 1px solid $dealer-locator-result-selector-btns-border;
  padding: 15px 0;
}

.dealer-locator-result-selector-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dealer-locator-result-selector-btn--active {
  background-color: $dealer-locator-options-bg-color;
  color: $color-white;
}

.dealer-locator-options {
  background-color: $dealer-locator-options-bg-color;
  padding: 15px 40px 40px;
}

.dealer-locator-text {
  color: $color-white;
  font-family: $fontfamily-regular;
  margin-bottom: 5px;
  margin-top: 25px;
}

.dealer-locator-search-input,
.dealer-locator-distance,
.dealer-locator-filter {
  width: 100%;
  padding: 18px;
  outline: none;
  font-family: $fontfamily-regular;
}

.dealer-locator-distance,
.dealer-locator-filter {
  background: transparent;
  border: 1px solid $color-white;
  color: $color-white;
  font-size: 16px;
}

.dealer-locator-options option {
  color: $dealer-locator-options-color;
}

.dealer-locator-search {
  @include font-size-and-line-height(20px, 44px);

  color: $color-white;
  background-color: $dealer-locator-search-btn;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding: 5px;
  border-bottom-right-radius: 20px;
  border: none;
  font-family: $fontfamily-regular;
}

.dealer-locator-search-results {
  width: 100%;
  background-color: $color-white;
  color: $color-blue;
  z-index: 2;
  padding: 20px 0;
  bottom: 0;
  position: absolute;
  height: 450px;
  overflow: auto;
}

.dealer-locator-view-marker-btn {
  background-color: $dealer-locator-view-marker-btn-bg;
  padding: 5px 14px;
  outline: none;
  border: none;
  font-size: 20px;
  color: $color-white;
  margin-left: auto;
  cursor: pointer;
  border-bottom-right-radius: 20px;
}

.dealer-locator-search-results-list {
  padding: 0;
}

.dealer-locator-search-results-list li {
  list-style-type: none;
  border-bottom: 1px solid $dealer-locator-search-results-border-color;
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  color: $dealer-locator-search-results-text-color;
}

.dealer-locator-search-results-list li a {
  color: $dealer-locator-search-results-text-color;
}

.dealer-locator-search-results-list li strong {
  color: $dealer-locator-search-results-bold-color;
}

@media (min-width: $grid-bp-md) {
  .dealer-locator-options,
  .dealer-locator-search-results {
    flex-basis: 33.33337%;
    max-width: 33.33337%;
    height: 640px;
    max-height: none;
    overflow: auto;
    position: relative;
  }

  .dealer-locator-map {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    height: 640px;
  }

  .dealer-locator-mobile-result-selector {
    display: none;
  }

  .dealer-locator-back-btn {
    display: block;
    border: none;
    outline: none;
    background: transparent;
    font-size: 20px;
    color: $dealer-locator-search-results-bold-color;
    margin-left: 30px;
    margin-bottom: 25px;
    cursor: pointer;
    padding: 0;
  }

  .dealer-locator-num-of-dealers-desktop {
    margin-left: 30px;
    margin-bottom: 25px;
    color: $dealer-locator-search-results-text-color;
    display: block;
  }
}