/* CSS for buy now */
.popUp-header {
  .popProduct-title {
    @include font-size-and-line-height($popup-product-title-font-size, $popup-product-title-line-height);

    font-family: $popup-product-title-font-family;
    color: $popup-product-title-color;
    padding-bottom: 10px;
  }

  .selectProduct-size {
    position: relative;
  }

  .selectProduct-sizeWrap {
    clear: left;
    margin-bottom: 5px;

    @media (max-width: $grid-bp-xs-max) {
      margin-top: 30px;
      text-align: center;
    }
  }

  .selectProduct-size {
    @include font-size-and-line-height($popup-product-select-font-size, $popup-product-select-line-height);

    color: $popup-product-select-color;
    font-family: $popup-product-select-font-family;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .popUp-row {
    display: flex;
    align-items: center;
  }

  .headProduct-img {
    text-align: center;
    .productImg {
      @include img-fluid();
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .popUp-row {
      flex-direction: column;
    }

    @if ($theme-name == "women" or $theme-name == "men" or $theme-name == "cgr") {
      .headProduct-img {
        margin: $vspace-sm;
      }

      .popProduct-title {
        font-family: $fontfamily-regular-bold;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}

.messageInfo-wrap {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  text-align: center;

  .messageInfo {
    font-family: $fontfamily-regular-bold;
    color: #aaa;
    font-size: 18px;
  }
}

.productDetails-wrap {
  padding-top: 10px;

  .u-rowTitle {
    font-family: $fontfamily-regular-bold;
  }
}

.productInfo-Details {
  align-items: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;

  &.hideBorder {
    border-width: 0;

    @media (max-width: $grid-bp-xs-max) {
      border-width: 1px;
    }
  }

  .productVendor-img {
    max-height: 60px;
    max-width: 200px;
    @include mediaquery(xxs) {
      max-width: 100%;
    }
  }

  .productCount-wrap .productCount {
    color: $accent-color;
    display: block;
    font-size: 18px;
  }
}

.productCount-wrap {
  word-break: break-all;
}

.productVendor-wrap,
.productCount-wrap {
  padding-bottom: 5px;
  padding-top: 5px;
}

.disclaimerInfo {
  padding-top: 1em;
}

.u-btnBuy {
  &.btn {
    @if ($theme-name == "women" or $theme-name == "cgr") {
      padding: 7px;
    }

    text-align: center;
    width: 100%;

    @if ($theme-name == "leukoplast") {
      @include btn("primary-light");

      padding-right: 40px;

      .icon-button-right {
        &:before {
          content: "\e605";
        }

        @media (max-width: $grid-bp-xs-max) {
          right: auto;
          margin-left: 8px;
        }
      }
    }
  }

  &.status-outofStock {
    background-color: #aaa;
    border-color: #aaa;
    cursor: default;

    @if ($theme-name == "men") {
      color: $inactive-color;

      &::after {
        display: none;
      }
    } @else {
      color: #fff;
    }

    &:hover {
      background-color: #aaa;
    }
  }
}

.buy-now-wrapper .hidden {
  display: none;
}

.mfp-content .buy-now-wrapper {
  margin-top: 50px;
}

@if ($theme-name == "actimove" or $theme-name == "jobst" or $theme-name == "leukoplast") {
  .buy-now-wrapper button.mfp-close {
    @include font-size-and-line-height(44px, 42px);

    background-color: $popup-product-close-button-background;
    border-radius: 50%;
    color: $color-white;
    font-family: none;
    opacity: 1;
    padding-left: 2px;
    top: 24px;
    right: 24px;
    width: 40px;
  }

  .buy-now-wrapper .popUp-header {
    margin-bottom: 20px;
  }

  .buy-now-wrapper .popUp-header .popUp-row {
    align-items: flex-start;
  }

  .buy-now-wrapper .validate-select {
    margin-left: -5px;

    @media (min-width: $grid-bp-xs) {
      margin-left: -10px;
    }
  }

  .buy-now-wrapper .popUp-header .headProduct-img {
    text-align: left;

    @media (max-width: $grid-bp-xs-max) {
      margin-bottom: 20px;
    }
  }

  .buy-now-wrapper .popUp-header .selectProduct-sizeWrap {
    margin-top: 4px;
    text-align: left;

    @media (max-width: $grid-bp-xs-max) {
      width: 60%;
    }
  }

  .buy-now-wrapper .productDetails-wrap {
    border-top: 1px solid $popup-product-details-border-color;
    padding-top: unset;
  }

  .buy-now-wrapper .productInfo-Details {
    border-bottom: unset;
    min-height: 100px;
    margin-inline: -5px;

    @media (min-width: $grid-bp-xs) {
      margin-inline: -10px;
    }
  }

  .buy-now-wrapper .productDetails-wrap .productCount-wrap {
    @include font-size-and-line-height($popup-product-details-font-size, $popup-product-details-line-height);

    font-family: $popup-product-details-font-fammily;
    color: $popup-product-details-color;
  }

  @media (max-width: $grid-bp-xs-max) {
    .buy-now-wrapper .productInfo-Details {
      flex-direction: column;
      align-items: center;
    }

    .buy-now-wrapper .productVendor-wrap,
    .buy-now-wrapper .productCount-wrap {
      text-align: center;
    }

    .buy-now-wrapper .productVendor-wrap {
      margin-bottom: 10px;
    }

    .buy-now-wrapper .u-btnBuy.btn {
      word-break: break-word;
      margin-top: 10px;

      &:after {
        right: auto;
        margin-left: 8px;
      }
    }
  }
}
