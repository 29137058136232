.dynamic-page {
  text-align: center;
}

.dynamic-page .component-intro {
  float: none;
  margin-bottom: 32px;
}

.dynamic-page .intro-content.has-preamble .intro-title {
  @include font-size-and-line-height(40px, 44px);

  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 0;
  letter-spacing: -0.5px;
  border-bottom: none;
  font-weight: normal;
}

.dynamic-page div,
.dynamic-page p {
  line-height: 19px;
  letter-spacing: -0.3px;
}

.dynamic-page .intro-text-content a {
  @include arrowIcon($dynamic-page-link-color);
  @include font-size-and-line-height(16px, 22px);

  display: block;
  color: $dynamic-page-link-color;
  font-weight: 700;
  word-break: break-word;
  margin-top: 21px;

  &:after {
    top: unset;
    bottom: -12px;
    right: unset;
    margin-left: 10px;
    font-weight: 500;
  }
}

.dynamic-page .section-title {
  @include font-size-and-line-height(28px, 32px);

  border-bottom: 1px solid $title-border-color;
  margin-top: 34px;
  margin-bottom: 46px;
  padding-bottom: 16px;
  display: inline-block;
  width: 100%;
}

.dynamic-page .image-list-image {
  float: none;
  margin-bottom: 0;
}

.dynamic-page .image-list-image img {
  max-width: fit-content;
}

.dynamic-page .product-title {
  @include font-size-and-line-height(28px, 32px);

  margin-top: 24px;
  margin-bottom: 8px;
}

.dynamic-page .component-article-list .article-list-container .article-list {
  border: none;
}

.dynamic-page .article-list-container .article-list .article-list-item .article-list-image {
  float: none;
  margin: auto;
  width: fit-content;
  height: 155px;
}

/* Because the wrapper element has a fixed height, image tag must have an inherit height value */
.dynamic-page .article-list-container .article-list .article-list-item .article-list-image img {
  height: inherit;
}

.dynamic-page .article-list-item-title {
  @include font-size-and-line-height(22px, 28px);

  font-family: $fontfamily-regular;
  font-weight: normal;
  margin-top: 24px;
  margin-bottom: 12px;
}

.dynamic-page .component-article-list .article-list-container .text-content {
  line-height: 22px;
}
