@if ($theme-name == "actimove") {
  .ui-lib-btn-primary-light {
    @include btn("primary-light");
  }

  .ui-lib-btn-secondary {
    @include btn("secondary");
  }

  .ui-lib-btn-secondary-light {
    @include btn("secondary-light");
  }

  .ui-lib-btn-textlink {
    @include btn("textlink");
  }
}

@if ($theme-name == "women") {
  .ui-lib-btn-primary-light {
    @include btn("primary-light");

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ui-lib-btn-secondary {
    @include btn("secondary");
  }
}

@if ($theme-name == "jobst") {
  .ui-lib-btn-primary-light {
    @include btn("primary-light");
  }

  .ui-lib-btn-secondary-light {
    @include btn("secondary-light");
  }

  .ui-lib-btn-textlink {
    @include btn("textlink");
  }

  .ui-lib-btn-textlink-light {
    @include btn("textlink-light");
  }

  .ui-lib-btn-mediaui {
    @include btn("media");
  }
}

@if ($theme-name == "cgr") {
  .ui-lib-btn-primary-light {
    @include btn("primary-light");

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ui-lib-btn-secondary {
    @include btn("secondary");
  }
}

@if ($theme-name == "leukoplast") {
  .ui-lib-btn-primary-light {
    @include btn("primary-light");
  }

  .ui-lib-btn-secondary {
    @include btn("secondary");
  }

  .ui-lib-btn-optional_1 {
    @include btn("optional_1");
  }

  .ui-lib-btn-optional_2 {
    @include btn("optional_2");
  }
}

.ui-lib-btn-dark-bg {
  padding: 10px;
  background-color: $color-black;
  display: inline-block;
}
