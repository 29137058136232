.items-list-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.items-list-container .items-list-heading {
  @include font-size($items-list-heading-font-size);

  margin-bottom: $items-list-common-margin-size;

  @if ($theme-name == "women") {
    @include font-size-and-line-height($items-list-heading-font-size, $items-list-heading-line-height);
  }
}

.items-list-container .items-list-subheading {
  @include font-size($items-list-subheading-font-size);

  font-family: $fontfamily-regular;
  text-transform: uppercase;
  margin-top: $items-list-common-margin-size;

  @if ($theme-name == "women") {
    @include font-size-and-line-height($items-list-subheading-font-size, $items-list-subheading-line-height);

    letter-spacing: 4px;
    font-weight: normal;
  }
}

.items-list-rtf {
  @include font-size-and-line-height(16px, $items-list-rtf-line-height);

  p {
    @include line-height($items-list-rtf-line-height);
  }
}

.items-list {
  display: flex;
  justify-content: center;
  gap: 0;
}

@if ($theme-name != "leukoplast") {
  .item-list-icon {
    height: $item-list-icon-height-sm;
    width: auto;

    @media (min-width: $grid-bp-sm) {
      height: $item-list-icon-height-lg;
    }
  }
}

.item-wrapper {
  display: flex;
  width: calc(33.33% - 20px);
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.item-wrapper-inner {
  display: flex;
  width: 100%;
}

.item-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item-box-img img {
  width: 100%;
}

.item-box-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.item-box-text {
  margin-bottom: $items-list-common-margin-size;
}

.item-box-text .title {
  @include font-size($font-size-items-list);

  @if ($theme-name == 'women') {
    color: $color-blue;
  }
}

.item-box-text p {
  @include font-size($font-size-paragraph);

  display: flex;
  flex-direction: column;
  margin: 0 20px;
  position: relative;
  text-align: center;
  width: auto;
}

.item-box-footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  text-align: center;
}

@media (max-width: $grid-bp-xs-max) {
  .items-list-container {
    padding: 0 5px;
  }

  .items-list {
    gap: 15px;
    width: 100%;
    flex-wrap: wrap;
  }

  .item-wrapper {
    width: calc(50% - 15px / 2);
    margin-right: 0;
    margin-bottom: 40px;
  }

  .item-list--single .item-wrapper {
    width: 100%;
  }

  .item-list--single .item-wrapper-inner {
    width: 50%;
    margin: 0 auto;
  }

  .items-list-container .btn {
    width: 100%;
  }

  .item-list-icon {
    max-height: 80vh;
  }
}

@if ($theme-name == "jobst") {
  .items-list-container .items-list-subheading,
  .items-list-container .items-list-rtf p,
  .items-list-container .items-list-rtf p a,
  .item-box-text p {
    @include font-size-and-line-height(18px, 26px);
  }

  .items-list-container .items-list-subheading,
  .items-list-container .items-list-heading,
  .item-box-text .title {
    color: $color-primary;
  }

  .items-list-container .items-list-heading,
  .item-box-text .title {
    font-weight: 700;
  }

  .items-list-container .items-list-subheading {
    letter-spacing: 2px;
    font-weight: 400;
  }

  .items-list-container .items-list-heading {
    @include font-size-and-line-height(28px, 32px);
  }

  .items-list-container .items-list-rtf p,
  .items-list-container .item-box-text p {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
  }

  .item-box-text .title {
    @include font-size-and-line-height(22px, 26px);
  }

  .items-list-container .component-btn .btn span {
    font-weight: 400;
  }

  @media (max-width: $grid-bp-sm) {
    .items-list-container .btn {
      text-align: center;

      &::after {
        right: unset;
        margin-left: 10px;
      }
    }
  }

  @media (max-width: $grid-bp-xxs-max) {
    .items-list-container .align-text-center {
      width: 90%;
      margin-inline: auto;
    }

    .item-list--single .item-wrapper-inner {
      width: 90%;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .items-list {
      flex-wrap: wrap;
    }

    .item-wrapper {
      flex: 0 0 calc(50% - 20px);
      margin-bottom: 30px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .item-wrapper {
      flex: 0 0 calc(33.33% - 20px);
    }
  }
}

@if ($theme-name == "actimove") {
  .items-list-container .items-list-heading {
    line-height: 32px;
    font-style: italic;
  }

  .items-list-container .items-list-subheading {
    line-height: 22px;
    margin-bottom: 10px;
  }

  .items-list-rtf,
  .item-box-text .text {
    color: $color-light-black;
  }

  .item-wrapper {
    margin-bottom: 15px;
  }

  .item-box-text .title {
    @include font-size-and-line-height(22px, 26px);

    font-style: italic;
  }

  .item-box-footer .btn {
    padding: 10px 20px;
    min-width: 120px;
    min-height: 40px;
    text-align: center;
  }

  @media (max-width: $grid-bp-xxs-max) {
    .items-list-container .align-text-center {
      width: 90%;
      margin-inline: auto;
    }

    .item-list--single .item-wrapper-inner {
      width: 90%;
    }
  }

  @media (min-width: $grid-bp-xs) and (max-width: $grid-bp-xs-max) {
    .item-list--single .item-wrapper-inner {
      width: 65%;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .items-list {
      flex-wrap: wrap;
    }

    .item-wrapper {
      flex: 0 0 calc(50% - 20px);
      margin-bottom: 30px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .item-wrapper {
      flex: 0 0 calc(33.33% - 20px);
    }
  }
}

@if ($theme-name == "cgr") {
  .items-list-subheading {
    color: $color-black;
    line-height: 22px;
    letter-spacing: 4px;
    font-weight: 400;
  }

  .items-list-heading {
    line-height: 32px;
    color: $color-heading;
    letter-spacing: 3px;
    font-family: $fontfamily-regular-bold;
    text-transform: uppercase;
  }

  .item-box-text .title {
    @include font-size-and-line-height($font-size-items-list, $line-height-items-list);

    letter-spacing: 1.5px;
    font-family: $fontfamily-regular-bold;
    text-transform: uppercase;
  }

  .item-box-text p {
    color: $color-primary-black;
  }
}