.btn {
  @include btn();
  @include font-size($font-size-btn);

  @include mediaquery(xxs) {
    display: block;
    float: none !important;
    text-align: left;
  }

  @if ($theme-name != "actimove" and $theme-name != "jobst") {
    background-color: $cta-bgcolor;
  }

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    line-height: 22px;
  }

  @if ($theme-name == "cgr") {
    line-height: 19px;
  }

  @if ($theme-name != "jobst") {
    border: 2px solid $cta-bgcolor;
  }

  border-radius: $btn-border-radius;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-weight;
  padding: $btn-padding;
  padding-right: $btn-padding-icon;
  position: relative;
  z-index: 1;

  @if $theme-name == "cutimed" {
    @include btn();
    text-align: center;
  }

  @if $theme-name == "actimove" {
    border: none;
    border-radius: 0;
  }

  @if $theme-name == "cgr" {
    border-width: 1px;
  }

  &.btn-search {
    position: absolute;
    z-index: 1;
    top: 22px;
    left: 5px;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 24px;
    border: transparent;
    background-size: 20px 20px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  &.btn-search--collapsed {
    background-color: transparent;
    border: transparent;
    bottom: 0;
    color: $home-color;
    cursor: pointer;
    padding: 0;
    position: absolute;

    &:hover {
      background-color: transparent;
    }
  }

  &.btn-search--collapsed .icon {
    @include font-size($btn-search-close-icon);

    position: static;
  }

  @if ($theme-name == "leukoplast") {
    @include btn("primary");

    .icon-button-right:before {
      content: "";
    }
  }

  @if ($theme-name == 'men') {
    @include btn("primary");
  }

  @if ($theme-name == 'women') {
    @include btn("primary");

    border: none;
    min-height: 40px;
    line-height: 19px;
  }

  @if ($theme-name == "jobst") {
    border-width: 2px;
  }

  &:hover {
    @if ($theme-name != "men") {
      color: $color-white;
    }

    @if ($theme-name == "jobst") {
      color: unset;
    }

    @if ($theme-name == "cutimed") {
      filter: brightness(90%);
    }
  }

  span {
    @include mediaquery(xs) {
      line-height: 22px;

      @if ($theme-name != "jobst") {
        line-height: 1.4;
      }
    }

    @if ($theme-name == "cgr") {
      font-family: $btn-font-family;
    } @else if ($theme-name == "women") {
      font-family: $btn-font-family;
      font-weight: 700;
    } @else {
        @include font-setup();

      @if ($theme-name == "actimove") {
        font-weight: 600;
      }

      @if ($theme-name == "jobst") {
        font-weight: normal;
      }
    }

    position: relative;
  }

  i {
    font-size: 12px;
    position: absolute;
    right: $btn-icon-right;
    top: 50%;
    transform: translateY(-50%);

    &.icon-arrow-down {
      font-size: 16px;
    }
  }
}

.component.component-btn span.btn {
  padding: 0 20px 0 0;
}

@if ($theme-name == "cutimed") {
  .cutimed-product-group-btn {
    border: 2px solid $cta-bgcolor;
    width: 300px;
    text-align: center;
  }
}

@if ($theme-name == "com") {
  a.btn:hover {
    text-decoration: none;
  }
}

@if ($theme-name != "leukoplast") {
  .btn.buy-now-highlighted {
    @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
      @include btn("secondary");
    } @else if ($theme-name == "cutimed" or $theme-name == "actimove" or $theme-name == "jobst") {
      @include btn("primary");
    } @else {
      background-color: $buy-now-button-color;
      border-color: $buy-now-button-color;
    }
  }
}

.btn.BtnLight {
  background-color: $color-white;
  border: 2px solid $color-white;
  color: $cta-bgcolor;
}

.btn.btn-iconLeft {
  padding-left: $btn-padding-icon;
  padding-right: 25px;

  &::after {
    display: none;
  }

  @include mediaquery(xs) {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }

  i {
    left: $btn-icon-right;
    right: auto;
  }
}

.btn.btn-hide {
  display: none;
}

.btn.clearfix {
  clear: both;
}

.btn.btn-hollow {
  @if ($theme-name != "men") {
    background-color: transparent;
  }

  &.btn-dark {
    @if $theme-name == "leukoplast" {
      border-color: inherit;
      color:  inherit;
    } @else {
      color: $cta-bgcolor;
    }

    &:hover {
      background-color: $cta-bg-hover;
      border-color: $cta-bg-hover;
      @if ($theme-name != "men") {
        color: $color-white;
      }
    }
  }

  &:hover {
    background-color: transparent;
  }
}

.btn.btn-no-icon {
  @include mediaquery(xxs) {
    text-align: center;
  }
  padding: $btn-padding-no-icon;

  i {
    display: none;
  }
}

.btn.horizontal-btns {
  @if $theme-name == "jobst" {
    @include mediaquery(sm) {
      float: none;
      margin: 10px 0;
      text-align: center;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  } @else {
    @include mediaquery(xs) {
      float: none;
      margin: 10px 0;
      text-align: center;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  float: left;
  margin-right: 10px;

  &.align-right {
    float: right;
  }

  @if $theme-name == "leukoplast" {
    text-align: center;
    width: calc(50% - 10px);
  }
}

.btn.btn-inverted {
  background-color: $color-white;
  border-color: $color-white;
  @if ($theme-name == "cgr") {
    color: $inactive-color;
  } @else {
    color: $btn-background;
  }

  &:hover {
    background-color: $btn-background-inverted-hover;
    border-color: $btn-background-inverted-hover;
  }
}

.btn.btn-inactive {
  background: $inactive-color-bg;
  border-color: $inactive-color-bg;
  color: $inactive-color;
  cursor: not-allowed;

  &:hover {
    background: $inactive-color-bg;
    border-color: $inactive-color-bg;
    color: $inactive-color;
  }
}

.btn.btn-text {
  background-color: transparent;
  border-color: transparent;
  color: $accent-color;
  margin: 9px 0;
  padding: 0;

  span {
    text-decoration: underline;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }

  &.no-link {
    cursor: default;

    span {
      text-decoration: none;
    }
  }
}

.btn.btn-popup-close {
  background-color: transparent;
  border: transparent;
  @if $theme-name == "leukoplast" {
    color: $segment-color-alt;
  } @else {
    color: $cta-bgcolor;
  }
  margin-top: 10px;
  text-align: left;

  @media (min-width: $grid-bp-sm) {
    margin-top: 0;
  }

  .icon-short-arrow-right {
    margin-left: 10px;
    position: static;
  }

  @if $theme-name == "leukoplast" {
    color: $segment-color-alt;
    &:hover {
      background-color: transparent;
      color: $segment-color-alt;

      span {
        text-decoration: underline;
      }
    }
  }
}

.btn.btn-secondary {
  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    @include btn("secondary");
  }
}

.btn.btn-no-icon {
  @include font-size-and-line-height($font-size-btn, $font-size-btn);
  @if ($theme-name != "cutimed") {
    padding: 13px 20px;
  } @else {
    line-height: 20px;
  }

  &::after {
    content: '';
    width: 20px;
    display: none;
  }
}

button.btn {
  @include mediaquery(xs) {
    width: 100%;

    @if ($theme-name == "men") {
      width: initial;
    }
  }
}

.btn-play-image-wrapper {
  position: relative;

  @if ($theme-name == "leukoplast") {
    text-align: center;
  }
}

@if ($theme-name == "men") {
  @media (max-width: $grid-bp-md) {
    .btn {
      @include font-size-and-line-height($font-size-btn, $font-size-btn);
      display: inline-block;
    }
  }
}

@if ($theme-name == "cutimed" or $theme-name == "actimove" or $theme-name == "jobst") {
  .btn .icon-button-right {
    display: none;
  }
}

@if ($theme-name == "actimove" or $theme-name == "jobst") {
  .component.component-btn .btn {
    min-height: 40px;
    min-width: 130px;
  }
}

@if ($theme-name == "actimove") {
  .component.component-btn .btn {
    padding-right: 50px;
  }
}


@if ($theme-name == "jobst") {
  .component.component-btn .btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}