.toggle-tooltip {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
}

.tooltip {
  @include mediaquery(xxs) {
      left: 0 !important;
      max-width: 100%;
      padding: 0 $container-padding-xs;
      width: 100%;

      .tooltip-content-wrapper {
          width: 100%;
      }
  }
  display: block;
  max-width: 270px;
  position: absolute;
  z-index: $z-index-high;

  .tooltip-content-wrapper {
      background: $menu-submenu-bg-color;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.08);
      padding: 15px;
      position: relative;
  }

  .tooltip-close {
      height: 30px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 5px;
      width: 30px;
  }

  .tooltip-arrow {
      @include mediaquery(xs) {
          display: block;
      }
      height: 12px;
      left: 50%;
      position: absolute;
      top: -11px;
      width: 24px;

      &:after,
      &:before {
          border-bottom: 12px solid $menu-submenu-bg-color;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          content: "";
          height: 0;
          left: -50%;
          position: absolute;
          width: 0;
      }

      &:before {
          border-bottom-color: $border-color;
          top: -1px;
      }
  }

  .tooltip-title {
      font-family: $fontfamily-regular-bold;
      font-size: 14px;
      margin-bottom: 5px;
  }

  p {
      font-size: 14px;
      margin-bottom: 0;
  }
}
