$mfp-overlay-color: #2d3f52;
$mfp-overlay-opacity: 0.85;
@import "../plugin/magnific-popup/magnific-popup";
@import "../plugin/swiper";
@import "../plugin/select2";
@import "../plugin/datatable";

.mfp-jwplayer-holder {
  .mfp-content {
    max-width: $mfp-iframe-max-width;
    width: 100%;
  }

  .mfp-close {
    color: $mfp-controls-color;
    padding-right: 6px;
    right: -6px;
    text-align: right;
    top: -40px;
  }
}
