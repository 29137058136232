.country-list-container {
  column-count: 3;
  column-width: 280px;
  padding: 0 0 0 10px;

  @if $theme-name == "leukoplast" {
    column-gap: 40px;
    column-rule: solid 1px $segment-color;
    column-width: 260px;
  }

  @if ($theme-name == "cutimed") {
    column-rule: 1px solid $border-color;
    column-gap: 45px;
  }

  @if ($theme-name == "actimove") {
    column-rule: 1px solid $color-aluminium;
    column-gap: 100px;
  }

  @if ($theme-name == "jobst") {
    column-rule: 1px solid $color-light;
    column-gap: 80px;
  }
}

.country-list-wrapper {
  break-inside: avoid-column;
  margin-bottom: 30px;

  @if ($theme-name == "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      border-bottom: 1px solid $border-color;
      margin-bottom: 15px;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @if ($theme-name == "actimove") {
    margin-bottom: 0;
  }
}

.country-list {
  @media (max-width: $grid-bp-sm-max) {
    @if ($theme-name == "cutimed") {
      margin-bottom: 15px;
    } @else {
      margin-bottom: 20px;
    }
  }

  margin: 0 0 22px;
  padding: 0;
  zoom: 1;

  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.country-list-title,
.country-list-item {
  @if $theme-name == "leukoplast" {
    font-size: 17px;
  } @else {
    font-size: 16px;
  }

  @if ($theme-name == "cutimed") {
    line-height: 25px;
  } @else {
    line-height: 22px;
  }

  display: block;
  list-style-type: none;
  word-break: break-word;
}

.country-list-title {
  font-family: $fontfamily-regular-bold;
  margin-bottom: 0;
}

@if ($theme-name == "actimove") {
  .country-list-wrapper {
    margin-bottom: 40px;

    @media (min-width: $grid-bp-sm) {
      margin-bottom: 24px;
    }
  }

  .country-list-title {
    font-family: $fontfamily-regular;
    color: $color-light-black;
    margin-bottom: 8px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .country-list-item {
    margin-bottom: 8px;
  }

  .country-list-item-link {
    color: $color-primary;
  }
}

@if ($theme-name == "jobst") {
  .country-list-wrapper {
    margin-bottom: 0;
  }

  .country-selector-title {
    @include font-size-and-line-height(22px, 26px);

    padding-bottom: 24px;
    margin-left: 40px;
    color: $country-selector-title-color;
    font-weight: 700;
  }

  .country-list-title,
  .country-list-item {
    @include font-size-and-line-height(18px, 26px);
  }

  .country-list-title {
    font-family: $fontfamily-regular;
    text-transform: uppercase;
    color: $color-gray;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }

  .country-list {
    margin-bottom: 16px;
  }

  .country-list-item {
    font-family: $fontfamily-regular-light;
    margin-bottom: 8px;
  }

  .country-list-item:last-child {
    margin-bottom: 0;
  }

  .country-list-item-link:hover {
    color: $color-primary-light;
  }
}
