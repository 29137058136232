.product-video {
  @include svg-play-icon();
}

.product-video .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
}

.product-video .svg-icon.play-icon .play-icon-arrow {
  transform: scale(0.75) translate(15px, 15px);
}

@media (max-width: $grid-bp-sm) {
  .product-video .svg-icon.play-icon {
    width: 50px;
    height: 50px;
  }

  .product-video .svg-icon.play-icon .play-icon-arrow {
    transform: scale(1) translate(0, 0);
  }
}

@if ($is-tena-theme) {
  .product-intro-container-frost .product-video .svg-icon.play-icon {
    width: 40px;
    height: 40px;
  }
}

@if ($theme-name == "actimove") {
  .product-intro-container-frost .product-video .svg-icon.play-icon {
    width: 60px;
    height: 60px;
  }

  .product-video {
    padding-left: 10px;
  }

  @media (min-width: $grid-bp-xs) {
    .product-video {
      padding-left: 20px;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .product-intro-container-frost .product-video .svg-icon.play-icon {
      width: 90px;
      height: 90px;
    }

    .product-video {
      padding-right: 20px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .product-video {
      padding-left: 30px;
    }
  }
}