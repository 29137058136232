.glossary-listing .text-content p {
  line-height: 22px;
}

.glossary-listing .section-title {
  @include font-size-and-line-height($font-size-section-title, $line-height-section-title);
}

.glossary-listing .article-list-item-heading {
  @include font-size-and-line-height(22px, 28px);
  font-family: $fontfamily-regular !important;

  @if ($theme-name == 'women') {
    color: $color-blue;
  }
}