.component-article-list .article-list-container .article-list {
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding-top: 30px;

  @media (max-width: $grid-bp-xs-max) {
    &.show-more {
      border: none;
    }
  }

  .article-list-item {
    margin-bottom: 10px;

    @if ($theme-name == "cutimed") {
      margin-bottom: 20px;
    }

    @media (min-width: $grid-bp-xs) {
      @if ($theme-name == "cutimed") {
        margin-bottom: 35px;
      } @else if ($theme-name == "leukoplast") {
        margin-bottom: 25px;
      }
    }

    overflow: hidden;
    @include mediaquery(xxs) {
      @if ($theme-name != "leukoplast") {
        text-align: center;
      }
    }

    h2:not(.article-list-item-heading) {
      @if ($theme-name == "men") {
        font-size: 22px;
      } @else if $theme-name == "leukoplast" {
        font-size: 17px;
        margin-bottom: 10px;
      } @else {
        font-size: 16px;
      }
      font-family: $article-list-title-font;
    }

    .article-list-image {
      @include mediaquery(xxs) {
        @if ($theme-name != "leukoplast") {
          float: none;
          margin: 0 auto 10px auto;
        }
      }
      float: left;
      overflow: hidden;

      @if ($theme-name == "cutimed") {
        width: 93px;
        margin-right: 38px;
      } @else if ($theme-name == "leukoplast") {
        width: 102px;
        margin-right: 20px;
      } @else {
        width: 80px;
        margin-right: 20px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.component-article-list .article-list-container .text-content {
  @if ($theme-name == "com") {
    line-height: normal;
  } @else if $theme-name == "cutimed" {
    line-height: 25px;
  } @else {
    line-height: 24px;
  }
}

@if ($is-tena-theme) {
  .product-intro-container-frost .component-article-list .article-list-container .article-list {
    padding-top: 5px;
  }

  .product-intro-container-frost .component-article-list .article-list-container .article-list .article-list-item {
    text-align: left;

    h2 {
      @if ($theme-name == "actimove") {
        line-height: 22px;
        font-family: $fontfamily-titles-thin;
        font-weight: 600;
      } @else {
        @include font-size-and-line-height(16px, 18px);

        font-family: $fontfamily-regular-bold;
      }
    }
  }

  .product-intro-container-frost .component-article-list .article-list .article-list-item .article-list-image {
    float: left;
    margin: 0 10px 0 0;

    @if ($theme-name == "actimove" or $theme-name == "jobst") {
      width: 80px;
    } @else {
      width: 50px;
    }
  }

  .product-intro-container-frost .article-list-item-text {
    border-bottom: 1px solid $product-intro-accordion-border;
    min-height: 60px;

    @if ($theme-name == "actimove") {
      color: $color-light-black;

      @media (min-width: $container-max-width) {
        width: 533px;
      }
    }
  }

  .article-list-item-title {
    @include font-size-and-line-height(16px, 22px);

    @if ($theme-name == "actimove") {
      font-weight: 600;
      font-style: italic;
    }

    @if ($theme-name == "women") {
      color: $color-blue;
    }
  }

  .product-intro-container-frost .article-list-image ~ .article-list-item-text {
    @if ($theme-name == "actimove") {
      margin-left: 95px;

      @media (min-width: $container-max-width) {
        width: 438px;
      }
    } @else {
      margin-left: 60px;
    }
  }

  .product-intro-container-frost .component-article-list .article-list-container .text-content {
    margin-bottom: 20px;

    @if ($theme-name == "actimove") {
      line-height: 22px;
    } @else {
      line-height: 25px;
    }
  }
}

@if ($theme-name == "jobst") {
  .accordion-item .component-article-list .article-list-container .article-list .article-list-item {
    margin-bottom: 24px;
  }

  .product-intro-container-frost .accordion-item .article-list .article-list-item .article-list-image {
    float: none;
    margin: 0 auto 16px auto;

    @media (min-width: $grid-bp-sm) {
      float: left;
      margin: unset;
    }
  }

  .product-intro-container-frost .accordion-item .article-list .article-list-item-text {
    padding-bottom: 16px;

    @media (max-width: $grid-bp-xs-max) {
      text-align: center;
    }
  }

  .product-intro-container-frost .accordion-item .article-list-image ~ .article-list-item-text {
    margin-left: 0;

    @media (min-width: $grid-bp-sm) {
      margin-left: 96px;
    }
  }

  .product-intro-container-frost .article-list-item-title {
    @include font-size-and-line-height(18px, 26px);

    color: $color-primary;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    margin-bottom: 4px;
  }

  .product-intro-container-frost .component-article-list .article-list .text-content {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
    margin-bottom: 0;
  }

  .product-intro-container-frost .accordion-item .component-article-list .article-list .text-content {
    color: $product-intro-accordion-benefits-text-color;
  }
}
