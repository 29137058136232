// Theme-specific
$theme-name: "com";
$display-shadows: true;

// Font sizes
$font-size-h2: (def: 26px, sm: 24px);
$font-size-h3: (def: 22px, sm: 20px);
$article-h3-font-size: $font-size-h3;
$font-size-preamble: $font-size-h3;
$font-size-cta-text: (def: 16px, xs: 20px);

// Colors
$segment-color: #1d388b;
$color-blue: #003A81;

// Crate
$crate-background-color: #e9f4fa;

$accent-color: $color-bright-blue;
$accent-color-alt: $color-mid-light-blue;

$caption-background-transparent: rgba($accent-color, 0.8);
$border-color: $color-sky-blue;
$link-color: $accent-color;

// Header
$header-section-title-color: $text-color;
$header-menu-item-main-title-hover-color: $segment-color;
$header-search-placeholder-font-family: $fontfamily-regular;
$header-height: 140px;
$font-size-menu-item: (def: 16px);
$header-country-selector-label-font-size: 14px;
$header-country-selector-label-line-height: 18px;
$header-country-selector-label-color: #003A81;

// Footer
$mobile-header-menu-background: $color-mid-light-blue;
$mobile-header-trigger-color: $color-dark-blue;
$footer-country-selector-label-font-size: 16px;
$footer-country-selector-label-line-height: 22px;
$footer-country-selector-label-color: $color-white;

/**
* Components
*/

// Article
$article-title-font: $fontfamily-titles-thin;
$article-h3-margin: 0 0 13px;

// Btn
$btn-border-light-color: #fff;
$btn-font-family: $fontfamily-regular-bold;

// Image Carousel
$image-carousel-thumbnail-border-color: $border-color;

// Product Intro
$product-intro-font-color: #fff;

// Pushbox
$push-box-title-font: $fontfamily-regular-bold;
$push-box-title-transform: none;
$push-box-border-color-alt: rgba($color-black, 0.3);
$push-box-border-radius: 3px;
$push-box-border-color: rgba($color-black, 0.3);

// Pagination
$pagination-selected-background-color: $accent-color-alt;
$pagination-selected-hover-background-color: darken($accent-color-alt, 4);

// table
$table-row-even-color: rgba(249, 246, 242, 0.5);

// Call to action
$cta-title-transform: none;
$cta-background-light-color: #B2DFE5;

// Sample order
$product-box-sample-button-background: $accent-color;

// Country list submenu
$country-language-selected-color: #1D388B;