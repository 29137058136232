@if ($theme-name == "com" or $theme-name == "leukoplast" or $theme-name == "jobst") {
  @media (max-width: $container-less-than-max) {
    .com-background {
      background-position: center 35%;
      background-size: 90%;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .com-background {
      background-position: center 5%;
      background-size: 90%;
    }
  }

  .com-background {
    background: url("../img/com/map.png") no-repeat center center;
    background-position: center 25%;
    background-size: 75%;
  }
}