.sample.btn.btn-inactive,
.sample.field-hidden-input input:not(:checked) + label.btn {
  background-color:  $product-box-sample-button-background;
  border-color: $product-box-sample-button-background;
  color: $color-white;
  cursor: not-allowed;
  width: 100%;
}

.sample.btn.btn-inactive:hover,
.sample.field-hidden-input input:not(:checked) + label.btn:hover {
  background-color:  $product-box-sample-button-background;
  border-color: $product-box-sample-button-background;
  color: $color-white;
}

@if ($theme-name == "com") {
  .field-hidden-input input:not(:checked) + label.sample.btn:hover,
  .sample.btn.btn-inactive:hover,
  .sample.field-hidden-input input:not(:checked) + label.btn:hover {
    background: #003b73;
    border-color: #003b73;
    color: #fff;
  }
}