/* Global utility classes */
.u-heightAuto {
  height: auto;
}

.u-noGutter {
  padding-left: 0;
  padding-right: 0;
}

.u-noGutter-left {
  padding-right: 0;
}

.u-noGutter-right {
  padding-right: 0;
}

.u-marginTopBottom {
  margin-bottom: 15px;
  margin-top: 15px;
}

.u-marginBottom15 {
  margin-bottom: 15px;
}

.u-noMargin {
  margin: 0 !important;
}

.u-marginRightSmall {
  margin-right: 5px;
}

.u-margin-top-lg {
  margin-top: $vspace-lg !important;
}

.u-margin-top-md {
  margin-top: $vspace-md !important;
}

.u-margin-top-sm {
  margin-top: $vspace-sm !important;
}

.u-margin-sm {
  margin: $vspace-sm !important;
}

.u-imageFluid {
  @include img-fluid();
}

.u-fullWidth {
  width: 100%;
}

.u-halfWidth {
  width: 50% !important;
}

.u-paddingLeft20 {
  padding-left: 20px;
}

.u-paddingLeftRight25 {
  padding-left: 25px;
  padding-right: 25px;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-xs-lg-none {
  @media (min-width: $grid-bp-xs) {
    padding: 0 !important;
  }
}

.u-padding-none-top {
  padding: 0 !important;
}

.u-padding-none-bottom {
  padding-bottom: 0 !important;
}

.u-padding-none-right {
  padding-right: 0 !important;
}

.u-padding-none-left {
  padding-left: 0 !important;
}

.u-clearfix {
  @include clearfix();
}

.u-position-relative {
  position: relative !important;
}

.u-display-block {
  display: block !important;
}

.u-display-none {
  display: none !important;
}

.u-display-none-sm {
  @media (max-width: $grid-bp-xs-max) {
    display: none !important;
  }
}

.u-display-none-cutimed {
  @if ($theme-name == "cutimed") {
    display: none !important;
  }
}

.u-hide-after::after {
  display: none;
}

.u-visibility-hidden {
  @if ($theme-name == 'men') {
    visibility: hidden !important;
  }
}

.u-display-inline-block {
  display: inline-block !important;
}

.u-display-flex {
  display: flex !important;
}

.u-max-width-full {
  max-width: 100% !important;
}

.u-width-full {
  width: 100% !important;
}

.u-width-auto {
  width: auto !important;
}

.u-max-width-xs-full {
  @media (max-width: $grid-bp-xs) {
    max-width: 100% !important;
  }
}

.u-border-none {
  border: none !important;
}

.u-border-top-none {
  border-top: 0 !important;
}

.u-height-full {
  height: 100% !important;
}

.u-border-top-none {
  border-top: 0 !important;
}

.u-flex-grow-1 {
  flex-grow: 1 !important;
}

.u-bg-transparent {
	background-color: transparent !important;
}

.u-float-none {
	float: none !important;
}

.u-object-fit-cover {
  object-fit: cover !important;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-y-hidden {
  overflow-y: hidden !important;
}

.u-font-weight-normal {
  font-weight: normal !important;
}

.u-disableLink {
  pointer-events: none !important;
}

.u-cursorPointer {
  cursor: pointer !important;
}

.u-min-width-auto {
  min-width: auto !important
}

.u-overflow-x-auto {
  overflow-x: auto !important;
}

.u-cutimed-padding-left-none {
  padding-left: 0;
}

.u-text-align-left {
  text-align: left;
}

.PageTitle {
  @include font-size($font-size-page-title);
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.1em;
  margin: 0;
  padding-bottom: 10px;
}

.fontRed {
  color: $error-color;
}

.txtSize-small p {
  font-size: 10px;
}

@media (min-width: $grid-bp-sm) {
  .align-right-sm {
    float: right;
  }
}

/* Hide image on load / fade image in after load */

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

/* Scroll to top */
.Button--backToTop {
  @if $theme-name == "leukoplast" {
    background-color: $cta-bgcolor-alt;
  } @else if $theme-name == "actimove" {
    background-color: $color-primary;
  } @else {
    background-color: $cta-bgcolor;
  }
  border-radius: 3px;
  bottom: 50px;
  color: $color-white;
  cursor: pointer;
  display: none;
  padding: 10px;
  position: fixed;
  right: 50px;
  transition: all 0.2s ease;
  z-index: 50;

  @include mediaquery(xs) {
    bottom: auto;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &:hover {
    background-color: $cta-bg-hover;
    color: $color-white;
  }

  &:after {
    content: "\e61d";
    font-family: $fontfamily-icon;
  }
}

// Delay the form load as critical css is causing the form to break in Firefox and IE
.form {
  &.ShowBlock {
    visibility: visible;
  }
}

.DropDown-link--header {
  color: $logo-color;

  &:after {
    content: "\e61b";
    display: inline-block;
    font-family: "Icomoon";
    left: 2px;
    position: relative;
    top: 2px;
    width: 15px;

    @if ($theme-name == 'men') {
      left: 5px;
    }

    @if ($theme-name == "jobst") {
      color: $color-primary-light;
    }
  }

  &.opened:after {
    content: "\e61d";
  }
}

@if ($theme-name != "leukoplast") {
  @media (min-width: $grid-bp-md) {
    .header-details .DropDown-link--header {
      position: relative;
      outline: none;

      &::after {
        align-items: center;
        display: flex;
        height: 30px;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        visibility: visible;
        width: 30px;

        @if ($theme-name == "men" ) {
          color: $color-yellow;
          font-size: 20px;
        }
      }

      &:focus-within::after {
        outline: auto;
      }
    }

    .DropDown-link--header-text {
      visibility: hidden;
    }
  }
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
