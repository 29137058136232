.product-intro-content {
  min-height: 150px;
	@media (min-width: $grid-bp-xs) {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
    min-height: 200px;
	}
}

.product-intro-content p {
  height: auto;
}

.product-image-container {
	padding-left: 0;
	padding-right: 0;
  min-height: 100px;
}

.product-intro-container {
  @media (max-width: $grid-bp-md) {
    min-height: 100px;
  }

	img {
		display: block;
		width: 250px;
		margin: 0 auto;
		@media (max-width: $grid-bp-sm) {
			width: 210px;
		}
	}

	p {
		font-size: 22px;
		font-family: $fontfamily-titles-thin;
		padding: 10px;
		margin: 0;
    height: inherit;
		@media (min-width: $grid-bp-xs) {
			padding-left: 20px;
		}
	}

	h1 {
    @if ($theme-name == 'men') {
      @include font-size((def: 42px, sm: 40px, xs: 34px));
    }	@else {
      @include font-size($intro-title-font-size);
    }
		text-align: left;
		font-family: $fontfamily-header;
		@media (max-width: $grid-bp-xs) {
			text-align: center;
		}
	}
}

.product-intro-container .cta-btn {
	@media (max-width: $grid-bp-xs) {
		width: 100%;
	}
}