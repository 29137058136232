.product-how-to {
  @include svg-play-icon();
}

.product-how-to .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.product-how-to-content-title {
  display: none;
}

@if ($theme-name == "cutimed") {
  .product-how-to-content {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

    color: $color-gray;
    font-family: $fontfamily-regular;
  }

  .product-how-to .component-title {
    @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

    color: $color-gray;
    font-family: $fontfamily-regular-bold;
  }

  .product-how-to-image--has-border {
    border: 1px solid $color-gray;
  }
}

@if ($is-tena-theme) {
  .accordion-item .product-how-to,
  .accordion-item .product-how-to .component-title {
    font-family: $fontfamily-regular;
    color: $home-color;
    text-transform: none;
  }

  .accordion-item .product-how-to .component-title {
    @include font-size-and-line-height(22px, 28px);

    margin-bottom: 20px;

    @if ($theme-name != "actimove") {
      margin-top: 50px;
    }

    @media (min-width: $grid-bp-sm) {
      @include font-size-and-line-height(28px, 32px);
    }
  }

  .accordion-item .product-how-to-content,
  .accordion-item .product-how-to-content p {
    line-height: $product-intro-accordion-how-to-content-line-height;

    @if ($theme-name == "actimove") {
      color: $color-light-black;
    }

    @if ($theme-name == "jobst") {
      font-family: $fontfamily-regular-light;
      color: $color-gray;
    }
  }

  .accordion-item .product-how-to-content p {
    margin-bottom: 0;
  }
}

@if ($theme-name == "actimove") {
  .accordion-item .product-how-to-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .accordion-item .product-how-to-content {
    margin-bottom: 10px;
  }

  .accordion-item .product-how-to-content-title {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
  }

  .accordion-item .product-how-to-wrapper,
  .accordion-item .product-how-to {
    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      max-width: 530px;
    }

    @media (min-width: $container-max-width) {
      max-width: 534px;
    }
  }
}

@if ($theme-name == "jobst") {
  .accordion-item .product-how-to-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .accordion-item .product-how-to-wrapper,
  .accordion-item .product-how-to {
    margin-inline: -15px;
    text-align: left;

    @media (min-width: $grid-bp-xs) {
      margin-inline: -30px;
    }
  }

  .accordion-item .product-how-to-content {
    margin-bottom: 24px;
  }
}
