.promo-banner {
  width: 100%;
  background-size: cover;
  background-repeat: round;
  text-align: center;
  color: $color-white;
  display: inline-block;
  background: $promo-banner-bg-color;

  @if ($theme-name == "leukoplast") {
    border-radius: 20px;
  }

  @if ($theme-name == "actimove") {
    transform: skew(-10deg, 0deg);
    box-shadow: 12px 12px 0px 0px $color-accent;
    margin: 0 25px 15px;
    width: auto;
  }
}

@if ($theme-name == "cutimed") {
  .promo-banner-bg {
    width: 100%;
  }
}

@if ($theme-name == "cgr") {
  .promo-banner-corner-bottom-img {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .promo-banner-corner-top-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.promo-banner-cover--mobile{
  display: none;
}

.promo-banner-cover--mobile:empty {
  display: none;
}

.promo-banner-cover--mobile:empty + .promo-banner-cover {
  display: block;
}

.promo-banner-cover-img {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: fill;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;

  @if ($theme-name == "women") {
    object-fit: cover;
  }
}

.promo-banner-content {
  margin: 70px 15px;

  @if ($theme-name == "actimove") {
    margin: 60px 25px;
    transform: skew(10deg, 0deg);
  }

  @if ($theme-name == "cutimed") {
    margin: 60px 25px 1px;
  }

  @if ($theme-name == "leukoplast") {
    margin: 40px 20px;
  }

  @if ($theme-name == "jobst") {
    margin: 60px 15px;
  }
}

.promo-banner-title-primary {
  color: $color-white;

  @if ($theme-name == "cutimed") {
    color: $color-blue;
  }

  @if ($theme-name == "actimove") {
    font-style: italic;
  }

  @if ($theme-name == "men") {
    text-transform: uppercase;
  }

  @if ($theme-name == "women") {
    color: $accent-color-alt-light;
  }

  @if ($theme-name == "cgr") {
    text-transform: uppercase;
  }
}

.promo-banner-title-secondary {
  color: $color-yellow;
  text-transform: uppercase;

  @if ($theme-name != "men") {
    display: none;
  }
}

.promo-banner-title-primary,
.promo-banner-title-secondary {
  @include font-size-and-line-height(
    $promo-banner-title-font-size,
    $promo-banner-title-line-height
  );

  margin: 0;
  font-family: $promo-banner-title-font;
  letter-spacing: $promo-banner-title-letter-spacing;
  font-weight: $promo-banner-title-font-weight;
  text-wrap: balance;
}

.promo-banner-desc,
.promo-banner-desc p {
  @include font-size-and-line-height(
    $promo-banner-desc-font-size,
    $promo-banner-desc-line-height
  );

  margin: 0;
  font-family: $promo-banner-desc-font;
  color: $color-white;

  @if ($theme-name == "actimove" or $theme-name == "cutimed" or $theme-name == "jobst" or $theme-name == "women") {
    margin-top: 16px;
  }

  @if ($theme-name == "cutimed") {
    color: $color-blue;
  }

  @if ($theme-name == "cgr" or $theme-name == "men") {
    margin-top: 10px;
  }

  @if ($theme-name == "leukoplast") {
    margin-top: 24px;
  }
}

@media (min-width: $grid-bp-xs) {
  @if ($theme-name == "actimove") {
    .promo-banner {
      margin: 0 0 15px;
      width: 100%;
    }
  }
}

@media (max-width: $grid-bp-sm) {
  .promo-banner-cover--mobile:not(:empty) {
    display: block;
  }

  .promo-banner-cover--mobile:not(:empty) ~ .promo-banner-cover {
    display: none;
  }
}

@media (min-width: $grid-bp-sm) {
  .promo-banner-content {
    @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
      max-width: 612px;
      margin: 70px auto;
    }

    @if ($theme-name == "actimove") {
      margin: 60px 100px;
    }

    @if ($theme-name == "cutimed") {
      max-width: 772px;
    }

    @if ($theme-name == "leukoplast" or $theme-name == "jobst") {
      max-width: 468px;
      margin: 60px auto;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .promo-banner-content {
    @if ($theme-name == "women" or $theme-name == "cgr") {
      max-width: 612px;
      margin: 70px auto;
    }

    @if ($theme-name == "men") {
      max-width: 750px;
      margin: 70px auto;
    }

    @if ($theme-name == "cutimed") {
      margin: 60px auto 1px;
    }

    @if ($theme-name == "leukoplast" or $theme-name == "jobst") {
      max-width: 612px;
    }
  }
}

@media (min-width: $grid-bp-lg) {
  .promo-banner-content {
    @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
      margin: 75px auto;
    }

    @if ($theme-name == "men") {
      max-width: 800px;
    }

    @if ($theme-name == "women" or $theme-name == "cgr") {
      max-width: 750px;
    }

    @if ($theme-name == "leukoplast" or $theme-name == "jobst") {
      max-width: 772px;
    }
  }
}
