.intro-carousel.carousel {
  @media (max-width: $grid-bp-sm) {
    min-height: 300px;
  }
}

.intro-carousel.carousel .intro-carousel-item-container {
  height: auto;
}

.carousel-campaign.swiper-backface-hidden .swiper-slide {
  @if ($theme-name == "cgr" or $theme-name == "women" or $theme-name == "men" or $theme-name == "jobst") {
    min-height: 500px;
  }

  @if ($theme-name == "jobst") {
    min-height: 660px;
    background-color: $color-primary;
  }
}

.carousel .play-pause-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: $btn-play-pause-background;
  position: absolute;
  z-index: 2;
  top: 25px;
  right: 25px;
  cursor: pointer;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    background-color: $btn-play-background-transparent;
  }
}

.carousel .play-pause-btn .play-icon-circle {
  fill-opacity: 0;
}

.carousel .play-pause-btn .svg-icon.play-icon {
  width: 45px;
  height: 45px;
}

.carousel .play-pause-btn .play-icon--is-paused .play-icon-arrow {
  fill: transparent;
}

.carousel .play-pause-btn .play-icon--is-paused .play-icon-circle,
.carousel .play-pause-btn .play-icon--is-paused .play-icon-arrow {
  stroke-width: 0;
}

.carousel .play-pause-btn .play-icon-circle,
.carousel .play-pause-btn .play-icon-arrow {
  stroke: $color-white;
  stroke-width: 0;
}

.carousel .play-pause-btn .play-icon--is-paused .play-icon-pause,
.carousel .play-pause-btn .play-icon-arrow {
  fill: $color-white;
  fill-opacity: 1;

  @if ($theme-name == "men") {
    fill: $color-yellow;
  }

  @if ($theme-name == "jobst") {
    fill: $color-primary;
  }
}

.carousel {
  max-width: 1920px;
  overflow: hidden;
  position: relative;
}

.carousel-slide {
  @include svg-play-icon();
  height: auto;
  padding-top: 2px;

  &--has-border {
    border-radius: $push-box-border-radius;
    border-style: solid;
    border-width: 1px;

    @if ($theme-name == "cutimed") {
      border: none;
      border-radius: 0;
    }

    @if ($theme-name == "com" or $theme-name == "men") {
      border-color: $push-box-border-color-alt;
    } @else {
      border-color: $border-color;
    }
  }
}

.carousel-slide .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.carousel-slide-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carousel-text-content {
  height: 100%;
  padding: 15px;
  word-break: break-word;
}

.carousel-title,
.carousel-subHeading,
.carousel-text {
  margin-bottom: 5px;
}

.carousel-title {
  color: $accent-color;
  font-family: $fontfamily-header;
  font-size: 22px;
  line-height: 1.3;
}

.carousel-text {
  line-height: 1.3;
}

.carousel-img-container {
  position: relative;
  width: 100%;
}

.carousel-img {
  height: auto;
  vertical-align: bottom;
  width: 100%;
}

/* Carousel controls */
.carousel-controls {
  height: 44px;
  overflow: hidden;
  text-align: center;
  width: 100%;

  @if ($theme-name == "men") {
    height: 65px;
    overflow: inherit;
  }
}

.carousel-go-left,
.carousel-go-right {
  @include disableSelection();

  cursor: pointer;
  position: absolute;
  @include mediaquery(xxs) {
    top: 162%;
  }

  &:hover {
    @if ($theme-name == "com") {
      color: $color-white;
    } @else if($theme-name == "men") {
      color: $carousel-box-control-color;
    } @else {
      color: $color-black;
    }
  }
}

.carousel-go-left {
  left: 0;
}

.carousel-go-right {
  right: 0;
}

.carousel-pager {
  @include disableSelection();
  display: inline-block;
  padding-top: 10px;
  text-align: center;
  width: 100%;
}

.carousel-btn {
  bottom: 20px;
  position: absolute;
  right: 20px;
}

.carousel-action-btn {
  bottom: 25px;
  position: absolute;
  right: 25px;
}

.carousel-disclaimer-container {
  bottom: 10px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
  width: auto;
}

.carousel-disclaimer {
  color: $color-white;
  font-size: 10px;
  margin: 0;

  &--is-dark {
    color: $home-color;
  }
}

.carousel-alt .testimonial-container {
  margin: auto;
  border: none;
  padding-bottom: 10px;
}

.carousel-alt .testimonial-container .testimonial-quote {
  @include font-size-and-line-height(
    $testimonials-quote-font-size,
    $testimonials-quote-line-height
  );
  letter-spacing: -0.5px;
  margin-bottom: 20px;

  @if ($theme-name == "men") {
    text-transform: uppercase;
    color: $color-purple;
    font-family: $fontfamily-gotham-black;
    margin: 0;
  }
}

.carousel-alt .testimonial-container .testimonial-quote-info {
  @include font-size-and-line-height(
    $testimonials-quote-info-font-size,
    $testimonials-quote-info-line-height
  );
  margin-bottom: 0;
}

.carousel-alt .carousel-controls {
  padding-top: 0;
  position: relative;
  margin-bottom: 20px;
}

.gallery-text {
  text-align: center;
  margin-bottom: 35px;
}

.gallery-heading {
  @include font-size-and-line-height($font-size-gallery-carousel-heading, $gallery-carousel-heading-line-height);

  margin-bottom: 20px;
}

.gallery-subheading {
  @include font-size-and-line-height($font-size-gallery-carousel-subheading, $gallery-carousel-subheading-line-height);

  font-family: $fontfamily-regular;
  font-weight: normal;
}

@media (max-width: $grid-bp-sm) {
  .carousel-alt .testimonial-container {
    padding: 10px;
  }
}

/* // Carousel controls */
@media (min-width: $grid-bp-sm) {
  .carousel-text-content {
    padding: 25px;
  }
}

.carousel-slide-link {
  display: block;
  overflow: hidden;
  height: 100%;
}

.campaign-slide__content {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.campaign-slide__title {
  color: $color-white;
  font-size: 48px;
  padding-bottom: 10px;
  @media (max-width: $grid-bp-sm) {
    font-size: 28px;
  }
}
.campaign-slide__subtitle {
  color: $color-white;
  padding-bottom: 10px;
  font-size: 16px;
}

.carousel-campaign {
  @media (min-width: $grid-bp-sm) {
    display: block;
    margin: 0 auto;
    max-width: 1600px;
  }
}

.campaign-slide__content .btn {
  @media (max-width: $grid-bp-xs) {
    margin: 0 auto;
    width: 140px;
  }
}

.carousel-campaign .swiper-button-prev {
  left: 10%;
  transform: translateY(-50%);
}

.carousel-campaign .swiper-button-next {
  right: 10%;
  transform: translateY(-50%);
}

.section .carousel-campaign .swiper-button-prev {
  transform: translateY(-100%);
}

.section .carousel-campaign .swiper-button-next {
  transform: translateY(-100%);
}

.carousel-campaign .swiper-button-next
{
  &::after {
    color: $color-white;
  }
}

.carousel-campaign .swiper-button-prev {
  &::after {
    color: $color-white;
  }
}

@if ($theme-name == "cutimed") {
  .carousel-text-content {
    background-color: $color-light-blue-2;
    padding: 30px 35px 0;
  }

  .carousel-title {
    @include font-size-and-line-height(28px, 32px);
    color: $color-gray;
    margin-bottom: 10px;
    font-family: $fontfamily-regular-bold-condensed;
  }

  .carousel-text {
    @include font-size-and-line-height(16px, 25px);
    color: $color-gray;
    font-family: $fontfamily-regular;
    margin-bottom: 40px;
  }

  .carousel-btn {
    width: 84%;
    margin-left: 8%;
    right: unset;
    bottom: 25px;
    background: $color-white;
    color: $color-gray;
    text-align: center;
    border: none;
    border-radius: 4px;
  }

  .carousel-btn span {
    @include font-size-and-line-height(15px, 20px);
  }

  .carousel-btn:hover {
    background: $color-white;
    color: $color-gray;
  }

  .carousel-disclaimer-container {
    bottom: 5px;
    left: -5px;
  }
}

.logo-carousel .swiper-slide {
  text-align: center;

  @media (min-width: $grid-bp-md) {
    text-align: left;
  }
}

@if ($theme-name == "actimove") {
  .campaign-slide-media {
    height: 100%;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .carousel-campaign .swiper-button-prev,
  .carousel-campaign .swiper-button-next {
    &::after {
      color: $color-primary;
      font-size: 18px;
    }
  }

  .region-intro .carousel-alt .carousel-controls .swiper-button-prev {
    left: 37%;
  }

  .region-intro .carousel-alt .carousel-controls .swiper-button-next {
    right: 37%;
  }

  .carousel-alt .carousel-controls .swiper-button-prev,
  .carousel-alt .carousel-controls .swiper-button-next {
    margin-top: 0;
    border: 1px solid $color-primary;
    border-radius: 50%;
    padding: 20px;
    width: 20px;
    height: 20px;
  }

  .carousel-alt .campaign-slide__btn {
    @include btn("primary-light");

    width: 100%;
    text-align: center;

    &::after {
      right: auto;
      margin-left: 10px;
    }
  }

  .campaign-slide__title {
    @include font-size-and-line-height($carousel-title-font-size, $carousel-title-line-height);

    font-style: italic;
    color: $carousel-content-dark-color;
  }

  .campaign-slide__subtitle {
    letter-spacing: 0.4px;
  }

  .campaign-slide__subtitle,
  .campaign-slide__description {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
    color: $carousel-content-dark-color;
  }

  .lightenText.campaign-slide__subtitle,
  .lightenText.campaign-slide__title,
  .lightenText.campaign-slide__description {
    color: $carousel-content-lighten-color;
  }

  .carousel-campaign .carousel-slide .svg-icon.play-icon {
    position: relative;
    left: 0;
    transform: none;
    margin-top: 20px;
  }

  .campaign-slide__content {
    bottom: 24px;
    top: unset;
    transform: none;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  .carousel-campaign .carousel-controls {
    height: 90px;
    overflow: unset;
  }

  .region-intro .carousel-controls {
    height: 50px;
    overflow: hidden;
  }

  .carousel-campaign .carousel-controls .swiper-button-prev {
    left: 35%;
  }

  .carousel-campaign .carousel-controls .swiper-button-next {
    right: 35%;
  }

  .carousel-campaign .carousel-slide {
    height: 520px;
    overflow-x: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba($color-black, 0) 0%, rgba($color-black, 0.54) 100%);
    }
  }

  @media (min-width: $grid-bp-xs) {
    .campaign-slide__content {
      bottom: 40px;
      width: 500px;
    }
  }

  @media (min-width: $grid-bp-md) {
    .carousel-campaign .carousel-slide {
      height: auto;

      &::before {
        content: none;
      }
    }

    .region-intro .carousel-campaign .carousel-controls .swiper-button-prev {
      left: 40%;
    }

    .region-intro .carousel-campaign .carousel-controls .swiper-button-next {
      right: 40%;
    }

    .carousel-campaign .carousel-controls .swiper-button-prev {
      left: 38%;
    }

    .carousel-campaign .carousel-controls .swiper-button-next {
      right: 38%;
    }

    .campaign-slide__content {
      text-align: left;
      padding: 60px 50px 75px 40px;
      background: $campaign-content-background-color;
      position: absolute;
      bottom: 0;
      right: unset;
      left: 0;
      top: unset;
      transform: none;
      // Calculates an angle of 10 degrees on the right side
      clip-path: polygon(0 0, 100% 0, calc(100% + -0.18 * 100vmax) 100vmax, 0 100vmax);
      width: 720px;
    }

    .carousel-campaign .swiper-slide {
      padding-bottom: 40px;
    }

    .carousel-campaign .campaign-slide__btn {
      width: fit-content;
    }
  }

  @media (min-width: $grid-bp-lg) {
    .region-intro .carousel-campaign .carousel-controls .swiper-button-prev {
      left: 42%;
    }

    .region-intro .carousel-campaign .carousel-controls .swiper-button-next {
      right: 42%;
    }

    .carousel-campaign .carousel-controls .swiper-button-prev {
      left: 40%;
    }

    .carousel-campaign .carousel-controls .swiper-button-next {
      right: 40%;
    }
  }

  .gallery-text {
    margin-bottom: 20px;
  }

  .gallery-heading {
    margin-bottom: 10px;
    font-style: italic;
  }

  .gallery-subheading {
    color: $color-light-black;
  }

  .carousel-gallery .carousel-controls {
    margin-top: 15px;
    margin-bottom: 0;
    height: 45px;
  }

  .carousel-alt.carousel-gallery .swiper-slide {
    padding-bottom: 0;
  }

  @media (max-width: $grid-bp-xs-max) {
    .carousel-alt.carousel-gallery .carousel-slide {
      height: auto;
    }

    .carousel-gallery + .component.component-btn .btn {
      width: 100%;
      text-align: center;
      padding-right: 20px;

      &:after {
        right: auto;
        margin-left: 10px;
      }
    }
  }

  .carousel-slide-content {
    background-color: $color-primary-light-blue;
  }

  .carousel-text-content {
    padding: 25px;
  }

  .carousel-title {
    margin-bottom: 8px;
    font-style: italic;
  }

  .carousel-subtitle {
    font-family: $fontfamily-regular;
    color: $home-color;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .carousel-text {
    color: $color-light-black;
  }

  .carousel-btn {
    margin: 0 auto 25px 25px;
    text-align: left;
  }
}

@if ($theme-name == "actimove" or $theme-name == "jobst" or $theme-name == "women") {
  .carousel-title {
    @include font-size-and-line-height($box-carousel-title-font-size, $box-carousel-title-line-height);

    color: $home-color;
  }

  .carousel-subtitle {
    @include font-size-and-line-height($box-carousel-subtitle-font-size, $box-carousel-subtitle-line-height);
  }

  .carousel-text {
    @include font-size-and-line-height($box-carousel-text-font-size, $box-carousel-text-line-height);
  }

  .carousel-text p {
    margin-bottom: 0;
  }

  .carousel-btn {
    position: relative;
    right: unset;
    bottom: unset;
  }
}


@if ($theme-name == "women") {
  .carousel-alt.carousel-gallery .carousel-controls {
    height: unset;
    min-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (min-width: $grid-bp-md) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .carousel .box-carousel {
    padding: 0 15px;
  }

  .carousel-subtitle {
    font-family: $fontfamily-regular;
    color: $color-primary-black;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 6px;
  }

  .carousel-title {
    color: $color-blue;
    font-family: $fontfamily-regular;
    font-weight: 400;
    margin-bottom: 6px;
  }

  .carousel-btn {
    margin: 0 auto 24px 24px;
  }

  .box-carousel .carousel-btn {
    display: flex;
    align-items: center;
  }

  .box-carousel .carousel-btn span {
    @include font-size-and-line-height(16px, 22px);
  }

  .box-carousel .carousel-slide {
    box-shadow: 1px 4px 16px 0px rgba($box-carousel-shadow-color, 0.2);
  }

  .box-carousel .carousel-slide a {
    &:hover {
      color: $color-primary-black;
    }
  }

  .carousel-alt.carousel-gallery ~ .component-btn .btn span {
    line-height: 19px;
  }
}

@if ($theme-name == "women" or $theme-name == "jobst") {
  .carousel-alt.carousel-gallery .carousel-pager {
    padding-top: unset;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: unset !important;
    width: auto;
  }

  .carousel-alt.carousel-gallery ~ .component-btn .btn {
    @include btn("primary-light");

    display: inline-block;
  }
}

@if ($theme-name == "jobst") {
  .carousel-slide:not(.type-full-image) .carousel-text-wrapper.carousel-bg-gradient {
    background: unset;
  }

  .gallery-heading {
    font-family: $fontfamily-regular-bold;
  }

  .gallery-subheading {
    color: $color-gray;
  }

  .carousel-alt .carousel-controls {
    height: unset;
    min-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .carousel-alt.carousel-gallery ~ .component-btn .btn span {
    font-weight: normal;
  }

  .box-carousel .carousel-slide {
    box-shadow: $promo-box-shadow;
    border-radius: 3px;
  }

  .carousel-bg-gradient {
    background: transparent;
  }

  .carousel-text-wrapper {
    height: 100%;
  }

  .carousel-text-content {
    padding: 24px;
  }

  .carousel-title {
    margin-bottom: 8px;
  }

  .carousel-subtitle {
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 4px;
    color: $color-primary;
  }

  .carousel-slide.type-full-image .carousel-subtitle {
    color: $color-white;
  }

  .carousel-text {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
    margin-bottom: 0;
  }

  .carousel-btn {
    @include btn("primary-light");

    margin: 0 auto 24px 24px;
  }

  .carousel-btn span {
    font-weight: normal;
  }

  .box-carousel .carousel-controls {
    height: unset;
    min-height: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }

  .box-carousel .carousel-pager {
    padding-top: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-carousel .play-pause-btn {
    position: unset;
    background-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: unset;
  }

  .box-carousel .play-pause-btn .svg-icon.play-icon {
    width: 33px;
    height: 32px;
  }

  .box-carousel .play-pause-btn .play-icon--is-paused .play-icon-pause,
  .box-carousel .play-pause-btn .play-icon-arrow {
    fill: $color-primary;
  }


  .play-pause-text {
    @include font-size-and-line-height(14px, 18px);

    margin-inline: 8px;
  }

  .carousel .type-full-image .carousel-slide-content {
    min-height: 400px;
  }

  .carousel .type-full-image .carousel-img-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .carousel .type-full-image .carousel-img-container .carousel-img {
    object-fit: cover;
    height: 100%;
  }

  .carousel .type-full-image .carousel-text-wrapper {
    height: 100%;
    z-index: 2;
    padding: 24px;
    padding-bottom: 88px;
  }

  .carousel .type-full-image .carousel-text-content {
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $color-white;
  }

  .carousel .type-full-image .carousel-title,
  .carousel .type-full-image .carousel-text {
    color: $color-white;
  }

  .carousel .type-full-image .carousel-btn {
    position: absolute;
    bottom: 0;
    z-index: 3;
  }

  .carousel .type-full-image .carousel-disclaimer-container {
    bottom: 3px;
    left: 4px;
    z-index: 3;
  }

  .carousel-pager {
    padding-top: 24px;
  }

  .carousel-pager,
  .play-pause-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel .carousel-pager .play-pause-btn {
    width: 20px;
    height: 20px;
    position: unset;
    background-color: transparent;
  }

  .carousel .carousel-pager .play-pause-btn .svg-icon.play-icon {
    width: 12px;
    height: 20px;
    position: unset;
    transform: unset;
  }

  .carousel-alt .play-pause-btn .play-icon--is-paused .play-icon-pause,
  .carousel-alt .play-pause-btn .play-icon-arrow,
  .carousel-pager .play-pause-text {
    fill: $color-primary;
    color: $color-primary;
  }

  .carousel-pager .play-pause-text {
    @include font-size-and-line-height(14px, 18px);

    margin-left: 0;
    margin-right: 8px;
  }

  .carousel-alt .carousel-controls {
    margin-top: 0;
  }

  .carousel-alt .campaign-slide__content {
    position: relative;
    top: unset;
    bottom: unset;
    right: unset;
    background-color: $color-primary;
    transform: unset;
    width: 100%;
    padding: 24px 20px;
    text-align: left;
    border-bottom-right-radius: none;

    @media (min-width: $grid-bp-xs) {
      padding: 40px 28px;
    }

    @media (min-width: $grid-bp-sm) {
      background-color: rgba($color-primary, 0.81);
      position: absolute;
      top: unset;
      bottom: 6%;
      right: 9%;
      transform: unset;
      width: initial;
      max-width: 580px;
      padding: 40px;
      text-align: left;
      border-bottom-right-radius: 64px;
    }
  }

  .carousel-campaign .u-object-fit-cover {
    position: sticky;

    @media (min-width: $grid-bp-sm) {
      position: absolute;
      left: 0;
    }
  }

  .campaign-slide-media {
    height: 390px;

    @media (min-width: $grid-bp-xs) {
      height: 480px;
    }

    @media (min-width: $grid-bp-sm) {
      height: auto;
    }
  }

  .carousel-campaign .u-object-fit-cover {
    position: static;

    @media (min-width: $grid-bp-sm) {
      position: absolute;
      left: 0;
    }
  }

  .carousel-text-wrapper {
    height: 100%;
  }

  .carousel-alt .campaign-slide__content .col-centered {
    width: inherit;
  }

  .carousel-alt .campaign-slide__title {
    @include font-size-and-line-height(18px, 26px);

    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  .carousel-alt .campaign-slide__subtitle {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold;
    padding-bottom: 15px;
  }

  .carousel-alt .campaign-slide__description {
    @include font-size-and-line-height(18px, 26px);

    color: $color-white;
    padding-bottom: 15px;
    margin: 0;
    font-family: $fontfamily-interstate-light;
  }

  .carousel-alt .campaign-slide__content .btn {
    width: 100%;
    border: 2px solid $color-white;
    border-radius: 3px;
    background: transparent;

    &:hover {
      color: $color-white;

      &::after {
        color: $color-white;
      }
    }

    @media (min-width: $grid-bp-xs) {
      width: initial;
    }
  }

  .carousel-alt .swiper-button-prev,
  .carousel-alt .swiper-button-next {
    top: 50%;
  }

  .carousel-campaign .swiper-button-prev {
    left: 2%;
  }

  .carousel-campaign .swiper-button-next {
    right: 2%;
  }
}

@if ($theme-name == "cgr") {
  .carousel-pager,
  .play-pause-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel .play-pause-btn {
    width: 32px;
    height: 32px;
    position: unset;
    background-color: transparent;
  }

  .carousel .play-pause-btn .svg-icon.play-icon {
    width: 32px;
    height: 32px;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: unset !important;
    width: initial;
  }

  .play-icon-pause {
    fill: transparent;
  }

  .carousel .play-pause-btn .play-icon--is-paused .play-icon-pause,
  .carousel .play-pause-btn .play-icon-arrow,
  .box-carousel .play-pause-btn .play-icon-arrow,
  .play-pause-text {
    fill: $segment-color;
    color: $segment-color;
  }

  .play-pause-text {
    @include font-size-and-line-height(14px, 18px);

    margin-left: 0;
    margin-right: 8px;
  }

  .carousel-alt .carousel-controls {
    margin-top: 0;
  }

  .carousel.carousel-campaign .swiper-button-prev {
    left: 1%;
    transform: translateY(-70%);
  }

  .carousel.carousel-campaign .swiper-button-next {
    right: 1%;
    transform: translateY(-70%);
  }

  .carousel .campaign-slide__title {
    @include font-size-and-line-height($carousel-title-font-size, $carousel-title-line-height);

    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: $fontfamily-regular-bold;
  }

  .carousel .campaign-slide__subtitle {
    @include font-size-and-line-height(16px, 22px);

    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: $fontfamily-regular;
    font-weight: 400;

    @media (min-width: $grid-bp-xs) {
      letter-spacing: 5px;
    }
  }

  .carousel .campaign-slide__description {
    @include font-size-and-line-height(16px, 22px);

    color: $color-white;
    margin-bottom: 24px;
  }
}

@if ($theme-name == "cgr" or $theme-name == "women" or $theme-name == "men") {
  .carousel-campaign .u-object-fit-cover {
    position: absolute;
    left: 0;
  }

  .carousel-text-wrapper {
    height: 100%;
  }
}