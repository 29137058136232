// Todo: figure out a common name
.rating,
.colors,
.pager {
    @include clearfix();
    @include disableSelection();
    cursor: default;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: inline;
        font-size: 15px;
        height: 11px;
        width: 11px;

        &.color-1 {
            color: red;
        }

        &.color-2 {
            color: green;
        }

        &.color-3 {
            color: blue;
        }

        &.color-yellow {
            color: yellow;
        }

        &.color-blue {
            color: blue;
        }

        &.color-lilac {
            color: #c8a2c8;
        }

        &.color-apricot {
            color: #fbceb1;
        }

        &.color-green {
            color: green;
        }

        &.color-white {
            color: white;
        }

        &.color-orange {
            color: orange;
        }

        &.color-red {
            color: red;
        }

        &.color-brown {
            color: #87421f;
        }

        &.color-grey {
            color: #809ea7;
        }
    }

    &.pager-centered {
        text-align: center;
        width: 100%;

        li {
            display: inline;
        }
    }

}

.colors {
    li {
        font-size: 26px;
    }
}