.one-col-text {
  @include svg-play-icon();
}

.one-col-text .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@if ($theme-name == "cutimed") {
  .one-col-text .component-title {
    @include font-size-and-line-height(28px, 32px);
  }
}

@if ($theme-name == "women") {
  .one-col-text .action-button-container {
    text-align: left;
  }
}

@if ($theme-name == "leukoplast") {
  .one-col-text {
    width: 100%;
  }

  .one-col-text .article .text-content {
    font-family: $fontfamily-regular;
  }
}

@if ($theme-name == "jobst") {
  .one-col-text .article .text-content {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
  }
}

@if ($theme-name == "actimove") {
  .one-col-text .component-title {
    @include font-size-and-line-height(22px, 26px);

    font-weight: 600;
  }

  .one-col-text .article .text-content {
    color: $color-light-black;
  }

  .one-col-text .text-content a {
    color: $color-primary;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      color: $color-olive;
    }
  }
}

@if ($theme-name == "cgr") {
  .one-col-text .component-title {
    @include font-size-and-line-height(22px, 28px);

    border: unset;
    color: $color-primary-black;
    font-family: $fontfamily-regular;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 0;
    margin-bottom: 10px;
  }

  .one-col-text .text-content a {
    text-decoration: underline;
    font-weight: 600;
  }

  .one-col-text strong,
  .one-col-text b {
    font-family: $fontfamily-regular-bold;
  }
}