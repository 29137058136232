.product-number-view-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.view-options {
  display: none;
}

.view-options-group {
  display: flex;
  gap: 5px;
}

.view-options-group-list-button,
.view-options-group-grid-button {
  background: none;
  border: none;
  padding: 0;
  height: 40px;

  @if ($theme-name == "jobst" or $theme-name == "women") {
    height: 44px;
  }
}

@if ($is-tena-theme) {
  .view-options {
    display: block;
  }

  .view-options-group--active-list .svg-product-filter-list-icon--tena .line-group,
  .view-options-group--active-grid .svg-product-filter-grid-icon--tena .line-group {
    stroke: $color-white;
  }

  @if ($theme-name == "cgr") {
    .svg-product-filter-grid-icon--tena .svg-border,
    .svg-product-filter-list-icon--tena .svg-background,
    .svg-product-filter-grid-icon--tena .line-group,
    .svg-product-filter-list-icon--tena .line-group {
      stroke: $segment-color;
    }

    .view-options-group--active-list .svg-product-filter-list-icon--tena .svg-background,
    .view-options-group--active-grid .svg-product-filter-grid-icon--tena g rect {
      fill: $segment-color;
    }

    .view-options-group--active-grid .svg-product-filter-grid-icon--tena > g > rect {
      stroke: $segment-color;
    }
  } @else if ($theme-name == "men") {
    .svg-product-filter-grid-icon--tena .svg-border,
    .svg-product-filter-list-icon--tena .svg-background,
    .svg-product-filter-grid-icon--tena .line-group,
    .svg-product-filter-list-icon--tena .line-group {
      stroke: $color-purple;
    }

    .view-options-group--active-list .svg-product-filter-list-icon--tena .svg-background,
    .view-options-group--active-grid .svg-product-filter-grid-icon--tena rect {
      fill: $color-purple;
    }
  } @else if ($theme-name == "women") {
    .view-options-group .svg-product-filter-grid-icon--tena,
    .view-options-group .svg-product-filter-list-icon--tena {
      width: 44px;
      height: 44px;
    }

    .view-options-group--active-list .svg-product-filter-list-icon--tena .svg-background,
    .view-options-group--active-grid .svg-product-filter-grid-icon--tena rect {
      fill: $view-options-view-button-color;
      stroke: $view-options-view-button-color;
    }

    .view-options-group--active-grid .svg-product-filter-grid-icon--tena #Grid-icon rect,
    .view-options-group--active-grid .svg-product-filter-grid-icon--tena line {
      stroke: $color-white;
    }

    .svg-product-filter-list-icon--tena rect,
    .svg-product-filter-list-icon--tena .line-group,
    .svg-product-filter-grid-icon--tena rect,
    .svg-product-filter-grid-icon--tena line {
      stroke: $view-options-view-button-color;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .view-options {
      display: none;
    }
  }
}

@if ($theme-name == "actimove") {
  .svg-product-filter-grid-icon--actimove,
  .svg-product-filter-list-icon--actimove {
    padding: 5px;
  }

  .view-options-group--active-grid .svg-product-filter-grid-icon--actimove,
  .view-options-group--active-list .svg-product-filter-list-icon--actimove {
    background-color: $color-accent;
  }

  .view-options-group--active-grid .svg-product-filter-grid-icon--actimove path,
  .view-options-group--active-list .svg-product-filter-list-icon--actimove path {
    fill: $color-white;
  }

  .view-options-icon:first-of-type {
    margin-right: 5px;
  }
}

.component-product-box {
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  overflow: hidden;
  cursor: pointer;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
    padding: 25px 0;
  } @else if ($theme-name == "cgr") {
    padding: 24px 16px 14px 16px;
  } @else if ($theme-name == "jobst") {
    padding: 34px 24px 25px;
  } @else {
    padding: 12px 10px 20px;
  }

  @if ($theme-name == "leukoplast") {
    border-bottom: none;
  }
}

.component-product-box .product-box {
  width: 100%;
  @if ($theme-name == "leukoplast") {
    flex-direction: column;
  }
}

.accordion-content-inner .component-product-box {
  border-bottom: transparent;
}

.product-box-content {
  flex-direction: column;
  position: relative;
  width: 100%;
}

.product-card-compare-content {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.product-card-compare-content input[type="checkbox"] {
  width: 24px;
  height: 24px;

  &:checked {
    accent-color: $product-compare-popup-checkbox-color;
  }
}

.product-card-compare-text {
  @include font-size-and-line-height(16px, 22px);

  margin: unset;
  margin-left: 5px;
  color: $home-color;
  font-family: $fontfamily-asap;
}

.product-box-container-image-left .product-box-content {
  word-break: break-word;

  @media (min-width: $container-max-width) {
    @if ($theme-name == "cutimed") {
      flex-direction: column;
    } @else {
      flex-direction: row;
    }
  }

  @media (min-width: $grid-bp-sm) and (max-width: $container-max-width) {
    flex-direction: column;
  }
}

.product-box-container-image-left .component-product-box {
  @media (min-width: $grid-bp-sm) {
    border-bottom: none;
  }
}

.product-box-content .detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.product-box-container-image-left .product-box-content .detail {
  margin-bottom: 20px;
}

.product-box-img {
  text-align: center;

  @include mediaquery(xs) {
    margin-right: 0;
    width: auto;
  }

  @media (min-width: $grid-bp-sm) {
    @if ($theme-name == "actimove") {
      min-height: 150px;
    } @else {
      min-height: 180px;
    }
  }

  img {
    min-height: 100px;
    max-height: 200px;
    object-fit: contain;

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-lg) {
      max-width: none;
    }
  }
}

.product-box-text {
  display: flex;
  flex-direction: column;
  margin: $product-box-text-margin;
  position: relative;
  width: auto;
  text-align: center;
  word-break: break-word;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      text-align: left;
    }
  }

  @if ($theme-name == "leukoplast") {
    margin: 0 20px;
  }

  @if $theme-name == "cutimed" {
    text-align: left;
    margin: 18px 0 20px;

    @media (min-width: $grid-bp-xs-max) {
      margin: 30px 0;
    }
  }

  h3 {
    @include font-size($font-size-paragraph);

    margin: 0 0 5px;

    @if ($theme-name == "leukoplast") {
      font-family: $fontfamily-regular-bold;
      margin-bottom: $product-box-text-margin-vertical;
      line-height: 22px;
      .product-box-text-link {
        color: $segment-color-alt;
      }
    } @else if ($theme-name == "actimove") {
      font-family: $fontfamily-regular;
      font-weight: 600;
      line-height: 22px;
    } @else if ($theme-name == "jobst") {
      font-weight: 400;
      margin-bottom: 12px;
    } @else if ($theme-name == "cgr") {
      color: $product-box-title;
    } @else {
      line-height: 22px;
    }
  }

  @if ($theme-name == "women") {
    h3 a {
      color: $color-black;
    }
  }

  p {
    @include font-size($font-size-product-box-text);
    margin: 0 0 15px;
  }

  .text {
    @if ($theme-name == "leukoplast") {
      @include mediaquery(md) {
        min-height: 0;
      }
      min-height: 30px;
    } @else {
      margin-top: 10px;
      line-height: 22px;
      min-height: 45px;

      @media (min-width: $grid-bp-xs) and (max-width: $grid-bp-sm) {
        min-height: 65px;
      }

      @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-lg) {
        min-height: 90px;
      }
    }
  }
}

.component-product-box .product-compact {
  height: 100%;
}

.product-box-container-image-left .product-compact .product-box-text {
  @include mediaquery(xs) {
    @if ($theme-name == "cutimed") {
      margin: 18px 0 20px;
    } @else {
      margin: 0 20px;
    }

    .product-details {
      width: 100%;
    }
  }

  @include mediaquery(xxs) {
    .product-details {
      width: 80%;
    }
  }

  @media (max-width: $container-max-width) {
    @if ($theme-name != "cutimed") {
      text-align: center;
    }
  }

  text-align: left;
  margin: 0 auto;

  &.sample-product-box-text {
    width: auto;
  }

  @media (min-width: $grid-bp-md) {
    min-height: 100px;
  }

  @media (min-width: $grid-bp-sm) {
    min-height: 50px;
    width: 100%;

    @if ($theme-name == "leukoplast") {
      min-height: 30px;
    }

    @if ($theme-name == "cutimed") {
      margin: 30px 0;
    }

    &.sample-product-box-text {
      width: 100%;
    }
  }
}

.product-box-container-image-left .title {
  font-size: $product-box-image-left-font-size;

  @if $theme-name == "leukoplast" {
    margin-bottom: 10px;
  }
}

.product-box-footer {
  @include mediaquery(xxs) {
    .btn {
      width: 100%;
    }
  }
  @if ($theme-name == "leukoplast") {
    .btn {
      @include btn("primary");
      min-width: $btn-min-width;
      @media (max-width: $grid-bp-xs) {
        text-align: center;
      }
    }
  } @else {
    min-height: 45px;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  text-align: center;
  margin-top: 5px;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      text-align: left;
      margin-left: 5px;
    }
  }

  @if ($theme-name == "cutimed" or $theme-name == "actimove") {
    display: none;
  }
}

.product-box-container-image-left .product-box-footer {
  justify-content: flex-end;

  @media (min-width: $container-max-width) {
    margin-top: 25px;
    @if $theme-name != "leukoplast" {
      margin-top: 5px;
      text-align: left;
    }
  }
}

.product-box-container-image-left .product-box-img img {
  @media (min-width: $grid-bp-sm) {
    @if $theme-name != "cutimed" {
      margin-right: 15px;
    }
  }
}

.product-box-container-image-left .product-box-footer .detail {
  min-height: 32px;
}

.product-box-container-image-left .product-box-footer .component-btn {
  min-height: 32px;
}

.product-list .component-btn .btn {
  @if ($theme-name == "men") {
    @include font-size-and-line-height($font-size-btn, $line-height-btn);
  } @else {
    @include font-size($font-size-paragraph);

    @if ($theme-name == "leukoplast") {
      line-height: 22px;
    }
  }
}

@if ($is-tena-theme) {
  .component-product-box .btn.btn-product-details {
    @include font-size-and-line-height($product-box-details-btn-font-size, $product-box-details-btn-line-height);

    background-color: transparent;
    color: $cta-bgcolor;
    border: none;
    padding-left: 10px;

    &::before {
      background-color: transparent;
    }

    @media (max-width: $grid-bp-xs-max) {
      padding-left: 0;
      text-align: left;
    }
  }

  @if ($theme-name != "women") {
    .component-product-box .btn.btn-product-details span {
      line-height: 18px;
    }
  }

  .component-product-box .btn.btn-product-details .icon-button-right {
    right: 15px;
    margin-left: 20px;
    display: inline-block;
  }

  @media (max-width: $grid-bp-sm) {
    .component-product-box .component-btn .buy-now-highlighted,
    .component-product-box .product-box-view-details .form .btn {
      padding-left: 8px;
      padding-right: 38px;

      @if ($theme-name != "women") {
        font-size: 14px;
      }
    }
  }

  @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
    .product-list .component-product-box {
      width: calc(33% - 20px);
    }
  }
}

.product-list .product-box-img img {
  height: initial;
  max-height: 150px;
  max-width: 150px;
}

.product-box.no-border .product-box-content {
  border-bottom: none;
}

.product-box.divider {
  border-color: rgba(255, 255, 255, 0.2);
  border-left: 1px solid;
}

.product-box-select-btn {
  pointer-events: none;
  .not-checked {
    display: block;
  }

  .checked {
    display: none;
  }

  input {
    &:checked + label {
      .not-checked {
        display: none;
      }

      .checked {
        display: block;
      }
    }
  }
}

@if ($theme-name == "men") {
  .product-box-select-btn input:checked + label {
    background-color: $color-blue;
    color: $color-white;

    &::before {
      background-color: $color-blue;
    }
  }
}

// Used with .flex-row (_grid.scss) to get equal height
.component-product-box,
.product-box,
.product-box-content {
  display: flex;
  height: auto;
}

.product-box-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 120px;
  width: 100%;

  @if ($theme-name == "actimove") {
    min-height: 75px;
  } @else if ($theme-name == "leukoplast") {
    min-height: 100px;
    @media (max-width: $grid-bp-md) {
      margin-bottom: $product-box-text-margin-vertical;
      margin-top: $product-box-text-margin-vertical;
    }
  } @else {
    min-height: 150px;
  }
}

.product-box-view-details {
  margin: 0 auto;
  text-align: center;
  width: 200px;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      margin: 0;
      width: 100%;
    }
  }

  @if ($theme-name == "jobst") {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.product-box-view-details .btn {
  margin-bottom: 10px;
  width: 100%;
}

.product-box-view-details .form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-box-view-details .form .btn {
  @if ($theme-name == "men" or $theme-name == "cgr") {
    @include btn("secondary");
  } @else if ($theme-name == "actimove") {
    @include btn("primary");
  } @else if ($theme-name == "women") {
    @include btn("secondary");
  } @else {
    background-color: $buy-now-button-color;
    border-color: $buy-now-button-color;
  }
}

@media (min-width: $container-max-width) {
  .product-box-container-image-left .product-box-view-details {
    text-align: right;
  }
}

@media (min-width: $grid-bp-xs) {
  .product-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @if $theme-name != "leukoplast" {
      min-height: 300px;
    }
  }

  .component-product-box {
    width: calc(50% - 20px);
  }
}

.product-box-container.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@if ($theme-name == "cutimed") {
  .product-container-title {
    @include font-size-and-line-height(23px, 26px);

    margin: 25px 0 10px 10px;
    font-family: $fontfamily-header;
  }

  .product-container-description {
    margin-left: 10px;
    max-width: 100%;

    @media (min-width: $grid-bp-sm) {
      max-width: 70%;
    }
  }
}

.product-list--single .component-product-box {
  width: 100%;

  @if ($theme-name == "leukoplast") {
    width: calc(33.33333% - 20px);
  }
}

.product-list.product-box-container {
  word-break: break-word;

  @if $theme-name == "cutimed" {
    padding: 10px 0 0 10px;
  } @else {
    padding: 10px 0 0;
  }
}

@media (max-width: $grid-bp-xs-max) {
  .product-list .component-product-box {
    @if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
      width: calc(100% - 20px);
    } @else {
      width: calc(50% - 20px);
    }
  }

  .product-list .component-product-box.component-product-box--list {
    @if ($theme-name == "jobst") {
      width: calc(100% - 14px);
    } @else {
      width: calc(100% - 20px);
    }
  }
}

@media (min-width: $grid-bp-sm) {
  .product-box-container {
    padding: 0 10px;
  }
}

@media (min-width: $grid-bp-md) {
  .component-product-box {
    width: calc(33.33333% - 20px);
    @if ($theme-name == "leukoplast") {
      margin-bottom: 25px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 25px;
    }
  }

  .accordion-content-inner .component-product-box {
    width: calc(50% - 20px);
  }

  .product-box-container {
    justify-content: flex-start;
  }

  .product-box-container .component-product-box {
    @if $theme-name == "leukoplast" {
      margin-right: 20px;
    }

    @if $theme-name != "leukoplast" {
      min-height: 230px;
    }

    &:last-of-type {
      @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
        margin: 0 10px 10px;
      } @else {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .product-box-container .component-product-box .product-box-content {
    @if $theme-name != "leukoplast" {
      min-height: 290px;
    }
  }
}

@if ($theme-name == "cutimed") {
  .product-box-container-image-left.product-box-container {
    padding: 10px;
  }

  .product-list .product-box-view-details,
  .product-box-container-image-left .product-box-view-details {
    width: 100%;
  }

  .product-list .product-box-view-details .btn,
  .product-box-container-image-left .btn {
    @include btn("secondary");
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-box-container-image-left .component-product-box {
      width: calc(100% - 20px);
    }
  }

  @media (min-width: $grid-bp-sm) {
    .product-list .component-product-box,
    .product-box-container-image-left .component-product-box {
      border: 2px solid $color-light-blue-2;
    }
  }
}

@if ($is-tena-theme) {
  .product-list-carousel {
    margin-bottom: 25px;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .product-list-carousel .swiper {
    width: 100%;
  }

  .product-list-carousel .product-carousel-title {
    @include font-size-and-line-height(28px, 32px);

    padding-bottom: 10px;
    color: $home-color;
    font-family: $fontfamily-regular;
    border-bottom: 1px solid $title-border-color;
    max-width: 100%;
  }

  .product-list-carousel .component-product-box {
    height: 100%;
  }

  .product-list-carousel .component-product-box--full-width {
    width: 100%;
    cursor: default;
  }

  .product-list-carousel .product-box-container {
    height: 100%;
  }

  .product-list-carousel .swiper-wrapper {
    padding-left: 10px;
  }

  .product-list-carousel .swiper-slide {
    height: auto;
    margin-bottom: 20px;
  }

  .product-list-carousel .swiper-scrollbar {
    background-color: $scrollbar-background-color;
  }

  .product-list-carousel .swiper-scrollbar-drag {
    background-color: $scrollbar-drag-color;
  }

  @media (min-width: $grid-bp-sm) {
    .product-list-carousel-wrapper {
      background-color: $color-white;
    }

    .product-list-carousel .product-carousel-title {
      max-width: 65%;
    }

    .product-list-carousel .swiper-wrapper {
      padding-left: 0;
    }
  }
}

.page-search-results .product-list.product-box-container {
  padding-left: 0;
  padding-right: 0;
}

@if ($is-tena-theme) {
  .product-list .component-product-box--list .product-box-img {
    grid-area: image;
    margin-right: 10px;
  }

  .product-list .component-product-box--list .product-box-details {
    grid-area: details;
    margin-top: 16px;
  }

  .product-list .component-product-box--list .product-box-main {
    grid-area: main;
  }

  .product-list .component-product-box--list .product-box-content {
    display: grid;
    grid-template-areas:
      "image image details details details details"
      "image image main main main main";
  }

  .product-list .component-product-box--list .product-box-view-details {
    // this value is set so that the button container would follow the position of the content
    margin-left: 160px;
    width: 200px;

    @media (max-width: $grid-bp-xxs-max) {
      width: unset;
    }
  }
}

@if ($theme-name == "actimove") {
  .product-list .component-product-box .product-box-view-details {
    max-width: 200px;
    width: unset;
  }

  .product-list .product-box-img {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .product-list .product-box-text {
    margin-inline: unset;
    margin-top: 0;
  }

  .product-list .product-box-text .title {
    margin-bottom: 10px;
  }

  .product-list .product-box-text .js-review ~ .text,
  .product-list .product-box-text [data-bv-show="rating_summary"] ~ .text {
    margin-top: 10px;
  }

  .product-list .product-box-text .text {
    margin-top: 0;
  }

  .product-list .component-product-box .btn.btn-product-details {
    @include btn("textlink");

    font-size: 16px;

    @media (max-width: $grid-bp-sm) {
      padding-left: 0;
      text-align: left;
    }
  }

  .component-product-box .btn.btn-product-details span {
    line-height: 22px;
  }

  .component-product-box .btn.btn-product-details .icon-button-right {
    display: none;
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-list .product-box-img {
      align-items: flex-start;
    }

    .product-list .product-box-text {
      text-align: left;
    }

    .product-list .product-box-view-details {
      margin: unset;
    }

    .product-list .component-product-box--list .product-box-img {
      grid-area: image;
      margin-right: 10px;
    }

    .product-list .component-product-box--list .product-box-img .product-box-badge-small + a > img {
      margin-top: 0;
    }

    .product-list .component-product-box--list .product-box-img a > img {
      margin-top: 16px;
    }

    .product-list .component-product-box--list .product-box-details {
      grid-area: details;
      margin-top: 16px;
    }

    .product-list .component-product-box--list .product-box-main {
      grid-area: main;
    }

    .product-list .component-product-box--list .product-box-content {
      display: grid;
      grid-template-areas:
        "image image details details details details"
        "image image main main main main";
    }

    .product-list .component-product-box--list .product-box-view-details {
      // this value is set so that the button container would follow the position of the content
      margin-left: 160px;
      width: 200px;

      @media (max-width: $grid-bp-xxs-max) {
        width: unset;
      }
    }

    .component-product-box .product-box-view-details .form .btn,
    .component-product-box .component-btn .buy-now-highlighted {
      @include font-size-and-line-height(16px, 22px);
    }
  }

  @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-lg) {
    .component-product-box .product-box-text .text {
      min-height: 45px;
    }
  }

  .product-list-btn {
    @include btn("secondary");

    width: fit-content;
    margin: 0 auto;
  }
}

@if ($theme-name == "jobst") {
  .component-product-box {
    box-shadow: 1px 4px 16px 0px rgba($color-primary, 0.26);

    background-color: $color-white;
  }

  .product-list .component-product-box {
    border-radius: 3px;
  }

  .product-list .product-box-view-details .btn:not(.btn-product-details),
  .product-box-container-image-left .btn {
    @include btn("primary-light");

    width: fit-content;
    margin: 0 auto 10px;
    padding: 7px 20px;

    &:after {
      content: none;
    }
  }

  .component-product-box .component.component-btn .btn {
    min-width: unset;
  }

  .product-list .product-box-view-details .btn:not(.btn-product-details) span {
    @media (max-width: $grid-bp-sm) {
      line-height: 26px;
    }
  }

  .component-product-box .btn.btn-product-details span {
    @include font-size-and-line-height(18px, 26px);
  }

  .product-box-text .text {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .product-list .component-product-box--list .product-box-view-details {
    margin-left: 160px;
  }

  .component-product-box .btn.btn-product-details .icon-button-right {
    display: none;
  }

  .btn.btn-product-details {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular;
    color: $color-primary;
    padding-right: 10px;
    display: grid;
    grid-template-columns: auto auto;
    max-width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto 5px;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: svg-uri($product-details-info-icon);
      margin-right: 5px;
    }

    &::after {
      content: none;
    }

    &:hover {
      color: $color-primary-light;

      &::before {
        background-image: svg-uri($product-details-info-icon-hover);
      }
    }
  }

  .svg-product-filter-list-icon--jobst .line-group,
  .svg-product-filter-grid-icon--jobst .line-group,
  .view-options-group--active-list .svg-product-filter-list-icon--jobst,
  .view-options-group--active-grid .svg-product-filter-grid-icon--jobst .svg-background {
    fill: $color-primary;
  }

  .svg-product-filter-grid-icon--jobst .svg-background,
  .view-options-group--active-list .svg-product-filter-list-icon--jobst .line-group,
  .view-options-group--active-grid .svg-product-filter-grid-icon--jobst .line-group {
    fill: $color-white;
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-list .component-product-box {
      padding-left: 16px;
      padding-right: 16px;
      width: calc(50% - 12px);
    }

    .product-list .component-product-box.component-product-box--list {
      padding: 26px 16px 24px;
    }

    .product-box-img {
      margin-bottom: 24px;
    }

    .product-list .product-box-footer,
    .product-list .component-product-box .btn-product-details,
    .product-list .component-product-box .product-box-view-details .btn.buy-now-highlighted {
      margin-left: 0;
    }

    .product-list .product-box-view-details {
      text-align: left;
    }
  }
}

@if ($theme-name == "women") {
  .component.expand-btn .btn {
    @include btn("primary-light");
  }

  .component-product-box .btn.btn-product-details {
    padding-left: 8px;

    &:hover {
      background-color: $primary-light-button-hover-backround;
    }
  }

  .component-product-box .btn.btn-product-details span,
  .component-product-box .btn.btn-product-details .icon-button-right {
    color: $primary-light-button-color;

    &:hover {
      color: $primary-light-button-color;
    }
  }
}