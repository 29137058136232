.tab-content {
    background: $tabs-background;
    display: none;

    .tabs-transparent & {
        background: transparent;
    }

    &.active {
        display: block;

        &.product-finder {
            background-color: $tabs-tab-background-active;

            &.with-border {
                border-top: 1px solid rgba(128, 158, 167, 0.3);
            }
        }
    }

    &.with-border {
        border: 1px solid $border-color;
        border-top: none;
        padding: 15px;

        .section-title {
            padding-top: 0;
        }

        &.with-light-beige-bg {
            background: #f9f6f2;
            border: 1px solid $border-color;
        }
    }

    &.with-overflow {
        overflow: visible;
    }
}

.tab-content-outside {
    display: none;

    &.active {
        display: block;
    }
}

.tab-list {
    @include clearfix();

    @include mediaquery(xs) {
        padding-left: 0;
    }
    border-bottom: 1px solid $border-color;
    margin: 0;
    padding: 0 0 0 20px;

    &.no-border {
        border-bottom: none;
    }

    &.tab-list-full {
        padding-left: 0;

        .tab {
            border-left-color: $product-intro-bg-color;
            border-right-color: $product-intro-bg-color;
            text-align: center;

            a {
                padding: 12px 0 8px;
            }
        }

        &.tab-list-count-1 {
            .tab {
                width: 100%;
            }
        }

        &.tab-list-count-2 {
            .tab {
                width: 50%;
            }
        }

        &.tab-list-count-3 {
            .tab {
                width: 33.33%;
            }
        }

        &.tab-list-count-4 {
            .tab {
                width: 25%;
            }
        }

        &.tab-list-count-5 {
            .tab {
                width: 20%;
            }
        }

        &.tab-list-count-6 {
            .tab {
                width: 16.6666667%;
            }
        }
    }

    .tab {
        @include font-size($font-size-tabs);

        @if ($theme-name == "com" or $theme-name == "men") {
            font-family: $fontfamily-header;
        } @else {
            font-family: $fontfamily-regular-bold;
        }

        @if ($theme-name == "cgr") {
            border-top-color: $color-off-white;
        } @else if($theme-name == "com") {
            border-top-color: $color-sky-light-blue;
        } @else {
            border-top-color: $background-color;
        }

        @include mediaquery(xs) {
            margin-right: 0;
            width: 50%;

            a {
                padding-left: 15px;
                padding-right: 15px;
            }

            &:before {
                content: none;
            }
        }
        background: $tabs-tab-background-inactive;
        border: 1px solid transparent;
        border-bottom-color: $border-color;
        bottom: -1px;
        display: block;
        float: left;
        position: relative;

        a {
            display: block;
            padding: 12px 30px 4px;
            position: relative;
        }

        &.active {
            z-index: $z-index-high;
        }

        &.active {
            background: $tabs-tab-background-active;
            border-bottom-color: $tabs-tab-background-active;
            border-left-color: $border-color;
            border-right-color: $border-color;
            border-top-color: $border-color;

            .tabs-transparent & {
                background: $product-intro-bg-color;
                border-bottom-color: $product-intro-bg-color;
            }
        }
    }
}
