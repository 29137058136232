.container {
    @include container-size();

    @include mediaquery(smallest-bp()) {
        padding: 0 $container-padding-xs;
    }
    margin: $container-margin;
    padding: 0 $container-padding;

    &.container-fluid {
        width: 100%;
    }
}

.row {
    @include clearfix();
    position: relative;
}

[class*="#{$grid-class}-"] {
    @include mediaquery(smallest-bp()) {
        padding-left: $grid-gutter-xs / 2;
        padding-right: $grid-gutter-xs / 2;
    }
    box-sizing: border-box;
    display: block;
    float: left;
    min-height: 1px;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
    position: relative;

    & > & {
        @for $i from 1 through length($screen-sizes) {
            $screen: nth($screen-sizes, $i);
            $type: nth($screen, 1);

            @include mediaquery($type) {
                &.#{$grid-class + "-" + $type + "-12"} {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &.col-centered {
        float: none;
        margin: 0 auto;
    }

    .row.align-cols-right > & {
        float: right;
    }
}

@include create-grid();

// Used in Product Box and Product Promo to get equal height
.flex-row {
    display: flex;
    flex-wrap: wrap;
}

.flex-row.container:before,
.flex-row.container:after,
.flex-row.row:before,
.flex-row.row:after {
    content: normal;
}
