.product-compare-popup--unselected-text,
.product-compare-popup--product-name {
  margin: unset;
}

.product-compare-popup--unselected-text {
  color: $product-compare-popup-product-box-unselected;
  text-align: center;
}

.product-compare-popup--product-name {
  @include font-size-and-line-height(14px, 18px);

  color: $home-color;
  font-family: $fontfamily-titles-normal;
}

.product-compare-popup {
  position: fixed;
  bottom: 0;
  background-color: $color-white;
  width: 100%;
  z-index: 2;
  max-width: 96%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0;
  right: 0;
  display: none;
}

.product-compare-popup-products {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  justify-content: center;
  max-width: calc(280px * 4 + 60px);
  margin: 0 auto;
}

.product-compare-popup-product-box:nth-last-child(-n + 2) {
  display: none;
}

.product-compare-popup-product-box {
  border: 1px solid $product-compare-popup-product-box-border-color;
  max-width: 280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;

  &.product-compare-popup-product-box--active {
    border: 2px solid $color-purple;
  }
}

.product-compare-popup-options {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.product-compare-popup--selected-product {
  display: flex;
  align-items: center;
  padding: 10px;
}

.product-compare-popup--product-img {
  max-width: 48px;
  height: auto;
  margin-right: 10px;
}

.product-compare-popup-options .btn:hover {
  background-color: $color-purple;

  @if $theme-name == "cgr" {
    background-color: $segment-color;
  }
}

.product-compare-popup-options .btn:nth-child(1) {
  margin-right: 16px;
}

.product-compare-popup-options .btn:nth-child(2) {
  background-color: $color-purple;
  color: $color-white;

  @if $theme-name == "cgr" {
    background-color: $segment-color;
  }
}

.product-compare-popup-options .btn:nth-child(2).btn-hollow::before {
  background-color: $color-purple;
}

.product-compare-popup-options .btn:nth-child(2)::after {
  color: $color-white;
}

@media (min-width: $grid-bp-sm) {
  .product-compare-popup--selected-product {
    padding: 16px;
  }

  .product-compare-popup-product-box:nth-last-child(-n + 2) {
    display: flex;
  }

  .product-compare-popup-product-box:nth-last-child(-n + 1) {
    display: none;
  }

  .product-compare-popup-products {
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: $grid-bp-lg) {
  .product-compare-popup-products {
    grid-template-columns: repeat(4, 1fr);
  }

  .product-compare-popup-product-box:nth-last-child(-n + 1) {
    display: flex;
  }
}
