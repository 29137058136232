[class^="icon-"],
[class*=" icon-"],
.DropStyle {
    font-family: $fontfamily-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
    content: "\e600";
}
.icon-arrow-down {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe600\;");
}
.icon-arrow-up:before {
    content: "\e601";
}
.icon-arrow-up {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe601\;");
}
.icon-button-left:before {
    content: "\e602";
    /*!rtl:raw:
content: "\e605";
*/
}
.icon-button-left {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe602\;");
}
.icon-button-right:before {
    content: "\e605";
    /*!rtl:raw:
content: "\e602";
*/
}
.icon-button-right {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe605\;");
}
.icon-color-circle:before {
    content: "\e60a";
}
.icon-color-circle {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60a\;");
}
.icon-drop-empty:before {
    content: "\e60c";
}
.icon-drop-empty {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60c\;");
}
.icon-drop-full:before {
    content: "\e60d";
}
.icon-drop-full {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60d\;");
}
.icon-drop-half-full:before {
    content: "\e60e";
}
.icon-drop-half-full {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60e\;");
}
.icon-footer-link:before {
    content: "\e60f";
    /*!rtl:raw:
display: inline-block;
transform: scaleX(-1);
*/
}
.icon-footer-link {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe60f\;");
}
.icon-home:before {
    content: "\e610";
}
.icon-home {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe610\;");
}
.icon-nav-menu:before {
    content: "\e61c";
}
.icon-nav-menu {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe61c\;");
}
.icon-radiobutton-outer_circle:before {
    content: "\e706";
}
.icon-radiobutton-outer_circle {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe706\;");
}
.icon-short-arrow-left:before {
    content: "\e619";
}
.icon-short-arrow-left {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe619\;");
}
.icon-short-arrow-right:before {
    content: "\e620";
}
.icon-short-arrow-right {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe620\;");
}
.icon-validation-cross:before {
    content: "\e707";
}
.icon-validation-cross {
    *zoom: expression(this.runtimeStyle["zoom"]="1", this.innerHTML="&#xe707\;");
}
.icon-validation-tick:before {
  content: "\e708";
}
$icon-arrow-down: "\e600";
$icon-arrow-up: "\e601";
$icon-button-left: "\e602";
$icon-button-minus: "\e603";
$icon-button-plus: "\e604";
$icon-button-right: "\e605";
$icon-color-circle: "\e60a";
$icon-drop-empty: "\e60c";
$icon-drop-full: "\e60d";
$icon-drop-half-full: "\e60e";
$icon-footer-link: "\e60f";
$icon-home: "\e610";
$icon-nav-menu: "\e61c";
$icon-radiobutton-outer_circle: "\e706";
$icon-short-arrow-left: "\e619";
$icon-short-arrow-right: "\e620";
$icon-validation-cross: "\e707";

$icon-drop-half-full-svg: '<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.14955 15.8996C9.09494 15.5945 10.9251 12.434 10.2461 9.66064C9.69174 7.72145 8.60355 6.03511 7.51308 4.34523C6.87384 3.35462 6.23383 2.3628 5.7001 1.31811C5.4808 0.888861 5.27944 0.450685 5.10345 0C4.96808 0.463207 4.797 0.911335 4.60006 1.34846C4.08058 2.50154 3.38122 3.57808 2.68293 4.65297C1.49695 6.47858 0.314071 8.2994 0.0208503 10.4823C-0.282975 13.9375 2.78051 16.5525 6.14955 15.8996ZM1.01509 10.5923C0.787585 13.3406 3.21665 15.4494 5.9593 14.9179L6.00262 14.9095L6.04651 14.9049C8.28178 14.6734 9.8266 12.1988 9.27914 9.91649C8.81617 8.30954 7.94381 6.8651 6.95413 5.32404C6.86011 5.17765 6.76487 5.03017 6.66888 4.88152C6.16906 4.10753 5.64885 3.30196 5.17488 2.45428C4.67299 3.42855 4.08182 4.33714 3.52369 5.19494C3.41075 5.36851 3.29917 5.54001 3.18995 5.70955C2.12665 7.36013 1.25272 8.86754 1.01509 10.5923Z" fill="#939598"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0.10089 10C0.0695112 10.1589 0.0427088 10.3196 0.0208503 10.4823C-0.282975 13.9375 2.78051 16.5525 6.14955 15.8996C8.9754 15.6069 10.7747 12.6858 10.3164 10H0.10089Z" fill="#003A81"/></svg>';
$icon-drop-full-svg: '<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.60345 0C6.93837 3.41863 9.73326 6.11761 10.7461 9.66064C11.4251 12.434 9.59494 15.5945 6.64955 15.8996C3.28051 16.5525 0.217025 13.9375 0.52085 10.4823C1.05216 6.52691 4.50451 3.7603 5.60345 0ZM1.91057 11.4177C1.96864 13.1756 3.5006 14.4035 5.18828 14.4568C5.41424 14.4716 5.58953 14.2985 5.60041 14.0819C5.6326 13.3694 3.92513 13.9551 3.07846 12.5738C2.79073 12.1645 2.64697 11.7115 2.70591 11.2046C2.63501 10.5164 1.81575 10.7398 1.91057 11.4177Z" fill="#003A81"/></svg>';
$icon-drop-empty-svg: '<svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.64955 15.8996C9.59494 15.5945 11.4251 12.434 10.7461 9.66064C10.1917 7.72145 9.10355 6.03511 8.01308 4.34523C7.37384 3.35462 6.73383 2.3628 6.2001 1.31811C5.9808 0.888861 5.77944 0.450685 5.60345 0C5.46808 0.463207 5.297 0.911335 5.10006 1.34846C4.58058 2.50154 3.88122 3.57808 3.18293 4.65297C1.99695 6.47858 0.814071 8.29941 0.52085 10.4823C0.217025 13.9375 3.28051 16.5525 6.64955 15.8996ZM1.51509 10.5923C1.28759 13.3406 3.71665 15.4494 6.4593 14.9179L6.50262 14.9095L6.54651 14.9049C8.78178 14.6734 10.3266 12.1988 9.77914 9.91649C9.31618 8.30954 8.44382 6.8651 7.45413 5.32404C7.36011 5.17765 7.26487 5.03017 7.16888 4.88152C6.66906 4.10753 6.14885 3.30196 5.67488 2.45428C5.17299 3.42855 4.58182 4.33714 4.02369 5.19494C3.91075 5.36851 3.79917 5.54001 3.68995 5.70955C2.62665 7.36013 1.75272 8.86754 1.51509 10.5923Z" fill="#8F8F94"/></svg>';

.svg-icon-container {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

$svg-dimension: 1.2em;

.svg-icon {
  fill: $color-black;
  height: $svg-dimension;
  line-height: 1;
  vertical-align: baseline;
  width: $svg-dimension;
}

.svg-icon-white {
  fill: $color-white;
}

.play-icon-pause {
  fill: transparent;
}
