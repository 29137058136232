.popUp-container {
	background-color: #fff;
	margin: 0 auto;
	max-width: 900px;
	padding: 15px 20px;
	position: relative;

	@media (max-width: $grid-bp-xs-max) {
		width: 100%;
	}
}

.popUp-header {
	margin-bottom: 30px;
}

.newsletter-signup-popup {
	max-width: 800px;
	padding: 15px 30px;

	@media (max-width: $grid-bp-xs-max) {
		padding: 15px 10px;
	}
}

.newsletter-signup-popup-image {
	margin: -15px -10px 15px;
	width: calc(100% + 20px);

	@media (min-width: $grid-bp-sm) {
		margin: -15px -30px 15px;
		margin-bottom: 40px;
		width: calc(100% + 60px);
	}
}

.newsletter-signup-popup .mfp-close {
	height: 20px;
	right: 25px;
	text-indent: -9999px;
	top: 25px;
	width: 20px;

	&::before,
	&::after {
		background-color: #fff;
		bottom: -1px;
		content: " ";
		height: 24px;
		left: 10px;
		position: absolute;
		width: 2px;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.newsletter-signup-popup .popUp-header,
.newsletter-signup-popup .text-heading {
	margin-bottom: 10px;

	@media (min-width: $grid-bp-sm) {
		margin-bottom: 15px;
	}
}

.newsletter-signup-popup .text-heading {
	font-size: 22px;
	line-height: 28px;

	@media (min-width: $grid-bp-sm) {
		font-size: 40px;
		line-height: 44px;
	}
}

.iframe-popup-large .mfp-iframe-scaler {
  padding-top: calc(100vh - #{$mfp-iframe-padding-top * 2});
}

@if $theme-name == "leukoplast" {
  .popUp-container {
    border: solid 1px $segment-color-alt;
    padding: 20px;
  }

  .text-heading {
    font-family: $fontfamily-regular-bold;
    font-weight: bold;
    margin-bottom: 20px;
    color: $logo-color;
  }

  .popUp-footer {
    border-top: solid 1px $segment-color-alt;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
  }

  .popUp-footer .btn {
    background-color: $color-white;
    border: 1px solid $logo-color;
    border-radius: 100px;
    color: $logo-color;
    font-size: 1em;
    padding-bottom: 9px;
    text-align: center;

    @media (min-width: $grid-bp-sm) {
      min-width: 210px;
    }

    &:hover {
      background-color: $logo-color;
      color: $color-white;
    }
  }
}

@if ($theme-name == "actimove" or $theme-name == "jobst" or $theme-name == "leukoplast") {
  .buy-now-wrapper.popUp-container {
    padding: 84px 24px 24px;
    width: 100%;

    @media (min-width: $grid-bp-sm) {
      max-width: 772px;
    }
  }
}