.sample-promo-box .pushbox-content-inner {
	@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-md) {
		display: table;
		min-height: auto;
	}
}

.sample-promo-box .pushbox .pushbox-content.has-button .pushbox-text {
	padding-bottom: 0;
	@media (max-width: $grid-bp-sm) {
		padding: 40px 40px 0 40px;
	}
	@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
		width: 50%;
		display: inline-block;
	}

}

.sample-promo-box .pushbox .pushbox-content .pushbox-img {
	padding: 15px 25px 20px;
	width: 100%;
	@media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
		width: 50%;
		display: inline-block;
	}
	@media (max-width: $grid-bp-sm-max){
		position: relative;
	}
}

.sample-promo-box .pushbox .pushbox-content .pushbox-img .imgDisclaimer {
	@media (max-width: $grid-bp-sm-max) {
		bottom: 30px;
		left: 40px;
	}
}

.sample-promo-box .pushbox .pushbox-content .pushbox-img img {
	width: auto;
  height: 130px;
	margin: auto;
	padding: 10px 0;
}

.sample-promo-box .pushbox .pushbox-content .pushbox-button {
	position: relative;
}

.sample-promo-box .pushbox .pushbox-content .pushbox-button .component-btn {
	@media (min-width: $grid-bp-sm) {
		margin-bottom: 25px;
	}
}

.sample-promo-box .pushbox .semi-transparent {
	background-image: none;
}

.sample-promo-box .pushbox .pushbox-shadow,
.contains-different-components .pushbox-shadow {
    background-repeat: no-repeat;
    background-size: cover;
}