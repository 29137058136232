.dataTables_wrapper {
  overflow-x: auto;
}

.dataTables_empty {
  text-align: left !important;
  padding-left: 10px !important;
  vertical-align: middle;

  @media (min-width: $grid-bp-sm) {
    text-align: center !important;
  }
}


.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate,
.dataTables_empty::before,
.dataTables_filter {
  display: none;
}