.banner {
  @include svg-play-icon();
  overflow: hidden;
  position: relative;
}

.banner .svg-icon.play-icon {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner .image-overlay-wrapper {
  background-color: transparent;
}

.banner .caption {
  @if ($theme-name == "actimove" or $theme-name == "jobst") {
    padding: 32px 24px;
  } @else {
    padding: 30px;
  }
}

.banner-disclaimer {
  bottom: 10px;
  font-size: 10px;
  left: 10px;
  margin: 0;
  position: absolute;
  z-index: 1;
  color: $color-white;

  &.disclaimer-dark-text {
    @if $theme-name == "cutimed" {
      color: $color-gray;
    } @else {
      color: $color-black;
    }
  }

  @include mediaquery(sm) {
    bottom: 5px;
    left: 15px;
  }
}

@if ($theme-name == "actimove") {
  .banner .caption-image .caption .caption-header {
    line-height: 26px;
    margin-bottom: 5px;
    font-style: italic;
  }

  .banner .svg-icon.play-icon {
    width: 60px;
    height: 60px;

    @media (min-width: $grid-bp-sm-max) {
      width: 90px;
      height: 90px;
    }
  }
}

@if ($theme-name == "actimove" or $theme-name == "jobst") {
  @media (max-width: $grid-bp-sm-max) {
    .banner .banner-media-wrapper {
      position: relative;
    }

    .banner .caption-image .caption {
      background-color: $color-primary;
    }
  }
}

@if ($theme-name == "jobst") {
  .banner .caption-image .caption .caption-header {
    font-family: $fontfamily-regular-bold;
    line-height: 26px;
    margin-bottom: 4px;
  }

  .banner .svg-icon.play-icon {
    width: 40px;
    height: 40px;

    @media (min-width: $grid-bp-sm) {
      width: 60px;
      height: 60px;
    }

    @media (min-width: $grid-bp-md) {
      width: 90px;
      height: 90px;
    }
  }

  .banner .play-icon-circle,
  .banner .play-icon-arrow {
    stroke: none;
    stroke-width: unset;
  }

  .banner-disclaimer {
    bottom: 0;
  }
}
