$landingPromoBarXxs: 480px;
$landingPromoBarXs: 569px;
$landingPromoBarSm: 640px;

.LandingPromoBar {
  min-height: 350px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.LandingPromoBar-item {
  color: $color-white;
  position: relative;
  text-align: center;
  width: 25%;

  @media (max-width: $grid-bp-md) {
    width: (100% / 3);
  }

  @media (max-width: $landingPromoBarSm) {
    width: 50%;
  }

  @media (max-width: $landingPromoBarXxs) {
    width: 100%;
  }
}

.LandingPromoBar-link:hover .LandingPromoBar-wrapper {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.7);
}

.LandingPromoBar-wrapper {
  border-radius: 5px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
  margin: 10px;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
}

.LandingPromoBar-image {
  height: 150px;
  object-fit: cover;
  vertical-align: middle;
  width: 100%;

  @media (min-width: $landingPromoBarXxs) {
    height: 242px;
  }

  @media (min-width: $landingPromoBarXs) {
    height: 330px;
  }

  @media (min-width: $landingPromoBarSm) {
    height: 300px;
  }

  @media (min-width: $grid-bp-md) {
    height: 294px;
  }

  @media (min-width: $grid-bp-lg) {
    height: 373px;
  }
}

// added to override opacity 0 on .preload
img.LandingPromoBar-image {
  opacity: 1;
}

.LandingPromoBar-content {
  bottom: 0;
  color: $color-white;
  left: 0;
  padding: 20px 10px;
  position: absolute;
  width: 100%;

  @media (max-width: $landingPromoBarXxs) {
    color: $color-white;
    text-align: left;
    top: 0;
    width: 190px;
  }
}

.LandingPromoBar-button {
  background-color: $cta-bgcolor;
  border-color: $cta-bg-hover;
  border-radius: $btn-border-radius;
  color: $color-white;
  line-height: 1;
  margin: 0 auto;
  padding: 10px;
  width: 170px;

  @media (max-width: $landingPromoBarXxs) {
    display: none;
  }

  @media (max-width: $grid-bp-lg) {
    font-size: 14px;
    padding: 5px;
    width: 100%;
  }
}

.LandingPromoBar-title {
  font-size: 30px;
  margin-bottom: 5px;

  @media (max-width: $grid-bp-lg) {
    font-size: 26px;
  }

  @media (max-width: $landingPromoBarXxs) {
    font-size: 22px;
  }
}

.LandingPromoBar-subtitle {
  font-size: 18px;
  margin-bottom: 15px;

  @media (max-width: $grid-bp-lg) {
    font-size: 14px;
  }
}
