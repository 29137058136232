.component-pushbox {
  @include clearfix();
}

.pushbox-header {
  font-family: $fontfamily-regular-bold;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 20px;
}

@if ($theme-name == "com") {
  @media (max-width: $grid-bp-xs-max) {
    .pushbox-header {
      display: none;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .pushbox-header {
      margin: 0 0 10px;
    }
  }
}

@if ($theme-name == "men") {
  .pushbox-list-background .pushbox-list-header {
    max-width: 70%;
  }

  .pushbox-list-vertical .pushbox-list-header {
    max-width: 100%;
  }

  .pushbox-list-title {
    font-family: $fontfamily-titles-thin;
    font-size: 28px;
    line-height: 32px;
  }

  .pushbox-list-vertical .pushbox-list-btn {
    margin: 40px 0 20px 10px;
  }

  .pushbox-list-btn {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .pushbox-shadow {
    display: none;
  }

  .pushbox-list-vertical .pushbox {
    margin-bottom: 15px;
  }

  .pushbox-list-vertical .pushbox-button .component-btn {
    margin: 0 25px;
  }

  .pushbox-list-vertical .pushbox-list-btn {
    margin-left: 0px;
    width: 100%;
  }

  .pushbox-list-vertical .pushbox .pushbox-content .pushbox-button .component-btn .btn {
    width: 100%;
    margin: 0;
  }

  @media (max-width: $grid-bp-xs-max) {
    .pushbox-list-btn {
      width: 100%;
    }

    .pushbox-button .btn {
      display: block;
    }

    .pushbox-list-title {
      font-size: 22px;
      line-height: 28px;
    }
  }

  @include mediaquery(md) {
    .pushbox-list-background .pushbox-list-header {
      max-width: 100%;
    }
  }
}

@if ($theme-name == "women") {
  .pushbox-list-title {
    color: $color-blue;
  }
}

.pushbox-column {
  @if ($theme-name != "leukoplast") {
    min-height: 120px;
  }
}

.pushbox-column p {
  height: auto;
}

.pushbox {
  @include mediaquery(md) {
    @if ($theme-name == "men") {
      margin-bottom: 15px;
    }

    &.col-sm-12 {
      .pushbox-content {
        background: #fff;
        border-color: #c1c2c0;
        min-height: 120px;

        .pushbox-text {
          @if ($theme-name == "leukoplast") {
            padding: map-get($pushbox-padding, xs);
          } @else {
            padding: 20px;
          }
          width: 50%;

          .text {
            display: none;
          }
        }

        .pushbox-img {
          height: 100%;
          right: 0;
          top: 0;
          width: 50%;
        }
      }
    }
  }

  @if $display-shadows == true {
    .pushbox-shadow {
      background-image: url("../img/box-shadow.png");

      // updated found in file comparision: Cgr, Women
      @if ($theme-name == "cgr" or $theme-name == "women") {
        background-size: 100% 100%;
        position: relative;
        top: -1px;
      }
      background-position: center;
      height: $pushbox-shadow-height;
      text-align: center;
      width: 100%;

      img {
        @if ($theme-name == "cgr" or $theme-name == "women") {
          display: none !important;
        }
        @include mediaquery(sm) {
          height: 12px;
        }
        vertical-align: top;
        width: 100%;
      }
    }

    &.type-half-image,
    &.type-full-image {
      .pushbox-content {
        &.border {
          margin-bottom: 0;
        }
      }
    }

    &.type-full-image {
      .dark {
        .pushbox-text-content {
          @if ($theme-name == "women") {
            text-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
          }

          @if ($theme-name == "cgr") {
            text-shadow: none;
          }
        }
      }

      .pushbox-text-content {
        @if ($theme-name == "women" or $theme-name == "cgr") {
          text-shadow: 0 1px 7px rgba(255, 255, 255, 0.8);
        }
      }
    }

    &.type-half-image-top {
      .pushbox-content-inner {
        flex-direction: column-reverse;
      }

      .pushbox-shadow {
        display: none;
      }

      @if ($theme-name != "actimove") {
        .pushbox-content {
          box-shadow: $promo-box-shadow;
        }
      }

      .pushbox-content .pushbox-button .component-btn .btn {
        float: left;
      }

      .pushbox-content .pushbox-img {
        position: relative;
      }

      .pushbox-content .pushbox-img,
      .pushbox-content .pushbox-img img {
        height: auto;
      }
    }
  } @else {
    .pushbox-shadow {
      @if ($theme-name == "men") {
        display: none;
      }

      @if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
        height: 17px;
      }
    }

    &.type-half-image-top .pushbox-content .pushbox-img {
      position: relative;
    }
  }

  .pushbox-content-inner {
    position: relative;
  }

  .pushbox-content-inner .pushbox-text {
    height: inherit;
  }

  &.clickable-xs {
    @include mediaquery(xxs) {
      .pushbox-clickable {
        display: block;
      }

      .pushbox-button {
        display: none;
      }
    }
  }

  .pushbox-content {
    @if ($theme-name == "cgr" or $theme-name == "women" or $theme-name == "cutimed") {
      background-color: $push-box-bg;
    }

    border-radius: $push-box-border-radius;
    overflow: hidden;
    position: relative;

    &.border {
      border: 1px solid $push-box-border-color;

      @if ($theme-name == "cutimed") {
        border-width: 2px;
      }

      &.no-border-bottom {
        border-bottom: 0;
      }
    }

    &.light {
      background-image: url("../img/box-bg-semitransparent.png");
    }

    &.dark {
      @if ($theme-name == "leukoplast") {
        color: $segment-color-alt;
      } @else {
        color: #fff;
      }

      .text p,
      .text div {
        color: $color-white;
      }

      .text,
      .title,
      .pretitle {
        a {
          color: #fff;

          &:hover {
            @if ($theme-name == "cgr") {
              color: $text-color-secondary;
            } @else if($theme-name == "women") {
              color: $color-dark-blue-70;
            } @else {
              color: darken(#fff, 5);
            }
          }
        }
      }
    }

    &.normal-height {
      min-height: 50px;
    }

    @if ($theme-name != "leukoplast") {
      &.has-button {
        .pushbox-text {
          padding-bottom: 80px;

          @if ($theme-name == "men") {
            padding-bottom: 90px;
          }
        }
      }
    }

    @if ($theme-name != "leukoplast") {
      &.has-button-below {
        .pushbox-button {
          margin-bottom: 50px;
        }
      }
    }

    .pushbox-text {
      @include clearfix();
      position: relative;

      .pushbox-text-content {
        .pretitle,
        .title,
        p {
          @include mediaquery(xxs) {
            margin: 0 0 5px;
          }
          border: 0;
          margin: 0 0 10px;
          padding: 0;
        }

        .pretitle {
          @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

          font-family: $fontfamily-regular-bold;
          text-transform: $push-box-pretitle-transform;

          @if ($theme-name == "men" or $theme-name == "cgr") {
            letter-spacing: 0.5px;
          }
          @if ($theme-name == "leukoplast") {
            font-family: $fontfamily-regular-bold;
            font-size: 16px;
          }
          @if ($theme-name == "women") {
            font-family: $fontfamily-regular;
            letter-spacing: 4px;
            font-weight: normal;
          }
          @if ($theme-name == "cutimed") {
            font-size: 28px;
            line-height: 32px;
            font-family: $fontfamily-regular-bold-condensed;
          }
        }

        .title {
          @include font-size($font-size-pushbox-title);
          text-transform: $push-box-title-transform;

          @if ($theme-name != "leukoplast") {
            @include font-size-and-line-height(22px, 28px);
            font-family: $fontfamily-header-thin;
            font-weight: normal;

            @if ($theme-name == "men") {
              font-family: $push-box-title-font;
            }

            @if ($theme-name == "cutimed") {
              @include font-size-and-line-height(21px, 25px);

              text-transform: none;
            }
          } @else {
            @include font-setup();
            font-family: $push-box-title-font;
            line-height: 28px;
          }
        }

        .text {
          p,
          div {
            @include font-size-and-line-height($font-size-pushbox-text, $line-height-pushbox-text);

            @if ($theme-name == "jobst") {
              color: $color-gray;
              font-family: $fontfamily-regular-light;
            }
          }
        }

        .icon {
          float: right;
          font-size: 24px;
        }
      }
    }

    .pushbox-form {
      .form {
        margin: 20px 0 0;

        .form-description-after {
          margin-top: 10px;
        }
      }
    }
    .pushbox-img {
      @if ($theme-name == "cutimed") {
        height: unset;
      } @else {
        height: 100%;
      }
      img {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    .pushbox-button {
      min-height: 30px;
      bottom: 0;
      position: absolute;
      width: 100%;

      .component-btn {
        @include mediaquery(sm) {
          @if ($theme-name != "cutimed") {
            margin-bottom: map-get($pushbox-padding, sm);
          }
        }

        @include mediaquery(xs) {
          @if ($theme-name != "cutimed") {
            margin-bottom: map-get($pushbox-padding, xs);
          }
        }

        margin-bottom: map-get($pushbox-padding, def);
        overflow: hidden;

        .btn {
          @if ($theme-name != "jobst") {
            @include mediaquery(sm) {
              margin: 0 map-get($pushbox-padding, sm);
            }

            @include mediaquery(xs) {
              margin: 0 map-get($pushbox-padding, xs);
            }
          }

          float: $btn-push-box-position;
          margin: 0 map-get($pushbox-padding, def);
        }
      }

      .btn {
        &.align-left {
          float: left;
        }
      }
    }

    .pushbox-play-btn {
      bottom: 0;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .pushbox-text {
      @include mediaquery(sm) {
        padding: map-get($pushbox-padding, sm);
      }
      @include mediaquery(xs) {
        padding: map-get($pushbox-padding, xs);
      }
      padding: map-get($pushbox-padding, def);
    }
  }

  &.type-full-image {
    .pushbox-text {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      @if ($theme-name != "leukoplast") {
        display: flex;
      }
    }

    @if ($theme-name != "leukoplast") {
      .pushbox-text .pushbox-text-content {
        margin-top: auto;
      }
    }

    .pushbox-content .pushbox-button .component-btn .btn {
      float: left;
    }

    .background {
      position: relative;
      background: {
        position: top center;
        repeat: no-repeat;
        size: cover;
      }

      .image-overlay {
        left: 0;
        position: absolute;
        top: 0;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .fixed-height {
      @include mediaquery(xs) {
        padding-bottom: 40%;
      }
      img {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
      }
    }
  }

  &.type-product-image {
    @include mediaquery(sm) {
      .col-sm-6 & {
        .pushbox-text-image {
          img {
            max-height: 170px;
          }
        }
      }

      .col-sm-12 & {
        .pushbox-text {
          padding: 0;
        }

        .pushbox-text-content {
          float: left;
          padding: map-get($pushbox-padding, sm);
          width: 50%;
        }

        .pushbox-text-image {
          float: right;
          position: relative;
          width: 50%;

          img {
            margin-top: 30px;
            max-width: 70%;
          }
        }

        .pushbox-button {
          .component-btn {
            .btn {
              float: left;
            }
          }
        }
      }
    }

    @include mediaquery(xs) {
      .col-xs-12 & {
        .fixed-height {
          .pushbox-text {
            padding: 0;
          }

          .pushbox-text-content {
            float: none;
            padding: map-get($pushbox-padding, xs);
            width: 100%;
          }

          .pushbox-text-image {
            float: none;
            width: 100%;

            img {
              margin: 10px auto 30px;
              max-height: 150px;
            }
          }

          .pushbox-button {
            .component-btn {
              .btn {
                float: right;
              }
            }
          }
        }

        .not-fixed-height {
          &.pushbox-content {
            min-height: 200px;
          }

          .pushbox-text {
            height: 100%;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 100%;
          }

          .pushbox-text-content {
            float: left;
            padding: map-get($pushbox-padding, xs);
            width: 50%;
          }

          .pushbox-text-image {
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 45%;

            img {
              left: 50%;
              margin: 0;
              max-height: none;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70%;
            }
          }

          .pushbox-button {
            .component-btn {
              .btn {
                float: left;
              }
            }
          }
        }
      }
    }

    @include mediaquery(xxs) {
      .col-xs-12 & {
        .fixed-height {
          .pushbox-text-content {
            .title {
              margin-bottom: 0;
            }

            .text {
              display: none;
            }
          }

          .pushbox-text-image {
            img {
              margin: 0 auto 15px;
              max-height: 110px;
            }
          }
        }

        .not-fixed-height {
          .pushbox-text {
            height: auto;
            overflow: auto;
            padding: map-get($pushbox-padding, xs);
            padding-bottom: 80px;
            position: relative;
            width: auto;
          }

          .pushbox-text-content {
            float: none;
            width: auto;
          }

          .pushbox-text-image {
            height: auto;
            position: inherit;
            right: auto;
            top: auto;
            width: auto;

            img {
              left: auto;
              margin: 0 auto;
              max-height: 170px;
              position: initial;
              top: auto;
              transform: none;
              width: auto;
            }
          }

          .pushbox-button {
            .component-btn {
              .btn {
                float: right;
              }
            }
          }
        }
      }
    }
    .pushbox-text-image {
      img {
        display: block;
        margin: 20px auto 0;
        max-height: 130px;
        max-width: 100%;
      }
    }

    .fixed-height {
      .pushbox-text-image {
        img {
          @include mediaquery(md) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &.type-half-image {
    @if ($theme-name == "leukoplast") {
      .pushbox-content {
        border: 1px $push-box-border-color-alt solid;
      }
    }

    @include mediaquery(xs) {
      .pushbox-text {
        padding: 15px;
      }
    }

    .fixed-height {
      .pushbox-img {
        height: 50%;
        position: absolute;
        top: 50%;
        width: 100%;

        img {
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }

    .not-fixed-height {
      .pushbox-text {
        @if ($theme-name == "cutimed") {
          padding-bottom: 68px;
        } @else {
          padding-bottom: 25px;
        }
      }

      .pushbox-img {
        flex-shrink: 0;
        height: auto;
        position: relative;
        top: 0;
      }
      .pushbox-img img {
        min-height: 100px;
      }
    }
  }

  &.no-shadow {
    .pushbox-shadow {
      display: none;
    }

    .pushbox-content {
      &.border {
        border-bottom: 1px $push-box-border-color-alt solid;
      }
    }
  }

  &.divider {
    border-color: rgba(255, 255, 255, 0.2);
    border-left: 1px solid;
  }
}

.imgDisclaimer {
  bottom: 10px;
  font-size: 10px;
  font-style: italic;
  left: 10px;
  margin: 0;
  position: absolute;
  z-index: 1;

  @include mediaquery(sm) {
    bottom: 5px;
    left: 15px;
  }

  &.darkenText {
    color: $home-color;
  }

  &.lightenText {
    color: $color-white;
  }

  &.campaign-disclaimer {
    left: 20px;
  }
}

.equal-height-container {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  @media (min-width: $grid-bp-sm) {
    align-items: stretch;
    min-height: 300px;
  }
}

.pushbox-link {
  display: block;
}

.pushbox-link,
.pushbox-content,
.pushbox-content-inner,
.pushbox-text {
  height: 100%;
}

.pushbox-content-inner {
  display: flex;
  flex-direction: column;
}

@if $theme-name == "men" {
  .type-half-image .pushbox-text,
  .type-half-image-top .pushbox-text {
    background-color: $push-box-bg-dark;
    color: $color-white;
  }

  .pushbox-button .btn {
    @include btn("secondary");
  }
}

@media (min-width: $grid-bp-sm) {
  .equal-height-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .equal-height-container.contains-different-components .pushbox {
    padding: 10px;
  }

  .equal-height-container .pushbox {
    flex: 0 0 auto;
    flex-basis: 50%;
    padding: 10px;
  }
}

@media (min-width: $grid-bp-md) {
  .equal-height-container .pushbox {
    flex-basis: 33.3333333333%;
  }
}

.pushbox.type-full-image .pushbox-content-inner {
  min-height: 360px;
}

.pushbox--no-image.type-half-image .pushbox-button {
  position: static;
}

.pushbox .pushbox-img {
  @include svg-play-icon();
  margin-top: auto;
}

.pushbox .pushbox-img .svg-icon.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@if ($theme-name != "leukoplast") {
  .pushbox-bg-gradient {
    background: $push-box-background-gradient;
  }
}

.pushbox.type-full-image .pushbox-img img {
  min-height: 360px;
  object-fit: cover;
}

.pushbox.type-full-image .not-fixed-height {
  min-height: 360px !important;
}

@if $theme-name == "leukoplast" {
  .pushbox-button {
    display: none;
  }

  .pushbox.type-full-image .pushbox-text {
    padding: 0;
  }

  .pushbox.type-full-image .pushbox-text-content {
    background-color: rgba($logo-color, 0.8);
    color: #fff;
    min-height: 180px;
    padding: map-get($pushbox-padding, def);

    @include mediaquery(sm) {
      padding: map-get($pushbox-padding, sm);
    }
    @include mediaquery(xs) {
      padding: map-get($pushbox-padding, xs);
    }
  }
}

@if ($theme-name == "cutimed") {
  .pushbox-content-inner {
    color: $color-gray;
    flex-direction: column-reverse;
  }

  .type-half-image .pushbox-content-inner {
    flex-direction: column;
  }

  .pushbox .pushbox-content .pushbox-button .component-btn {
    margin-bottom: unset;
  }

  .pushbox .pushbox-content .pushbox-button .component-btn .btn {
    @include btn("secondary");

    display: flex;
    justify-content: center;
    margin: 0 38px 38px;
  }

  .type-half-image .pushbox-content .pushbox-button .component-btn .btn {
    @include btn("secondary");

    margin: 0 34px 38px;
  }

  .pushbox .pushbox-content .pushbox-text {
    padding-left: 38px;
    padding-right: 38px;
  }

  .pushbox-text-content {
    margin-bottom: 28px;
    word-break: break-word;
  }

  .type-half-image .pushbox-text-content {
    margin-bottom: unset;
  }
}

@if ($theme-name == "actimove") {
  .pushbox.type-half-image-top {
    margin-bottom: 20px;
  }

  .pushbox-bg-gradient {
    background: none;
  }

  .pushbox.type-full-image .pushbox-bg-gradient {
    background: $push-box-background-gradient;
  }

  .pushbox .pushbox-content.border {
    border: none;
  }

  .pushbox .pushbox-content-inner {
    background-color: $pushbox-background-color;
  }

  .pushbox .pushbox-content {
    border-radius: 0;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .pretitle {
    @include font-size-and-line-height(16px, 22px);

    font-family: $pushbox-pretitle-font;
    letter-spacing: 0.4px;
    font-weight: 400;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .title {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-titles-normal;
    font-style: italic;
    text-transform: none;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .text {
    color: $pushbox-text-color;
  }

  .pushbox .pushbox-content .pushbox-button .component-btn .btn {
    text-align: left;
  }

  .pushbox-button .pushbox-button-primary-light {
    @include btn("primary-light");
  }

  @media (min-width: $grid-bp-sm) {
    .pushbox.type-half-image-top {
      margin-bottom: 0;
    }
  }
}

@if ($theme-name == "leukoplast") {
  .pushbox.type-half-image-top .pushbox-content {
    border: 1px solid $push-box-border-color-alt;
  }

  .pushbox.type-half-image-top .pushbox-content-inner {
    display: flex;
    flex-direction: column-reverse;
  }
}

@if ($theme-name == "jobst") {
  @media (max-width: $grid-bp-xs-max) {
    .pushbox {
      margin-bottom: 20px;
    }
  }

  .pushbox-link {
    &:hover {
      color: $color-primary;
    }
  }

  .pushbox-link {
    &:hover {
      color: $color-primary;
    }
  }

  .pushbox-link .pretitle {
    color: $color-primary;
  }

  .pushbox.type-full-image .pretitle {
    color: $color-white;
  }

  .pushbox.type-half-image .pushbox-content .pushbox-img,
  .pushbox.type-half-image .pushbox-content .pushbox-img img,
  .pushbox.type-half-image-top .pushbox-content .pushbox-img,
  .pushbox.type-half-image-top .pushbox-content .pushbox-img img {
    height: auto;
  }

  .pushbox .pushbox-content.border,
  .pushbox.type-half-image .pushbox-content.border,
  .pushbox.type-full-image .pushbox-content.border {
    border: none;
  }

  .pushbox .pushbox-content {
    box-shadow: 1px 4px 16px 0px rgba($color-primary, 0.15);
  }

  .pushbox.type-half-image-top .pushbox-content-inner {
    flex-direction: column-reverse;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .pretitle {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular;
    font-weight: normal;
    letter-spacing: 2px;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .title {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold;
  }

  .pushbox .pushbox-content.dark .pushbox-text .pushbox-text-content .text p,
  .pushbox .pushbox-content.dark .pushbox-text .pushbox-text-content .text div {
    color: $color-white;
  }

  .pushbox .pushbox-content .pushbox-button .component-btn .btn {
    @include btn("primary-light");

    @media (max-width: $grid-bp-xxs-max) {
      padding-right: 20px;
    }
  }
}

@if ($theme-name == "cgr") {
  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .title {
    @include font-size-and-line-height(22px, 28px);

    font-weight: 600;
    letter-spacing: 2px;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .pretitle {
    font-family: $pushbox-pretitle-font;
    letter-spacing: 3px;
    font-weight: 400;
  }

  .pushbox .pushbox-content .pushbox-text .pushbox-text-content .title {
    text-transform: uppercase;
  }

  .pushbox .component-btn .btn {
    display: inline-block;
  }
}

@if ($theme-name == "women") {
  .pushbox-text-content .title {
    color: $color-blue;
  }

  .pushbox-text.pushbox-bg-gradient .title {
    color: $color-white;
  }
}