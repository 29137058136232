.testimonial-container {
  padding: 65px 16px;

  @media (min-width: $grid-bp-sm) {
    padding: 80px 0;
  }
}

.testimonial-container .testimonial-quote {
  @include font-size-and-line-height($testimonials-quote-font-size, $testimonials-quote-line-height);

  font-family: $testimonials-quote-font-family;
  color: $testimonials-quote-color;
  letter-spacing: $testimonials-quote-letter-spacing;
  margin-bottom: 8px;

  @media (min-width: $grid-bp-md) {
    margin-bottom: 16px;
  }

  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    text-transform: uppercase;
  }
}

.testimonial-container .testimonial-quote-info {
  @include font-size-and-line-height($testimonials-quote-info-font-size, $testimonials-quote-info-line-height);

  color: $testimonials-quote-info-color;
  margin-bottom: 0;
}

.testimonial-container .testimonial-quote-info,
.testimonial-container .testimonial-quote-info span {
  font-family: $testimonials-quote-info-font-family;
}

.testimonials-full-width {
  background-color: $testimonials-full-width-background;
  position: relative;
}

.testimonial-full-width-quote-left,
.testimonial-full-width-quote-right {
  position: absolute;
  width: 64px;
  height: 64px;
  background-size: contain;

  @media (min-width: $grid-bp-md) {
    width: 100px;
    height: 100px;
  }
}

.testimonial-full-width-quote-left {
  background-image: url($testimonials-quote-left-img);
  top: 6px;
  left: 16px;

  @media (min-width: $grid-bp-sm) {
    top: 14px;
    left: 40px;
  }

  @media (min-width: $grid-bp-md) {
    top: 20px;
    left: 42px;
  }
}

.testimonial-full-width-quote-right {
  background-image: url($testimonials-quote-right-img);
  right: 16px;
  bottom: 6px;

  @media (min-width: $grid-bp-sm) {
    bottom: 14px;
    right: 40px;
  }

  @media (min-width: $grid-bp-md) {
    bottom: 20px;
    right: 42px;
  }
}

.testimonials-full-width .testimonial-container {
  @media (min-width: $grid-bp-sm) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: $grid-bp-md) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (min-width: $container-max-width) {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .testimonial-quotation {
  @include font-size-and-line-height($testimonial-quotation-font-size, $testimonial-quotation-line-height);

  font-weight: normal;
  margin: auto 15px 20px 15px;
  padding-top: 20px;

  @if ($theme-name == "men") {
    text-transform: uppercase;
    color: $color-white;
    font-family: $fontfamily-titles-thin;
    line-height: 28px;
    margin-left: 0;
  } @else if ($theme-name == "actimove") {
    margin: unset;
    margin-bottom: 8px;
    padding-top: 0;
    font-family: $fontfamily-titles-normal;
    font-style: italic;
  } @else {
    text-transform: none;
    font-family: $fontfamily-regular;
  }
}

@if ($theme-name == "men") {
  .testimonial-box .pushbox-content-inner {
    background: $color-blue;
    border-radius: 3px;
  }

  .testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pretitle {
    color: $color-white;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .text {
  @include font-size-and-line-height(16px, 22px);
  margin-top: auto;

  @if ($theme-name == "men") {
    color: $color-white;
    margin: auto 0 15px;
  }

  @if ($theme-name == "actimove") {
    margin-bottom: 24px;
  }
}

.testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .text p {
  margin: 0;
}

.testimonial-box .pushbox-content .pushbox-button {
  position: relative;
}

.testimonial-box .pushbox-content.has-button .pushbox-text {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.testimonial-box .pushbox-content.semi-transparent {
  background-image: none;
}

.testimonial-box .pushbox-content-inner {
  min-height: auto;
}

@media (max-width: $grid-bp-sm) {
  @if ($theme-name == "men") {
    .testimonial-box .pushbox-content .pushbox-text .pushbox-text-content .pushbox-testimonial .text {
      text-transform: uppercase;
    }
  }
}

@if ($theme-name == "cutimed") {
  .testimonial-box .pushbox-content-inner {
    flex-direction: column;
  }

  .testimonial-banner {
    background-color: $color-light-blue-2;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 50px;
    word-break: break-word;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: $grid-bp-md) {
      flex-direction: row;
      padding-top: 50px;
      padding-left: 125px;
      padding-right: 125px;
    }
  }

  .testimonial-banner-heading {
    @include font-size-and-line-height($testimonial-banner-heading-font-size, $testimonial-banner-heading-line-height);

    font-family: $fontfamily-regular-bold-condensed;
    margin-bottom: 15px;

    @media (min-width: $grid-bp-md) {
      margin-top: 8px;
    }
  }

  .testimonial-banner-description {
    @include font-size-and-line-height(16px, 25px);

    font-family: $fontfamily-regular-bold;
    margin-bottom: 56px;

    @media (min-width: $grid-bp-md) {
      margin-bottom: 0;
    }
  }

  .testimonial-banner-image {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    display: block;
    margin-inline: auto;
    margin-bottom: 30px;
    object-fit: cover;

    @media (min-width: $grid-bp-md) {
      margin-inline: unset;
    }
  }

  .testimonial-banner-image-title {
    @include font-size-and-line-height(16px, 20px);

    font-family: $fontfamily-regular-bold;
    text-align: center;
    margin-bottom: 8px;

    @media (min-width: $grid-bp-md) {
      text-align: left;
    }
  }

  .testimonial-banner-image-description {
    font-family: $fontfamily-regular-bold;
    font-style: italic;
    margin-bottom: 0;
  }

  @media (min-width: $grid-bp-md) {
    .testimonial-banner--reverse {
      flex-direction: row-reverse;
    }

    .testimonial-banner--reverse .testimonial-banner-text-content {
      margin-right: 0;
      margin-left: 55px;
    }

    .testimonial-banner-text-content {
      width: 70%;
      margin-right: 55px;
    }

    .testimonial-banner-media {
      width: 30%;
    }
  }
}

@if ($theme-name == "women") {
  .testimonial-container .testimonial-quote {
    color: $color-blue;
  }
}

@if ($theme-name == "actimove") {
  .pushbox.testimonial-box {
    @media (max-width: $grid-bp-xs-max) {
      margin-bottom: 15px;
    }
  }

  .pushbox.testimonial-box .pushbox-link {
    &:hover {
      color: unset;
    }
  }

  .pushbox.testimonial-box .pushbox-text .pushbox-text-content .pretitle {
    margin-bottom: 4px;
  }

  .pushbox.testimonial-box .pushbox-text {
    padding: 24px;
  }

  .pushbox.testimonial-box .pushbox-shadow {
    display: none;
  }
}
