.product-finder .question-box {
  @media (max-width: $container-max-width) {
    margin-top: 15px;
  }
}

.product-finder input[type="radio"] {
  margin-left: 0;
}

.product-finder-results {
  display: flex;
  flex-wrap: wrap;
}

.product-finder-results .crate,
.product-finder-results .component-sample-box,
.product-finder-results .sample-box,
.product-finder-results .sample-box-information,
.product-finder-results .sample-box-details,
.product-finder-results .crate-content {
  height: 100%;
}

.product-finder-results .sample-box,
.product-finder-results .sample-box-information,
.product-finder-results .sample-box-details {
  display: flex;
  flex-direction: column;
}

.product-finder-subheading {
  margin-bottom: 15px;
}

.product-finder-results .product-details {
  margin-top: auto;
}

.product-finder .sample-box-description {
  margin-bottom: 20px;
}