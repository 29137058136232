.boxes {
  color: $boxes-text-color;
  text-align: $boxes-text-align;

  @media (min-width: $grid-bp-xs) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.box {
  margin: 0 10px 20px;
  min-height: 320px;
  padding: 40px 15px;

  @media (min-width: $grid-bp-xs) {
    width: calc(100% / 2 - 20px);
  }

  @media (min-width: $grid-bp-md) {
    width: calc(100% / 3 - 20px);
  }

  &--white-text {
    color: $color-white;
  }
}

.box-content {
  padding: 0 25px;
}

.box-image {
  margin-bottom: 20px;
  max-width: 100%;

  @if ($theme-name == "cgr") {
    max-height: 64px;
  }
}

.box-title {
  @include font-size-and-line-height($font-size-boxes-title, $line-height-boxes-title);
  margin-bottom: 20px;
}

.box-text-content,
.box-text-content * {
  @include font-size-and-line-height($font-size-boxes-text, $line-height-boxes-text);
}

@if ($theme-name != "men") {
  .box .btn.box--white-button {
    border: 2px solid $color-white;
  }

  .box .btn.box--white-button,
  .box .btn.box--white-button:hover {
    color: $color-white;
  }
}

.box--white-text p {
  color: $color-white;
}

@if ($theme-name == "men") {
  .box {
    padding: 24px 24px 30px;
  }

  .box-content {
    padding: 0;
  }

  .box-title {
    font-family: $fontfamily-titles-thin;
    text-transform: uppercase;
  }

  .box .btn.btn-hollow {
    background-color: $color-purple;
  }
}

@if ($theme-name != "men") {
  .box .btn.box--white-button {
    border: 1px solid $color-white;
  }

  .box .btn.box--white-button,
  .box .btn.box--white-button:hover {
    color: $color-white;
  }

  .box .btn-hollow {
    color: $home-color;
    border: 2px solid $home-color;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: $home-color;

      @if ($theme-name == "cgr") {
        color: $color-white;
      }
    }
  }
}

@if ($theme-name == "actimove") {
  .box-title {
    font-style: italic;
  }

  .box-image {
    max-height: 64px;
  }

  .box .boxes-button {
    @include btn("secondary");
  }

  .box .boxes-button--light {
    @include btn("secondary-light");
  }
}

@if ($theme-name == "jobst") {
  .box-title {
    font-family: $fontfamily-regular-bold;
    color: $color-primary;
  }

  .box-image {
    height: 64px;
  }

  .box--white-text .box-title {
    color: $color-white;
  }

  .box-text-content,
  .box-text-content * {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
  }

  .box-text-content a {
    color: $color-primary;
  }

  .box .boxes-button,
  .box .boxes-button--light {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .box .boxes-button {
    @include btn("secondary");
  }

  .box .boxes-button--light {
    @include btn("secondary-light");

    color: #fff;
  }
}

@if ($theme-name == "women") {
  .box .btn {
    @include btn("primary-light");

    border-color: $box-cta-blue-color;
    color: $box-cta-blue-color;
  }

  .box-content .box-title {
    color: $color-blue;
  }

  .box--white-text .box-title {
    color: $color-white;
  }
}

@if ($theme-name == "cgr") {
  .box-title {
    font-family: $fontfamily-regular-bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .box .btn {
    @include btn("primary");
  }
}