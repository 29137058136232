@import "../component/header/logo";
@import "../component/header/menu";
@import "../component/header/submenu";
@import "../component/header/links";
@import "../component/header/header-items";
@import "../component/header/header-details";
@import "../component/header/header-sample";
@import "../component/header/mobile-header";

.header {
  background-color: $color-white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  @if ($theme-name == "actimove") {
    padding-bottom: 35px;

    @media (max-width: $grid-bp-sm-max) {
      height: 70px;
      border-bottom: 1px solid $header-border-bottom-color;
    }
  }

  @if ($theme-name == "cutimed") {
    box-shadow: 0 8px 11px 0 rgba($color-black, 0.1);
  }

  @if $theme-name == 'men' {
    background-color: transparent;
    padding-bottom: 0;
  }

  @media (max-width: $grid-bp-sm-max) {
    @if ($theme-name == "actimove" or $theme-name == "jobst") {
      height: 70px;
      border-bottom: $header-border-bottom-width solid $header-border-bottom-color;
    }
  }
}

.header-on-scroll {
  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    top: -100px;

    @media (min-width: $grid-bp-xs) {
      top: -50px;
    }

    @media (min-width: $grid-bp-md) {
      top: 0;
    }
  }
}

.header-on-scroll .header-menu-container {
  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    top: 100px;

    @media (min-width: $grid-bp-xs) {
      top: 50px;
    }

    @media (min-width: $grid-bp-md) {
      top: 0;
    }
  }
}

.header-top {
  display: flex;

  @if ($theme-name == "leukoplast") {
    border-bottom: 1px solid $segment-color;
    padding-top: 10px;
  } @else if ($theme-name == "men") {
    padding-bottom: 7px;
  } @else if ($theme-name == "cutimed") {
    padding-bottom: 0;
  } @else if ($theme-name == "actimove") {
    justify-content: center;
  } @else if ($theme-name == "jobst") {
    border-bottom: unset;
  } @else if ($theme-name == "cgr") {
    border-bottom: 5px solid $segment-color;
  } @else {
    border-bottom: 2px solid $segment-color;
  }

  @if ($theme-name == "women" or $theme-name == "cgr") {
    align-items: center;
    padding: 15px 0;
    margin-top: -15px;
  }

  @if ($theme-name == "women") {
    border-bottom: none;
  }

  @if ($theme-name == "com") {
    padding: 15px 0;
  }

  @if ($theme-name == "men") {
    align-items: center;
    background: $page-background-color;

    @media (min-width: $grid-bp-md) {
      background: none;
    }
  }
}

@if ($theme-name == "women") {
  .header-background {
    background-image: url("../img/women/header-background-mobile.png");
    background-size: cover;
    background-position: bottom;
    border-bottom: 5px solid $header-golden-line-color;

    @media (min-width: $grid-bp-xs) {
      background-position: left;
      background-image: url("../img/women/header-background-desktop.png");
    }
  }
}

.header-container {
  @include transition(left $mobile-header-animation-speed);
  @include mobileMenuWidth();
  @include mobileMenuLeft();

  background-color: $accent-color;
  height: 100vh;
  position: fixed;
  z-index: 10;
}

@if $theme-name == "men" {
  .header-menu-container .select2-results {
    width: auto;
  }

  .header-menu-container .select2-container--default .select2-selection--single .select2-selection__arrow b {
    padding: 6px;
  }

  .svg-header-hamburger {
    fill: $color-white;
  }
}

@if ($theme-name == "jobst") {
  .svg-header-hamburger-wrapper {
    width: 28px;
    height: 28px;
  }
}

@if ($theme-name == "cgr") {
  .header-menu {
    background: transparent;
  }

  .menu-wrapper {
    border: none;
  }

  .header-background {
    background-image: url("../img/cgr/header/CGR_Background_XS.jpg");
    background-size: cover;
    background-position: center;

    @media (min-width: $grid-bp-md) {
      background-image: url("../img/cgr/header/CGR_Background_L.jpg");
    }

    @media (min-width: $grid-bp-lg) {
      background-image: url("../img/cgr/header/CGR_Background_XL.jpg");
    }
  }
}

.header-segments {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  list-style-type: none;
  margin-top: 0;
  padding-left: 0;

  @if (
    $theme-name == "leukoplast"
    or $theme-name == "cutimed"
    or $theme-name == "actimove"
    or $theme-name == "com"
    or $theme-name == "jobst") {
    display: none;
  }

  @if ($theme-name == "men") {
    @media (max-width: $grid-bp-xs) {
      margin-bottom: 0;
    }
  }
}

.header-segments-element {
  width: 100%;

  @if ($theme-name == "women" or $theme-name == "cgr") {
    border-right: 1px solid $color-white;
    border-bottom: 1px solid $color-white;

    @media (min-width: $grid-bp-sm) {
      border-bottom: none;
    }
  }
}

.header-segments-link {
  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    @include font-size-and-line-height(14px, 18px);
  }

  padding: 15px 20px;
  color: $home-color;
  display: flex;
  justify-content: center;

  @if ($theme-name == "men") {
    color: $color-white;
    border: unset;
  }

  &:hover {
    @if ($theme-name == "men") {
      color: $color-yellow;
    }

    @if ($theme-name == "women" or $theme-name == "cgr") {
      color: $header-link-active-color;
    }
  }

  &--active {
    color: $header-link-active-color;
    background-color: $header-link-active-bg;

    @if ($theme-name == "men") {
      background-color: $header-link-active-bg-mobile;

      @media (min-width: $grid-bp-md) {
        background-color: $header-link-active-bg-desktop;
      }
    }
  }
}

.header-search {
  margin-right: 15px;
  cursor: pointer;
}

.header-search svg {
  width: 24px;
  height: 24px;
}

.header-search .search-icon-path {
  stroke: $header-search-icon-color;

  @if ($theme-name == "men") {
    stroke: $color-white;
  }
}

@media (min-width: $grid-bp-xs) {
  .header-segments {
    grid-template-columns: auto auto auto auto;
  }

  .header-segments-element {
    margin: 0;
  }

  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    .header-top {
      margin-top: -20px;
    }
  }
}

@if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
  .svg-header-hamburger {
    fill: $color-white;
  }
}

.header-segments {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  list-style-type: none;
  margin-top: 0;
  padding-left: 0;

  @if ($theme-name == "leukoplast" or $theme-name == "cutimed" or $theme-name == "actimove" or $theme-name == "com" or $theme-name == "jobst") {
    display: none;
  }

  @if ($theme-name == "men") {
    @media (max-width: $grid-bp-xs) {
      margin-bottom: 0;
    }
  }
}

.header-segments-element {
  width: 100%;

  @if ($theme-name == "women" or $theme-name == "cgr") {
    border-right: 1px solid $color-white;
    border-bottom: 1px solid $color-white;

    @media (min-width: $grid-bp-sm) {
      border-bottom: none;
    }
  }
}

.header-segments-link {
  @if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
    @include font-size-and-line-height(14px, 18px);
  }

  padding: 15px 20px;
  color: $home-color;
  display: flex;
  justify-content: center;

  @if ($theme-name == "men") {
    color: $color-white;
    border: unset;
  }

  &:hover {
    @if ($theme-name == "men") {
      color: $color-yellow;
    }

    @if ($theme-name == "women" or $theme-name == "cgr") {
      color: $header-link-active-color;
    }
  }

  &--active {
    color: $header-link-active-color;
    background-color: $header-link-active-bg;

    @if ($theme-name == "men") {
      background-color: $header-link-active-bg-mobile;

      @media (min-width: $grid-bp-md) {
        background-color: $header-link-active-bg-desktop;
      }
    }
  }
}

.header-search {
  margin-right: 15px;
  cursor: pointer;
}

.header-search svg {
  width: 24px;
  height: 24px;
}

.header-search .search-icon-path {
  stroke: $header-search-icon-color;

  @if ($theme-name == "men") {
    stroke: $color-white;
  }
}

@media (min-width: $grid-bp-xs) {
  .header-segments {
    grid-template-columns: auto auto auto auto;
  }

  .header-segments-element {
    margin: 0;
  }

  @if ($theme-name == "men") {
    .header-top {
      margin-top: -20px;
    }
  }

  @if ($theme-name == "women" or $theme-name == "cgr") {
    .header-top {
      margin-top: -16px;
    }
  }
}

@media (min-width: $grid-bp-md) {
  .header {
    border-bottom: 0;
    padding-bottom: 0;
    position: relative;
  }

  .header-segments {
    grid-template-columns: auto auto auto auto;
    padding: 0;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  .header-segments-link {
    padding: 10px 20px;
  }

  .header-top {
    @include container-size();

    min-height: 100px;
    border-bottom: 0;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;

    @if ($theme-name == "leukoplast" or $theme-name == "cutimed" or $theme-name == "actimove" or $theme-name == "jobst") {
      margin: -35px auto 0;
    }

    @if ($theme-name == "women") {
      padding: 4px 30px 0;
    }

    @if ($theme-name == "cgr") {
      padding: 10px 30px;
    }

    @if $theme-name == "leukoplast" {
      min-height: 105px;
    } @else if ($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr" or $theme-name == "com") {
      min-height: unset;
    } @else {
      min-height: $header-height;
    }
  }

  .header-search-expanded .header-top {
    @if ($theme-name == "leukoplast") {
      margin: -15px auto 0;
    }

    @if ($theme-name == "jobst") {
      margin-top: 0;
    }
  }

  .header-search-expanded .header-logo {
    @if ($theme-name == "cutimed") {
      top: 55px;
    } @else if ($theme-name == "actimove") {
      margin-top: 25px;
    }
  }

  .header-search {
    display: none;
  }

  .header-container {
    background: $page-background-color;
    height: initial;
    position: initial;
    width: 100%;
  }
}

@if ($theme-name == "actimove") {
  .svg-header-hamburger {
    fill: $color-light-black;
  }
}

@if ($theme-name == "men") {
  .header-top {
    align-items: end;
  }
}