.sample-box {
    position: relative;

    .sample-box-title {
        @include font-size($font-size-product-box-title);
        margin: 0 0 5px;
    }

    h4 {
        @include font-size($font-size-product-box-text);
        font-family: $fontfamily-regular;
    }

    p {
        @include font-size($font-size-product-box-text);
        margin: 0 0 7px;

        &.selected-sizes {
            margin: 0;
        }
    }

    .product-id {
        font-family: $fontfamily-regular-bold;
        font-size: 12px;
        margin: 0 0 7px;
    }

    .small-type {
        display: block;
        font-size: 12px;
        margin-top: 5px;
    }

    .sample-box-preview {
        color: $link-color;
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
    }

    &.sample-box-wide {
        @include mediaquery(md) {
            .sample-box-img {
                position: relative;
                width: 100%;
            }

            .sample-box-information {
                padding-left: 0;
                width: 50%;
            }
        }

        @include mediaquery(sm) {
            .sample-box-information {
                border: none;
                width: 100%;
            }
        }
        .sample-box-img {
            left: 0;
            position: absolute;
            top: 0;
            width: 100px;
        }

        .sample-box-information {
            border-right: 1px solid $border-color;
            float: left;
            padding-left: 120px;
            padding-right: 20px;
            width: 60%;
        }
    }

    .sample-box-img {
        height: 60px;
        margin-bottom: $vspace-xs;

        img {
            display: block;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .sample-box-information {
        .sample-box-details {
            @include clearfix();

            h4 {
                font-family: $fontfamily-regular-bold;
            }

            .product-details {
                @include clearfix();
            }

            .detail-rating,
            .detail-colors {
                float: left;
            }

            .detail-rating {
                h4 {
                    margin-bottom: 5px;
                }

                .rating {
                    max-width: 100%;
                }
            }

            .detail-colors {
                font-size: 26px;
                width: 50px;
            }
        }
    }
}