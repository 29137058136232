.campaign-media-container .jw-preview.jw-reset {
  background-color: transparent !important;
  background-size: cover !important;
  display: block;
}

.inline-video-container .jwplayer {
  background-color: transparent !important;
}

.jwplayer.jw-stretch-uniform .jw-media video {
  object-fit: cover !important;
}

.video-container {
  position: relative;
}

.video-player-bg-image {
  bottom: 0;
  display: block;
  height: 100%;
  margin: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-playback-svg {
  margin-right: 5px;
}

.video-playback-play-icon {
  display: none;
}

.video-playback-svg--is-paused .video-playback-pause-icon {
  display: none;
}

.video-playback-svg--is-paused .video-playback-play-icon {
  display: block;
}

@if $theme-name == "leukoplast" {
  .mfp-content .jw-display.jw-reset {
    display: table !important;
  }

  .mfp-content .jw-display-icon-container {
    background-color: $btn-play-background-transparent !important;
    border-radius: 50%;
  }

  .jw-display-icon-rewind {
    display: none !important;
  }

  .jw-display .jw-icon {
    height: 100px !important;
    line-height: 100px !important;
    width: 100px !important;
    @media (max-width: $grid-bp-sm){
      height: 70px !important;
      line-height: 70px !important;
      width: 70px !important;
    }
  }

  .jw-display .jw-display-icon-next.jw-reset {
    display: none !important;
  }

}

.campaign-media-container .jw-wrapper {
  background-color: transparent;
}

@if ($theme-name == "jobst") {
  .carousel-campaign .video-playback {
    position: absolute;
    top: 3%;
    right: 2%;
    background-color: rgba($color-black, 0.54);
    z-index: 1;
    border-radius: 16px;
    padding: 8px 12px 8px 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .carousel-campaign .video-playback-text {
    @include font-size-and-line-height(14px, 18px);

    color: $color-white;
  }

  .carousel-campaign .jwplayer.jw-flag-aspect-mode {
    min-height: 390px;

    @media (min-width: $grid-bp-xs) {
      min-height: 480px;
    }

    @media (min-width: $grid-bp-sm) {
      min-height: 660px;
    }
  }

  .inline-video-container {
    height: 390px;

    @media (min-width: $grid-bp-xs) {
      height: 480px;
    }

    @media (min-width: $grid-bp-sm) {
      height: 660px;
    }
  }
}