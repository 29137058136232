@if ($is-tena-theme) {
  .content-column {
    float: right;
    position: unset;
    top: 90px;
    right: 0;
    left: 0;

    @media (min-width: $grid-bp-md) {
      position: sticky;
      margin-top: 15px;
      margin-bottom: 50px;
    }
  }

  .product-content-frost .product-intro-content-header {
    @media (max-width: $grid-bp-sm-max) {
      display: none;
    }
  }

  .product-badge {
    @include font-size-and-line-height($product-intro-badge-font-size, $product-intro-badge-line-height);

    font-family: $fontfamily-regular-bold;
    color: $color-white;
    background-color: $product-intro-badge-bg;
    width: fit-content;
    padding: 5px;
    border-radius: 15px;
  }

  .product-intro-container-frost .product-subtitle {
    @include font-size-and-line-height(16px, 22px);

    font-family: $product-intro-subtitle;
  }

  .product-intro-container-frost .product-title {
    @include font-size-and-line-height($product-intro-title-font-size, $product-intro-title-line-height);

    @if ($theme-name != "actimove") {
      margin-top: 10px;
      font-family: $product-intro-title-font;
      font-weight: 400;
      color: $product-intro-title-color;

      @if ($theme-name == "cgr") {
        letter-spacing: $product-intro-letter-spacing;
        text-transform: $product-intro-text-transform;
      }
    }
  }

  .product-intro-container-frost .bazaarVoice-left-aligned {
    margin-bottom: 20px;
  }

  .product-description-short {
    line-height: 25px;
    margin-bottom: 40px;
  }

  .product-absorbency h3 {
    @include font-size-and-line-height($product-intro-label-font-size, $product-intro-label-line-height);

    margin-bottom: 6px;
  }

  .product-content-frost .product-options {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .product-label-text {
    @include font-size-and-line-height($product-intro-label-font-size, $product-intro-label-line-height);

    font-family: $product-intro-label;
    margin-bottom: 6px;
  }

  .product-intro-option {
    width: 50%;
  }

  .product-option-value {
    line-height: 17px;
  }

  .product-content-frost .product-color-circle {
    width: 50%;
    margin: 0 0 16px;
  }

  .product-content-frost .product-color-outer {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }

  .product-content-frost .product-color-inner {
    width: 16px;
    height: 16px;
    margin-top: 8px;
  }

  .product-content-frost .product-guide-popup {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;

    .product-label-text {
      color: $product-intro-guide-popup-label-color;
      margin-top: 2px;
      margin-left: 6px;
    }
  }

  .reviews-guide-popup {
    @include font-size-and-line-height(16px, 22px);

    color: $color-black;
    font-family: $fontfamily-regular;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .accordion-content .accordion-content-inner .reviews-guide-popup a {
    color: $pr-link-color;
    font-family: $fontfamily-regular-bold;
    text-decoration: underline;

    &:hover {
      color: $pr-link-color;
      text-decoration: none;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .product-buttons-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-white;
      padding: 16px 10px;
      box-shadow: 0px 2px 10px $product-intro-button-container-color;
      z-index: 9;

      @if ($theme-name == "jobst") {
        background-color: $color-light-25;
      }
    }
  }

  .product-buttons-container .product-buttons {
    display: flex;
    flex-direction: row;
    margin: auto;

    @media (max-width: $grid-bp-xs-max) {
      width: 100%;
    }

    @media (min-width: $grid-bp-md) {
      margin-bottom: 25px;
    }
  }

  .product-buttons-container .btn.horizontal-btns {
    @if ($theme-name != "actimove" and $theme-name != "jobst") {
      @include font-size-and-line-height(19px, 19px);

      span {
        line-height: 19px;
      }
    }

    text-align: left;
    @if ($theme-name != "men" and $theme-name != "actimove" and $theme-name != "jobst") {
      padding: 10px 80px 10px 18px;
    }

    @media (max-width: $grid-bp-xs-max) {
      margin: 0 10px 0 0;
    }
  }

  .product-intro-list {
    padding-left: 0;
  }

  .product-intro-list-item {
    list-style-type: none;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    line-height: 22px;
  }

  .product-intro-list-item p {
    margin-bottom: 0;
  }

  .product-intro-list-item-text {
    @include font-size-and-line-height(16px, 22px);
  }

  .product-content-frost .icon-validation-tick:before {
    color: $color-white;
    background-color: $color-black;
    padding: 2px;
    border-radius: 50px;
    margin-right: 10px;
  }

  .panel-wrapper {
    @include fadeInOut();

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 51;
    background-color: rgba($color-black, 0.67);
    display: none;
  }

  .compare-products .panel-wrapper {
    @if($theme-name == "women" or $theme-name == "cgr" or $theme-name == "men") {
      background-color: transparent;
    }
  }

  .panel-wrapper--opened {
    display: flex;
    align-items: flex-end;

    .panel {
      overflow-y: auto;
    }

    @media (min-width: $grid-bp-sm) {
      justify-content: flex-end;
    }
  }

  .panel {
    padding: 40px 14px 70px;
    max-height: 95vh;
    height: auto;
    background-color: $color-white;
    box-shadow: 0px 0px 5px rgba($color-black, 0.2);
    border-radius: 8px 8px 0 0;
    width: 100%;

    @media (min-width: $grid-bp-sm) {
      top: 0;
      max-height: unset;
      height: 100%;
      padding: 68px 20px;
      border-radius: 0;
      width: 415px;
    }
  }

  .panel-close-button {
    @include font-size-and-line-height(28px, 38px);

    position: absolute;
    bottom: 16px;
    left: 45%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $product-intro-panel-close-button;
    color: $color-white;
    text-align: center;
    cursor: pointer;

    @media (min-width: $grid-bp-sm) {
      top: 12px;
      right: 18px;
      left: unset;
    }
  }

  .reviews-wrapper {
    align-items: center;
    justify-content: center;
  }

  .reviews-wrapper .panel {
    border-radius: 0;
    margin-inline: 15px;
    height: 90vh;
    padding: 74px 10px 12px 40px;
    position: relative;
    overflow-y: unset;

    @media (min-width: $grid-bp-sm) {
      height: 555px;
      max-height: unset;
      width: 675px;
    }

    @media (min-width: $grid-bp-md) {
      height: 755px;
      width: 875px;
    }
  }

  .reviews-wrapper .panel-close-button {
    @include font-size-and-line-height(28px, 20px);
    background-color: transparent;
    color: $color-black;
    width: 24px;
    height: 24px;
    top: 40px;
    right: 40px;
    left: unset;
  }

  .reviews-wrapper .panel-scrollbar {
    height: 100%;
    overflow-y: auto;
    padding-right: 24px;

    &::-webkit-scrollbar {
      background-color: $scrollbar-background-color;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $product-intro-accordion-reviews-panel-scrollbar;
      border-radius: 4px;
    }
  }

  .reviews-wrapper .panel-content {
    @include font-size-and-line-height(16px, 22px);

    color: $product-intro-accordion-reviews-panel-content;
    font-family: $fontfamily-regular;

    span {
      line-height: 25px;
    }
  }

  .panel-header {
    @include font-size-and-line-height(22px, 28px);

    font-family: $product-intro-panel-header;
    text-align: left;

    @if ($theme-name == "actimove") {
      font-weight: 600;
      color: $color-light-black;
    } @else {
      font-weight: 400;
    }
  }

  .reviews-wrapper .panel-header {
    @include font-size-and-line-height(28px, 32px);

    color: $product-intro-accordion-reviews-panel-header;
    font-family: $fontfamily-regular;
    margin-bottom: 10px;
  }

  .panel-media {
    margin-top: 30px;
    height: auto;
    text-align: center;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .panel-description {
    margin-top: 20px;
    margin-bottom: 0;

    @if ($theme-name == "actimove") {
      line-height: 22px;
      font-weight: 600;
      color: $color-light-black;
    } @else {
      line-height: 25px;
    }
  }

  .panel-size-guide {
    margin-top: 35px;
  }

  .panel-size-label {
    @include font-size-and-line-height(14px, 18px);

    font-family: $fontfamily-regular-bold;
    margin-top: 10px;
    line-height: 25px;
  }

  .panel-size-info {
    line-height: 25px;
    margin-bottom: 0;
  }

  .product-intro-frost-ruler rect,
  .product-intro-frost-ruler line {
    stroke: $product-intro-ruler-color;
  }

  .MdrDetails-container .panel-header {
    @if ($theme-name == "actimove") {
      @include font-size-and-line-height(16px, 22px);

      margin-bottom: 5px;
    } @else {
      @include font-size-and-line-height(20px, 22px);
    }
  }

  .MdrDetails-container .panel-description,
  .MdrDetails-container .panel-size-info {
    @if ($theme-name == "actimove") {
      line-height: 22px;
      font-weight: 400;
    } @else {
      line-height: 25px;
    }
  }

  .MdrDetails-container .panel-media {
    text-align: left;
  }
}

@if ($theme-name == "actimove") {
  .product-intro-container-frost .product-subtitle,
  .product-intro-list-item-text {
    font-family: $intro-text-font-family;
  }

  .product-badge {
    font-family: $fontfamily-regular;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    background-color: unset;
    color: $home-color;
    padding-left: 0;
  }

  .product-badge-img {
    width: 14px;
    height: 16px;
    margin-right: 5px;
  }

  .product-intro-container-frost .product-title {
    font-style: italic;
  }

  .product-subtitle,
  .panel-size-label {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
  }

  .product-intro-container-frost .product-title,
  .product-subtitle {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .product-description-short,
  .product-option-value,
  .panel-size-info {
    line-height: 22px;
  }

  .product-content-frost .product-options {
    flex-direction: column;
  }

  .product-label-text {
    font-family: $fontfamily-regular;
    line-height: 22px;
    font-weight: 600;
  }

  .product-content-frost .product-color-circle,
  .product-intro-option {
    width: 100%;
  }

  .product-circle-wrapper,
  .product-circle {
    display: flex;
  }

  .product-circle:not(:first-child) {
    margin-left: 10px;
  }

  .product-content-frost .product-color-inner {
    margin-top: 2px;
  }

  .product-circle-text {
    margin-bottom: 0;
    color: $color-light-black;
    margin-left: 4px;
  }

  .product-content-frost .product-guide-popup .product-label-text {
    margin-left: 0;
  }

  .product-buttons-container .buy-now-highlighted {
    @include btn("primary");
  }

  .product-buttons-container .btn-secondary {
    @include btn("secondary");
  }

  .product-buttons-container .buy-now-highlighted span,
  .product-buttons-container .btn-secondary span {
    @include font-size-and-line-height(16px, 22px);

    font-family: unset;
    font-weight: 600;
  }

  @media (max-width: $grid-bp-sm-max) {
    .product-buttons-container .btn.horizontal-btns {
      text-align: center;
    }
  }

  @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
    .product-buttons {
      justify-content: center;
    }

    .product-buttons-container .btn.horizontal-btns {
      min-width: 350px;
    }
  }

  .product-description-short,
  .product-support-value,
  .product-option-value {
    color: $color-light-black;
  }

  .product-content-frost .icon-validation-tick:before {
    background-color: $product-intro-icon-color;
  }

  @media (min-width: $grid-bp-sm) {
    .panel {
      padding: 90px 40px;
      width: 512px;
    }
  }

  .panel-close-button {
    left: 45%;
    bottom: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: $home-color;

    @media (min-width: $grid-bp-xs-max) {
      top: 40px;
      right: 40px;
      left: unset;
    }
  }

  .panel-list {
    padding-left: 30px;
  }

  .panel-list-item {
    color: $color-light-black;
    line-height: 28px;

    &::marker {
      color: $color-light-black;
    }
  }

  .panel-list-item p {
    margin-bottom: 0;
    line-height: 28px;
  }

  .panel-size-label {
    font-weight: 600;
  }

  .MdrDetails-container .panel-description {
    margin-top: 0;
  }

  .MdrDetails-container .panel-media {
    @include svg-play-icon();

    margin-top: 15px;
  }

  .MdrDetails-container .panel-content--accordion .panel-description {
    font-weight: 600;
  }

  .MdrDetails-container .panel-video {
    margin-bottom: 15px;
  }

  .MdrDetails-container .panel-media .panel-description:last-of-type {
    margin-bottom: 15px;
  }

  .MdrDetails-container .panel-content-wrapper {
    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      width: 530px;
    }

    @media (min-width: $container-max-width) {
      width: 534px;
    }
  }

  .MdrDetails-container .one-col-text {
    color: $color-light-black;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      max-width: 530px;
    }

    @media (min-width: $container-max-width) {
      max-width: 534px;
    }
  }

  .MdrDetails-container .panel-content--accordion {
    flex-direction: column;
  }

  .MdrDetails-container .one-col-text .text-content b {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

@if ($theme-name == "jobst") {
  .content-column {
    float: right;
  }

  .product-intro-container-frost .product-title {
    margin-bottom: 8px;
  }

  .product-content-frost .product-intro-content-header ~ .product-description-short {
    display: none;
  }

  .product-description-short {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
    margin-bottom: 24px;
  }

  .product-content-frost .product-options {
    display: none;
  }

  .product-content-frost .product-benefits-intro .article-list-container .article-list {
    padding-top: 0;
    margin-bottom: 40px;
  }

  .product-content-frost .product-benefits-intro .article-list-container .article-list-item {
    margin-bottom: 16px;
  }

  .MdrDetails-container .one-col-text {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .product-content-frost .product-benefits-intro .article-list .article-list-item .article-list-image {
    width: 48px;
    float: none;
    margin: 0 auto 8px auto;

    @media (min-width: $grid-bp-sm) {
      float: left;
      margin: unset;
    }
  }

  .product-content-frost .product-benefits-intro .article-list-item-text {
    border: unset;

    @media (max-width: $grid-bp-xs-max) {
      margin-left: 0;
      text-align: center;
    }
  }

  .product-content-frost .product-benefits-intro .article-list-image ~ .article-list-item-text {
    @media (min-width: $grid-bp-sm) {
      margin-left: 64px;
    }
  }

  .product-content-frost .product-buttons {
    @media (max-width: $grid-bp-xs-max) {
      flex-direction: column;
      row-gap: 8px;
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      justify-content: center;
    }
  }

  .product-content-frost .product-buttons-container .btn.buy-now-highlighted {
    @include btn("primary-light");
  }

  .product-content-frost .product-buttons-container .btn.btn-secondary {
    @include btn("secondary");
  }

  .product-content-frost .product-buttons-container .btn.horizontal-btns,
  .product-content-frost .product-buttons-container .btn.buy-now-highlighted {
    @media (max-width: $grid-bp-sm-max) {
      text-align: center;
      padding-right: 30px;

      &:after {
        right: auto;
        margin-left: 10px;
      }
    }

    @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
      min-width: 350px;
      width: unset;
      margin: unset;
      margin-right: 10px;
    }
  }

  .product-intro-container-frost .text-content,
  .product-intro-container-frost .text-content p,
  .product-intro-container-frost .text-content ul li {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .product-intro-container-frost .swiper-scrollbar.swiper-scrollbar-horizontal {
    border: 1px solid $color-light;
    background-color: transparent;
    border-radius: 3px;
    height: 6px;
  }

  .product-content-frost .product-guide-popup {
    margin-bottom: 24px;
    justify-content: center;

    @media (min-width: $grid-bp-sm) {
      justify-content: left;
    }

    .product-label-text {
      @include arrowIcon($color-primary-light);

      position: relative;
      margin-left: 0;

      &:after {
        top: 13px;
        right: unset;
        margin-left: 15px;
      }
    }
  }

  .panel {
    padding: 24px;
    border-radius: none;

    @media (min-width: $grid-bp-sm) {
      width: 512px;
    }
  }

  .panel-content {
    margin-top: 22px;
  }

  .panel-header {
    line-height: 26px;
    margin-bottom: 16px;
  }

  .panel-close-button {
    @include font-size-and-line-height(22px, 18px);

    position: unset;
    right: 24px;
    bottom: unset;
    left: unset;
    width: unset;
    height: unset;
    border-radius: unset;
    color: $color-black;
    text-align: right;
  }

  .panel .panel-content .article-list-container .article-list {
    padding-top: 0;
  }

  .panel .panel-content .article-list-container .article-list .article-list-item {
    text-align: left;
  }

  .panel .panel-content .article-list .article-list-item .article-list-image {
    float: none;
    width: 48px;
    margin: unset;
    margin-bottom: 4px;
  }

  .panel .panel-content .article-list-item-title {
    @include font-size-and-line-height(18px, 26px);

    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $fontfamily-regular;
    font-weight: normal;
    margin-bottom: 4px;
  }

  .panel .panel-content .article-list-container .text-content {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
    margin-bottom: 6px;
  }

  .product-options {
    width: 100%;
  }

  .options-heading {
    @include font-size-and-line-height(18px, 26px);

    color: $color-primary;
    letter-spacing: 2px;
    margin-bottom: 16px;
    text-transform: uppercase;
    text-align: left;
  }

  .options-items,
  .options-items-image {
    display: grid;
    margin-bottom: 24px;
  }

  .options-items {
    gap: 8px;

    @media (min-width: $grid-bp-sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .options-item {
    text-align: center;
  }

  .options-items-image {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: $grid-bp-sm) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .options-item-image {
    height: 80px;
  }

  .options-items-image .options-item-label {
    margin-left: 0;
  }

  .options-item-square,
  .options-item-circle {
    background-color: $color-light-50;
    display: flex;
    align-items: center;
  }

  .options-item-square {
    padding: 6px 8px;
  }

  .options-item-circle {
    padding: 3px 8px;
  }

  .options-item-square .options-item-color {
    border-bottom-right-radius: 10px;
    height: 100%;
    width: 57px;
  }

  .options-item-circle .options-item-color,
  .options-item-circle-image {
    border: 1px solid $color-gray-50;
    border-radius: 50%;
  }

  .options-item-circle .options-item-color {
    height: 44px;
    width: 44px;
  }

  .options-item-circle-image {
    height: 46px;
    width: 46px;
  }

  .options-item-label {
    @include font-size-and-line-height(18px, 26px);

    color: $color-gray;
    font-family: $fontfamily-regular-light;
    margin-left: 8px;
  }

  .options-text {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
    text-align: left;
  }
}

@if ($theme-name == "women") {
  .product-buttons .btn.btn-secondary {
    @include btn("primary-light");

    height: fit-content;
  }

  .product-buttons .field.field-select {
    margin-top: 10px;
  }

  .product-buttons .form {
    margin-right: 10px;
  }

  .product-content-frost .product-guide-popup .product-label-text {
    color: $color-blue;
  }

  .product-intro-frost-ruler rect,
  .product-intro-frost-ruler line {
    stroke: $color-blue;
  }
}

@if ($theme-name == "men") {
  @media (max-width: $grid-bp-sm) {
    .product-buttons-container .btn.horizontal-btns::after {
      right: 95px;
    }
  }
}

@if ($theme-name == "women" or $theme-name == "men" or $theme-name == "cgr") {
  .product-buttons-container {
    width: 75%;
  }

  .product-buttons-container .product-buttons .btn {
    max-width: 192px;
    width: 100%;
    padding-right: 38px;
    margin-left: 0;
  }

  .product-buttons-container .product-buttons {
    flex-direction: column;
  }

  .product-buttons-container .btn-secondary {
    margin-top: 16px;
  }

  @media (max-width: $grid-bp-sm-max) {
    .product-buttons-container {
      width: 100%;
      padding: 14px 10px;
    }

    .product-buttons-container .product-buttons {
      flex-direction: row;
      justify-content: center;
    }

    .product-buttons-container .btn-secondary {
      margin-top: initial;
    }
  }

  @media (max-width: $grid-bp-sm) {
    .product-buttons-container {
      padding: 8px 15px;
    }

    .product-buttons-container .product-buttons {
      flex-direction: column;
    }

    .product-buttons-container .product-buttons .btn.horizontal-btns {
      max-width: initial;
      text-align: center;
    }

    .product-buttons-container .btn.btn-secondary {
      margin-top: 8px;
    }

    .product-buttons-container .btn .icon-button-right {
      right: 95px;
    }
  }
}

@if ($theme-name == "actimove") {
  .content-column {
    float: right;
  }
}