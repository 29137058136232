.product-filter-categories-title,
.product-filter-accordion-title {
  @include font-size-and-line-height(22px, 28px);

  font-weight: 400;

  @if ($theme-name == "men") {
    font-family: $intro-title-font-family;
    text-transform: uppercase;
    color: $color-purple;
  } @else if ($theme-name == "cgr") {
    color: $product-filter-accordion-title-color;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  } @else if ($theme-name == "women") {
    font-family: $fontfamily-regular;
    text-transform: uppercase;
  } @else {
    font-family: $fontfamily-regular;
  }
}

.product-filter-categories-title {
  padding-bottom: 5px;
  border-bottom: 1px solid $title-border-color;
  margin-top: 0;
  margin-bottom: 10px;
}

.product-filter-accordion-title {
  margin-top: 5px;
  word-break: break-word;
}

.product-filter-products-container .text-content {
  margin-left: -5px;
}

.product-filter-products-container .Button--Accordion.btn {
  text-align: left;
}

.product-filter-container {
  display: grid;
  grid-template-columns: 1fr;
}

.product-filter-accordion-content {
  display: flex;
  flex-direction: column;
}

.product-filter-title {
  display: none;
}

.product-filter-accordion-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.product-filter-accordion-checkbox,
.product-filter-accordion-label {
  cursor: pointer;
}

.product-filter-accordion-checkbox {
  @include font-size-and-line-height(16px, 22px);
}

.product-filter-accordion-input-and-label {
  display: flex;
  align-items: center;
}

.product-filter-accordion-content input[type=checkbox] {
  margin: 0 10px 0 0;
  width: 20px;
  min-width: 20px;
  height: 20px;

  @if ($theme-name == "men") {
    accent-color: $color-purple;
  }

  @if ($theme-name == "women") {
    accent-color: $segment-color;
  }

  @if ($theme-name == "cgr") {
    accent-color: $segment-color;
  }

  @if ($theme-name == "jobst") {
    accent-color: $color-primary;
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.5;
}

.product-filter-item-active {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

.accordion-expanded .product-filter-item-active {
  display: none;
}

.product-filter-accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.product-filter-number-of-products {
  @include font-size-and-line-height(16px, 25px);

  margin-top: 25px;

  @if ($theme-name == "leukoplast") {
    display: none;
  }
}

.product-filter-container .equal-height-container {
  flex-direction: row;
}

.product-filter-container .product-list .component-product-box {
  width: calc(50% - 20px);

  @media (min-width: $grid-bp-sm) and (max-width: $grid-bp-sm-max) {
    width: calc(33% - 20px);
  }
}

.product-filter-container .product-list .component-product-box.component-product-box--list {
  width: calc(100% - 20px);

  @media (min-width: $grid-bp-sm) {
    width: calc(33% - 20px);
  }
}

.product-filter-container .product-box-view-details {
  width: 100%;
}

$product-filter-icon-size: 24px;

.product-filter-items,
.product-filter-apply,
.product-filter-reset-all,
.product-filter-single-apply,
.product-filter-item-title .complementary-info {
  display: none;
}

.product-filter--is-active .product-filter-items,
.product-filter--is-active .product-filter-apply {
  display: block;
}

.product-filter--has-applied-filters .product-filter-applied-list,
.product-filter--has-applied-filters .product-filter-footer,
.product-filter--has-applied-filters .product-filter-reset-all,
.product-filter--is-active .product-filter-reset-all {
  display: inline-block;
}

.component-btn .btn {
  @if ($theme-name == "women") {
    @include btn("secondary");
  }
}

.product-filter-apply {
  font-size: 1rem;
  line-height: 1.125;
  margin: 0 auto;

  @if ($theme-name == "men") {
    @include btn("secondary");
  }
}

.btn.product-filter-reset-all {
  @include font-size-and-line-height(16px, 17px);

  font-family: $fontfamily-regular-bold;
  color: $segment-color;
  margin-bottom: 10px;
  margin-top: 0;
  text-decoration: underline;

  @if ($theme-name == "men" or $theme-name == "jobst") {
    color: $logo-color;
    clip-path: none;

    &::before,
    &::after {
      display: none;
    }
  }

  @if ($theme-name == "jobst") {
    @include font-size-and-line-height(18px, 26px);

    color: $color-primary;
  }
}

.product-filter--is-active .product-filter-reset-all {
  margin-top: 20px;
}

.product-filter {
  background-color: $color-white;

  @if ($theme-name == 'jobst') {
    background-color: $color-light-25;
  }
  user-select: none;

  &--is-active {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 50;
  }

  @if ($theme-name == 'jobst') {
    background-color: $color-light-25;
  }
}

.product-filter-mobile-bar {
  background-color: $segment-color;
  border-radius: 3px;
  color: $color-white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 15px;
  border: none;
  width: 100%;
}

.product-filter--is-active .product-filter-footer {
  bottom: 0;
  left: 0;
  margin: 15px;
  position: fixed;
  right: 0;
}

.product-filter--is-active .product-filter-mobile-bar {
  color: inherit;
  margin-left: 0;
  margin-right: 0;
  background-color: $color-white;
  flex-direction: row-reverse;
}

.product-filter-toggle {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;
}

.product-filter-toggle-mobile {
  cursor: pointer;
  font-size: $product-filter-icon-size;
  padding: 0;
}

.product-filter-toggle-label {
  font-family: $fontfamily-regular-bold;
  line-height: $product-filter-icon-size;
}

.product-filter-single-active,
.product-filter--is-active .product-filter-toggle-label {
  font-size: 14px;
  text-transform: uppercase;

  @if ($theme-name == 'cgr') {
    text-transform: none;
  }
}

.product-filter--is-active .product-filter-toggle-label {
  color: $logo-color;
}

.product-filter-single-active {
  @include font-size-and-line-height(16px, 22px);

  margin-left: 10px;
  color: $product-filter-single-active-color;
  line-height: 1.14;

  &::after {
    content: ",";
  }

  &:last-of-type::after {
    content: none;
  }

  @if ($theme-name == 'jobst') {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-orange;
    text-transform: none;
  }
}

.product-filter-item {
  border: 1px solid $border-color;
  margin-bottom: 10px;
  padding: 9px 9px 5px;
}

.product-filter-item .icon-arrow-down {
  padding-top: 4px;
}

.product-filter-item-title {
  color: $logo-color;
  font-size: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.product-filter-item-title .complementary-info {
  margin-left: 5px;
}

.product-filter-accordion-checkbox-wrapper {
  justify-content: space-between;
}

.product-filter-item-title .complementary-info,
.product-filter-accordion-checkbox-wrapper .complementary-info {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}

.product-filter-items {
  padding: 15px;
}

.product-filter--is-active .product-filter-items {
  max-height: calc(100% - 150px);
  overflow-y: auto;
}

.product-filter-btn {
  @include roundedButton();

  border: 1px solid $product-filter-border-color;
  border-radius: 5px;
}

.product-filter-btn:disabled {
  cursor: initial;
  opacity: 0.2;
}

.product-filter-applied-list .product-filter-btn {
  background-color: $accent-color-alt-light;
  border: none;
  color: $home-color;
  line-height: 1.125;
}

.product-filter-btn-info {
  cursor: default;
  pointer-events: none;
}

.product-filter-item-name {
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
}

.product-filter-applied-list {
  padding: 15px 0;
}

.product-filter--is-active .product-filter-applied-list {
  display: none;
}

.product-filter-toggle-icon {
  fill: $color-white;
  height: $product-filter-icon-size;
  vertical-align: middle;
  width: $product-filter-icon-size;
}

.product-filter-item--is-active .arrow {
  display: none;
}

.product-filter-all-filters {
  display: none;
}

.product-filter-scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@if ($theme-name == 'cutimed') {
  .product-filter-number-of-products {
    @include font-size-and-line-height(16px, 25px);

    color: $color-blue;
    font-family: $fontfamily-regular-bold;
  }

  .product-filter-container .product-list .component-product-box {
    border: 1px solid $color-light-blue-2;
    padding: 20px 5px;
    width: calc(50% - 10px);
    margin: 0 5px 15px;
  }

  .product-filter-container .product-list .product-box {
    padding-left: 5px;
    padding-right: 5px;
  }

  .product-filter-container .product-list .component-product-box .title {
    @include font-size-and-line-height(23px, 26px);
  }

  .product-filter--is-active,
  .product-filter--is-active .product-filter-mobile-bar {
    background-color: $color-light-blue-2;
  }

  .product-filter-accordion-label {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .product-filter-single-active {
    @include font-size-and-line-height(14px, 17px);

    font-family: $fontfamily-regular-bold;
    color: $color-blue;
  }

  .product-filter .product-filter-item-title {
    @include font-size-and-line-height(18px, 25px);

    font-family: $fontfamily-regular;
    color: $color-gray;
  }

  .product-filter-accordion-item.accordion-collapsed {
    background-color: $color-light-blue-2;
  }

  .product-filter-mobile-bar {
    background-color: $color-blue;
  }

  .product-filter-item-img {
    max-width: 100px;
  }

  .product-filter-apply {
    background-color: $color-blue;
    border: none;
  }

  .product-filter-item {
    border: none;
    border-bottom: 1px solid $color-blue;
    margin-bottom: 0;
  }

  .product-filter-item-title {
    margin: 0;
  }

  .product-filter-item-active {
    margin-bottom: 0;
  }

  .product-filter-item-active .product-filter-single-active:first-of-type {
    margin-left: 0;
  }

  .product-filter-item .product-filter-icon::before {
    color: $product-filter-arrow-color;
  }

  .product-filter .product-filter-title {
    color: $color-blue;
  }

  .product-filter--is-active .product-filter-reset-all {
    margin-left: 15px;
  }

  .product-filter .product-filter-reset-all {
    color: $color-pink;
  }

  .product-filter .product-filter-title,
  .product-filter .product-filter-reset-all {
    @include font-size-and-line-height(16px, 25px);

    font-family: $fontfamily-regular-bold;
    text-decoration: none;
  }

  .product-filter-mobile-bar.icon-validation-cross::before {
    background: $color-blue;
    font-size: 15px;
    padding: 3px;
    color: $color-white;
    border-radius: 50%;
  }
}

@media (min-width: $grid-bp-md) {
  .product-filter-item-title .complementary-info {
    display: block;
  }

  .product-filter {
    margin-right: 15px;
  }

  .product-filter-container {
    grid-template-columns: 1fr 3fr;
  }

  .product-filter-products-container .product-filter-categories-title,
  .product-filter-products-container .product-filter-accordion-title {
    @include font-size-and-line-height(28px, 32px);
  }

  .product-filter-products-container .text-content {
    margin-left: -10px;
  }

  .product-filter-scroll {
    height: unset;
    position: sticky;
    top: 70px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .product-filter-scroll {
    scrollbar-width: thin;
    scrollbar-color: $filter-scrollbar-color $color-white;
  }

  .product-filter-scroll::-webkit-scrollbar {
    width: 5px;
  }

  .product-filter-scroll::-webkit-scrollbar-track {
    background: $color-white;
  }

  .product-filter-scroll::-webkit-scrollbar-thumb {
    background-color: $filter-scrollbar-color;
    border-radius: 10px;
  }

  .product-filter-container.product-filter-container--four-column {
    grid-template-columns: 1fr;
  }

  .product-filter-container .product-list .component-product-box {
    width: calc(33.33333% - 20px);
  }

  .product-filter-products-container--four-column .product-list .component-product-box {
    width: calc(25% - 20px);
  }

  .product-filter-container .product-box-view-details {
    width: 200px;
  }

  .product-filter-mobile-bar {
    display: none;
  }

  .product-filter-single-apply {
    display: block;
    margin-top: 40px;
  }

  .product-filter-items {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .product-filter-item {
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .product-filter-icon::before {
    line-height: 1;
    vertical-align: middle;
    color: $product-filter-arrow-color;
    font-size: 14px;
  }

  .product-filter--is-active {
    position: static;
  }

  .product-filter--is-active .product-filter-items {
    max-height: 100%;
    overflow-y: initial;
  }

  .product-filter--is-active .product-filter-items {
    display: flex;
  }

  .product-filter-accordion-checkbox-wrapper .complementary-info,
  .product-filter-footer {
    display: none;
  }

  .product-filter-all-filters {
    display: flex;
    color: $logo-color;
    font-size: 18px;
    font-family: $fontfamily-regular-bold;
    border: none;
    background-color: transparent;
    padding-bottom: 4px;
    padding-top: 5px;
    margin-bottom: 4px;
  }

  .product-filter-item .icon-arrow-down {
    padding-top: 0;
  }

  .product-filter-item-title,
  .product-filter-title {
    color: $home-color;
    font-weight: 400;
    font-family: $fontfamily-regular;
  }

  .product-filter-item-title {
    @include font-size-and-line-height(16px, 22px);

    margin: 15px 0 10px;
  }

  .product-filter-title {
    @include font-size-and-line-height(22px, 28px);

    display: block;
    margin-bottom: 30px;
  }

  .product-filter-accordion-item {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $product-filter-border-color;
    margin-right: 20px;
  }

  .svg-all-filters-icon {
    fill: $logo-color;
    width: 20px;
    margin-left: 10px;
  }

  .product-filter-applied-list .product-filter-btn {
    display: none;
  }

  .btn.product-filter-reset-all {
    margin-top: 30px;
  }

  @if ($theme-name == 'cutimed') {
    .product-filter-number-of-products {
      margin-right: 10px;
      margin-top: -50px;
      text-align: right;
    }

    .btn.product-filter-reset-all {
      margin-top: 10px;
      margin-left: 0;
    }

    .product-filter {
      min-width: 290px;
    }

    .product-filter-container {
      gap: 25px;
    }

    .product-filter-scroll {
      background-color: $color-light-blue-2;
      border: 1px solid $color-blue;
      padding: 15px;
    }

    .product-filter-scroll::-webkit-scrollbar-track {
      background: $color-light-blue-2;
    }

    .product-filter .product-filter-title {
      margin-bottom: 10px;
    }

    .product-filter-items {
      margin-left: 15px;
    }

    .product-filter-item .product-filter-accordion-item {
      margin-right: 0;
      background-color: $color-light-blue-2;
      border-bottom: 1px solid $color-blue;
    }

    .product-filter-item-active {
      margin-bottom: 5px;
    }

    .product-filter-item-img {
      max-width: 100px;
    }

    .product-filter-container .product-list .component-product-box .title {
      @include font-size-and-line-height(28px, 32px);
    }
  }

  @if ($theme-name == "cgr") {
    .product-filter-accordion-title {
      letter-spacing: 3px;
    }
  }
}

@if ($theme-name == "actimove") {
  .product-filter-number-of-products {
    line-height: 22px;
    color: $color-black;
  }

  .product-filter-mobile-bar.icon-validation-cross::before {
    background: $segment-color;
    font-size: 15px;
    padding: 3px;
    color: $color-white;
    border-radius: 50%;
  }
}

@if ($theme-name == "women") {
  .product-filter-footer .btn.product-filter-reset-all {
    color: $color-blue;
  }

  .product-filter-mobile-bar {
    background-color: $color-blue;
  }

  .product-filter-single-active {
    @include font-size-and-line-height(16px, 22px);

    text-transform: none;
  }

  .product-filter-applied-list .product-filter-btn {
    @include font-size-and-line-height(16px, 22px);

    border: none;
    color: $applied-item-color;
  }

  .product-filter-mobile-bar.icon-validation-cross::before {
    font-size: 24px;
  }
}

@if ($theme-name == "cgr") {
  .product-filter .product-filter-title {
    color: $color-primary-black;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .product-filter--is-active .product-filter-toggle-label {
    @include font-size-and-line-height(22px, 28px);

    color: $product-filter-accordion-title-color;
    font-family: $fontfamily-regular;
    font-weight: 600;
  }

  .product-filter--is-active .product-filter-mobile-bar {
    padding: 90px 30px 20px 30px;
  }

  .product-filter--is-active .product-filter-items {
    padding-left: 30px;
    padding-right: 30px;
  }

  .product-filter-item-title,
  .product-filter--is-active .product-filter-item-title {
    @include font-size-and-line-height(16px, 22px);

    color: $product-filter-item-title-color;
    font-family: $fontfamily-regular;
    font-weight: 400;
  }

  .product-filter--is-active .product-filter-item {
    border: unset;
    border-bottom: 1px solid $product-filter-accordion-item-border;
    padding-left: 0;
    padding-right: 0;
  }

  .product-filter--is-active .icon-arrow-down.product-filter-icon {
    color: $segment-color;
  }

  .product-filter-products-container .text-content p,
  .product-filter-products-container .text-content ul li {
    color: $color-primary-black;
  }
}

@if ($theme-name == 'jobst') {
  .product-filter-products-container .product-filter-categories-title {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold;
    border-bottom: none;
  }

  .product-filter-products-container .product-filter-accordion-title {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-regular-bold;
  }

  .product-filter-products-container .Button--AccordionWrap .Button--Accordion {
    background-color: $color-light-25;
  }

  .product-filter-mobile-bar {
    background-color: $color-primary-light;
  }

  .product-filter--is-active .product-filter-mobile-bar {
    background-color: $color-light-25;
  }

  .product-filter-applied-list .product-filter-btn {
    background-color: $color-light;
    border-bottom-right-radius: 8px;
    color: $color-gray;
  }

  .product-filter-accordion-item-container {
    display: flex;
    align-items: center;
  }

  .product-filter-accordion-circle {
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    border: 1px solid $color-gray-50;
    margin-right: 10px;
  }

  .product-filter-accordion-square {
    display: block;
    width: 48px;
    min-width: 48px;
    height: 40px;
    border-bottom-right-radius: 20px;
    margin-right: 10px;
  }

  .product-filter-accordion-label {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
    margin-bottom: 0;
  }

  .product-filter-accordion-checkbox-wrapper {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .product-filter-accordion-input-and-label {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .accordion-content {
    padding-bottom: 15px;
  }

  .product-filter-accordion-item-image {
    height: 60px;
    margin-right: 10px;
  }

  .product-filter-item-title .complementary-info {
    transition: 0.3s;

    &:hover {
      background-color: $color-primary-light;
      border-radius: 100%;

      .complementary-info-circle {
        fill: $color-primary-light;
      }

      .complementary-info-letter {
        fill: $color-white;
      }
    }
  }

  @media (min-width: $grid-bp-md) {
    .product-filter-products-container .product-filter-categories-title {
      @include font-size-and-line-height(40px, 44px);
    }

    .product-filter-products-container .product-filter-accordion-title {
      @include font-size-and-line-height(28px, 32px);
    }
  }

  @media (max-width: $grid-bp-md) {
    .product-filter-item--is-active {
      border: 1px solid $color-primary-light;
      border-radius: 3px;
    }
  }
}
