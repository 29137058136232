.sample-container .crate {
  border: none;
  background-color: unset;
}

.sample-container .crate .crate-content {
  margin: unset;
}

.sample-container form:not(.form) {
  display: grid;
  row-gap: 24px;

  @media (min-width: $grid-bp-sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 15px;
  }
}

.sample-container .product-box-container {
  padding: unset;
  height: 100%;
}

.sample-container .product-box-container .component-product-box:last-of-type {
  margin: unset;
}

.sample-product-box {
  cursor: pointer;

  @if ($theme-name == "cutimed") {
    padding: 24px;
    background-color: $color-white;
    border: 2px solid $color-light-blue-2;
  } @else {
    padding: 16px 8px;
    background-color: $product-box-sample-background;
    border-radius: 8px;
  }
}

.sample-product-box .product-box {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sample-product-box .product-box-content {
  overflow: hidden;
  width: 100%;
}

.sample-product-box .product-box-text {
  text-align: left;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: 60px;
}

.sample-product-box .product-box-content .title {
  @include font-size-and-line-height(16px, 22px);

  font-family: $fontfamily-regular-bold;
  margin-top: 16px;
  margin-bottom: unset;
}

.sample-product-box .product-box-text .text {
  @include font-size-and-line-height(16px, 22px);

  font-family: $fontfamily-regular;
  color: $product-box-sample-text-color;
  min-height: unset;
  margin-top: 8px;
  margin-bottom: 16px;
}

.sample-product-box .product-box-text .text p {
  color: unset;
  margin-bottom: unset;
}

.product-box-sample-details {
  display: flex;
  margin-bottom: 8px;
}

.product-box-sample-details-img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.sample-product-box .product-box-sample-details-label,
.sample-product-box .product-box-sample-details-value {
  @include font-size-and-line-height(16px, 22px);

  margin-bottom: 0;
}

.sample-product-box .product-box-sample-details-value {
  font-family: $fontfamily-regular-bold;
  margin-left: 8px;
}

.sample-product-box .sample-order-product-size-container {
  margin-top: 16px;
  margin-bottom: unset;

  @media (min-width: $grid-bp-sm) {
    margin-top: 38px;
  }
}

.sample-product-box .sample-order-product-size-container .field-label {
  @include font-size-and-line-height(16px, 22px);

  font-family: $fontfamily-regular;
  margin-bottom: 8px;
}

.sample-product-box .sample-box-btn {
  text-align: center;
}

.sample-product-box .sample-box-btn .btn {
  @include font-size-and-line-height($product-box-sample-button-font-size, $product-box-sample-button-line-height);

  font-family: $product-box-sample-button-font-family;
  width: 100%;
  min-height: 40px;

  @if ($theme-name == "men") {
    padding: 10px 18px 10px;
    color: $color-white;
    background-color: $color-blue-dark;
  } @else if($theme-name == "cgr") {
    padding: 10px 18px 10px;
  } @else {
    padding: 8px 18px 8px;
  }

  span {
    @if ($theme-name == "men") {
      @include arrowIcon($color-white);

      &:after {
        right: auto;
        margin-left: 16px;
      }
    }

    &:after {
      @if ($theme-name == "women" or $theme-name == "cgr") {
        font-family: "Icomoon";
        content: "\e605";
        position: absolute;
        right: auto;
        margin-left: 16px;
        font-weight: lighter;
        font-size: 14px;
      }
    }
  }

  @if ($theme-name == "men") {
    &::before {
      background-color: $color-blue-dark;
    }
  }
}

.sample-container .txtArea {
  width: 100%;
}

.sample-loading-active {
  overflow: hidden;
}

.sample-loading-active .body,
.sample-loading-active .header-container {
  position: unset;
}

.sample-loading-active .footer-frost,
.sample-loading-active .header-container,
.sample-loading-active .Breadcrumbs-container {
  display: none;
}

.sample-loading-active .sample-loading {
  display: block;
}

.sample-loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  overflow: hidden;
  background-color: $color-white;
  background-size: cover;
  background-position: center;

  @if ($theme-name == "women" or $theme-name == "men" or $theme-name == "cgr") {
    background-image: url($sample-loading-bg-image);
  }

  @if ($theme-name == "cutimed") {
    background-color: $color-blue;
  }
}

.sample-loading-img {
  @include rotate();

  margin-bottom: 15px;
}

.sample-loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 390px;
  padding: 0 24px;

  @media (min-width: $grid-bp-xs) {
    max-width: 346px;
  }

  @media (min-width: $grid-bp-sm) {
    max-width: 574px;
    padding: 0;
  }

  @media (min-width: $grid-bp-md) {
    max-width: 772px;
  }
}

.sample-loading-subtitle {
  @include font-size-and-line-height(16px, 22px);

  color: $color-white;

  @if ($theme-name == "women") {
    letter-spacing: 4px;
  }

  @if ($theme-name == "women" or $theme-name == "men" or $theme-name == "cgr") {
    text-transform: uppercase;
  }
}

.sample-loading-title {
  color: $color-white;

  @if ($theme-name == "women" or $theme-name == "men" or $theme-name == "cgr" or $theme-name == "cutimed") {
    @include font-size-and-line-height($sample-loading-title-font-size, $sample-loading-title-line-height);
  }

  @if ($theme-name == "men" or $theme-name == "cgr") {
    text-transform: uppercase;
  }

  @if ($theme-name == "men") {
    font-family: $sample-order-loading-title;
    letter-spacing: -1px;
  }

  @if ($theme-name == "cutimed") {
    font-family: $fontfamily-regular-bold;
  }
}

.sample-confirm-description {
  font-family: $fontfamily-regular;
}

.sample-confirm-btn-group {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 24px;

  @media (min-width: $grid-bp-sm) {
    flex-direction: row;
    justify-content: right;
  }
}

.sample-confirm-btn-group .btn.btn-confirm-contact {
  @include btn("primary-light");

  @if ($theme-name == "cutimed") {
    background-color: $color-white;
    border: 2px solid $color-gray;
    color: $color-gray;
    border-radius: 3px;
  }

  @if ($theme-name == "men") {
    font-family: $fontfamily-regular;
  }
}

.sample-confirm-btn-group .btn.btn-confirm-contact span {
  font-family: $fontfamily-regular-bold;

  @if ($theme-name == "men") {
    font-family: $fontfamily-regular;
  }
}

.sample-confirm-btn-group .btn.btn-confirm-return {
  @include btn("primary");
  margin-bottom: 16px;

  @if ($theme-name == "women") {
    border: 1px solid $color-blue;
  }

  @if ($theme-name == "cutimed") {
    color: $color-white;
  }

  @media (min-width: $grid-bp-sm) {
    margin-left: 24px;
    margin-bottom: 0;
  }
}

.sample-confirm-btn-group .btn.btn-confirm-return span {
  font-family: $fontfamily-regular-bold;

  @if ($theme-name == "men") {
    font-family: $fontfamily-regular;
  }
}

.wrapper:has(.sample-confirm-container--bg-dark) {
  margin-bottom: 0;
}

.section:has(.sample-confirm-container--bg-dark) {
  background-color: $color-heading;
  margin-bottom: 0;
  padding-bottom: 20px;

  .component-intro .intro-content .intro-text-content .intro-pretitle,
  .component-intro .intro-content .intro-text-content .intro-title,
  .sample-confirm-description,
  .section-title,
  .sample-info p {
    color: $color-white;
  }

  .btn.btn-confirm-contact {
    background-color: $color-white;
    color: $color-white;

    &::before {
      background-color: $color-heading;
    }

    &::after {
      color: $color-white;
    }
  }

  .btn.btn-confirm-return {
    background-color: $color-white;
    color: $color-heading;

    &::before {
      background-color: $color-white;
    }

    &::after {
      color: $color-heading;
    }
  }

  .sample-ordered-product--border {
    border: 1px solid $sample-ordered-product-border-color;
    border-radius: 8px;
    padding: 24px;
  }

  .sample-ordered-product-order-number {
    letter-spacing: normal;
  }
}

.sample-ordered-product--border {
  background-color: $sample-ordered-product-bg-color;
  padding: 24px;
  border-radius: 8px;
}

.sample-confirm-note {
  margin-top: 40px;
  padding: 40px 20px;
  border-top: 1px solid $sample-ordered-note-border-color;
  border-bottom: 1px solid $sample-ordered-note-border-color;

  @media (min-width: $grid-bp-sm) {
    padding: 40px 90px;
  }
}

.sample-confirm-note-text {
  @include font-size-and-line-height(28px, 32px);

  text-align: center;

  @if ($theme-name == 'men') {
    color: $color-white;
    font-family: $fontfamily-titles-thin;
    text-transform: uppercase;
  }

  @if ($theme-name == 'cutimed') {
    color: $color-gray;
    font-family: $sample-ordered-note-font-family;
  }
}

.sample-confirm-note-info {
  @include font-size-and-line-height(16px, 22px);

  display: block;
  text-align: center;

  @if ($theme-name == 'men') {
    color: $color-white;
  }

  @if ($theme-name == 'cutimed') {
    color: $color-gray;
    font-family: $fontfamily-regular;
  }
}

.sample-ordered-product {
  margin-bottom: 40px;
}

.sample-ordered-product-container {
  display: flex;
  border-bottom: 1px solid $sample-ordered-product-border-color;
  padding-bottom: 15px;
}

.sample-ordered-product-img-container {
  padding: 0 16px;
}

.sample-ordered-product-img {
  min-height: 100px;
  max-height: 120px;
}

.sample-ordered-product-content {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

.sample-ordered-product-content-title {
  @include font-size-and-line-height(16px, 22px);

  font-family: $fontfamily-regular-bold;
}

.sample-ordered-product-title {
  @include font-size-and-line-height(16px, 22px);

  display: block;
  font-family: $fontfamily-regular;
  text-transform: uppercase;

  @if ($theme-name == 'men') {
    color: $color-white;
  }

  @if ($theme-name == 'women') {
    letter-spacing: 4px;
  }

  @if ($theme-name == 'cutimed') {
    font-family: $sample-ordered-product-title-font-family;
    letter-spacing: 4px;
    color: $sample-ordered-product-title-color;
  }

  @media (min-width: $grid-bp-md) {
    display: none;
  }
}

.sample-ordered-product-order-number {
  @include font-size-and-line-height(16px, 22px);

  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: 15px;

  @if ($theme-name == 'cutimed') {
    text-transform: initial;
    letter-spacing: initial;
  }
}

.sample-ordered-product-content-size {
  font-family: $fontfamily-regular;
}

.sample-ordered-product-content-size strong,
.sample-ordered-product-order-number strong,
.sample-confirm-description strong {
  font-family: $fontfamily-regular-bold;

  @if ($theme-name == 'cutimed') {
    font-family: $fontfamily-helveticaNow-bold;
  }
}

.sample-ordered-product-order-number strong {
  letter-spacing: normal;
}

.sample-container .component-intro .intro-content .intro-text-content .intro-title {
  @include font-size-and-line-height(40px, 44px);
  color: $home-color;

  @if ($theme-name == "men") {
    color: $color-white;
  }

  @if ($theme-name == "women") {
    @include font-size-and-line-height(48px, 48px);
  }

  @if ($theme-name == "cutimed") {
    @include font-size-and-line-height(48px, 48px);
    color: $color-gray;
  }
}

.sample-container .field.field-input,
.sample-container .select2 {
  height: 38px;
  border: 1px solid $input-sample-border;
  border-radius: 4px;
}

.sample-container .validate.invalid.validate-select .select2-selection {
  height: 38px;

  @if ($theme-name == "cutimed") {
    height: 36px;
  }
}

.sample-container .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.sample-container .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.sample-container .field.field-textarea {
  border: 1px solid $input-sample-border;
  border-radius: 4px;
}

.sample-container .select2-container .select2-selection--single {
  height: 100%;
}

.sample-container .validate.invalid .icon-validation-cross {
  top: 50%;
  transform: translateY(-50%);
}

.sample-container .field-checkbox-container {
  display: flex;
  align-items: center;
}

.sample-container .field-checkbox-container a {
  text-decoration: underline;
}

.sample-container .product-box-container .component-product-box .product-box-content,
.sample-container .product-box-container .component-product-box,
.sample-container .product-box-container,
.sample-container .product-box-img {
  min-height: initial;
}

.sample-container .component-product-box {
  width: 100%;
}

.sample-container .product-box-content {
  flex-direction: row;
}

.sample-container .product-box-container-image-left .product-compact .product-box-text {
  text-align: left;
}

.sample-container .product-box-img img {
  max-height: 120px;

  @media (min-width: $grid-bp-md) and (max-width: $container-less-than-max) {
    max-height: 100px;
  }

  @if ($theme-name == "cutimed") {
    max-width: 150px;
    max-height: initial;
    min-height: 150px;
  }
}

.sample-container .product-box-img {
  padding: 0 16px;
}

.sample-container .product-box-content .select2 {
  max-width: 100px;
}

.sample-container .select2 .select2-selection__arrow b,
.sample-container .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border: solid $sample-selection-border-color;
  border-width: 0 1px 1px 0;
  padding: 3px;
  margin-top: 0;
}

.sample-container .select2 .select2-selection__arrow b {
  transform: rotate(45deg) translateY(-50%);
}

.sample-container .product-box-container-image-left .title,
.sample-container .product-box-text .field-label {
  @include font-size-and-line-height(16px, 22px);
}

.sample-container .sample-order-product-list .product-box-content {
  flex-direction: column;
}

.sample-selected-info-container .sample-order-aside-img {
  width: 100%;
}

.sample-selected-info-container .sample-order-category {
  padding: 24px;
  text-align: center;
  background-color: $sample-order-category-bg-color;

  @if ($theme-name == 'men' or $theme-name == 'cutimed') {
    text-align: left;
  }
}

.sample-selected-info-container .sample-order-category-text {
  @include font-size-and-line-height(28px, 32px);

  color: $sample-order-category-text-color;
  font-family: $sample-order-category-text-fontfamily;

  @if ($theme-name == 'men') {
    text-transform: uppercase;
  }
}

.sample-selected-info-container .sample-order-category .btn {
  width: 100%;
  background-color: $sample-order-category-btn-bg-color;
  text-decoration: none;

  &::before {
    background-color: $sample-order-category-btn-bg-color;
  }
}

.sample-selected-info-container .section-title {
  border-bottom: none;
  margin-bottom: 9px;
  text-transform: unset;
  color: $sample-order-section-title;
  letter-spacing: normal;

  &::after {
    display: none;
  }
}

.sample-selected-info-container .sample-order-category .btn .icon-button-right {
  &::before {
    color: $sample-order-category-icon-color;
  }
}

.sample-selected-info-container .sample-order-category .btn span {
  @include font-size-and-line-height(16px, 22px);

  color: $sample-order-category-btn-color;
  font-family: $sample-order-category-btn-fontfamily;
  font-weight: normal;
  text-decoration: none;
}

.sample-container .sample-order-product-list .select2 {
  max-width: 100%;
}

.sample-container .product-box-text .field-label {
  font-family: $fontfamily-regular;
}

.sample-container .product-box-container-image-left .title {
  margin-bottom: 16px;
}

.sample-container .para_align_text {
  padding-left: 10px;
}

.sample-container .field.field-checkbox {
  width: 24px;
  height: 24px;
  border-radius: unset;
  border: 1px solid $input-sample-border;
}

.sample-container .form {
  margin-top: 40px;
}

.sample-container .form .form-field .field-label {
  @include font-size-and-line-height(16px, 22px);

  color: $color-black;
}

.sample-container .field.field-checkbox label a {
  text-decoration: underline;
}

.sample-newsletter {
  padding: 24px;
  background-color: $sample-newsletter-bg;
}

.sample-newsletter-title {
  text-transform: uppercase;
  color: $sample-newsletter-title-color;
  letter-spacing: $sample-newsletter-title-letter-spacing;
}

.sample-newsletter .field-checkbox,
.sample-newsletter .field-checkbox-container,
.sample-newsletter .form-field,
.sample-newsletter .component-form {
  margin: 0;
  padding: 0;
}

.sample-newsletter .sample-newsletter-content .field-checkbox-container label {
  @include font-size-and-line-height(16px, 22px);
}

.sample-selected-info {
  padding: 16px;
  background-color: $sample-selected-info-bg-color;
  z-index: 1;
  margin-bottom: 40px;
  width: 100%;

  @media (min-width: $grid-bp-sm) {
    padding: 24px;
  }
}

.sample-container .component.component-intro {
  margin-bottom: 16px;
}

@if ($theme-name == "cutimed") {
  .sample-container .section-title {
    @include font-size-and-line-height(21px, 25px);

    margin-top: 50px;
    border: none;
  }

  .sample-container .sample-selected-info {
    border: unset;
  }

  .sample-product-box .product-box-content .title {
    @include font-size-and-line-height(28px, 32px);

    margin-top: 24px;
  }

  .sample-container .intro-subtitle p {
    font-family: $fontfamily-regular-bold;
    margin-bottom: 24px;
  }
  .sample-selected-info-container .section-title {
    @include font-size-and-line-height(28px, 32px);

    border: none;
    padding-bottom: 0;
  }

  .sample-selected-info-container .sample-info .section-title {
    border: none;
    margin-top: 0;
  }

  .sample-selected-info {
    border: 1px solid $border-color-dropwdown;
    border-radius: 3px;
    margin-bottom: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sample-selected-info .section-title {
    border: none;
    text-align: center;
  }

  .sample-selected-info .component-product-box .title {
    @include font-size-and-line-height(16px, 25px);

    font-family: $fontfamily-regular-bold;
  }

  .sample-selected-info .component-product-box {
    border: none;
  }

  .sample-selected-info .product-box-img {
    margin-bottom: 25px;
  }

  .sample-selected-info .product-box.product-compact .product-box-text {
    text-align: left;
    margin-bottom: 20px;
  }

  .sample-container .form .select2-container--default .select2-selection--single {
    border: unset;
  }

  .sample-container .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px;
  }

  .sample-container .product-box-select-btn input:checked + label {
    color: $color-white;
    background: $color-blue;
    border-color: $color-blue;
  }

  .sample-container .question-box-submit .btn {
    min-width: 180px;
    padding: 10px 15px;
  }

  .sample-container .sample-previous-btn i {
    display: none;
  }

  .sample-newsletter .sample-newsletter-content {
    display: flex;
    flex-direction: row-reverse;
  }

  .sample-newsletter .sample-newsletter-text {
    padding-left: 10px;
  }

  .sample-newsletter .sample-newsletter-title {
    display: none;
  }

  .sample-newsletter .sample-newsletter-content .field-checkbox-container .para_align_text {
    display: none;
  }

  .sample-newsletter .component-form {
    margin-top: 3px;
  }

  .sample-container .sample-form-footer .btn.sample-previous-btn,
  .sample-container .sample-form-footer .btn.sample-previous-btn:hover {
    background-color: transparent;
    color: $color-pink;
    border-color: $color-pink;
  }

  .sample-container .btn.field-submit {
    text-align: center;
  }

  @media (min-width: $grid-bp-md) {
    .sample-selected-info {
      padding-bottom: 0;
    }

    .sample-selected-info-container .sample-info {
      padding: 0 30px;
    }

    .sample-container .validate.field-checkbox-container {
      padding: 0;
    }

    .sample-container .field-label {
      margin-bottom: 5px;
    }

    .form-order-sample {
      margin-left: -10px;
    }
  }
}

@if ($theme-name == "cgr") {
  .sample-container .component-intro .intro-content .intro-text-content .intro-title {
    color: $color-heading;
    font-weight: 600;
  }

  .sample-container .crate .crate-content .crate-title {
    @include font-size-and-line-height(18px, 22px);

    color: $color-black;
    font-weight: 700;
  }

  .crate {
    background: transparent;
    border-color: $sample-product--border-color;
  }

  .crate .product-box-container-image-left .title {
    @include font-size-and-line-height(16px, 22px);

    font-weight: 700;
  }

  .crate .product-box-text p {
    @include font-size-and-line-height(16px, 22px);
  }

  .sample-info a,
  .para_align_text a {
    text-decoration: underline;
    font-weight: 600;
  }

  .sample-selected-info-container a {
    text-decoration: underline;
    font-weight: 600;
    color: $segment-color;
  }

  .sample-selected-info-container a {
    &:hover {
      color: $cta-bg-hover;
    }
  }

  .sample-box-btn .icon-button-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .sample-box-btn .btn span {
    padding-right: 30px;
  }

  .crate .selection {
    width: 100%;
  }

  .sample-container .section-title {
    @include font-size-and-line-height(22px, 28px);

    border: none;
  }

  .sample-container .section-title::after {
    background-image: none;
  }
}

@if ($theme-name == "women") {
  .product-box-container-image-left .title {
    @include font-size-and-line-height(16px, 22px);

    color: $color-black;
  }

  .sample-container .section-title {
    color: $color-black;
  }

  .sample-box-btn .icon-button-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .sample-box-btn .btn {
    @include btn("secondary");
  }

  .sample-box-btn .btn span {
    padding-right: 30px;
  }

  .sample-selected-info-container a {
    text-decoration: underline;
    font-family: $fontfamily-regular;
    font-weight: normal;
    color: $color-primary-black;

    &:hover {
      color: $color-primary-black;
    }
  }

  .sample-container .sample-form-footer .btn.sample-previous-btn:hover {
    background-color: transparent;
    color: $color-blue;
  }

  .sample-container .section-title {
    @include font-size-and-line-height(22px, 28px);

    color: $color-primary-black;
    border: none;
  }
}

@if ($theme-name == "men") {
  .sample-container .component-intro .intro-content .intro-text-content .intro-title {
    color: $color-heading;
  }

  .sample-container .field-submit {
    span {
      color: $color-white;
    }

    &::before {
      background-color: $color-blue-dark;
    }

    &::after {
      color: $color-white;
    }
  }

  .sample-container:has(.sample-confirm-container--bg-dark) div,
  .sample-container:has(.sample-confirm-container--bg-dark) p,
  .sample-container:has(.sample-confirm-container--bg-dark) a,
  .sample-container:has(.sample-confirm-container--bg-dark) h2,
  .section:has(.sample-confirm-container--bg-dark) .sample-aside-container p,
  .section:has(.sample-confirm-container--bg-dark) .sample-aside-container div {
    color: $color-white;
  }

  .section:has(.sample-confirm-container--bg-dark) .sample-aside-container a {
    color: $color-white;
    font-family: $fontfamily-regular-bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .sample-container .section-title {
    @include font-size-and-line-height(22px, 28px);

    border: none;
  }
}