.crate {
    @include clearfix();

    @if ($theme-name == "cgr" or $theme-name == "women") {
        border-radius: 0;
    } @else {
        border-radius: 5px;
    }

    @if ($theme-name == "com") {
      border: 1px solid $crate-border-color;
    } @else if ($theme-name == "cutimed" or $theme-name == "actimove") {
      border: none;
    } @else {
      border: 1px solid $border-color;
    }

    background: $crate-background-color;
    word-break: break-word;

    .crate-content {
        margin: 0 25px;

        &.with-margin-sm {
            margin: 20px;
        }

        .crate-title {
            @include font-size($font-size-section-description-title);

            margin: 0;
            padding: 30px 0;

            @if ($theme-name == "com" or $theme-name == "men") {
                font-family: $fontfamily-regular-bold;
            } @else if ($theme-name == "actimove") {
                line-height: 32px;
                font-family: $fontfamily-titles-normal;
                font-style: italic;
                padding-bottom: 20px;
            } @else if ($theme-name == "women") {
                @include font-size-and-line-height(18px, 22px);

                color: $color-black;
            } @else if ($theme-name == "jobst") {
                @include font-size-and-line-height(28px, 32px);

                font-family: $fontfamily-regular-bold;
                padding-bottom: 4px;
                color: $color-primary;
            } @else {
                font-family: $fontfamily-regular-light;
            }

            &.no-top-padding {
                padding-top: 0;
            }
        }

        @if ($theme-name == "cutimed") {
          color: $color-gray;

          a {
            color: $color-blue;
          }

          @media (min-width: $grid-bp-md) {
            h2 {
              font-family: $fontfamily-regular-bold;
              font-size: 28px;
              line-height: 32px;
            }
          }
        }
      }

    &.top-padding {
        padding-top: 20px;
    }

    &.bottom-padding {
        padding-bottom: 20px;
    }

    &.crate-transparent {
        background: transparent;
    }

    &.crate-white {
        background: #fff;
    }

    &.crate-bg-alt {
        background: $tabs-background;
    }

    &.crate-dark {
        background: $accent-color;
        color: #fff;
    }

    &.manual-padding {
        @include mediaquery(xs) {
            padding: 15px;

            &.with-col {
                padding: 15px 10px;
            }

            &.small-padding {
                padding: 10px;

                &.with-col {
                    padding: 10px 5px;
                }
            }

            &.medium-padding,
            &.large-padding {
                padding: 20px;

                &.with-col {
                    padding: 20px 10px;
                }
            }
        }
        padding: 25px;

        &.with-col {
          @if ($theme-name != "cutimed") {
            padding: 25px 15px;
          } @else {
            padding: 25px 15px 25px 0;
          }

          @if ($theme-name == "jobst") {
            padding: 24px;

            @media (min-width: $grid-bp-md) {
              padding: 40px;
            }
          }
        }

        &.small-padding {
            padding: 15px;

            &.with-col {
                padding: 15px 5px;
            }
        }

        &.medium-padding {
            padding: 30px;

            &.with-col {
                padding: 30px 20px;
            }
        }

        &.large-padding {
            padding: 40px;

            &.with-col {
                padding: 40px 30px;
            }
        }

        .crate-title {
            padding-top: 10px;
        }

        .crate-content,
        p:last-child {
            margin: 0;
        }
    }

    &.no-border {
        border: none;
    }
}

.crate.crate-newsletter-signup {
  padding-bottom: 0;
  padding-top: 40px;
  @media (min-width: $grid-bp-md) {
    padding-bottom: 15px;
    padding-top: 40px;
  }
}

.crate.crate-newsletter-signup .crate-title {
  font-size: 28px;
  line-height: 32px;
  padding-bottom: 15px;

  @media (min-width: $grid-bp-md) {
    font-size: 40px;
    line-height: 44px;
  }
}

@if $theme-name == "leukoplast" {
  .crate.leukoplast-form-crate.with-col,
  .crate.crate-newsletter-signup {
    background: transparent !important;
    border: none;
    padding-bottom: 0;
		padding-left: 0;

		@media (max-width: $grid-bp-sm-max) {
			padding-right: 0;
		}
  }

  .crate.crate-newsletter-signup {
    padding-top: 0;
	}

	.leukoplast-form-crate {
		margin-top: 20px;
	}

  .leukoplast-form-crate .form,
  .crate.crate-newsletter-signup,
  .crate.crate-newsletter-signup .form {
    margin-left: -5px;
    margin-right: -5px;

    @media (min-width: $grid-bp-xs) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

@if ($theme-name == "cutimed") {
  .form-confirmation {
    background-color: $color-white;
    border: 1px solid $border-color-dropwdown;
    border-radius: 3px;
    margin-left: 10px;
    padding: 25px;
  }

  // Negative values ​​are used to anulate paddings
  .crate.manual-padding.crate-newsletter-signup {
    padding-left: 0;
    padding-right: 0;
    margin-left: -5px;

    @media (min-width: $grid-bp-xs) {
      margin-left: -10px;
    }
  }
}

@if ($theme-name == "actimove") {
  .crate {
    border-radius: 0;
  }

  .crate-content .section-title {
    @include font-size-and-line-height(28px, 32px);

    padding-left: 10px;
    padding-right: 10px;
    border-bottom: none;
  }

  .form-confirmation h2 {
    @include font-size-and-line-height(22px, 26px);

    font-style: italic;
  }

  .form-confirmation p {
    color: $color-light-black;
  }

  .form-confirmation p a {
    position: relative;
    padding: 8px 45px 8px 15px;
    border: 1px solid $color-primary;
    display: inline-block;
    font-weight: 600;
    min-width: 185px;
    min-height: 40px;
    text-align: center;

    @media (max-width: $grid-bp-xs-max) {
      width: 100%;
    }

    &:after {
      content: $icon-short-arrow-right;
      font-family: $fontfamily-icon;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $color-primary;
      right: 25px;
    }

    &:hover {
      border-color: $color-accent;
      color: $color-accent;
      text-decoration: none;

      &:after {
        color: $color-accent;
      }
    }
  }
}

@if ($theme-name == "jobst") {
  .crate {
    background-color: $contas-us-form-background;
    border: none;
    border-radius: 0;
  }

  .form-confirmation h2 {
    @include font-size-and-line-height(28px, 32px);

    font-family: $fontfamily-regular-bold;
    margin-bottom: 24px;
  }

  .form-confirmation p {
    @include font-size-and-line-height(18px, 26px);

    color: $color-gray;
  }

  .form-confirmation p a {
    @include btn("secondary");

    text-align: center;

    &::after {
      right: unset;
      margin-left: 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}