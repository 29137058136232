@if $theme-name == "leukoplast" {
  .MdrAccordion-container {
    background-color: transparent !important;
    transition: none;
  }

  .MdrAccordion-container .accordion-title {
    @include font-size($font-size-section-title);

    background-color: transparent;
    color: inherit;
    padding-left: 0;
    padding-right: 0;
    pointer-events: none;
  }

  .MdrAccordion-container .accordion-content {
    display: block !important;
    padding: 0;
    transition: none;
  }

  .MdrAccordion-container .icon-arrow-down {
    display: none;
  }

  .MdrAccordion-container h4 {
    font-weight: bold;
  }

  .MdrDetails-container {
    padding-bottom: 0 !important;
    transition: none !important;
  }
} @else {
  .MdrAccordion-container {
    margin-top: 30px;
  }
}

.MdrDetails-container {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  padding-top: 20px !important;

  @media (min-width: $grid-bp-md) {
    align-items: flex-start;
    flex-direction: row;
    padding-bottom: 20px;
    padding-top: 0 !important;
  }
}

.MdrDetails {
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--no-text {
    flex: 0 1 auto;
  }

  @media (min-width: $grid-bp-md) {
    margin-bottom: 0;
  }
}

.MdrItem {
  display: inline-block;

  @media (min-width: $grid-bp-md) {
    padding-right: 20px;
  }
}

.MdrItem,
.MdrIcon {
  float: left;
}

.MdrIcon {
  align-self: flex-start;
  height: auto;
  margin-right: 30px;
}

.icon-CE {
  width: 40px;
}

.icon-MD {
  width: 35px;
}

.icon-singleUse {
  width: 30px;
}

.icon-visitorAddress {
  width: 25px;
}

.icon-manufacturerAddress {
  width: 30px;
}

@if ($is-tena-theme) {
  .product-intro-container-frost .MdrAccordion-container .section {
    width: 100%;

    @if ($theme-name == "actimove") {
      // This value is added to anulate the container and col-12 padding
      margin-left: -15px;

      @media (min-width: $grid-bp-xs) {
        margin-left: -30px;
      }
    }
  }

  .product-intro-container-frost .MdrAccordion-container {
    margin-top: 0;
  }

  .product-intro-container-frost .accordion-content-inner.MdrDetails-container {
    flex-direction: column;
    overflow: hidden;
    word-break: break-word;

    @if ($theme-name == "actimove") {
      padding-bottom: 0;
    } @else {
      padding-bottom: 10px;
    }
  }

  .product-intro-container-frost .icon-arrow-down,
  .product-intro-container-frost .icon-arrow-up {
    color: $product-intro-accordion-arrow;
  }

  .product-intro-container-frost .MdrDetails {
    margin-bottom: 20px;
    width: 100%;
  }

  .product-intro-container-frost .MdrItem {
    padding-bottom: 18px;
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid $product-intro-accordion-border;
  }

  .product-intro-container-frost .MdrItem .MdrItem-title {
    @include font-size-and-line-height(16px, 18px);
  }

  .product-intro-container-frost .MdrItem .MdrItem-text {
    line-height: 25px;
  }

  .product-intro-container-frost .MdrIcon {
    margin-right: 10px;
  }

  .product-intro-container-frost .icon-CE,
  .product-intro-container-frost .icon-MD,
  .product-intro-container-frost .icon-singleUse {
    width: 40px;
  }

  .product-intro-container-frost .icon-visitorAddress,
  .product-intro-container-frost .icon-manufacturerAddress {
    width: 35px;
  }
}

@if ($theme-name == "jobst") {
  .MdrDetails-container {
    padding-top: 24px !important;
  }
}
