.article {
  @include clearfix();

  @if ($theme-name == "com") {
    h2 {
      font-family: $fontfamily-regular;
      font-size: 26px;
      margin-bottom: 13px;

      @media (max-width: $grid-bp-sm-max) {
        font-size: 24px;
      }
    }
  }

  h3 {
    @include font-size($article-h3-font-size);

    font-family: $article-title-font;
    margin: $article-h3-margin;
  }

  h4 {
    @include font-size($article-h4-font-size);
    font-family: $article-title-font;
    margin: $article-h4-margin;
  }

  @if ($theme-name == "leukoplast") {
    h1,
    h2,
    h3,
    h4 {
      font-family: $fontfamily-regular;
    }
  }
}
