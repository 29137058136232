.landing-sample {
  padding: 35px 5px 50px;
  position: relative;
}

.landing-sample a {
  font-family: $fontfamily-regular-bold;
}

.landing-sample--dark,
.landing-sample--dark a {
  color: #fff;
}

.landing-sample-bg-image {
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.landing-sample--dark .form .form-field .para_align_text a {
  @if ($theme-name == "men") {
    color: $color-yellow;
  }

  @if ($theme-name == "women") {
    color: $color-white;
    text-decoration: underline;
  }
}

.landing-sample .form-inner-button {
  margin-right: 0;
}

.landing-sample .component-form {
  margin: 50px 15px 80px;
}

.landing-sample-section {
  margin: 0 25px;
}

.landing-sample .select2-container--default .select2-selection--single {
  border-radius: 0;
  height: 32px;
}

.landing-sample .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 32px;
}

.landing-sample .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}

@media (min-width: $grid-bp-md) {
  .landing-sample-content {
    display: flex;
    flex-direction: row-reverse;
  }

  .landing-sample-section {
    align-self: center;
    flex-basis: 0;
    flex-grow: 1;
  }

  .landing-sample-section-aside {
    padding: 0 25px;
  }

  .landing-sample--confirmation .landing-sample-content {
    flex-direction: column-reverse;
    width: 50%;
  }
}

.sample-form-footer {
  display: flex;
  flex-direction: column;
}

.sample-footnotes {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: $fontfamily-regular;
}
