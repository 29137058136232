.question-box-item-title {
  @include font-size($font-size-question-box-title);
  font-family: $question-box-fontfamily;
  font-size: 16px;
  margin-bottom: $vspace-sm;
}

.question-box .btn {
  @include mediaquery(xxs) {
    float: none;
    text-align: center;
    width: 100%;
  }
}

.question-box .field {
  margin-bottom: 10px;

  &.field-radio {
    padding-right: 0;
  }
}