// Theme-specific
$theme-name: "" !default;
$is-tena-theme: true !default;
$display-shadows: false !default;

// Breakpoints
$grid-bp-xxs: 420px;
$grid-bp-xs: 570px;
$grid-bp-sm: 768px;
$grid-bp-md: 992px;
$grid-bp-lg: 1200px;
$grid-bp-xxs-max: $grid-bp-xs - 1px;
$grid-bp-xs-max: $grid-bp-sm - 1px;
$grid-bp-sm-max: $grid-bp-md - 1px;
$screen-sizes: (md $grid-bp-md, sm $grid-bp-sm, xs $grid-bp-xs, xxs $grid-bp-xxs);
$grid-class: col !default;
$grid-columns: 12 !default;
$grid-gutter: 20px !default;
$grid-gutter-xs: 10px !default;
$fixed-grid: true;
$fixed-grid-fluid-xs: true !default;
$container-padding: 20px !default;
$container-padding-xs: 10px !default;
$container-max-width: 1228px;
$container-less-than-max: $container-max-width - 1px;
$container-margin: 0 auto !default;

// Fonts
$fontfamily-fallback: Arial, Helvetica, "Microsoft Yahei", "微软雅黑", "Hiragino Sans GB",
"冬青黑体", "SimSun", "宋体", STXihei, "华文细黑", Amiri, sans-serif !default;
$fontfamily-asap: "Asap", $fontfamily-fallback !default;
$fontfamily-asap-bold: "Asap Bold", $fontfamily-fallback !default;
$fontfamily-regular: $fontfamily-asap !default;
$fontfamily-regular-italic: $fontfamily-asap !default;
$fontfamily-regular-bold: $fontfamily-asap-bold !default;
$fontfamily-regular-light: $fontfamily-asap !default;
$fontfamily-titles-normal: $fontfamily-asap !default;
$fontfamily-titles-thin: $fontfamily-asap !default;
$fontfamily-header: $fontfamily-asap-bold !default;
$fontfamily-header-thin: $fontfamily-regular-light !default;
$fontfamily-icon: "Icomoon" !default;
$intro-title-font-family:  $fontfamily-header-thin !default;
$fontfamily-roboto-regular: RobotoRegular, $fontfamily-fallback !default;

// Font sizes
$font-size-h2: (def: 24px, md: 22px) !default;
$font-size-h3: (def: 23px) !default;
$font-size-h4: (def: 16px) !default;
$font-size-header-title: (def: 24px, sm: 19px) !default;
$font-size-header-item-title: (def: 14px) !default;
$font-size-menu-item: (def: 18px) !default;
$font-size-footer-column-title: 22px !default;
$line-height-footer-column-title: 28px !default;
$font-size-footer-toolbar: 14px !default;
$font-size-footer-paragraph: 16px !default;
$font-size-sticky-menu: (def: 16px, md: 14px) !default;
$font-size-accordion-title: (def: 22px) !default;
$line-height-accordion-title: (def: 28px) !default;
$font-size-btn: (def: 16px) !default;
$font-size-cta-title: (def: 42px, md: 36px, sm: 32px) !default;
$font-size-cta-text: (def: 24px, xs: 20px) !default;
$font-size-paragraph: (def: 16px) !default;
$line-height-paragraph: (def: 22px) !default;
$font-size-paragraph-preamble: (def: 22px, md: 21px, sm: 19px) !default;
$font-size-intro-carousel-title: (def: 28px, xs: 22px) !default;
$line-height-intro-carousel-title: (def: 32px, xs: 28px) !default;
$font-size-pushbox-title: (def: 28px) !default;
$font-size-pushbox-text: $font-size-paragraph !default;
$line-height-pushbox-text: 22px !default;
$font-size-section-title: (def: 28px) !default;
$line-height-section-title: (def: 32px) !default;
$font-size-section-title-large: (def: 48px, sm: 40px, xs: 34px) !default;
$font-size-section-description-title: $font-size-h2 !default;
$font-size-items-list: (def: 22px) !default;
$line-height-items-list: (def: 28px) !default;
$font-size-table-header: (def: 16px, xs: 14px) !default;
$font-size-product-box-title: (def: 18px) !default;
$font-size-product-box-text: (def: 14px) !default;
$font-size-question-box-title: (def: 16px) !default;
$font-size-tabs: (def: 24px, sm: 20px) !default;
$font-size-form-fields: (def: 14px, xs: 16px) !default;
$font-size-form-error-message: (def: 24px) !default;
$font-size-caption-image: (def: 16px, xxs: 14px) !default;
$font-size-caption-image-header: (def: 48px, sm: 32px, xs: 24px) !default;
$line-height-caption-image-header: 1em !default;
$font-size-caption-image-subheader: (def: 16px, xxs: 14px) !default;
$line-height-caption-image-subheader: 22px !default;
$font-size-placeholder-site-search: 14px !default;
$font-size-input-search: (def: 16px) !default;
$line-height-input-search: (def: 22px) !default;
$font-size-page-title: (def: 48px, md: 48px, xs: 38px);
$font-size-preamble: (def: 22px, md: 21px, xs: 18px) !default;
$font-size-campaign-image-title-large: (def: 48px, md: 40px, sm: 34px, xs: 28px) !default;
$campaign-subtitle-font: $fontfamily-regular !default;
$font-size-boxes-title: (def: 28px, sm: 22px) !default;
$line-height-boxes-title: (def: 32px, sm: 28px) !default;
$font-size-boxes-text: (def: 16px) !default;
$line-height-boxes-text: (def: 22px) !default;
$font-size-gallery-carousel-heading: (def: 28px) !default;
$font-size-gallery-carousel-subheading: (def: 16px) !default;
$text-color: #2e2e2e !default;
$paragraph-line-height: 22px !default;
$line-height-section-title-large: $font-size-section-title-large !default;
$letter-spacing-section-title-large: -1px !default;
$line-height-cta-title: (def: 48px, sm: 32px) !default;
$product-carousel-title-font-size: 28px !default;
$product-carousel-title-line-height: 32px !default;
$gallery-carousel-heading-line-height: 32px !default;
$gallery-carousel-subheading-line-height: 22px !default;

// General Colors
$home-color: #303030 !default;
$logo-color: #003b73 !default;
$color-black: #000 !default;
$color-white: #fff !default;
$color-gray: #4a4a4a !default;
$color-light-blue: #809ea7 !default;
$color-bright-blue: #003b73 !default;
$color-bright-green: #13913e !default;
$color-light-green: #5aa621 !default;
$color-yellow: #ffdc00 !default;
$color-purple: #002c6f !default;
$color-mid-light-blue: #b9d9eb !default;
$color-sky-light-blue: #dff4ff !default;
$color-off-white-blue: #416e98 !default;
$color-dark-blue: #003357 !default;
$color-sky-blue: #e2f5ff !default;
$color-white-blue: #f8fbfd !default;
$color-dark-blue-70: #7388a4 !default;
$color-off-white: #fdfbf8 !default;
$color-light-gray: #808080 !default;
$color-white-green: #f9fbf6 !default;
$color-off-white-green: #edf4f3 !default;
$color-blue: #395c7e !default;
$accent-color: #003b73 !default;
$accent-color-alt: #00abbd !default;
$accent-color-alt-light: #C9E9ED !default;
$inactive-color: #a2a2a2 !default;
$inactive-color-bg: #cfcfcf !default;
$border-color-alt: #abb0b3 !default;
$border-color: #cfcfcf !default;
$background-color: $color-white !default;
$background-color-alt: $color-white !default;
$page-background-color: $color-white !default;
$link-color: $accent-color-alt !default;
$submenu-link-color: #1d388b !default;
$submenu-link-hover: #162a68 !default;
$header-selector-link-color: #808080 !default;
$header-gradient-color1: #e3ebec !default;
$header-gradient-color2: #ecf4f6 !default;
$menu-submenu-bg-color: #fff !default;
$menu-submenu-border-color: #d9dede !default;
$menu-arrow-border-color: $menu-submenu-border-color;
$menu-item-expand-keyboard-color: $submenu-link-color !default;
$breadcrumb-color: #989ea3 !default;
$testimonials-border-color: transparent !default;
$testimonial-quotation-font-size: 22px !default;
$testimonial-quotation-line-height: 28px !default;
$segment-color: #757575 !default;
$success-color: #85ba35 !default;
$error-color: #f00 !default;
$header-section-title-color: $color-bright-green !default;
$buy-now-button-color: #6c9638 !default;
$title-border-color: #d8dfdf !default;
$color-blue-light: #A5BBD8 !default;

// Spacings
$vspace-lg: 55px !default;
$vspace-md: 35px !default;
$vspace-sm: 20px !default;
$vspace-xs: 10px !default;
$vspace-xxs: 5px !default;

// Z index
$z-index-below: -1;
$z-index-above: 1;
$z-index-high: 2;
$z-index-overlay: 9999;
$z-index-top: 10000;

// Buttons
$btn-background: $accent-color-alt !default;
$btn-background-inverted-hover: #eff6f5 !default;
$btn-font-family: $fontfamily-regular !default;
$btn-border-radius: 3px !default;
$btn-play-background: #fff !default;
$btn-play-background-transparent: rgba(255, 255, 255, 0.75) !default;
$btn-play-pause-background: rgba(0, 0, 0, 0.6) !default;
$btn-padding: 8px 18px 8px !default;
$btn-padding-icon: 55px !default;
$btn-padding-no-icon: 8px 18px 8px !default;
$btn-weight: normal !default;
$btn-icon-right: 16px !default;
$cta-bgcolor: #003b73 !default;
$cta-bg-hover: #003b73 !default;
$cta-button-background-color: $color-white !default;
$cta-button-text-color: $cta-bgcolor !default;
$cta-button-hover-text-color: $cta-button-text-color !default;
$cta-button-hover-background-color: rgba(255, 255, 255, 0.85) !default;
$btn-search-close-icon: 28px !default;
$btn-search-page-close-icon: 20px !default;
$btn-search-icons-position-top: 20px !default;
$btn-search-reset-position-top: 21px !default;
$btn-box-shadow: rgba(0, 0, 0, 0.2) !default;

// Intro
$intro-title-font-size: (def: 48px, sm: 40px, xs: 34px) !default;
$intro-title-line-height: $intro-title-font-size !default;
$intro-product-detail-title-font-size: (def: 35px, md: 31px, xxs: 23px) !default;
$intro-text-font-size: (def: 16px) !default;
$intro-text-font-family: $fontfamily-regular !default;
$intro-line-height: 22px !default;
$intro-text-color: $home-color !default;
$intro-subtitle-color: $home-color !default;
$intro-pretitle-letter-spacing: 0 !default;
$intro-pretitle-fontfamily: $fontfamily-regular !default;

// Crate
$crate-background-color: #fcfbf8 !default;
$crate-border-color: #abb0b3 !default;

// Header
$color-heading: rgba(232, 232, 232, 0.502) !default;
$header-height: 108px !default;
$header-logo-width: 100px !default;
$breadcrumb-hover-color: $home-color !default;
$country-selector-border-color: #e3e3e3 !default;
$header-link-container-wrapper-bg: $color-heading !default;
$header-search-icon-color: #1D388B !default;
$header-link-active-bg: $color-white !default;
$header-link-active-color: #1D388B !default;
$header-search-placeholder-font-family: $fontfamily-regular-bold !default;
$header-item-link-hover: #1D388B !default;
$header-golden-line-color: #B9944B !default;
$icon-cart-color: #1d398f !default;
$header-gray-border-color: #E6E7E8 !default;
$item-sample-title-font-size: (def: 16px) !default;
$item-sample-title-line-height: (def: 18px) !default;
$header-links-color: $logo-color !default;
$header-menu-item-title-scroll-color: $submenu-link-color !default;
$header-menu-item-title-scroll-hover: $submenu-link-hover !default;
$item-sample-bg-color: #1D388B !default;
$header-country-selector-label-font-size: 14px !default;
$header-country-selector-label-line-height: 18px !default;
$header-country-selector-label-color: #666 !default;

// Mobile header
$mobile-header-logo-width: 250px !default;
$mobile-header-height: 60px !default;
$mobile-header-menu-background: $btn-background !default;
$mobile-header-trigger-color: $logo-color !default;
$mobile-menu-trigger-width: 60px !default;
$mobile-header-animation-speed: 0.5s !default;
$mobile-panel-width: 320px !default;
$mobile-menu-expand-icon-width: 16px !default;
$mobile-header-search-close-icon-color: $color-white !default;
$mobile-header-details-position-bottom: unset !default;

// Footer
$footer-fontfamily-bold: $fontfamily-regular-bold !default;
$footer-line-height-paragraph: 22px !default;
$footer-line-height-links: (def: 24px, xs: 20px) !default;
$footer-bg-color: #e6e7e8 !default;
$footer-bg-tena-color: #1d388b !default;
$footer-links-color: #a4afd0 !default;
$footer-toolbar-background-color: $submenu-link-color !default;
$footer-link-hover-color: $color-mid-light-blue !default;
$footer-disclaimer-font-size: (def: 32px, sm: 22px) !default;
$footer-disclaimer-line-height: (def: 36px, sm: 28px) !default;
$footer-disclaimer-heading-font-family: $fontfamily-titles-normal !default;
$footer-disclaimer-heading-color: $submenu-link-color !default;
$footer-disclaimer-footnote-font-size: (def: 16px) !default;
$footer-disclaimer-footnote-line-height: (def: 30px, sm: 22px) !default;
$footer-disclaimer-footnote-color: $home-color !default;
$footer-disclaimer-border-color: $submenu-link-color !default;
$footer-disclaimer-border-radius: 10px !default;
$footer-disclaimer-text-color: #00005A !default;
$footer-disclaimer-font-family: $fontfamily-roboto-regular !default;
$footer-country-selector-label-font-size: 16px !default;
$footer-country-selector-label-line-height: 22px !default;
$footer-country-selector-label-color: #666 !default;
$country-language-selected-color: #fff !default;

// Tabs
$tabs-background: #edf4f3 !default;
$tabs-tab-background-inactive: #e1e8e7 !default;
$tabs-tab-background-active: $color-off-white-green !default;

// Box shadow
$box-shadow-color: rgba(21, 43, 79, 0.2) !default;

/*
*    Components
*/

$component-title-color: $header-section-title-color !default;

// Accordion
$accordion-fontfamily: $fontfamily-titles-normal !default;
$accordion-title-icon-margin: 0 0 0 15px !default;
$content-slide-text-font-size: (def: 16px) !default;
$content-slide-text-line-height: (def: 25px) !default;

// Push Box
$pushbox-padding: (def: 25px, sm: 25px, xs: 20px) !default;
$push-box-bg-dark: #003c73 !default;
$push-box-background-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%) !default;
$push-box-border-radius: 5px !default;
$push-box-border-color-alt: rgba(0, 0, 0, 0.1) !default;
$push-box-title-font: $fontfamily-titles-normal !default;
$push-box-title-transform: uppercase !default;
$push-box-pretitle-transform: uppercase !default;
$btn-push-box-position: "right" !default;
$pushbox-shadow-height: 17px !default;
$promo-box-shadow: 1px 4px 16px 0 $box-shadow-color !default;
$push-box-border-color: #cfcfcf !default;

// Boxes
$boxes-text-align: center !default;
$boxes-text-color: $home-color !default;

// Campaign image
$font-size-campaign-title: 28px !default;
$line-height-campaign-image-title: (def: 32px) !default;
$line-height-campaign-image-subheader: 22px !default;
$line-height-campaign-large-title: (def: 1em) !default;
$campaign-buttons-margin: 8px !default;
$line-height-campaign-image-title-large: (def: 1em) !default;
$campaign-light-background-color: #1D388B !default;

// CTA
$cta-title-font: $fontfamily-header !default;
$cta-title-transform: uppercase !default;
$cta-padding: (def: 43px 0, md: 25px 0, xs: 30px 0, xxs: 20px) !default;
$cta-spacing: 20px !default;
$cta-btn-font-size: 20px !default;

// Table
$table-row-even-color: #f9f6f2 !default;
$table-row-odd-color: #f3f1ee !default;
$table-light-border-color: #3b7dae !default;
$table-filter-active-color: $home-color !default;
$table-filter-controls-color: $segment-color !default;
$table-filter-applied-filter-bg-color: #E7EFF6 !default;

// Forms
$form-field-disabled-color: #cfcfcf !default;
$input-search-color: #939598 !default;
$input-search-placeholder-color-active: $input-search-color !default;
$input-search-placeholder-color: #1d388b !default;
$input-search-border-color: #e6e7e8 !default;
$input-search-border-color-active: #1d388b !default;
$input-sample-border: #939598 !default;

// Question box
$question-box-fontfamily: $fontfamily-regular-bold !default;

// Pagination
$pagination-selected-background-color: #83bb26 !default;
$pagination-hover-background-color: #f9f6f2 !default;
$pagination-selected-hover-background-color: #77aa23 !default;
$pagination-arrow-icon-color: #303030 !default;

// Caption
$caption-background-transparent: rgba(0, 60, 115, 0.8) !default;
$caption-background: rgb(0, 60, 115) !default;

// Promo
$promo-font: $fontfamily-titles-thin !default;
$promo-box-shadow: 1px 4px 16px 0 $box-shadow-color !default;

// Landing
$landing-background-color: #000 !default;

// Product promo
$product-promo-image-max-width: (def: 345px, md: 180px) !default;

// Product box
$product-badge-small-background: #97CB64 !default;
$product-box-background: #F9F9F9 !default;
$product-text-color: $home-color !default;
$product-box-text-margin: 0 !default;
$product-sample-font-size: (def: 12px, xs: 10px) !default;
$product-sample-line-height: (def: 13px, xs: 11px) !default;
$product-paragraph-line-height: (def: 18px) !default;
$product-box-shadow: rgba(21,43,79,0.2) !default;
$product-box-image-left-font-size: 18px !default;
$product-box-details-btn-font-size: 14px !default;
$product-box-details-btn-line-height: 18px !default;

// Sample order
$product-box-sample-background: $product-box-background !default;
$product-box-sample-text-color: $home-color !default;
$product-box-sample-button-font-size: 16px !default;
$product-box-sample-button-line-height: 22px !default;
$product-box-sample-button-font-family: $fontfamily-regular-bold !default;
$product-box-sample-select-border-color: $home-color !default;
$product-box-sample-button-background: $logo-color !default;
$sample-ordered-product-border-color: #E6E7E8 !default;
$sample-ordered-note-border-color: #E6E7E8 !default;
$sample-newsletter-bg: transparent !default;
$sample-newsletter-title-color: #003A81 !default;
$sample-newsletter-title-letter-spacing: 0px !default;
$sample-selected-info-bg-color: #F9F9F9 !default;
$sample-selection-border-color: $home-color !default;
$sample-order-category-bg-color: #003A81 !default;
$sample-order-category-text-color: $color-white !default;
$sample-order-category-btn-bg-color: $color-white !default;
$sample-order-category-btn-color: $color-white !default;
$sample-order-category-btn-fontfamily: $fontfamily-asap-bold !default;
$sample-order-category-text-fontfamily: $fontfamily-asap !default;
$sample-order-category-icon-color: $color-white !default;
$sample-order-section-title: #303030 !default;
$sample-ordered-product-bg-color: #F9F9F9 !default;

// Popup buy now
$popup-product-title-font-size: (def: 36px) !default;
$popup-product-title-line-height: (def: 42px) !default;
$popup-product-title-font-family: $fontfamily-regular !default;
$popup-product-title-color: $accent-color !default;
$popup-product-select-font-size: (def: 18px) !default;
$popup-product-select-line-height: (def: 22px) !default;
$popup-product-select-color: $accent-color !default;
$popup-product-select-font-family: $fontfamily-regular-bold !default;

// Items list
$items-list-heading-font-size: 28px !default;
$items-list-subheading-font-size: 16px !default;
$item-list-icon-height-lg: 100px !default;
$item-list-icon-height-sm: 80px !default;
$items-list-rtf-line-height: 22px !default;
$items-list-common-margin-size: 16px !default;

// Product badge
$product-intro-bg-color: $color-sky-light-blue !default;
$product-intro-font-color: $text-color !default;
$product-promo-bg-color: $color-white !default;
$compare-product-bg-color: #e6e7e8 !default;
$product-carousel-scrollbar-color: #005E94 !default;
$compare-product-type-link-color: #939598 !default;
$compare-product-benefit-text-border-color: #303030 !default;
$compare-panel-box-shadow-color: #152B4F !default;

// Carousel
$carousel-box-control-color: $color-light-blue !default;
$carousel-thumbail-slider-icon: #dcdede !default;
$swiper-pagination-color: transparent !default;
$swiper-pagination-border-color: $logo-color !default;
$swiper-pagination-active-color: $logo-color !default;
$swiper-pagination-active-border-color: $logo-color !default;
$swiper-pagination-color-hover: rgba($color-black, 0.27) !default;
$swiper-pagination-border-color-hover: $logo-color !default;
$swiper-pagination-active-color-hover: $logo-color !default;
$swiper-pagination-active-border-color-hover: $logo-color !default;

// Image Carousel
$image-carousel-arrow-color: #939598 !default;
$image-carousel-thumbnail-bg: #fdfbfa !default;
$image-carousel-thumbnail-bg-alt: rgba(255, 255, 255, 0.6) !default;
$image-carousel-thumbnail-border-color: rgba(128, 158, 167, 0.3) !default;
$image-carousel-background-image: url("../img/bg-carousel.jpg") !default;
$image-carousel-enlarge: url("../img/icon-enlarge.png") !default;
$image-carousel-caption-color: $color-white !default;
$image-carousel-arrow-hover-color: #c1ccd5 !default;
$image-carousel-enlarge-stroke-color: #303030 !default;

// Power reviews
$pr-idle-color: #e6e6e6 !default;
$pr-star-color: #eaa944 !default;
$pr-block-light-color: $header-gradient-color2 !default;
$pr-block-dark-color: #bdcfde !default;
$pr-inactive-color: #5d83a6 !default;
$pr-star-0: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}"/></svg>'
    !default;
$pr-star-25: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="107 45 73 40 58 9 43 40 33 41 33 69 33 69 33 69 33 100 58 87 88 103 82 69 107 45" fill="#{$pr-idle-color}"/><polygon points="33 69 33 69 33 69 33 69" fill="none"/><polygon points="27 103 33 100 33 69 27 103" fill="#{$pr-star-color}"/><polygon points="8 45 33 69 33 41 8 45" fill="#{$pr-star-color}"/><polygon points="33 69 33 69 33 69 33 69" fill="none"/></svg>'
    !default;
$pr-star-50: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="58 87 27 103 33 69 8 45 43 40 58 9 58 87" fill="#{$pr-star-color}"/><polygon points="88 103 58 87 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}"/></svg>'
    !default;
$pr-star-75: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="82 69 107 45 82 41 82 100 88 103 82 69" fill="#{$pr-idle-color}"/><polygon points="73 40 58 9 43 40 8 45 33 69 27 103 58 87 82 100 82 41 73 40" fill="#{$pr-star-color}"/></svg>'
    !default;
$pr-star-100: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-star-color}"/></svg>'
    !default;
$pr-link-color: #1D388B !default;

// Campaign
$campaing-image-h4-padding-bottom: 15px !default;
$campaign-media-title-font: $fontfamily-regular !default;
$letter-spacing-campaign-image-title-large: unset !default;

// Campaign - Media
$campaign-overlay-cta-margin-top: 24px !default;

// Article
$article-list-title-font: $fontfamily-regular-bold !default;
$article-title-font: $fontfamily-regular-bold !default;
$article-h3-font-size: (def: 21px, md: 19px, sm: 18px) !default;
$article-h3-margin: 0 0 7px !default;
$article-h4-margin: 0 0 5px !default;
$article-h4-font-size: (def: 16px, xs: 14px) !default;

$question-box-step-bg-color: $accent-color !default;

// Absorbency
$absorbency-color-drop-style-filled: $home-color !default;
$abosrbency-color-drop-style-empty: $input-search-color !default;

// Product intro frost
$product-intro-badge-bg: #97CB64 !default;
$product-intro-title-font-size: (def: 22px) !default;
$product-intro-title-line-height: (def: 28px) !default;
$product-intro-title-font: $fontfamily-regular !default;
$product-intro-title-color: $color-black !default;
$product-intro-label: $fontfamily-regular-bold !default;
$product-intro-subtitle: $fontfamily-regular-bold !default;
$product-intro-guide-popup-label-color: #007DC5 !default;
$product-intro-badge-font-size: (def: 16px, sm: 13px) !default;
$product-intro-badge-line-height: (def: 17px, sm: 14px) !default;
$product-intro-label-font-size: 16px !default;
$product-intro-label-line-height: (def: 18px, sm: 22px) !default;
$product-intro-ruler-color: #007DC5 !default;
$product-intro-thumbnail-active-border-color: #005890 !default;
$product-intro-panel-close-button: #939598 !default;
$product-intro-panel-header: $fontfamily-regular !default;
$product-intro-thumbnail-slider-icon: #939598 !default;
$product-intro-button-container-color: #D8D8D8 !default;
$product-intro-accordion-border: #E5E5E5 !default;
$product-intro-accordion-title-font-size: (def: 22px, sm: 16px) !default;
$product-intro-accordion-title-line-height: (def: 28px, sm: 22px) !default;
$product-intro-accordion-arrow: #1D388B !default;
$product-intro-accordion-reviews-link-color: #007dc5 !default;
$product-intro-accordion-how-to-content-line-height: 25px !default;
$product-intro-accordion-reviews-panel-header: #333 !default;
$product-intro-accordion-reviews-panel-content: #666 !default;
$product-intro-accordion-reviews-panel-scrollbar: #939598 !default;

// Scrollbar
$scrollbar-background-color: #E6E7E8 !default;
$scrollbar-drag-color: #005E94 !default;

// Product filter
$product-filter-arrow-color: #007dc5 !default;
$product-filter-border-color: #E5E5E5 !default;
$product-filter-single-active-color: #00ABBD !default;

// Filter
$filter-main-text-color: #606D77 !default;
$disable-filters-apply-border-color: #888 !default;
$filter-scrollbar-color: #ababab !default;

// Highlight section
$highlight-section-font-size: 35px !default;
$highlight-section-line-height: 42px !default;

// Sitemap
$sitemap-list-title-font-size: 24px !default;

// Call to action
$cta-background-color: $logo-color !default;
$cta-background-light-color: $color-light-blue !default;

// Dynamic page
$dynamic-page-link-color: #004e9e !default;

// Tena Protects
$protects-pagination-bullet-color: #1D388B !default;
$protects-font-family: $fontfamily-regular;

// Promo Banner
$promo-banner-title-font: $fontfamily-regular !default;
$promo-banner-title-font-size: (def: 50px, sm: 40px) !default;
$promo-banner-title-line-height: (def: 52px, sm: 40px) !default;
$promo-banner-title-letter-spacing: 0 !default;
$promo-banner-title-font-weight: 400 !default;
$promo-banner-desc-font-size: (def: 22px, xs: 16px) !default;
$promo-banner-desc-line-height: (def: 28px, xs: 22px) !default;
$promo-banner-desc-font: $fontfamily-regular !default;
$promo-banner-bg-color: transparent !default;

// Product carousel
$product-carousel-text-font-size: (def: 22px, sm: 16px) !default;
$product-carousel-text-line-height: (def: 28px, sm: 18px) !default;

// Landing promo links
$landing-link-color: #1D388B !default;
$landing-link-font-size: (def: 28px, xs: 16px) !default;
$landing-link-line-height: (def: 22px) !default;

// Product compare popup
$product-compare-popup-product-box-border-color: #939598 !default;
$product-compare-popup-product-box-unselected: #939598 !default;
$product-compare-popup-checkbox-color: #1D388B !default;

// Quotation (Testimonial)
$testimonials-quote-font-size: (def: 40px, sm: 28px) !default;
$testimonials-quote-line-height: (def: 44px, sm: 32px) !default;
$testimonials-quote-font-family: $fontfamily-regular !default;
$testimonials-quote-color: #1D388B !default;
$testimonials-quote-letter-spacing: -1px !default;
$testimonials-quote-info-font-size: 16px !default;
$testimonials-quote-info-line-height: 22px !default;
$testimonials-quote-info-font-family: $fontfamily-regular !default;
$testimonials-quote-info-color: $home-color !default;
$testimonials-full-width-background: transparent !default;
$testimonials-quote-left-img: '../img/quotation/ic-quotation-tena-main-left.png' !default;
$testimonials-quote-right-img: '../img/quotation/ic-quotation-tena-main-right.png' !default;

// Dealer Locator
$dealer-locator-options-bg-color: #1d388b !default;
$dealer-locator-result-selector-btns-border: #00000026 !default;
$dealer-locator-search-results-text-color: #4A4949 !default;
$dealer-locator-search-btn: #f0593d !default;
$dealer-locator-options-color: #333 !default;
$dealer-locator-search-results-bold-color: #0F265C !default;
$dealer-locator-search-results-border-color: #00000026 !default;
$dealer-locator-view-marker-btn-bg: #00add9 !default;

// Country list submenu
$country-language-selected-color: $color-black !default;
$country-language-selected-font-weight: 700 !default;
