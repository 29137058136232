/**
 * Theme overrides for BazaarVoice integration.
 *
 * Since it is impossible to disable default plugin styles that use too specific selectors,
 * the selectors in this file will seem too specific.
 * For now we don't have other options to override these styles
 */

[data-bv-show="rating_summary"] .bv_main_container.bv_main_container {
  display: block !important;
  text-align: center !important;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    @media (max-width: $grid-bp-xs-max) {
      text-align: left !important;
    }
  }
}

@media (min-width: $container-max-width) {
  .product-box-container-image-left [data-bv-show="rating_summary"] .bv_main_container.bv_main_container {
    text-align: left !important;
  }
}

[data-bv-show="rating_summary"] .bv_main_container div.bv_main_container_row_flex.bv_main_container_row_flex {
  display: block !important;

  @if ($theme-name != "leukoplast" and $theme-name != "cutimed") {
    margin-top: 5px !important;
  }
}

.compare-products-item [data-bv-show="inline_rating"] .bv_main_container.bv_inline_rating_container_left {
  justify-content: center !important;
}

[data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex.bv_main_container_row_flex {
  padding-right: 0 !important;
}

.bazaarVoice-left-aligned [data-bv-show="rating_summary"] .bv_main_container.bv_main_container {
  text-align: left !important;
}

@if ($is-tena-theme) {
  .accordion-item [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
  .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
  .product-intro-item [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
  .product-intro-item .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
    display: none !important;
  }

  .accordion-item [data-bv-show="rating_summary"] {
    display: flex;
    width: 100% !important;
  }

  .accordion-item [data-bv-show="rating_summary"] .bv_main_container.bv_main_container {
    width: 100% !important;
  }

  .accordion-item [data-bv-show="rating_summary"] .bv_main_container_row_flex.bv_main_container_row_flex {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100% !important;
  }

  .accordion-item [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 0 !important;
  }

  @media (min-width: $grid-bp-sm) {
    [data-bv-rating] .bv_main_container,
    [data-bv-show="inline_rating"] .bv_main_container,
    .component-product-box [data-bv-show="inline_rating"] .bv_main_container.bv_inline_rating_container_left {
      justify-content: center !important;
    }
  }

  .accordion-item [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
  .accordion-item [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text:hover {
    color: $home-color !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-decoration: none !important;

    @media (min-width: $grid-bp-md) {
      font-size: 22px !important;
    }
  }

  @media (max-width: $grid-bp-xxs) {
    .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
      flex-direction: column !important;
    }
  }

  .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
  .product-intro-item [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    text-decoration: none !important;
  }
}

@if ($theme-name == "actimove") {
  .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
  .product-intro-item [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container {
    display: none !important;
  }

  .product-box [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text,
  .product-intro-item [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    color: $color-primary !important;
  }

  .product-box [data-bv-show="rating_summary"] .bv_main_container.bv_main_container {
    @media (max-width: $grid-bp-xs-max) {
      text-align: left !important;
    }
  }

  .product-box stop[offset="0%"],
  .product-intro-item stop[offset="0%"] {
    stop-color: $pr-star-color !important;
  }

  .product-box stop[offset="1%"],
  .product-intro-item stop[offset="1%"] {
    stop-color: $color-primary-light-blue !important;
  }
}

@if ($theme-name == "women") {
  [data-bv-show="rating_summary"] svg,
  [data-bv-show="rating_summary"] polygon,
  [data-bv-show="inline_rating"] path,
  [data-bv-show="inline_rating"] polygon {
    stroke: $pr-star-stroke-color !important;
    stroke-width: 1px !important;
  }

  .product-box stop[offset="0%"],
  .product-intro-item stop[offset="0%"] {
    stop-color: $pr-star-color !important;
  }

  .product-box stop[offset="1%"],
  .product-intro-item stop[offset="1%"] {
    stop-color: $pr-idle-color !important;
  }
}

@if ($theme-name == "jobst") {
  [data-bv-show="rating_summary"] svg,
  [data-bv-show="rating_summary"] polygon,
  [data-bv-show="inline_rating"] path,
  [data-bv-show="inline_rating"] polygon {
    stroke: $color-primary !important;
    stroke-width: 1px !important;
  }

  .product-box stop[offset="0%"],
  .product-intro-item stop[offset="0%"] {
    stop-color: $color-primary !important;
  }

  .product-box stop[offset="1%"],
  .product-intro-item stop[offset="1%"] {
    stop-color: $color-white !important;
  }
}

.accordion-boxed .accordion-content .accordion-content-inner [data-bv-show="reviews"] {
  margin: 0 -20px !important;
}

.bv_modal_outer_content .bv_button_buttonFull {
  display: none !important;
}
