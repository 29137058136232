/* Dots styling for product absorbency */

.DotStyle {
    border: 1px solid $home-color;
    border-radius: 50px;
    display: inline-block;
    height: 11px;
    width: 11px;
}

.DotStyle.is-halfFilled {
    border-left: 6px solid $home-color;
    border-radius: 10px;
}

.DotStyle.is-filled {
    background-color: $home-color;
}

@if ($is-tena-theme) {
  .DotStyle.is-empty {
    border: 1px solid $abosrbency-color-drop-style-empty;
  }

  .DotStyle.is-halfFilled {
    border: 1px solid $abosrbency-color-drop-style-empty;
    background-color: $absorbency-color-drop-style-filled;
  }

  .DotStyle.is-filled {
    border: 1px solid $absorbency-color-drop-style-filled;
    background-color: $absorbency-color-drop-style-filled;
  }
}