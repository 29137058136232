@if ($theme-name != "leukoplast") {
  .accordion-component
  .accordion-component p {
    @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  }
}

%accordion-base {
  &.accordion-bg-light {
    background: $crate-background-color;
  }

  &.accordion-bg-transparent .accordion-item {
    background: url("../img/box-bg-semitransparent.png");
  }

  .accordion-item {
    @include clearfix();

    @if $theme-name != "leukoplast" {
      margin-bottom: 10px;
    }

    @if ($theme-name == "jobst") {
      background-color: $accordion-item-bg;
      color: $accordion-item-color;
    }

    &.accordion-inactive {
      border-color: $inactive-color-bg;
      color: $inactive-color;
      .accordion-title {
        color: $inactive-color;
        cursor: not-allowed;
        &:hover {
          color: inherit;
        }
      }
      .accordion-content {
        display: none;
      }
      &:hover {
        color: $inactive-color;
      }
    }

    &.accordion-collapsed-xs {
      @include mediaquery(xs) {
        .accordion-content {
          display: none;
        }
      }
    }
  }
  &.accordion-single {
    .accordion-item {
      margin-bottom: 0;
    }
  }
  &.accordion-prepend-arrow {
    .accordion-title-content {
      display: block;
      margin-left: 34px;
    }
    .accordion-title-small {
      .accordion-title-content {
        margin-left: 30px;
      }
    }
  }

  .accordion-content,
  .accordion-title {
    @include mediaquery(xs) {
      padding: 10px;
    }
    padding: 15px;
  }

  .accordion-title:first-child {
    @include font-setup();
  }

  .accordion-content {
    padding-top: 0;
  }

  .accordion-content p:last-of-type {
    margin-bottom: 0;
  }

  .accordion-title {
    @include font-size-and-line-height($font-size-accordion-title, $line-height-accordion-title);

    @if ($theme-name == "cgr") {
      color: $text-color;
    } @else if $theme-name == "leukoplast" {
      background-color: $segment-color-alt;
      color: $color-white;
    } @else if $theme-name == "jobst" {
      text-transform: uppercase;
      letter-spacing: 2px;
    } @else {
      color: $accent-color;
    }
    cursor: pointer;
    font-family: $accordion-fontfamily;
    font-weight: normal;
    height: 100%;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      float: none;
      margin: $accordion-title-icon-margin;
      position: relative;
      top: 2px;
      vertical-align: baseline;
    }
    &.accordion-title-small {
      @include mediaquery(xs) {
        font-size: 14px;
      }
      font-size: 16px;
    }
    .form-field-checkbox {
      margin: 0;
      .field-checkbox-container {
        margin-bottom: 0;
      }
    }
    &:hover {
      @if ($theme-name == "cgr") {
        color: $color-bright-gray;
      } @else if ($theme-name == "jobst") {
        color: unset;
      } @else {
        color: desaturate(lighten($accent-color, 20), 60);
      }
    }
  }

  .accordion-content {
    @include clearfix();

    @if ($theme-name == "jobst") {
      background-color: $color-white;
    }

    .accordion-content-inner {
      @include font-size-and-line-height($font-size-paragraph, $line-height-paragraph);

      transition: all 1s ease-out;
      font-family: $fontfamily-regular;

      @if ($theme-name == "jobst") {
        color: $color-gray;

        @media (max-width: $grid-bp-sm) {
          text-align: center
        }
      }

      @include clearfix();
      a {
        @if $theme-name == "leukoplast" {
          color: $logo-color;
          word-break: break-all;

          &:hover {
            text-decoration: underline;
          }
        } @else if ($theme-name == "actimove") {
          font-family: $fontfamily-regular;
          font-weight: 600;
          color: $color-primary;
          text-decoration: underline;
          line-height: 22px;

          &:hover {
            text-decoration: none;
          }
        } @else {
          font-family: $fontfamily-regular-bold;
          &:hover {
            color: $cta-bg-hover;
          }
        }

        &.btn {
          @if ($is-tena-theme) {
            color: $cta-bgcolor;

            &.buy-now-highlighted {
              color: $color-white;
            }
          } @else {
            color: $color-white;
          }
        }
      }

      @include mediaquery(xs) {
        padding: 0 10px 10px;
        &.with-cols {
          padding: 0 5px 10px;
        }
      }
      padding: 35px 20px;
      &.with-cols {
        padding: 15px 0;
      }
    }
  }
}

.accordion-boxed {
  @if ($theme-name != "leukoplast") {
    @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  }

  @extend %accordion-base;
  .accordion-item {
    @if ($theme-name == "leukoplast") {
      background-color: $accordion-bg-color;
    } @else if($theme-name == "men" or $theme-name == "women" or $theme-name == "cgr") {
      background-color: #fff;
    }
    @if ($theme-name == "cgr" or $theme-name == "women" or $theme-name == "leukoplast" or $theme-name == "jobst") {
      border-radius: 0;
    } @else {
      border-radius: 5px;
    }
    @if $theme-name == "leukoplast" {
      border-bottom: 1px solid $color-white;
    } @else if $theme-name == "jobst" {
      border-bottom: 1px solid $color-white;
      margin-bottom: 0;
    } @else {
      border: 1px solid $border-color;
      margin-bottom: $vspace-sm;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@if ($theme-name != "leukoplast") {
  .accordion-boxed p ,
  .accordion-content-inner p {
    @include font-size-and-line-height($font-size-paragraph, $line-height-boxes-text);
  }
}

.show-more-container {
  margin-top: 25px;

  @media (min-width: $grid-bp-sm) {
    @if ($is-tena-theme) {
      margin-top: 54px;
    }
  }
}

.ProductDetails {
  min-height: 165px;
}
.ContentSlide {
  overflow: hidden;
  @if $theme-name == "leukoplast" {
    height: 110px;
  }

  h3 {
    padding-bottom: 15px;
  }

  ul {
    margin-top: 15px;
  }
}

.Button--AccordionWrap {
  display: none;
  margin-top: 25px;
  position: relative;
  text-align: center;
  &:before {
    border-top: 1px solid #e5e3df;
    bottom: 0;
    content: " ";
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 80%;
    z-index: -1;
    @include mediaquery(sm) {
      display: none;
    }
  }
}

.Button--AccordionWrap .Button--Accordion {
  background-color: $color-white;
  border: 0;
  color: $cta-bgcolor;
  padding: 7px 10px;
  overflow: visible;

  @media (max-width: $grid-bp-xs) {
    padding: 7px 0;
  }
  &:after {
    content: "\e61b";
    font-family: $fontfamily-icon;
    font-size: 14px;
    font-weight: bold;
    left: 5px;
    position: relative;
    top: 2px;
  }
  &:hover {
    text-decoration: none;
    color: $cta-bg-hover;
    background-color: $color-white;
  }
  &:focus {
    border: 0;
  }
  &.slideHide-show {
    &:after {
      content: "\e61d";
    }
  }

  @if ($is-tena-theme) {
    text-align: left;
  }

  @if ($theme-name == "jobst") {
    background-color: $color-light-25;
  }
}

.ProductDetails .Button--AccordionWrap {
  text-align: left;
  padding-left: 1px;
  padding-bottom: 1px;

  &:before {
    display: none;
    border: 0;
  }
}

.ProductDetails .Button--AccordionWrap .Button--Accordion {
  padding: 7px 7px 7px 0;
}

@if $theme-name == "men" {
  .Button--Accordion.btn {
    clip-path: none;
  }

  .ProductDetails .Button--AccordionWrap .Button--Accordion,
  .ProductDetails .Button--AccordionWrap .Button--Accordion::before {
    background-color: transparent;
  }
}

// Disable show more functionality for Leukoplast
@if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
  .ProductDetails .ContentSlide {
    height: initial !important;
  }

  .ProductDetails .Button--AccordionWrap {
    display: none !important;
  }
}

.accordion .component-product-box {
  margin-bottom: 20px;
  margin-top: 20px;
}

@if ($is-tena-theme) {
  .ContentSlide-title,
  .ContentSlide-subtitle {
    @include font-size-and-line-height(28px, 32px);

    padding-bottom: 15px;
    margin: 0;
    font-weight: 400;

    @if ($theme-name == "women") {
      @include font-size-and-line-height($accordion-title-font-size, $accordion-title-line-height);
    }
  }

  .ContentSlide-text {
    @include font-size-and-line-height($content-slide-text-font-size, $content-slide-text-line-height);
  }

  .ContentSlide-title,
  .ContentSlide-subtitle,
  .ContentSlide-text {
    color: $home-color;
    font-family: $fontfamily-regular;
  }

  .ContentSlide-title {
    @if ($theme-name == "men") {
      @include font-size-and-line-height($content-slide-title-font-size, $content-slide-title-line-height);

      font-family: $fontfamily-titles-thin;
    }
  }

  .product-intro-container-frost .ProductDetails .Button--Accordion {
    text-align: left;
  }

  .accordion-item .product-box-view-details {
    @media (max-width: $container-max-width) {
      width: 100%;
    }
  }

  .accordion-item .accordionReview {
    display: flex;
    flex: 1;
  }

  .Button--AccordionWrap {
    text-align: left;
  }

  .Button--AccordionWrap::before {
    border: none;
  }

  .Button--AccordionWrap .Button--Accordion {
    padding: 0;
  }

  .Button--AccordionWrap .Button--Accordion span {
    @include font-size-and-line-height(16px, 22px);

    @if ($theme-name == "women" or $theme-name == "cgr") {
      @include font-size-and-line-height(19px, 19px);
    }
  }

  .product-intro-container-frost .accordion-item {
    border: none;

    @if ($theme-name != "actimove" and $theme-name != "jobst") {
      border-bottom: 1px solid $product-intro-accordion-border;
    }
  }

  .product-intro-container-frost .accordion-title,
  .product-intro-container-frost .accordion-title:first-child {
    @include font-size-and-line-height($product-intro-accordion-title-font-size, $product-intro-accordion-title-line-height);

    font-weight: normal;
    color: unset;
    padding-left: 0;

    @if ($theme-name == "actimove") {
      font-family: $fontfamily-titles-normal;
      font-style: italic;
    } @else if ($theme-name == "jobst") {
      font-family: $fontfamily-regular-bold;
    } @else{
      font-family: $fontfamily-regular;
    }
  }

  .product-intro-container-frost .accordion-content {
    padding-left: 0;
    padding-right: 0;
  }

  @if ($theme-name != "jobst") {
    @media (min-width: $grid-bp-md) {
      .product-intro-container-frost .accordion-boxed {
        margin-inline: 10px;
      }
    }
  }

  @if ($theme-name == "actimove") {
    .product-intro-item .accordion-boxed .accordion-title {
      border-bottom: 1px solid $color-aluminium;
    }

    .product-intro-item .accordion-content {
      margin-top: 15px;
    }

    .accordion-title-text {
      @include font-size-and-line-height(22px, 26px);

      font-family: $fontfamily-titles-normal;
      font-style: italic;
      font-weight: 600;
    }
  }
}

@if $theme-name == "cutimed" {
  .accordion-component .rtf-content,
  .accordion-component .rtf-content p {
    @include font-size-and-line-height(16px, 22px);
  }

  .accordion-boxed .accordion-item {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid $color-blue;
  }

  .accordion-boxed .accordion-title {
    color: $color-gray;
    font-family: $fontfamily-regular-bold;
  }

  .accordion-boxed .accordion-title:hover {
    color: $color-gray;
  }

  .accordion-boxed .accordion-title i {
    float: right;
    background-color: $color-blue;
    padding: 5px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
  }

  .accordion-content-inner div {
    font-family: $fontfamily-regular;
    color: $color-gray;
    @include font-size-and-line-height(16px, 22px);
  }

  .accordion-expanded {
    background-color: $color-light-blue-2;
    transition: 0.5s;
  }

  .accordion-collapsed {
    background-color: $color-white;
    transition: 0.5s;
  }
}

@if ($theme-name == "actimove") {
  .Button--AccordionWrap .Button--Accordion.btn {
    background-image: unset;

    &:after {
      color: $product-intro-icon-color;
      top: 4px;
      left: 10px;
      font-size: 16px;
    }

    &:hover {
      background-image: unset;
    }

    span {
      font-family: $fontfamily-regular;
      font-weight: 700;
      color: $home-color;
    }
  }

  .ContentSlide-text {
    line-height: 22px;
    color: $color-light-black;
  }

  .ContentSlide-title,
  .ContentSlide-subtitle {
    font-family: $fontfamily-titles-normal;
    font-style: italic;

    @media (max-width: $grid-bp-sm-max) {
      @include font-size-and-line-height(22px, 26px);
    }
  }

  .Button--AccordionWrap .Button--Accordion {
    cursor: pointer;
    color: $home-color;

    &::after {
      color: $color-accent;
      font-size: 20px;
      top: 4px;
      left: 8px;
    }

    span {
      font-weight: 600;
    }
  }

  .accordion-component .component-title {
    @include font-size-and-line-height(28px, 32px);

    color: $color-black;
  }

  .accordion-component .border {
    border-bottom: 1px solid $color-aluminium;
  }

  .accordion-component .accordion-boxed .accordion-item {
    border: unset;
    margin-bottom: 0;
  }

  .accordion-boxed .accordion-content,
  .accordion-boxed .accordion-title {
    padding-left: 0;
    padding-right: 0;
  }

  .accordion-component div,
  .accordion-component p {
    color: $color-light-black;
  }

  .accordion-component .accordion-title {
    border-bottom: 1px solid $color-aluminium;
  }

  .accordion-component .accordion-title-text {
    color: $home-color;
  }

  .accordion-component .accordion-content-inner {
    color: $color-light-black;
    padding-bottom: 0;
  }

  .accordion-component .accordion-boxed .accordion-content .accordion-content-inner.with-cols {
    padding-bottom: 0;
  }

  .accordion-component .accordion-content-header {
    @include font-size-and-line-height(16px, 22px);

    font-family: $fontfamily-regular;
    font-weight: 600;
    color: $color-black;
    margin-bottom: 5px;
  }
}

@if ($theme-name == "cgr") {
  .ContentSlide-title,
  .ContentSlide-subtitle {
    @include font-size-and-line-height($accordion-title-font-size, $accordion-title-line-height);

    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $color-heading;
  }
}

@if ($theme-name == "jobst") {
  .accordion-media {
    width: 100%;
    text-align: center;

    @media (min-width: $grid-bp-sm) {
      width: max-content;
    }
  }

  .accordion-media-img {
    width: 100px;
    height: 100px;
  }

  .accordion-container {
    @include font-size-and-line-height(18px, 26px);

    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 300;
    color: $accordion-description-color;

    @media (min-width: $grid-bp-sm) {
      flex-direction: row;
      text-align: left;
    }
  }

  .Button--AccordionWrap {
    margin-top: 18px;
  }

  .Button--AccordionWrap .Button--Accordion {
    color: $color-primary;
    background-color: transparent;

    span {
      @include font-size-and-line-height(18px, 26px);
    }

    &:after {
      color: $color-primary-light;
      font-size: 16px;
      font-weight: normal;
    }

    &:hover {
      border: none;
      color: $color-primary;
      background-color: transparent;

      &:after {
        color: $color-primary-light;
      }
    }
  }

  .product-intro-container-frost .accordion-item {
    background-color: unset;
    // Negative values ​​are used to anulate paddings
    margin-inline: -5px;

    @media (min-width: $grid-bp-xs) {
      margin-inline: -10px;
    }
  }

  .product-intro-container-frost .accordion-boxed .accordion-title {
    text-transform: none;
    letter-spacing: unset;
    padding: 10px 0;
    border-bottom: 1px solid $color-light;
  }

  .product-intro-container-frost .accordion-boxed .accordion-title-text {
    @include font-size-and-line-height(22px, 26px);

    font-family: $fontfamily-regular-bold;
  }

  .show-more-container {
    margin-top: 10px;

    @media (min-width: $grid-bp-sm) {
      margin-top: 32px;
    }
  }

  .ContentSlide-title {
    @include font-size-and-line-height($accordion-title-font-size, $accordion-title-line-height);

    font-family: $fontfamily-regular-bold;
  }

  h1.ContentSlide-title {
    margin-top: 10px;

    @media (min-width: $grid-bp-md) {
      margin-top: 28px;
    }
  }

  h3.ContentSlide-title {
    @include font-size-and-line-height($accordion-title-small-font-size, $accordion-title-small-line-height);
  }

  .ContentSlide-text,
  .ContentSlide .text-content,
  .ContentSlide .text-content p,
  .ContentSlide .text-content ul li {
    @include font-size-and-line-height(18px, 26px);

    font-family: $fontfamily-regular-light;
    color: $color-gray;
  }

  .accordion-content .accordion-content-text {
    font-family: $fontfamily-regular-light;
  }

  .accordion-boxed .accordion-content {
    background-color: transparent;
  }
}