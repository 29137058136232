.component-product-intro-right {
    .product-intro-item {
        padding: 35px 0;

        .second-column {
            padding-top: 5px;
        }

        .product-intro-carousel {
            @if ($theme-name == "cutimed" or $theme-name == "leukoplast") {
              @include mediaquery(sm) {
                  padding-bottom: 20px;
              }
            }

            .product-intro-cps {
              text-align: center;
              margin-top: 10px;
              color: $home-color;
              font-style: italic;
              font-family: $fontfamily-regular-italic;
            }
        }

        .LandingPage-productContainer {
            padding-top: 70px;
            @include mediaquery(sm) {
                padding-top: 0;
            }
        }

        .image-carousel {
            margin: 0 auto;
        }

        .ProductContent {
            padding-left: 20px;

            @media (max-width: $grid-bp-sm) {
              padding-left: 10px;

              @if ($theme-name == "cutimed") {
                text-align: center;

                .ProductDetails ul {
                  text-align: center;
                  list-style-position: inside;
                }
              }
            }

            .product-title {
                @if ($theme-name == "men" or $theme-name == "com" or $theme-name == "cutimed") {
                    font-family: $fontfamily-header;
                } @else {
                    font-family: $fontfamily-regular-light;
                }
                @include font-size($intro-product-detail-title-font-size);

                @if ($theme-name != "leukoplast") {
                    line-height: 1.2;
                    text-transform: none;
                }

                @include mediaquery(sm) {
                    @if ($theme-name == "com") {
                        font-size: 24px;
                    }
                }
                font-weight: normal;
                letter-spacing: normal;

                &.uppercase_title {
                    text-transform: uppercase;
                }
            }

            .text-link {
                @if ($theme-name == "cgr" or $theme-name == "women") {
                    color: $accent-color;
                } @else {
                    color: #fff;
                }
                display: block;
                font-family: $fontfamily-regular-bold;
                margin-bottom: 5px;

                &:hover {
                    @if ($theme-name == "cgr" or $theme-name == "women") {
                        color: darken($accent-color, 20);
                    }
                    text-decoration: underline;
                }
            }

            .top-heading {
                @include mediaquery(xxs) {
                    margin-bottom: 0;
                    margin-top: 0;
                }
                margin-top: 0;
                padding-top: 0;

                &.main-heading {
                  @if ($theme-name == "cutimed") {
                    margin-bottom: 25px;

                    @media (min-width: $grid-bp-md) {
                      margin-bottom: 35px;
                    }
                  } @else {
                    margin-bottom: 10px;
                  }
                }
            }
          @if $theme-name == "leukoplast" {
            display: flex;
            flex-direction: column;
          }
        }
    }
    .LandingPage-container {
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        margin-bottom: 40px;
        overflow: hidden;
        position: relative;

        p,
        .form a {
            color: #fff;
        }
        .top-heading {
            color: #fff;
        }
        @if ($theme-name == "cgr") {
            color: #fff;
            p {
                color: #fff;
            }
            .form a {
                color: $color-dark-green;
            }
            &.product-intro-item .top-heading {
                color: #fff;
            }
        }

        &.SectionColor--dark {
            color: $home-color;

            p,
            .form a,
            &.product-intro-item .top-heading {
                color: $home-color;
            }
        }

        .LandingPage-image {
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .form-inner-button {
            @media (max-width: $grid-bp-sm) {
                margin-right: 0;
            }
            .btn {
                @media (max-width: $grid-bp-sm) {
                    width: 100%;
                }
            }
        }
    }
}

@if $theme-name == "leukoplast" {
  .product-intro-item .text-content li {
    list-style: none;
    margin-left: 1em;

    &::before {
      content: "-";
      display: inline-block;
      margin-left: -1em;
      width: 1em;
    }
  }

  @media (min-width: $grid-bp-md) {
    .product-intro-item > .row {
      display: flex;
    }
  }
}

@if ($theme-name == "cutimed") {
  .product-intended-purpose {
    margin-top: 30px;
  }

  .product-intended-purpose-title {
    font-family: $fontfamily-regular-bold-condensed;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .product-intro-item-container {
    background-color: $color-light-blue-2;
  }

  .product-buttons .btn {
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: $grid-bp-xs-max) {
    .product-buttons {
      padding-left: 50px;
      padding-right: 50px;
    }

    .product-buttons .btn {
      margin-bottom: 25px;
      margin-top: 0;
    }
  }

  @media (max-width: $grid-bp-sm-max) {
    .component-product-intro-right .product-intro-item {
      padding-top: 15px;
    }
  }

  @media (min-width: $grid-bp-sm) {
    .product-buttons {
      display: flex;
    }

    .product-buttons .btn {
      flex: 1;
    }
  }
}
