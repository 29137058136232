@import "../../../node_modules/select2/src/scss/core.scss";

@if ($theme-name != "actimove") {
  .select2 {
    width: 100% !important;
  }
}

.select2-container--default .select2-results__group {
  color: #939393;
  font-family: $fontfamily-regular-italic;
  font-size: 10px;
  font-style: italic;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  @if ($theme-name == "actimove") {
    background-color: $color-primary;
  } @else if ($theme-name == "jobst") {
    color: $color-gray;
  } @else if ($theme-name == "cgr") {
    background-color: $segment-color;
  } @else if ($theme-name == "women") {
    background-color: $color-blue;
  } @else {
    background-color: $accent-color;
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border: 1px solid $accent-color;
}

// OptionGroup in dropdown
.select2-container.select2-container--default .select2-results__group {
  color: $color-black;
  font-style: normal;

  @if $theme-name == "cutimed" {
    font-size: 17px;
  } @else if $theme-name == "actimove" {
    display: none;
  } @else {
    font-size: inherit;
  }
}

.page-search-results .select2-container--default .select2-selection--single {
  background-color: $color-white;
  border: 1px solid $input-search-border-color;
  border-radius: 3px;
  height: 38px;
  padding: 3px 0;
}

.page-search-results .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $home-color $home-color;
  border-width: 0 0 2px 2px;
  left: 0;
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;

  @if ($theme-name == "leukoplast") {
    top: 18px;
  } @else {
    top: 12px;
  }
}

.sample-product-box  .select2.select2-container--default {
  border: 1px solid $product-box-sample-select-border-color;
  border-radius: 4px;
}

.sample-product-box .select2-container--default .select2-selection--single {
  display: flex;
  align-items: center;
  min-height: 38px;
  background-color: transparent;
}

.sample-product-box .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $product-box-sample-text-color;
  font-size: 16px;
  line-height: 22px;
}

.sample-product-box .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 8px;
  right: 10px;
}

.sample-product-box .select2-container--default .select2-selection--single .select2-selection__arrow b,
.sample-product-box .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b  {
  border-color: $product-box-sample-text-color;
  padding: 4px;
}

.sample-product-box .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  margin-top: -4px;
}

@if ($theme-name == "leukoplast" or $theme-name == "cutimed") {
  .select2-dropdown {
    border-radius: 0;
  }

  .validate:not(.invalid) .select2-selection--single:focus .select2-selection__rendered {
    border: 1px solid $accent-color;
  }

  .select2-container--default .select2-selection--single {
    border: transparent;
    border-radius: 0;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b,
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: solid #5c6e78;
    border-width: 0 1px 1px 0;
    display: inline-block;
    margin-left: -17px;
    margin-top: -15px;
    padding: 8px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: rotate(45deg);
  }

  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    margin-top: -5px;
    transform: rotate(-135deg);
  }
}

@if $theme-name == "leukoplast" {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $segment-color-alt;
  }

  .page-search-results .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 50px;
  }
}

@if $theme-name == "cutimed" {
  .select2-container--default .select2-results__option .select2-results__option--highlighted[aria-selected] {
    color: $color-white;
  }

  .select2-container--default .select2-results__option .select2-results__option {
    color: $color-gray;
    font-size: 17px;
  }

  .form .select2-container--default .select2-selection--single {
    border: 1px solid $border-color-dropwdown;
    border-radius: 3px;
    height: 36px;
  }

  .form .select2-selection--single .select2-selection__arrow b {
    border-color: $color-gray;
    margin-left: -10px;
    margin-top: -2px;
    padding: 4px;
  }

  .form .select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: $color-gray;
    margin-left: -10px;
    margin-top: 2px;
    padding: 4px;
  }

  .form .select2-selection--single .select2-selection__rendered {
    padding-top: 8px;
    line-height: 17px;
  }

  .form .validate:not(.invalid) .select2-selection--single:focus .select2-selection__rendered {
    border: none;
  }

  .page-search-results .select2-container--default .select2-selection--single {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .page-search-results .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $color-gray;
    line-height: 19px;
  }

  .page-search-results .select2-container--default .select2-selection--single .select2-selection__arrow b {
    top: 30px;
    left: 10px;
    padding: 4px;
    border-width: 0 0 1px 1px;
  }

  .page-search-results .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    margin-top: -8px;
    border: solid $color-gray;
    border-width: 0 1px 1px 0;
  }

  .page-search-results .select2-container--default .select2-results__option[aria-disabled=true] {
    color: $input-search-placeholder-color-active;
  }

  .select2-container--default .select2-results>.select2-results__options {
    box-shadow: 1px 1px 4px 0 rgba($color-black, 0.5);
  }

  .sample-product-box .select2-selection--single .select2-selection__rendered {
    padding-top: unset;
  }

  .sample-product-box .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 2px;
    right: 3px;
  }

  .sample-product-box .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    margin-top: 2px;
  }
}

@if $theme-name == "men" {
  .select2-container--default .select2-selection--single {
    border: transparent;
  }

  .select2-container--open .select2-dropdown--below {
    border: transparent;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b,
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: solid $color-heading;
    border-width: 0 2px 2px 0;
    display: inline-block;
    margin-left: -10px;
    margin-top: -10px;
    padding: 6px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: rotate(45deg);
  }

  .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    margin-top: 0;
    transform: rotate(225deg);
  }

  .select2-container.select2-container--default .select2-results__group {
    color: $color-black;
    padding: 20px 16px;
    text-transform: uppercase;
  }

  .compare-products .select2-container.select2-container--default .select2-results__group {
    font-family: $fontfamily-regular-bold;
    padding: 6px 16px 20px;
  }

  .select2-search--dropdown .select2-search__field,
  .select2-container--default .select2-results__option[aria-disabled="true"] {
    display: none;
  }

  .select2-container--default .select2-results__option .select2-results__option {
    color: $color-purple;
    line-height: 24px;
    margin-left: 8px;
    padding: 8px;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $color-yellow;
    word-break: break-word;
    color: $color-purple;
  }

  .select2-results {
    background-color: $color-white;
    border-radius: 3px;
    box-shadow: 0 0 9px $box-shadow-color;
  }

  .select2-container--default .select2-results > .select2-results__options {
    max-height: 420px;
  }

  .compare-products .select2-container--default .select2-results__option:last-child {
    margin-left: 8px;
    padding: 8px;
    margin-bottom: 12px;
    color: $color-purple;
  }

  .compare-products .select2-container--default .select2-results__option--highlighted:last-child {
    color: $color-purple;
  }

  @media (min-width: $grid-bp-md) {
    .select2-container--default .select2-results > .select2-results__options {
      max-height: 560px;
    }
  }

  .select2-results__options::-webkit-scrollbar {
    width: 16px;
  }

  .select2-results__options::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #d8d8d8;
    border: 5px solid transparent;
    border-radius: 8px;
  }
}

@if ($theme-name == "actimove") {
  .select2-container--default .select2-selection--single .select2-selection__arrow b,
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: solid $color-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    margin-left: -15px;
    margin-top: -10px;
    padding: 5px;
  }

  .select2-search--dropdown {
    background-color: $color-primary-light-blue;
    border-radius: 3px;
  }

  .component-form .select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: rotate(45deg);
  }

  .component-form .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    margin-top: -2px;
    transform: rotate(-135deg);
  }

  .select2-results {
    background-color: $color-primary-light-blue;
  }

  .select2-results__option {
    padding: 10px;
    border-bottom: 1px solid $form-dropdown-border-color;
    color: $color-black;
  }

  .select2-container--default .select2-results__option .select2-results__option {
    padding-left: 5px;
  }

  .select2-container--default .select2-results>.select2-results__options {
    max-height: 250px;
  }

  .page-search-results .select2-container {
    width: 100% !important;
  }

  .page-search-results .select2-container--default .select2-selection--single {
    border-color: $color-aluminium;
  }

  .page-search-results .select2-selection--single .select2-selection__rendered {
    line-height: 22px;
    padding-top: 5px;
    padding-left: 12px;
    color: $home-color;
  }

  .page-search-results .select2-selection--single .select2-selection__arrow {
    top: 6px;
    right: -8px;
    color: $color-aluminium;
  }

  .buy-now-wrapper .validate-select .select2-container {
    width: 100% !important;
  }

  .buy-now-wrapper .validate-select .select2-selection--single {
    border-color: $color-aluminium;
    border-radius: 3px;
    height: 40px;
    padding-top: 8px;
  }

  .buy-now-wrapper .validate-select .select2-selection--single .select2-selection__rendered {
    color: $home-color;
    line-height: 22px;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -2px;
    transform: rotate(45deg) translate(-50%, -50%);
    border-color: $color-aluminium;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    transform: rotate(225deg);
  }
}

@if ($theme-name == "cgr" or $theme-name == "women") {
  .select2.select2-container {
    border: 1px solid rgba($color-black, 0.2);
    border-radius: 5px;
  }

  .select2-container--default .select2-selection--single,
  .compare-products-filters .select2.select2-container {
    border: unset;
  }

  .select2-container--default {
    display: inline-block;
    align-items: center;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b,
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: solid $compare-arrow-color;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: rotate(45deg) translate(-50%, -50%);
  }

  .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    margin-top: 0;
    transform: rotate(225deg);
  }
}

@if ($theme-name == "jobst") {
  .select2-results__option {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
  }

  .buy-now-wrapper .validate-select .select2-selection--single {
    border-color: $color-light;
    border-radius: 3px;
    height: 40px;
    padding-top: 8px;
  }

  .buy-now-wrapper .validate-select .select2-selection--single .select2-selection__rendered {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
    line-height: 22px;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: solid $color-primary;
    border-width: 0 1px 1px 0;
    margin-left: -15px;
    margin-top: -2px;
    padding: 5px;
    transform: rotate(45deg) translate(-50%, -50%);
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    transform: rotate(225deg);
  }

  .page-search-results .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $color-gray;
    font-family: $fontfamily-regular-light;
    font-size: 18px;
    line-height: 26px;
    padding-top: 2px;
  }

  .page-search-results .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $color-aluminium $color-aluminium;
  }

  .page-search-results .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    top: 16px;
    transform: rotate(135deg);
  }

  .select2-container--default .select2-results__option[aria-disabled=true] {
    color: $color-gray-50;
  }
}

@if ($theme-name == "leukoplast") {
  .buy-now-wrapper .validate-select .select2-selection--single {
    background-color: $popup-product-select2-background;
    height: 40px;
    padding-top: 8px;
  }

  .buy-now-wrapper .validate-select .select2-selection--single .select2-selection__rendered {
    color: $segment-color-alt;
    font-size: 16px;
    line-height: 22px;
  }

  .buy-now-wrapper .validate:not(.invalid) .select2-selection--single:focus .select2-selection__rendered {
    border: unset;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: solid $segment-color-alt;
    border-width: 0 1px 1px 0;
    margin-left: -15px;
    margin-top: -2px;
    padding: 5px;
    transform: rotate(45deg) translate(-50%, -50%);
  }

  .buy-now-wrapper .select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    transform: rotate(225deg);
  }
}
